var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./clojure.walk.js");require("./medley.core.js");require("./metabase.mbql.util.js");require("./metabase.mbql.util.match.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var vY,Xia,xY,zY,AY,Yia,CY,DY,uY,EY,Zia,$ia,aja,bja,HY,IY,KY,LY,MY,NY,OY,PY,wY,dja,eja,SY,fja,TY,gja,VY,hja,ija,jja,kja,lja,rY;$CLJS.sY=function(a,b){if("string"===typeof b)return rY(a,b);throw new TypeError("re-seq must match against a string.");};$CLJS.tY=function(a,b,c){var d=$CLJS.Zl(a,b);if($CLJS.n(d)){var e=$CLJS.S.j;d=$CLJS.zb(d);c=c.h?c.h(d):c.call(null,d);a=e.call($CLJS.S,a,b,c)}return a};
vY=function(a){var b=uY;return $CLJS.vC(function(c){return function(d,e,f){e=b.h?b.h(e):b.call(null,e);return c.j?c.j(d,e,f):c.call(null,d,e,f)}},a)};Xia=function(a){var b=wY;return $CLJS.vC(function(c){return function(d,e,f){f=b.h?b.h(f):b.call(null,f);return c.j?c.j(d,e,f):c.call(null,d,e,f)}},a)};xY=function(a){if($CLJS.md(a))return a;throw $CLJS.ai("Invalid pattern: don't know how to handle symbol.",new $CLJS.h(null,1,[$CLJS.yi,a],null));};
$CLJS.yY=function(a,b,c){return $CLJS.wd(c)?$CLJS.Wf.g(c,function(){return function f(e){return new $CLJS.le(null,function(){for(;;){var k=$CLJS.z(e);if(k){if($CLJS.zd(k)){var l=$CLJS.kc(k),m=$CLJS.E(l),t=$CLJS.oe(m);return function(){for(var A=0;;)if(A<m){var D=$CLJS.kd(l,A),J=$CLJS.H(D,0,null),M=$CLJS.H(D,1,null);D=t;var V=$CLJS.R,Z=J;J=$CLJS.$d.g(b,J);J=a.g?a.g(J,M):a.call(null,J,M);D.add(new $CLJS.Q(null,2,5,V,[Z,J],null));A+=1}else return!0}()?$CLJS.re($CLJS.te(t),f($CLJS.lc(k))):$CLJS.re($CLJS.te(t),
null)}var u=$CLJS.B(k),v=$CLJS.H(u,0,null),x=$CLJS.H(u,1,null);return $CLJS.ce(new $CLJS.Q(null,2,5,$CLJS.R,[v,function(){var A=$CLJS.$d.g(b,v),D=x;return a.g?a.g(A,D):a.call(null,A,D)}()],null),f($CLJS.Kc(k)))}return null}},null,null)}(c)}()):$CLJS.vd(c)?$CLJS.Gk.g($CLJS.Me(a,$CLJS.B(c)instanceof $CLJS.K?$CLJS.$d.g(b,$CLJS.B(c)):b),c):c};zY=function(a){var b=(b=a instanceof $CLJS.K)?$CLJS.ge(a):b;return $CLJS.n(b)?[$CLJS.ge(a),"/",$CLJS.Zg(a)].join(""):$CLJS.Zg(a)};
AY=function(a,b){var c=$CLJS.vd(b)&&!$CLJS.qf(b)&&$CLJS.B(b)instanceof $CLJS.K;return c?$CLJS.td(a)?(b=$CLJS.B(b),a=$CLJS.Yg(a),a.h?a.h(b):a.call(null,b)):$CLJS.F.g(a,$CLJS.B(b)):c};
Yia=function(a,b){return $CLJS.$e(function(c){var d=$CLJS.bf;a:try{if($CLJS.yd(c)&&1<=$CLJS.E(c))try{var e=$CLJS.Lk.j(c,0,1);if($CLJS.yd(e)&&1===$CLJS.E(e))try{var f=$CLJS.bd(e,0);if($CLJS.n($CLJS.Me($CLJS.F,a)(f))){var k=$CLJS.Lk.g(c,1);var l=new $CLJS.Q(null,1,5,$CLJS.R,[k],null)}else throw $CLJS.TW;}catch(t){if(t instanceof Error){var m=t;if(m===$CLJS.TW)throw $CLJS.TW;throw m;}throw t;}else throw $CLJS.TW;}catch(t){if(t instanceof Error){m=t;if(m===$CLJS.TW)throw $CLJS.TW;throw m;}throw t;}else throw $CLJS.TW;
}catch(t){if(t instanceof Error){m=t;if(m===$CLJS.TW){l=new $CLJS.Q(null,1,5,$CLJS.R,[new $CLJS.Q(null,1,5,$CLJS.R,[c],null)],null);break a}throw m;}throw t;}return $CLJS.B($CLJS.z(d($CLJS.Ta,l)))},$CLJS.G([b]))};
CY=function(a,b){for(;;)switch(b=$CLJS.Yk.h($CLJS.bf($CLJS.Ta,b)),$CLJS.E(b)){case 0:return null;case 1:return a=$CLJS.B(b),BY.h?BY.h(a):BY.call(null,a);default:if($CLJS.n($CLJS.Je($CLJS.Me(AY,a),b))){var c=a;b=Yia(a,b);a=c}else{c=$CLJS.Ze.g(BY,b);if($CLJS.F.g(c,b))return $CLJS.Wf.g(new $CLJS.Q(null,1,5,$CLJS.R,[a],null),b);b=c}}};DY=function(a){var b=$CLJS.vd(a);return b?(b=!$CLJS.qf(a))?$CLJS.pC($CLJS.ee,$CLJS.Va)($CLJS.B(a)):b:b};
uY=function(a){return $CLJS.n($CLJS.pC($CLJS.ee,$CLJS.Va)(a))?$CLJS.rh.h($CLJS.tC(zY(a).toLowerCase(),/_/,"-")):a};EY=function(a,b){var c=DY(b);return $CLJS.n(c)?(b=uY($CLJS.B(b)),$CLJS.td(a)?(a=$CLJS.Yg(a),a.h?a.h(b):a.call(null,b)):$CLJS.F.g(a,b)):c};Zia=function(a){return $CLJS.I.j(new $CLJS.h(null,3,[$CLJS.Wh,$CLJS.Rd,$CLJS.dj,uY,$CLJS.rP,uY],null),a,function(b){var c=$CLJS.I,d=c.g;b=$CLJS.Ce([a,b]);b=FY.g?FY.g(b,GY):FY.call(null,b,GY);return d.call(c,b,a)})};
$ia=function(a){a=$CLJS.Wf.j($CLJS.N,$CLJS.Ze.h(function(b){var c=$CLJS.H(b,0,null);b=$CLJS.H(b,1,null);c=uY(c);var d=Zia(c);return new $CLJS.Q(null,2,5,$CLJS.R,[c,d.h?d.h(b):d.call(null,b)],null)}),a);return $CLJS.F.g($CLJS.dj.h(a),$CLJS.KN)&&$CLJS.Sa($CLJS.rP.h(a))?$CLJS.S.j(a,$CLJS.rP,$CLJS.oI):a};aja=function(a){return $CLJS.Wf.j($CLJS.N,$CLJS.Ze.h(function(b){var c=$CLJS.H(b,0,null);b=$CLJS.H(b,1,null);c=zY(c);return new $CLJS.Q(null,2,5,$CLJS.R,[c,$CLJS.S.j($ia(b),$CLJS.T,c)],null)}),a)};
bja=function(a){a=FY.h?FY.h(a):FY.call(null,a);return wY.h?wY.h(a):wY.call(null,a)};HY=function(a){return $CLJS.tY($CLJS.tY($CLJS.ab(function(b,c){return $CLJS.tY(b,c,$CLJS.rh)},a,new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.UE,$CLJS.Kca,$CLJS.eQ,cja,$CLJS.RB,$CLJS.ki],null)),$CLJS.UD,bja),$CLJS.tP,$CLJS.XW)};IY=function(a){return $CLJS.Ed(a)?new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.UH,a,null],null):a};KY=function(a){return JY.h(IY(a))};
LY=function(a){var b=$CLJS.z(a);a=$CLJS.B(b);b=$CLJS.C(b);return BY($CLJS.Wf.g(new $CLJS.Q(null,1,5,$CLJS.R,[a],null),$CLJS.Ze.g(JY,b)))};MY=function(a){var b=$CLJS.z(a);a=$CLJS.B(b);var c=$CLJS.C(b);b=$CLJS.B(c);c=$CLJS.C(c);return $CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[a,KY(b)],null),$CLJS.Ze.g(JY,c))};NY=function(a){var b=$CLJS.H(a,0,null);a=$CLJS.H(a,1,null);return $CLJS.n(a)?new $CLJS.Q(null,2,5,$CLJS.R,[b,KY(a)],null):new $CLJS.Q(null,1,5,$CLJS.R,[b],null)};
OY=function(a){var b=$CLJS.H(a,0,null);a=$CLJS.H(a,1,null);return new $CLJS.Q(null,2,5,$CLJS.R,[b,KY(a)],null)};PY=function(a){var b=$CLJS.H(a,0,null);a=$CLJS.H(a,1,null);return new $CLJS.Q(null,2,5,$CLJS.R,[b,JY.h(a)],null)};
wY=function(a){return QY(function(b){if($CLJS.wd(b))return Xia(b);if($CLJS.Sa(DY(b)))return b;try{return JY.h(b)}catch(f){if(f instanceof Error){var c=f,d=$CLJS.zC($CLJS.tA);if($CLJS.n($CLJS.yC("metabase.mbql.normalize",d))){var e=$CLJS.vG("Invalid clause:");e instanceof Error?$CLJS.xC("metabase.mbql.normalize",d,$CLJS.fy.l($CLJS.G([b])),e):$CLJS.xC("metabase.mbql.normalize",d,$CLJS.fy.l($CLJS.G([e,b])),null)}throw $CLJS.$h($CLJS.WF("Invalid MBQL clause: {0}",$CLJS.G([$CLJS.UW(c)])),new $CLJS.h(null,
1,[$CLJS.AT,b],null),c);}throw f;}},a)};dja=function(a){return $CLJS.uf($CLJS.Wl(wY,RY(a)))};eja=function(a){for(;;)if($CLJS.n(DY(a)))a=new $CLJS.Q(null,1,5,$CLJS.R,[a],null);else return $CLJS.De($CLJS.Gk.g(IY,a))};SY=function(a){a=$CLJS.O(a);var b=$CLJS.I.g(a,$CLJS.KN);return $CLJS.n(b)?$CLJS.Hk.j(a,$CLJS.KN,JY):a};
fja=function(a){return $CLJS.Wf.g($CLJS.N,function(){return function d(c){return new $CLJS.le(null,function(){for(;;){var e=$CLJS.z(c);if(e){if($CLJS.zd(e)){var f=$CLJS.kc(e),k=$CLJS.E(f),l=$CLJS.oe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m),u=$CLJS.H(t,0,null);t=$CLJS.H(t,1,null);u=new $CLJS.Q(null,2,5,$CLJS.R,[u,SY(t)],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.re($CLJS.te(l),d($CLJS.lc(e))):$CLJS.re($CLJS.te(l),null)}f=$CLJS.B(e);l=$CLJS.H(f,0,null);f=$CLJS.H(f,1,null);return $CLJS.ce(new $CLJS.Q(null,
2,5,$CLJS.R,[l,SY(f)],null),d($CLJS.Kc(e)))}return null}},null,null)}(a)}())};TY=function(a){a=$CLJS.O(a);var b=$CLJS.I.g(a,$CLJS.hS);return $CLJS.n(b)?$CLJS.Hk.j(a,$CLJS.hS,fja):a};gja=function(a){var b=$CLJS.O(a);a=$CLJS.I.g(b,$CLJS.KR);b=$CLJS.Sa(a)?UY.h?UY.h(b):UY.call(null,b):b;return $CLJS.n(a)?TY(b):b};VY=function(a){return $CLJS.td(a)?$CLJS.z(a):null!=a};
hja=function(a){var b=$CLJS.O(a);a=$CLJS.I.g(b,$CLJS.iO);var c=$CLJS.I.g(b,$CLJS.WO),d=$CLJS.I.g(b,$CLJS.sQ),e=$CLJS.I.g(b,$CLJS.KR);try{if($CLJS.n(d)){var f=$CLJS.O(b),k=$CLJS.I.g(f,$CLJS.sQ);var l=$CLJS.vN($CLJS.wk.g(f,$CLJS.sQ),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.iO,$CLJS.sQ],null),k)}else l=b;var m=$CLJS.n(a)?$CLJS.Hk.j(l,$CLJS.iO,UY):l,t=$CLJS.n(c)?$CLJS.Hk.j(m,$CLJS.WO,$CLJS.Me($CLJS.Gk,wY)):m,u=$CLJS.n(e)?$CLJS.Hk.j(t,$CLJS.KR,TY):t;return wY(u)}catch(v){if(v instanceof Error)throw m=v,$CLJS.$h($CLJS.WF("Error canonicalizing query: {0}",
$CLJS.G([$CLJS.UW(m)])),new $CLJS.h(null,1,[$CLJS.iO,a],null),m);throw v;}};
ija=function(a){a=$CLJS.O(a);var b=$CLJS.I.g(a,$CLJS.iO),c=$CLJS.O(b);b=$CLJS.I.g(c,$CLJS.IH);c=$CLJS.I.g(c,$CLJS.DG);return $CLJS.z(b)&&$CLJS.z(c)?(b=$CLJS.Wf.j($CLJS.Ug,$CLJS.fE,$CLJS.z($CLJS.bf($CLJS.Ta,function k(e,f){try{if($CLJS.yd(f)&&3===$CLJS.E(f))try{var l=$CLJS.bd(f,0);if($CLJS.fe(l,$CLJS.UH)){var m=$CLJS.bd(f,1),t=$CLJS.bd(f,2);return new $CLJS.Q(null,1,5,$CLJS.R,[new $CLJS.Q(null,2,5,$CLJS.R,[f,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.UH,m,$CLJS.wk.g(t,$CLJS.$H)],null)],null)],null)}throw $CLJS.TW;
}catch(u){if(u instanceof Error){l=u;if(l===$CLJS.TW)throw $CLJS.TW;throw l;}throw u;}else throw $CLJS.TW;}catch(u){if(u instanceof Error){l=u;if(l===$CLJS.TW)return $CLJS.bX(k,e,f);throw l;}throw u;}}($CLJS.tf,b)))),$CLJS.WY.j(a,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.iO,$CLJS.DG],null),$CLJS.Ek.g($CLJS.uf,$CLJS.Me($CLJS.Fk,b)))):a};
jja=function(a){try{return ija(a)}catch(c){if(c instanceof Error){var b=c;throw $CLJS.$h($CLJS.vG("Error performing whole query transformations"),new $CLJS.h(null,1,[$CLJS.iO,a],null),b);}throw c;}};
kja=function(a,b){var c=$CLJS.Wf.g($CLJS.jd(a),function(){return function f(e){return new $CLJS.le(null,function(){for(var k=e;;)if(k=$CLJS.z(k)){if($CLJS.zd(k)){var l=$CLJS.kc(k),m=$CLJS.E(l),t=$CLJS.oe(m);return function(){for(var A=0;;)if(A<m){var D=$CLJS.kd(l,A),J=$CLJS.H(D,0,null);D=$CLJS.H(D,1,null);var M=$CLJS.$d.g(b,J);D=XY.g?XY.g(D,M):XY.call(null,D,M);null!=D&&t.add(new $CLJS.Q(null,2,5,$CLJS.R,[J,D],null));A+=1}else return!0}()?$CLJS.re($CLJS.te(t),f($CLJS.lc(k))):$CLJS.re($CLJS.te(t),
null)}var u=$CLJS.B(k),v=$CLJS.H(u,0,null),x=$CLJS.H(u,1,null);u=function(){var A=x,D=$CLJS.$d.g(b,v);return XY.g?XY.g(A,D):XY.call(null,A,D)}();if(null!=u)return $CLJS.ce(new $CLJS.Q(null,2,5,$CLJS.R,[v,u],null),f($CLJS.Kc(k)));k=$CLJS.Kc(k)}else return null},null,null)}(a)}());return $CLJS.z(c)?c:null};lja=function(a,b){a=$CLJS.Gk.g(function(c){var d=$CLJS.$d.g(b,YY);return XY.g?XY.g(c,d):XY.call(null,c,d)},a);return $CLJS.n($CLJS.Je($CLJS.Ta,a))?a:null};
rY=function rY(a,b){var d=a.exec(b);if(null==d)return null;var e=d[0],f=1===d.length?e:$CLJS.uf(d);return $CLJS.ce(f,new $CLJS.le(null,function(){var k=e.length;k=d.index+(1>k?1:k);return k<=b.length?(k=b.substring(k),rY.g?rY.g(a,k):rY.call(null,a,k)):null},null,null))};
$CLJS.WY=function WY(a){switch(arguments.length){case 3:return WY.j(arguments[0],arguments[1],arguments[2]);case 4:return WY.v(arguments[0],arguments[1],arguments[2],arguments[3]);case 5:return WY.N(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4]);case 6:return WY.W(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4],arguments[5]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return WY.l(arguments[0],arguments[1],arguments[2],
arguments[3],arguments[4],arguments[5],new $CLJS.y(c.slice(6),0,null))}};$CLJS.WY.j=function(a,b,c){var d=$CLJS.z(b);b=$CLJS.B(d);if(d=$CLJS.C(d))a=$CLJS.S.j(a,b,$CLJS.WY.j($CLJS.I.g(a,b),d,c));else{d=$CLJS.S.j;var e=$CLJS.I.g(a,b);c=c.h?c.h(e):c.call(null,e);a=d.call($CLJS.S,a,b,c)}return a};
$CLJS.WY.v=function(a,b,c,d){var e=$CLJS.z(b);b=$CLJS.B(e);if(e=$CLJS.C(e))a=$CLJS.S.j(a,b,$CLJS.WY.v($CLJS.I.g(a,b),e,c,d));else{e=$CLJS.S.j;var f=$CLJS.I.g(a,b);c=c.g?c.g(f,d):c.call(null,f,d);a=e.call($CLJS.S,a,b,c)}return a};$CLJS.WY.N=function(a,b,c,d,e){var f=$CLJS.z(b);b=$CLJS.B(f);if(f=$CLJS.C(f))a=$CLJS.S.j(a,b,$CLJS.WY.N($CLJS.I.g(a,b),f,c,d,e));else{f=$CLJS.S.j;var k=$CLJS.I.g(a,b);c=c.j?c.j(k,d,e):c.call(null,k,d,e);a=f.call($CLJS.S,a,b,c)}return a};
$CLJS.WY.W=function(a,b,c,d,e,f){var k=$CLJS.z(b);b=$CLJS.B(k);if(k=$CLJS.C(k))a=$CLJS.S.j(a,b,$CLJS.WY.W($CLJS.I.g(a,b),k,c,d,e,f));else{k=$CLJS.S.j;var l=$CLJS.I.g(a,b);c=c.v?c.v(l,d,e,f):c.call(null,l,d,e,f);a=k.call($CLJS.S,a,b,c)}return a};$CLJS.WY.l=function(a,b,c,d,e,f,k){var l=$CLJS.z(b);b=$CLJS.B(l);return(l=$CLJS.C(l))?$CLJS.S.j(a,b,$CLJS.P.l($CLJS.WY,$CLJS.I.g(a,b),l,c,d,$CLJS.G([e,f,k]))):$CLJS.S.j(a,b,$CLJS.P.l(c,$CLJS.I.g(a,b),d,e,f,$CLJS.G([k])))};
$CLJS.WY.B=function(a){var b=$CLJS.B(a),c=$CLJS.C(a);a=$CLJS.B(c);var d=$CLJS.C(c);c=$CLJS.B(d);var e=$CLJS.C(d);d=$CLJS.B(e);var f=$CLJS.C(e);e=$CLJS.B(f);var k=$CLJS.C(f);f=$CLJS.B(k);k=$CLJS.C(k);return this.l(b,a,c,d,e,f,k)};$CLJS.WY.A=6;
var QY=function QY(a,b){return $CLJS.VW($CLJS.Me(QY,a),$CLJS.Rd,a.h?a.h(b):a.call(null,b))},BY=function BY(a){for(;;){if($CLJS.wd(a))return $CLJS.Gu(a,BY);if($CLJS.Cd(a))a=$CLJS.uf(a);else if($CLJS.yd(a)){if($CLJS.Sa($CLJS.Je($CLJS.Ta,a)))return null;var c=a,d=$CLJS.z(c),e=$CLJS.B(d),f=$CLJS.C(d),k=e,l=f,m=$CLJS.H(l,0,null),t=l,u=k,v=u instanceof $CLJS.K?u.T:null;switch(v){case "not":if($CLJS.Fl(m)){var x=$CLJS.B(m),A=x instanceof $CLJS.K?x.T:null;switch(A){case "not":a=$CLJS.hd(m);continue;case "and":return CY($CLJS.kt,
$CLJS.Ze.g(function(){return function(D){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.mt,D],null)}}(a,x,A,u,v,c,d,e,f,k,l,m,t),$CLJS.Kc(m)));case "or":return CY($CLJS.qt,$CLJS.Ze.g(function(){return function(D){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.mt,D],null)}}(a,x,A,u,v,c,d,e,f,k,l,m,t),$CLJS.Kc(m)));default:return a}}else return a;case "and":return CY($CLJS.qt,t);case "or":return CY($CLJS.kt,t);default:return $CLJS.Gk.g(BY,a)}}else return a}},mja=new $CLJS.K(null,"value_field","value_field",
-980977878),ZY=new $CLJS.K(null,"ascending","ascending",-988350486),$Y=new $CLJS.K(null,"named","named",-422393479),aZ=new $CLJS.K(null,"values_source_config","values_source_config",-590570309),bZ=new $CLJS.K(null,"descending","descending",-24766135),cZ=new $CLJS.K(null,"binning-strategy","binning-strategy",2063329158),dZ=new $CLJS.K(null,"datetime-field","datetime-field",21731696),nja=new $CLJS.K(null,"use-as-display-name?","use-as-display-name?",686752941),eZ=new $CLJS.K(null,"field-literal","field-literal",
-1295883554),fZ=new $CLJS.K(null,"viz-settings","viz-settings",256055379),oja=new $CLJS.K(null,"rows","rows",850049680),pja=new $CLJS.K(null,"special-fn","special-fn",1290649344),YY=new $CLJS.K("metabase.mbql.normalize","sequence","metabase.mbql.normalize/sequence",-1700645683),GY=new $CLJS.K(null,"ignore-path","ignore-path",944069061),qja=new $CLJS.K(null,"label_field","label_field",-1573182765),rja=new $CLJS.K(null,"fk-\x3e","fk-\x3e",-499026738),sja=new $CLJS.K(null,"joined-field","joined-field",
-2048778268),cja=new $CLJS.K(null,"visibility_type","visibility_type",-508434247);var gZ,tja=$CLJS.Oe($CLJS.N),uja=$CLJS.Oe($CLJS.N),vja=$CLJS.Oe($CLJS.N),wja=$CLJS.Oe($CLJS.N),xja=$CLJS.I.j($CLJS.N,$CLJS.$i,$CLJS.Nh.o?$CLJS.Nh.o():$CLJS.Nh.call(null));gZ=new $CLJS.Xh($CLJS.xh.g("metabase.mbql.normalize","normalize-mbql-clause-tokens"),$CLJS.Ek.g(uY,$CLJS.B),xja,tja,uja,vja,wja);gZ.m(null,$CLJS.JH,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);b=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.JH,b],null);return null!=a?$CLJS.$d.g(b,a):b});
gZ.m(null,$CLJS.dD,function(a){$CLJS.H(a,0,null);a=$CLJS.H(a,1,null);return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.dD,a instanceof $CLJS.K?zY(a):a],null)});gZ.m(null,cZ,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null),c=$CLJS.H(a,2,null);a=$CLJS.H(a,3,null);return $CLJS.n(a)?$CLJS.$d.g(gZ.h(new $CLJS.Q(null,3,5,$CLJS.R,[cZ,b,c],null)),a):new $CLJS.Q(null,3,5,$CLJS.R,[cZ,FY.g?FY.g(b,GY):FY.call(null,b,GY),uY(c)],null)});
gZ.m(null,$CLJS.UH,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);var c=FY.g?FY.g(a,GY):FY.call(null,a,GY);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.UH,b,function(){var d=$CLJS.n($CLJS.gD.h(c))?$CLJS.Hk.j(c,$CLJS.gD,$CLJS.rh):c;d=$CLJS.n($CLJS.$H.h(c))?$CLJS.Hk.j(d,$CLJS.$H,$CLJS.rh):d;return $CLJS.n($CLJS.WS.h(c))?$CLJS.Hk.j(d,$CLJS.WS,function(e){return $CLJS.n($CLJS.oG.h(e))?$CLJS.Hk.j(e,$CLJS.oG,$CLJS.rh):e}):d}()],null)});
gZ.m(null,eZ,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[eZ,b instanceof $CLJS.K?zY(b):b,$CLJS.rh.h(a)],null)});gZ.m(null,dZ,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null),c=$CLJS.H(a,2,null);a=$CLJS.H(a,3,null);return $CLJS.n(a)?new $CLJS.Q(null,4,5,$CLJS.R,[dZ,FY.g?FY.g(b,GY):FY.call(null,b,GY),$CLJS.ku,uY(a)],null):new $CLJS.Q(null,3,5,$CLJS.R,[dZ,FY.g?FY.g(b,GY):FY.call(null,b,GY),uY(c)],null)});
gZ.m(null,$CLJS.fI,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null),c=$CLJS.H(a,2,null),d=$CLJS.H(a,3,null);a=$CLJS.H(a,4,null);return $CLJS.n(a)?$CLJS.$d.g(gZ.h(new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.fI,b,c,d],null)),FY.g?FY.g(a,GY):FY.call(null,a,GY)):new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.fI,FY.g?FY.g(b,GY):FY.call(null,b,GY),$CLJS.Ed(c)?c:uY(c),uY(d)],null)});
gZ.m(null,$CLJS.EK,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return $CLJS.n(a)?new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.EK,b,uY(a)],null):new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.EK,$CLJS.vx],null)});gZ.m(null,$CLJS.CK,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.CK,b,uY(a)],null)});
gZ.m(null,$CLJS.MK,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null),c=$CLJS.H(a,2,null);a=$CLJS.H(a,3,null);return new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.MK,FY.g?FY.g(b,GY):FY.call(null,b,GY),c,uY(a)],null)});gZ.m(null,$CLJS.SK,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null),c=$CLJS.H(a,2,null);a=$CLJS.H(a,3,null);return new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.SK,FY.g?FY.g(b,GY):FY.call(null,b,GY),c,uY(a)],null)});
gZ.m(null,$CLJS.KK,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return $CLJS.n(a)?new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.KK,FY.g?FY.g(b,GY):FY.call(null,b,GY),uY(a)],null):new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.KK,FY.g?FY.g(b,GY):FY.call(null,b,GY)],null)});
gZ.m(null,$CLJS.QK,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null),c=$CLJS.H(a,2,null);a=$CLJS.H(a,3,null);return $CLJS.n(a)?new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.QK,FY.g?FY.g(b,GY):FY.call(null,b,GY),uY(c),uY(a)],null):new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.QK,FY.g?FY.g(b,GY):FY.call(null,b,GY),uY(c)],null)});
gZ.m(null,$CLJS.BK,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null),c=$CLJS.H(a,2,null);a=$CLJS.H(a,3,null);return new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.BK,FY.g?FY.g(b,GY):FY.call(null,b,GY),FY.g?FY.g(c,GY):FY.call(null,c,GY),uY(a)],null)});gZ.m(null,$CLJS.uj,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uj,b,a],null)});
gZ.m(null,$CLJS.Wh,function(a){var b=$CLJS.z(a);a=$CLJS.B(b);b=$CLJS.C(b);return $CLJS.Wf.j(new $CLJS.Q(null,1,5,$CLJS.R,[uY(a)],null),$CLJS.Ze.h(function(c){return FY.g?FY.g(c,GY):FY.call(null,c,GY)}),b)});
var hZ=function hZ(a){if($CLJS.n($CLJS.pC($CLJS.ee,$CLJS.Va)(a))){var c=uY(a);var d=new $CLJS.Tg(null,new $CLJS.h(null,18,[$CLJS.Sn,null,$CLJS.Rs,null,$CLJS.kI,null,$CLJS.cI,null,$CLJS.mu,null,$CLJS.VH,null,$CLJS.pI,null,$CLJS.qI,null,$CLJS.yH,null,$CLJS.FH,null,$CLJS.Qs,null,$CLJS.zH,null,$CLJS.hI,null,$CLJS.aI,null,$CLJS.Zj,null,$CLJS.Iz,null,$CLJS.OH,null,$CLJS.gI,null],null),null);c=d.h?d.h(c):d.call(null,c)}else c=null;return $CLJS.n(c)?c:$CLJS.n(DY(a))?(a=$CLJS.B(a),hZ.h?hZ.h(a):hZ.call(null,
a)):null},yja=new $CLJS.h(null,8,[$CLJS.dj,uY,$CLJS.KR,function(a){a=vY(a);return $CLJS.z($CLJS.hS.h(a))?$CLJS.Hk.j(a,$CLJS.hS,aja):a},$CLJS.iO,new $CLJS.h(null,6,[$CLJS.JH,function iZ(a){if($CLJS.n($CLJS.pC($CLJS.ee,$CLJS.Va)(a)))return uY(a);if($CLJS.n(EY($Y,a))){a=$CLJS.z(a);$CLJS.B(a);var c=$CLJS.C(a);a=$CLJS.B(c);c=$CLJS.C(c);return $CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$Y,iZ.h?iZ.h(a):iZ.call(null,a)],null),c)}return $CLJS.n($CLJS.n(DY(a))?hZ($CLJS.hd(a)):null)?$CLJS.Gk.g(iZ,a):FY.g?FY.g(a,
GY):FY.call(null,a,GY)},$CLJS.AR,function(a){return $CLJS.Wf.g($CLJS.N,function(){return function d(c){return new $CLJS.le(null,function(){for(;;){var e=$CLJS.z(c);if(e){if($CLJS.zd(e)){var f=$CLJS.kc(e),k=$CLJS.E(f),l=$CLJS.oe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m),u=$CLJS.H(t,0,null);t=$CLJS.H(t,1,null);u=new $CLJS.Q(null,2,5,$CLJS.R,[zY(u),FY.g?FY.g(t,GY):FY.call(null,t,GY)],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.re($CLJS.te(l),d($CLJS.lc(e))):$CLJS.re($CLJS.te(l),null)}f=
$CLJS.B(e);l=$CLJS.H(f,0,null);f=$CLJS.H(f,1,null);return $CLJS.ce(new $CLJS.Q(null,2,5,$CLJS.R,[zY(l),FY.g?FY.g(f,GY):FY.call(null,f,GY)],null),d($CLJS.Kc(e)))}return null}},null,null)}(a)}())},$CLJS.pT,function(a){return $CLJS.uf(function(){return function d(c){return new $CLJS.le(null,function(){for(;;){var e=$CLJS.z(c);if(e){if($CLJS.zd(e)){var f=$CLJS.kc(e),k=$CLJS.E(f),l=$CLJS.oe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m);t=$CLJS.n(DY(t))?gZ.h(t):$CLJS.ae(gZ.h($CLJS.ae(t)));l.add(t);m+=
1}else{f=!0;break a}return f?$CLJS.re($CLJS.te(l),d($CLJS.lc(e))):$CLJS.re($CLJS.te(l),null)}l=$CLJS.B(e);return $CLJS.ce($CLJS.n(DY(l))?gZ.h(l):$CLJS.ae(gZ.h($CLJS.ae(l))),d($CLJS.Kc(e)))}return null}},null,null)}(a)}())},$CLJS.jS,function(a){a=vY(a);a=$CLJS.O(a);var b=$CLJS.I.g(a,$CLJS.KR);if($CLJS.n(b))return a=$CLJS.aX(a,new $CLJS.h(null,1,[$CLJS.KR,$CLJS.iO],null)),b=new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.KR],null),a=FY.g?FY.g(a,b):FY.call(null,a,b),$CLJS.aX(a,new $CLJS.h(null,1,[$CLJS.iO,$CLJS.KR],
null));b=new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.iO],null);return FY.g?FY.g(a,b):FY.call(null,a,b)},$CLJS.sQ,new $CLJS.h(null,1,[YY,HY],null),$CLJS.eP,new $CLJS.h(null,1,[YY,function(a){a=FY.g?FY.g(a,$CLJS.iO):FY.call(null,a,$CLJS.iO);var b=$CLJS.O(a),c=$CLJS.I.g(b,$CLJS.oG),d=$CLJS.I.g(b,$CLJS.DG);a=$CLJS.I.g(b,$CLJS.HG);b=$CLJS.n(c)?$CLJS.Hk.j(b,$CLJS.oG,uY):b;d=$CLJS.n($CLJS.pC($CLJS.ee,$CLJS.Va)(d))?$CLJS.Hk.j(b,$CLJS.DG,uY):b;return $CLJS.n(a)?$CLJS.Hk.j(d,$CLJS.HG,zY):d}],null)],null),$CLJS.xA,
new $CLJS.h(null,1,[$CLJS.QP,$CLJS.Rd],null),$CLJS.WO,new $CLJS.h(null,1,[YY,function(a){var b=$CLJS.O(a),c=$CLJS.I.g(b,$CLJS.dj),d=$CLJS.I.g(b,$CLJS.XK),e=$CLJS.I.g(b,$CLJS.Qi);a=$CLJS.I.g(b,aZ);b=$CLJS.n(e)?$CLJS.Hk.j(b,$CLJS.Qi,zY):b;c=$CLJS.n(c)?$CLJS.Hk.j(b,$CLJS.dj,uY):b;d=$CLJS.n(d)?$CLJS.Hk.j(c,$CLJS.XK,function(f){return FY.g?FY.g(f,GY):FY.call(null,f,GY)}):c;d=$CLJS.n(a)?$CLJS.WY.j(d,new $CLJS.Q(null,2,5,$CLJS.R,[aZ,qja],null),function(f){return FY.g?FY.g(f,GY):FY.call(null,f,GY)}):d;return $CLJS.n(a)?
$CLJS.WY.j(d,new $CLJS.Q(null,2,5,$CLJS.R,[aZ,mja],null),function(f){return FY.g?FY.g(f,GY):FY.call(null,f,GY)}):d}],null),$CLJS.RO,function(a){return null==a?null:uY(a)},$CLJS.sQ,new $CLJS.h(null,1,[YY,HY],null),fZ,uY],null),FY=function FY(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return FY.l(arguments[0],1<c.length?new $CLJS.y(c.slice(1),0,null):null)};
FY.l=function(a,b){b=$CLJS.H(b,0,null);var c=b instanceof $CLJS.K?new $CLJS.Q(null,1,5,$CLJS.R,[b],null):$CLJS.uf(b);b=$CLJS.z(c)?$CLJS.qC(yja,c):null;try{return $CLJS.md(b)?b.h?b.h(a):b.call(null,a):$CLJS.xd(a)?a:$CLJS.wd(a)?$CLJS.Wf.g($CLJS.N,function(){return function k(f){return new $CLJS.le(null,function(){for(;;){var l=$CLJS.z(f);if(l){if($CLJS.zd(l)){var m=$CLJS.kc(l),t=$CLJS.E(m),u=$CLJS.oe(t);a:for(var v=0;;)if(v<t){var x=$CLJS.kd(m,v),A=$CLJS.H(x,0,null);x=$CLJS.H(x,1,null);A=uY(A);A=new $CLJS.Q(null,
2,5,$CLJS.R,[A,FY.l(x,$CLJS.G([$CLJS.$d.g($CLJS.uf(c),A)]))],null);u.add(A);v+=1}else{m=!0;break a}return m?$CLJS.re($CLJS.te(u),k($CLJS.lc(l))):$CLJS.re($CLJS.te(u),null)}m=$CLJS.B(l);u=$CLJS.H(m,0,null);m=$CLJS.H(m,1,null);u=uY(u);return $CLJS.ce(new $CLJS.Q(null,2,5,$CLJS.R,[u,FY.l(m,$CLJS.G([$CLJS.$d.g($CLJS.uf(c),u)]))],null),k($CLJS.Kc(l)))}return null}},null,null)}(a)}()):$CLJS.n(DY(a))?gZ.h(a):$CLJS.vd(a)?$CLJS.Gk.g(function(e){return FY.l(e,$CLJS.G([$CLJS.$d.g($CLJS.uf(c),YY)]))},a):a}catch(e){if(e instanceof
Error){var d=e;throw $CLJS.$h($CLJS.WF("Error normalizing form: {0}",$CLJS.G([$CLJS.UW(d)])),new $CLJS.h(null,3,[$CLJS.PB,a,$CLJS.jl,c,pja,b],null),d);}throw e;}};FY.A=1;FY.B=function(a){var b=$CLJS.B(a);a=$CLJS.C(a);return this.l(b,a)};
var JY=function(){var a=$CLJS.Oe($CLJS.N),b=$CLJS.Oe($CLJS.N),c=$CLJS.Oe($CLJS.N),d=$CLJS.Oe($CLJS.N),e=$CLJS.I.j($CLJS.N,$CLJS.$i,$CLJS.Nh.o?$CLJS.Nh.o():$CLJS.Nh.call(null));return new $CLJS.Xh($CLJS.xh.g("metabase.mbql.normalize","canonicalize-mbql-clause"),function(f){return $CLJS.n(DY(f))?$CLJS.B(f):null},e,a,b,c,d)}();JY.m(null,$CLJS.Wh,function(a){return a});
JY.m(null,$CLJS.UH,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);if($CLJS.n(EY($CLJS.UH,b))){$CLJS.H(b,0,null);var c=$CLJS.H(b,1,null);b=$CLJS.H(b,2,null);return JY.h(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.UH,c,$CLJS.De($CLJS.Wk.l($CLJS.G([b,a])))],null))}return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.UH,b,$CLJS.De(a)],null)});
JY.m(null,$CLJS.JH,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return $CLJS.sd(a)?new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.JH,b],null):new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.JH,b,a],null)});JY.m(null,$CLJS.yM,function(a){$CLJS.H(a,0,null);a=$CLJS.H(a,1,null);return $CLJS.n(DY(a))?JY.h(a):new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.UH,a,null],null)});
JY.m(null,eZ,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.UH,b,new $CLJS.h(null,1,[$CLJS.gD,a],null)],null)});JY.m(null,rja,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);var c=KY(b);$CLJS.H(c,0,null);b=$CLJS.H(c,1,null);$CLJS.H(c,2,null);c=KY(a);$CLJS.H(c,0,null);a=$CLJS.H(c,1,null);c=$CLJS.H(c,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.UH,a,$CLJS.S.j(c,$CLJS.fP,b)],null)});
JY.m(null,sja,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);a=KY(a);return $CLJS.P.v($CLJS.hX,a,$CLJS.S,$CLJS.G([$CLJS.VQ,b]))});
JY.m(null,dZ,function(a){var b=$CLJS.E(a);switch(b){case 3:$CLJS.H(a,0,null);b=$CLJS.H(a,1,null);var c=$CLJS.H(a,2,null);a=KY(b);b=c;$CLJS.H(a,0,null);$CLJS.H(a,1,null);c=$CLJS.H(a,2,null);c=$CLJS.O(c);var d=$CLJS.I.g(c,$CLJS.gD);$CLJS.Sa(d)||$CLJS.lU.g(d,b)?a=$CLJS.P.v($CLJS.hX,a,$CLJS.S,$CLJS.G([$CLJS.$H,b])):(c=$CLJS.zC($CLJS.vA),$CLJS.n($CLJS.yC("metabase.mbql.util",c))&&(b=$CLJS.WF("{0} is not a valid temporal unit for {1}; not adding to clause {2}",$CLJS.G([b,d,$CLJS.Eh.l($CLJS.G([a]))])),b instanceof
Error?$CLJS.xC("metabase.mbql.util",c,$CLJS.fy(),b):$CLJS.xC("metabase.mbql.util",c,$CLJS.fy.l($CLJS.G([b])),null)));return a;case 4:return $CLJS.H(a,0,null),b=$CLJS.H(a,1,null),$CLJS.H(a,2,null),c=$CLJS.H(a,3,null),JY.h(new $CLJS.Q(null,3,5,$CLJS.R,[dZ,b,c],null));default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}});
JY.m(null,cZ,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null),c=$CLJS.H(a,2,null),d=$CLJS.H(a,3,null);a=$CLJS.H(a,4,null);var e=KY(b);$CLJS.H(e,0,null);b=$CLJS.H(e,1,null);e=$CLJS.H(e,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.UH,b,$CLJS.S.j(e,$CLJS.WS,$CLJS.Wk.l($CLJS.G([new $CLJS.h(null,1,[$CLJS.oG,c],null),$CLJS.n(d)?$CLJS.Ce([c,d]):null,a])))],null)});
for(var jZ=$CLJS.z(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.qt,$CLJS.kt,$CLJS.mt],null)),kZ=null,lZ=0,mZ=0;;)if(mZ<lZ){var nZ=kZ.X(null,mZ);JY.m(null,nZ,function(){return function(a){return LY(a)}}(jZ,kZ,lZ,mZ,nZ));mZ+=1}else{var oZ=$CLJS.z(jZ);if(oZ){var pZ=oZ;if($CLJS.zd(pZ)){var qZ=$CLJS.kc(pZ),zja=$CLJS.lc(pZ),Aja=qZ,Bja=$CLJS.E(qZ);jZ=zja;kZ=Aja;lZ=Bja}else{var rZ=$CLJS.B(pZ);JY.m(null,rZ,function(){return function(a){return LY(a)}}(jZ,kZ,lZ,mZ,rZ,pZ,oZ));jZ=$CLJS.C(pZ);kZ=null;lZ=0}mZ=0}else break}
JY.m(null,$CLJS.BH,function(a){a=$CLJS.z(a);$CLJS.B(a);var b=$CLJS.C(a);a=$CLJS.B(b);var c=$CLJS.C(b);b=$CLJS.B(c);c=$CLJS.C(c);return $CLJS.Wf.g(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.BH,KY(a),KY(b)],null),c)});JY.m(null,$CLJS.fI,function(a){a=$CLJS.z(a);$CLJS.B(a);var b=$CLJS.C(a);a=$CLJS.B(b);b=$CLJS.C(b);var c=KY(a);a=$CLJS.n(AY($CLJS.UH,a))?$CLJS.hX.l(c,$CLJS.wk,$CLJS.G([$CLJS.$H])):c;return $CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fI,a],null),b)});
for(var sZ=$CLJS.z(new $CLJS.Q(null,15,5,$CLJS.R,[$CLJS.LH,$CLJS.eI,$CLJS.TH,$CLJS.MH,$CLJS.Ns,$CLJS.mI,$CLJS.Ks,$CLJS.Ms,$CLJS.Gs,$CLJS.Is,$CLJS.wH,$CLJS.DH,$CLJS.uH,$CLJS.GH,$CLJS.vH],null)),tZ=null,uZ=0,vZ=0;;)if(vZ<uZ){var wZ=tZ.X(null,vZ);JY.m(null,wZ,function(){return function(a){return MY(a)}}(sZ,tZ,uZ,vZ,wZ));vZ+=1}else{var xZ=$CLJS.z(sZ);if(xZ){var yZ=xZ;if($CLJS.zd(yZ)){var zZ=$CLJS.kc(yZ),Cja=$CLJS.lc(yZ),Dja=zZ,Eja=$CLJS.E(zZ);sZ=Cja;tZ=Dja;uZ=Eja}else{var AZ=$CLJS.B(yZ);JY.m(null,AZ,
function(){return function(a){return MY(a)}}(sZ,tZ,uZ,vZ,AZ,yZ,xZ));sZ=$CLJS.C(yZ);tZ=null;uZ=0}vZ=0}else break}JY.m(null,oja,function(){return null});JY.m(null,$CLJS.VN,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.VN,JY.h(b),a],null)});
JY.m(null,$Y,function(a){a=$CLJS.z(a);$CLJS.B(a);a=$CLJS.C(a);var b=$CLJS.B(a),c=$CLJS.C(a);a=$CLJS.B(c);var d=$CLJS.C(c);c=JY.h;var e=$CLJS.R;b=JY.h(b);d=$CLJS.H(d,0,null);d=$CLJS.O(d);a=!1===$CLJS.I.g(d,nja)?new $CLJS.h(null,1,[$CLJS.T,a],null):new $CLJS.h(null,1,[$CLJS.aG,a],null);return c.call(JY,new $CLJS.Q(null,3,5,e,[$CLJS.VN,b,a],null))});
for(var BZ=$CLJS.z(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Iz,$CLJS.VH],null)),CZ=null,DZ=0,EZ=0;;)if(EZ<DZ){var FZ=CZ.X(null,EZ);JY.m(null,FZ,function(){return function(a){return NY(a)}}(BZ,CZ,DZ,EZ,FZ));EZ+=1}else{var GZ=$CLJS.z(BZ);if(GZ){var HZ=GZ;if($CLJS.zd(HZ)){var IZ=$CLJS.kc(HZ),Fja=$CLJS.lc(HZ),Gja=IZ,Hja=$CLJS.E(IZ);BZ=Fja;CZ=Gja;DZ=Hja}else{var JZ=$CLJS.B(HZ);JY.m(null,JZ,function(){return function(a){return NY(a)}}(BZ,CZ,DZ,EZ,JZ,HZ,GZ));BZ=$CLJS.C(HZ);CZ=null;DZ=0}EZ=0}else break}
for(var KZ=$CLJS.z(new $CLJS.Q(null,9,5,$CLJS.R,[$CLJS.OH,$CLJS.rI,$CLJS.yH,$CLJS.kI,$CLJS.gI,$CLJS.Sn,$CLJS.Zj,$CLJS.hI,$CLJS.zH],null)),LZ=null,MZ=0,NZ=0;;)if(NZ<MZ){var OZ=LZ.X(null,NZ);JY.m(null,OZ,function(){return function(a){return OY(a)}}(KZ,LZ,MZ,NZ,OZ));NZ+=1}else{var PZ=$CLJS.z(KZ);if(PZ){var QZ=PZ;if($CLJS.zd(QZ)){var RZ=$CLJS.kc(QZ),Ija=$CLJS.lc(QZ),Jja=RZ,Kja=$CLJS.E(RZ);KZ=Ija;LZ=Jja;MZ=Kja}else{var SZ=$CLJS.B(QZ);JY.m(null,SZ,function(){return function(a){return OY(a)}}(KZ,LZ,MZ,NZ,
SZ,QZ,PZ));KZ=$CLJS.C(QZ);LZ=null;MZ=0}NZ=0}else break}JY.m(null,$CLJS.FH,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.FH,KY(b),a],null)});
for(var TZ=$CLJS.z(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.aI,$CLJS.cI],null)),UZ=null,VZ=0,WZ=0;;)if(WZ<VZ){var XZ=UZ.X(null,WZ);JY.m(null,XZ,function(){return function(a){return PY(a)}}(TZ,UZ,VZ,WZ,XZ));WZ+=1}else{var YZ=$CLJS.z(TZ);if(YZ){var ZZ=YZ;if($CLJS.zd(ZZ)){var $Z=$CLJS.kc(ZZ),Lja=$CLJS.lc(ZZ),Mja=$Z,Nja=$CLJS.E($Z);TZ=Lja;UZ=Mja;VZ=Nja}else{var a_=$CLJS.B(ZZ);JY.m(null,a_,function(){return function(a){return PY(a)}}(TZ,UZ,VZ,WZ,a_,ZZ,YZ));TZ=$CLJS.C(ZZ);UZ=null;VZ=0}WZ=0}else break}
JY.m(null,$CLJS.pI,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.pI,JY.h(b),JY.h(a)],null)});
JY.m(null,$CLJS.CH,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return $CLJS.n(a)?$CLJS.$d.g(JY.h(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.CH,b],null)),FY.l(a,$CLJS.G([GY]))):new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.CH,$CLJS.uf(function(){return function e(d){return new $CLJS.le(null,function(){for(;;){var f=$CLJS.z(d);if(f){if($CLJS.zd(f)){var k=$CLJS.kc(f),l=$CLJS.E(k),m=$CLJS.oe(l);a:for(var t=0;;)if(t<l){var u=$CLJS.kd(k,t),v=$CLJS.H(u,0,null);u=$CLJS.H(u,1,null);v=new $CLJS.Q(null,
2,5,$CLJS.R,[JY.h(v),JY.h(u)],null);m.add(v);t+=1}else{k=!0;break a}return k?$CLJS.re($CLJS.te(m),e($CLJS.lc(f))):$CLJS.re($CLJS.te(m),null)}k=$CLJS.B(f);m=$CLJS.H(k,0,null);k=$CLJS.H(k,1,null);return $CLJS.ce(new $CLJS.Q(null,2,5,$CLJS.R,[JY.h(m),JY.h(k)],null),e($CLJS.Kc(f)))}return null}},null,null)}(b)}())],null)});
JY.m(null,$CLJS.nH,function(a){a=$CLJS.z(a);$CLJS.B(a);var b=$CLJS.C(a);a=$CLJS.B(b);var c=$CLJS.C(b);b=$CLJS.B(c);c=$CLJS.C(c);return $CLJS.Wf.g(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.nH,JY.h(a),$CLJS.F.g(0,b)?1:JY.h(b)],null),$CLJS.Ze.g(JY,c))});
var RY=function RY(a){return function f(d,e){try{if($CLJS.n(function(){var x=xY($CLJS.Cd);return x.h?x.h(e):x.call(null,e)}()))return f(d,$CLJS.uf(e));throw $CLJS.TW;}catch(x){if(x instanceof Error)if(d=x,d===$CLJS.TW)try{if($CLJS.n(function(){var A=xY($CLJS.ee);return A.h?A.h(e):A.call(null,e)}()))return new $CLJS.Q(null,1,5,$CLJS.R,[new $CLJS.Q(null,1,5,$CLJS.R,[e],null)],null);throw $CLJS.TW;}catch(A){if(A instanceof Error)if(d=A,d===$CLJS.TW)try{if($CLJS.yd(e)&&2<=$CLJS.E(e))try{var k=$CLJS.Lk.j(e,
0,2);if($CLJS.yd(k)&&2===$CLJS.E(k))try{var l=$CLJS.bd(k,0);if($CLJS.n($CLJS.oN($CLJS.ee,$CLJS.Ke(new $CLJS.Tg(null,new $CLJS.h(null,5,[$CLJS.Rs,null,$CLJS.mu,null,$CLJS.qI,null,$CLJS.Qs,null,$Y,null],null),null)))(l)))try{var m=$CLJS.bd(k,1);if($CLJS.n(hZ(m)))return $CLJS.Wf.j($CLJS.tf,$CLJS.VF(RY),a);throw $CLJS.TW;}catch(D){if(D instanceof Error){var t=D;if(t===$CLJS.TW)throw $CLJS.TW;throw t;}throw D;}else throw $CLJS.TW;}catch(D){if(D instanceof Error){t=D;if(t===$CLJS.TW)throw $CLJS.TW;throw t;
}throw D;}else throw $CLJS.TW;}catch(D){if(D instanceof Error){t=D;if(t===$CLJS.TW)throw $CLJS.TW;throw t;}throw D;}else throw $CLJS.TW;}catch(D){if(D instanceof Error)if(t=D,t===$CLJS.TW)try{if($CLJS.yd(e)&&1<=$CLJS.E(e))try{var u=$CLJS.Lk.j(e,0,1);if($CLJS.yd(u)&&1===$CLJS.E(u))try{if($CLJS.bd(u,0)instanceof $CLJS.K)return new $CLJS.Q(null,1,5,$CLJS.R,[e],null);throw $CLJS.TW;}catch(J){if(J instanceof Error){var v=J;if(v===$CLJS.TW)throw $CLJS.TW;throw v;}throw J;}else throw $CLJS.TW;}catch(J){if(J instanceof
Error){v=J;if(v===$CLJS.TW)throw $CLJS.TW;throw v;}throw J;}else throw $CLJS.TW;}catch(J){if(J instanceof Error){v=J;if(v===$CLJS.TW)return e;throw v;}throw J;}else throw t;else throw D;}else throw d;else throw A;}else throw d;else throw x;}}($CLJS.tf,a)},b_=function b_(a){return function f(d,e){try{var k=xY($CLJS.Cd);var l=k.h?k.h(e):k.call(null,e);if($CLJS.n(l))return f(d,$CLJS.uf(e));throw $CLJS.TW;}catch(D){if(D instanceof Error)if(l=D,l===$CLJS.TW)try{if($CLJS.yd(e)&&2===$CLJS.E(e))try{var m=
$CLJS.bd(e,1);if($CLJS.fe(m,$CLJS.RH)){var t=$CLJS.bd(e,0);return f(d,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.RH,t],null))}throw $CLJS.TW;}catch(J){if(J instanceof Error)if(l=J,l===$CLJS.TW)try{m=$CLJS.bd(e,1);if($CLJS.fe(m,$CLJS.KH))return t=$CLJS.bd(e,0),f(d,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.KH,t],null));throw $CLJS.TW;}catch(M){if(M instanceof Error){var u=M;if(u===$CLJS.TW)try{m=$CLJS.bd(e,1);if($CLJS.fe(m,ZY))return t=$CLJS.bd(e,0),f(d,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.RH,t],null));throw $CLJS.TW;
}catch(V){if(V instanceof Error)if(l=V,l===$CLJS.TW)try{m=$CLJS.bd(e,1);if($CLJS.fe(m,bZ))return t=$CLJS.bd(e,0),f(d,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.KH,t],null));throw $CLJS.TW;}catch(Z){if(Z instanceof Error)if(m=Z,m===$CLJS.TW)try{var v=$CLJS.bd(e,0);if($CLJS.fe(v,ZY))return t=$CLJS.bd(e,1),f(d,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.RH,t],null));throw $CLJS.TW;}catch(fa){if(fa instanceof Error)if(m=fa,m===$CLJS.TW)try{v=$CLJS.bd(e,0);if($CLJS.fe(v,bZ))return t=$CLJS.bd(e,1),f(d,new $CLJS.Q(null,
2,5,$CLJS.R,[$CLJS.KH,t],null));throw $CLJS.TW;}catch(Da){if(Da instanceof Error)if(m=Da,m===$CLJS.TW)try{v=$CLJS.bd(e,0);if($CLJS.fe(v,$CLJS.RH))return t=$CLJS.bd(e,1),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.RH,KY(t)],null);throw $CLJS.TW;}catch(Ha){if(Ha instanceof Error)if(m=Ha,m===$CLJS.TW)try{v=$CLJS.bd(e,0);if($CLJS.fe(v,$CLJS.KH))return t=$CLJS.bd(e,1),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.KH,KY(t)],null);throw $CLJS.TW;}catch(mb){if(mb instanceof Error){t=mb;if(t===$CLJS.TW)throw $CLJS.TW;throw t;
}throw mb;}else throw m;else throw Ha;}else throw m;else throw Da;}else throw m;else throw fa;}else throw m;else throw Z;}else throw l;else throw V;}else throw u;}else throw M;}else throw l;else throw J;}else throw $CLJS.TW;}catch(J){if(J instanceof Error)if(l=J,l===$CLJS.TW)try{if($CLJS.yd(e)&&0<=$CLJS.E(e))try{var x=$CLJS.Lk.j(e,0,0);if($CLJS.yd(x)&&0===$CLJS.E(x))try{var A=$CLJS.Lk.g(e,0);if($CLJS.F.g(A,a))return $CLJS.uf($CLJS.Yk.h($CLJS.Ze.g(b_,a)));throw $CLJS.TW;}catch(M){if(M instanceof Error){u=
M;if(u===$CLJS.TW)throw $CLJS.TW;throw u;}throw M;}else throw $CLJS.TW;}catch(M){if(M instanceof Error){u=M;if(u===$CLJS.TW)throw $CLJS.TW;throw u;}throw M;}else throw $CLJS.TW;}catch(M){if(M instanceof Error){u=M;if(u===$CLJS.TW)return $CLJS.yY(f,d,e);throw u;}throw M;}else throw l;else throw J;}else throw l;else throw D;}}($CLJS.tf,a)},UY=$CLJS.Ek.g(wY,function(a){var b=VY($CLJS.JH.h(a))?$CLJS.Hk.j(a,$CLJS.JH,dja):a;b=VY($CLJS.IH.h(a))?$CLJS.Hk.j(b,$CLJS.IH,eja):b;b=VY($CLJS.DG.h(a))?$CLJS.Hk.j(b,
$CLJS.DG,$CLJS.Me($CLJS.Gk,IY)):b;b=VY($CLJS.pT.h(a))?$CLJS.Hk.j(b,$CLJS.pT,b_):b;return VY($CLJS.jS.h(a))?$CLJS.Hk.j(b,$CLJS.jS,gja):b}),Oja=new $CLJS.h(null,3,[$CLJS.KR,$CLJS.Rd,$CLJS.iO,new $CLJS.h(null,2,[$CLJS.jS,function(a){a=$CLJS.O(a);var b=$CLJS.I.g(a,$CLJS.KR);if($CLJS.n(b))return a=$CLJS.aX(a,new $CLJS.h(null,1,[$CLJS.KR,$CLJS.iO],null)),b=new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.KR],null),a=XY.g?XY.g(a,b):XY.call(null,a,b),$CLJS.aX(a,new $CLJS.h(null,1,[$CLJS.iO,$CLJS.KR],null));b=new $CLJS.Q(null,
1,5,$CLJS.R,[$CLJS.iO],null);return XY.g?XY.g(a,b):XY.call(null,a,b)},$CLJS.eP,new $CLJS.h(null,1,[YY,function(a){var b=new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.iO],null);return XY.g?XY.g(a,b):XY.call(null,a,b)}],null)],null),fZ,$CLJS.Rd],null),XY=function XY(a){switch(arguments.length){case 1:return XY.h(arguments[0]);case 2:return XY.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};XY.h=function(a){return XY.g(a,$CLJS.tf)};
XY.g=function(a,b){try{var c=$CLJS.z(b)?$CLJS.qC(Oja,b):null;return $CLJS.md(c)?c.h?c.h(a):c.call(null,a):$CLJS.xd(a)?a:$CLJS.wd(a)?kja(a,b):$CLJS.vd(a)?lja(a,b):a}catch(d){if(d instanceof Error)throw $CLJS.$h("Error removing empty clauses from form.",new $CLJS.h(null,2,[$CLJS.PB,a,$CLJS.jl,b],null),d);throw d;}};XY.A=2;
$CLJS.c_=function(){var a=$CLJS.Ek.l(XY,jja,hja,$CLJS.G([FY]));return function(b){try{return a(b)}catch(d){if(d instanceof Error){var c=d;throw $CLJS.$h($CLJS.WF("Error normalizing query: {0}",$CLJS.G([$CLJS.UW(c)])),new $CLJS.h(null,1,[$CLJS.iO,b],null),c);}throw d;}}}();$CLJS.d_=function d_(a,b){if($CLJS.Sa($CLJS.z(a)))a=(0,$CLJS.c_)(b);else{var d=$CLJS.I,e=d.g;var f=$CLJS.Eu(a);b=$CLJS.Ce([$CLJS.id(a),b]);f=d_.g?d_.g(f,b):d_.call(null,f,b);a=e.call(d,f,$CLJS.id(a))}return a};