var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./malli.registry.js");require("./malli.util.js");
'use strict';var IF,JF,KF,ada,LF,NF,OF;
$CLJS.HF=function(a,b){return function(){function c(l,m,t){l=null==l?b:l;return a.j?a.j(l,m,t):a.call(null,l,m,t)}function d(l,m){l=null==l?b:l;return a.g?a.g(l,m):a.call(null,l,m)}function e(l){l=null==l?b:l;return a.h?a.h(l):a.call(null,l)}var f=null,k=function(){function l(t,u,v,x){var A=null;if(3<arguments.length){A=0;for(var D=Array(arguments.length-3);A<D.length;)D[A]=arguments[A+3],++A;A=new $CLJS.y(D,0,null)}return m.call(this,t,u,v,A)}function m(t,u,v,x){return $CLJS.P.N(a,null==t?b:t,u,
v,x)}l.A=3;l.B=function(t){var u=$CLJS.B(t);t=$CLJS.C(t);var v=$CLJS.B(t);t=$CLJS.C(t);var x=$CLJS.B(t);t=$CLJS.Kc(t);return m(u,v,x,t)};l.l=m;return l}();f=function(l,m,t,u){switch(arguments.length){case 1:return e.call(this,l);case 2:return d.call(this,l,m);case 3:return c.call(this,l,m,t);default:var v=null;if(3<arguments.length){v=0;for(var x=Array(arguments.length-3);v<x.length;)x[v]=arguments[v+3],++v;v=new $CLJS.y(x,0,null)}return k.l(l,m,t,v)}throw Error("Invalid arity: "+arguments.length);
};f.A=3;f.B=k.B;f.h=e;f.g=d;f.j=c;f.l=k.l;return f}()};IF=function(a,b){this.db=a;this.vh=b;this.C=393216;this.I=0};JF=function(a){for(var b=null;;){var c;a=$CLJS.ro.g(a,b);a=c=$CLJS.Kn(a)?$CLJS.En(a):a;if(!$CLJS.Kn(c))return a}};KF=function(a){$CLJS.H(a,0,null);a=$CLJS.H(a,1,null);return!($CLJS.wd(a)&&!0===$CLJS.Ft.h(a))};
ada=function(a,b,c){var d=JF($CLJS.ro.g(a,c));return $CLJS.xq($CLJS.vn(d),$CLJS.sn(d),function(){var e=$CLJS.un(d);return b.h?b.h(e):b.call(null,e)}(),function(){var e=$CLJS.tn($CLJS.ro.g(d,null));return $CLJS.n(e)?e:c}())};LF=function(a){return function(b,c,d){var e=$CLJS.z(c);b=$CLJS.B(e);e=$CLJS.C(e);c=$CLJS.Gk.g(function(f){return $CLJS.ro.g(f,d)},c);return new $CLJS.Q(null,3,5,$CLJS.R,[c,$CLJS.Gk.g($CLJS.du,c),$CLJS.ab(function(f,k){return a.j?a.j(f,k,d):a.call(null,f,k,d)},b,e)],null)}};
$CLJS.MF=function(a,b,c,d,e,f,k,l,m,t,u,v,x,A,D,J,M,V,Z){this.form=a;this.options=b;this.qe=c;this.forms=d;this.U=e;this.Xd=f;this.ya=k;this.children=l;this.min=m;this.Eb=t;this.Rb=u;this.parent=v;this.Li=x;this.Me=A;this.type=D;this.cache=J;this.max=M;this.Ue=V;this.Uh=Z;this.C=393216;this.I=0};NF=function(a,b,c,d,e,f,k,l,m){this.Ue=a;this.qe=b;this.type=c;this.min=d;this.max=e;this.Xd=f;this.Eb=k;this.Rb=l;this.Me=m;this.C=393216;this.I=0};
OF=function(a){var b=$CLJS.O(a),c=$CLJS.I.g(b,$CLJS.dj),d=$CLJS.I.g(b,$CLJS.Sn),e=$CLJS.I.g(b,$CLJS.Zj),f=$CLJS.I.g(b,$CLJS.zu),k=$CLJS.I.g(b,$CLJS.Zp),l=$CLJS.I.g(b,$CLJS.ht);return new NF(a,b,c,d,e,f,k,l,new $CLJS.h(null,1,[$CLJS.dj,$CLJS.Ep],null))};$CLJS.Y=function(a,b){$CLJS.Gh.v(PF,$CLJS.S,a,b);$CLJS.Pe($CLJS.QF,$CLJS.N);return null};IF.prototype.P=function(a,b){return new IF(this.db,b)};IF.prototype.O=function(){return this.vh};IF.prototype.rd=$CLJS.r;
IF.prototype.bd=function(a,b){return $CLJS.fn($CLJS.hn($CLJS.q(this.db)),b)};var RF=function RF(a){switch(arguments.length){case 2:return RF.g(arguments[0],arguments[1]);case 3:return RF.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};RF.g=function(a,b){return RF.j(a,b,null)};
RF.j=function(a,b,c){function d(v,x){return $CLJS.F.g($CLJS.Yj,v)?new $CLJS.Q(null,2,5,$CLJS.R,[null,x],null):$CLJS.Ye.g(new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.Mq.h(x)],null),$CLJS.Oq.h(x))}function e(v,x){return $CLJS.n($CLJS.n(v)?x:v)?$CLJS.Wk.l($CLJS.G([v,x])):$CLJS.n(v)?v:x}a=$CLJS.n(a)?JF($CLJS.ro.g(a,c)):null;b=$CLJS.n(b)?JF($CLJS.ro.g(b,c)):null;var f=$CLJS.n(a)?$CLJS.Yn.h(a):null,k=$CLJS.n(b)?$CLJS.Yn.h(b):null,l=$CLJS.O(c),m=$CLJS.I.j(l,$CLJS.uu,function(v,x){return x}),t=$CLJS.I.j(l,$CLJS.xu,
function(v,x){return x});if(null==a)return b;if(null==b)return a;if($CLJS.Sa(function(){var v=new $CLJS.Tg(null,new $CLJS.h(null,2,[$CLJS.qt,null,$CLJS.Yj,null],null),null);v=v.h?v.h(f):v.call(null,f);return $CLJS.n(v)?(v=new $CLJS.Tg(null,new $CLJS.h(null,2,[$CLJS.qt,null,$CLJS.Yj,null],null),null),v.h?v.h(k):v.call(null,k)):v}()))return m.j?m.j(a,b,c):m.call(null,a,b,c);if($CLJS.F.g(f,$CLJS.Yj)&&$CLJS.F.g(k,$CLJS.Yj)){l=e($CLJS.sn(a),$CLJS.sn(b));var u=$CLJS.Oe($CLJS.Ug);a=$CLJS.ab(function(v,x){var A=
$CLJS.H(x,0,null);if($CLJS.n(function(){var D=$CLJS.q(u);return D.h?D.h(A):D.call(null,A)}()))return $CLJS.ab(function(D,J){var M=$CLJS.H(J,0,null),V=$CLJS.$d.g;if($CLJS.F.g(M,A)){M=$CLJS.H(J,0,null);var Z=$CLJS.H(J,1,null),fa=$CLJS.H(J,2,null);$CLJS.H(x,0,null);var Da=$CLJS.H(x,1,null),Ha=$CLJS.H(x,2,null);J=KF(J);var mb=KF(x);J=t.g?t.g(J,mb):t.call(null,J,mb);Z=$CLJS.Wk.l($CLJS.G([Z,Da]));Ha=new $CLJS.Q(null,3,5,$CLJS.R,[M,$CLJS.S.j(Z,$CLJS.Ft,$CLJS.Sa(J)),RF.j?RF.j(fa,Ha,c):RF.call(null,fa,Ha,
c)],null);M=$CLJS.H(Ha,0,null);fa=$CLJS.H(Ha,1,null);Ha=$CLJS.H(Ha,2,null);M=$CLJS.Sa(Ha)?new $CLJS.Q(null,2,5,$CLJS.R,[M,fa],null):$CLJS.n($CLJS.n(fa)?!1===$CLJS.Ft.h(fa)&&$CLJS.F.g(1,$CLJS.E(fa)):fa)?new $CLJS.Q(null,2,5,$CLJS.R,[M,Ha],null):$CLJS.Sa($CLJS.z(fa))?new $CLJS.Q(null,2,5,$CLJS.R,[M,Ha],null):!1===$CLJS.Ft.h(fa)?new $CLJS.Q(null,3,5,$CLJS.R,[M,$CLJS.wk.g(fa,$CLJS.Ft),Ha],null):new $CLJS.Q(null,3,5,$CLJS.R,[M,fa,Ha],null)}else M=J;return V.call($CLJS.$d,D,M)},$CLJS.tf,v);$CLJS.Gh.j(u,
$CLJS.$d,A);return $CLJS.$d.g(v,x)},$CLJS.tf,$CLJS.Wf.g($CLJS.un(a),$CLJS.un(b)));return $CLJS.xq($CLJS.Yj,l,a,c)}return function(v,x){var A=$CLJS.z(v);v=$CLJS.B(A);var D=$CLJS.C(A);A=$CLJS.B(D);D=$CLJS.C(D);var J=$CLJS.z(x);x=$CLJS.B(J);var M=$CLJS.C(J);J=$CLJS.B(M);M=$CLJS.C(M);return $CLJS.xq($CLJS.qt,e(v,x),$CLJS.Ye.l(new $CLJS.Q(null,1,5,$CLJS.R,[RF.j(A,J,c)],null),D,$CLJS.G([M])),c)}(d(f,a),d(k,b))};RF.A=3;
var SF=function SF(a){switch(arguments.length){case 2:return SF.g(arguments[0],arguments[1]);case 3:return SF.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};SF.g=function(a,b){return SF.j(a,b,null)};
SF.j=function(a,b,c){return RF.j(a,b,$CLJS.Hk.j($CLJS.Hk.j(c,$CLJS.uu,$CLJS.HF($CLJS.Rd,function(d,e,f){var k=$CLJS.F.g($CLJS.du.g(d,null),$CLJS.du.g(e,null));return $CLJS.n(k)?d:$CLJS.ro.g(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.kt,d,e],null),f)})),$CLJS.xu,$CLJS.HF($CLJS.Rd,function(d,e){return $CLJS.n(d)?e:d})))};SF.A=3;
var TF=function TF(a){switch(arguments.length){case 2:return TF.g(arguments[0],arguments[1]);case 3:return TF.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};TF.g=function(a,b){return TF.j(a,b,null)};TF.j=function(a,b,c){var d=$CLJS.Yg(b);return ada(a,function(e){return $CLJS.bf(function(f){f=$CLJS.H(f,0,null);return d.h?d.h(f):d.call(null,f)},e)},c)};TF.A=3;$CLJS.g=$CLJS.MF.prototype;
$CLJS.g.P=function(a,b){return new $CLJS.MF(this.form,this.options,this.qe,this.forms,this.U,this.Xd,this.ya,this.children,this.min,this.Eb,this.Rb,this.parent,this.Li,this.Me,this.type,this.cache,this.max,this.Ue,b)};$CLJS.g.O=function(){return this.Uh};$CLJS.g.Ya=$CLJS.r;$CLJS.g.xa=function(){return $CLJS.pn(this.ya)};$CLJS.g.La=function(){return this.options};$CLJS.g.Ta=function(a,b,c,d){return $CLJS.$o(this,new $CLJS.Q(null,1,5,$CLJS.R,[this.ya],null),b,c,d)};$CLJS.g.$a=function(){return this.U};
$CLJS.g.ta=function(){return this.children};$CLJS.g.Sa=function(){return $CLJS.q(this.form)};$CLJS.g.Ra=function(a,b){return $CLJS.qn(this.ya,b)};$CLJS.g.Za=function(){return this.parent};$CLJS.g.Wa=$CLJS.r;$CLJS.g.ia=$CLJS.ta(5);$CLJS.g.ie=$CLJS.r;$CLJS.g.Kd=function(){return this.ya};$CLJS.g=NF.prototype;$CLJS.g.P=function(a,b){return new NF(this.Ue,this.qe,this.type,this.min,this.max,this.Xd,this.Eb,this.Rb,b)};$CLJS.g.O=function(){return this.Me};$CLJS.g.Xa=$CLJS.r;$CLJS.g.Pa=function(){return this.type};
$CLJS.g.Qa=function(){return this.Eb};$CLJS.g.Oa=function(a,b,c,d){var e=this;$CLJS.Tn(e.type,b,c,e.min,e.max);a=function(){var m=$CLJS.uf(c);return e.Rb.j?e.Rb.j(b,m,d):e.Rb.call(null,b,m,d)}();var f=$CLJS.H(a,0,null),k=$CLJS.H(a,1,null),l=$CLJS.H(a,2,null);return new $CLJS.MF(new $CLJS.Bh(function(){return $CLJS.xo(e.type,b,k,d)}),d,e.qe,k,b,e.Xd,l,f,e.min,e.Eb,e.Rb,this,a,e.Me,e.type,$CLJS.wo(),e.max,e.Ue,new $CLJS.h(null,1,[$CLJS.dj,$CLJS.cq],null))};
$CLJS.UF=new $CLJS.K("metabase.lib.schema.temporal-bucketing","unit","metabase.lib.schema.temporal-bucketing/unit",1808010880);var PF;$CLJS.QF=$CLJS.Oe($CLJS.N);
PF=$CLJS.Oe($CLJS.Wk.l($CLJS.G([$CLJS.Bt(),new $CLJS.h(null,3,[$CLJS.vu,OF(new $CLJS.h(null,2,[$CLJS.dj,$CLJS.vu,$CLJS.ht,LF(RF)],null)),$CLJS.tu,OF(new $CLJS.h(null,2,[$CLJS.dj,$CLJS.tu,$CLJS.ht,LF(SF)],null)),$CLJS.Au,OF(new $CLJS.h(null,5,[$CLJS.dj,$CLJS.Au,$CLJS.zu,1,$CLJS.Sn,2,$CLJS.Zj,2,$CLJS.ht,function(a,b,c){return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Hk.j(b,0,function(d){return $CLJS.ro.g(d,c)}),$CLJS.Hk.j(b,0,function(d){return $CLJS.du.g(d,c)}),$CLJS.P.g(TF,$CLJS.$d.g(b,c))],null)}],null))],
null)])));$CLJS.Pe($CLJS.pl,$CLJS.hn(new IF(PF,$CLJS.N)));