var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.metadata.protocols.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.util.malli.registry.js");
'use strict';var U_,Bka,Cka,Dka,Eka,Fka,$_,Gka,Hka,Ika,c0,Jka,Kka,Lka,h0,i0,Mka,Nka,Oka,r0,Pka,Qka,Rka,u0,Ska,Tka,Uka,Vka;U_=function(){};$CLJS.V_=function(a){return null!=a?$CLJS.r===a.fi?!0:a.Vc?!1:$CLJS.Wa(U_,a):$CLJS.Wa(U_,a)};Bka=new $CLJS.w(null,"metabase.lib.metadata.protocols","metabase.lib.metadata.protocols",128660233,null);$CLJS.W_=new $CLJS.K("lib","source-column-alias","lib/source-column-alias",1494821386);
Cka=new $CLJS.K("metabase.lib.schema.metadata","database","metabase.lib.schema.metadata/database",270611124);Dka=new $CLJS.K("lib","persisted-info","lib/persisted-info",-118569625);$CLJS.X_=new $CLJS.K("source","previous-stage","source/previous-stage",1962610878);$CLJS.Y_=new $CLJS.K(null,"database-id","database-id",1883826326);Eka=new $CLJS.w("metabase.lib.metadata.protocols","metadata-provider?","metabase.lib.metadata.protocols/metadata-provider?",-472923865,null);
$CLJS.Z_=new $CLJS.K("source","table-defaults","source/table-defaults",909975194);Fka=new $CLJS.K(null,"human-readable-values","human-readable-values",-624842907);$_=new $CLJS.K("metabase.lib.schema.metadata","column.remapping.internal","metabase.lib.schema.metadata/column.remapping.internal",-285151559);Gka=new $CLJS.K("metabase.lib.schema.metadata","metadata-providerable","metabase.lib.schema.metadata/metadata-providerable",1520216538);Hka=new $CLJS.K(null,"dbms-version","dbms-version",-69238036);
Ika=new $CLJS.K(null,"field-name","field-name",1300687948);$CLJS.a0=new $CLJS.K("metabase.lib.schema.metadata","column","metabase.lib.schema.metadata/column",-570344650);$CLJS.b0=new $CLJS.K(null,"dataset","dataset",1159262238);c0=new $CLJS.K("metabase.lib.schema.metadata","persisted-info","metabase.lib.schema.metadata/persisted-info",1457716669);Jka=new $CLJS.K(null,"field-definitions","field-definitions",1650155116);$CLJS.d0=new $CLJS.K("source","expressions","source/expressions",-458367840);
$CLJS.e0=new $CLJS.K(null,"definition","definition",-1198729982);Kka=new $CLJS.K(null,"state","state",-1988618099);$CLJS.f0=new $CLJS.K("source","implicitly-joinable","source/implicitly-joinable",-729101197);Lka=new $CLJS.K(null,"is-audit","is-audit",327058420);$CLJS.g0=new $CLJS.K("source","joins","source/joins",1225821486);h0=new $CLJS.K("metabase.lib.schema.metadata","column-source","metabase.lib.schema.metadata/column-source",1071627828);
i0=new $CLJS.K("metabase.lib.schema.metadata","metadata-provider","metabase.lib.schema.metadata/metadata-provider",-1514728827);$CLJS.j0=new $CLJS.K("lib","desired-column-alias","lib/desired-column-alias",-1998967402);$CLJS.k0=new $CLJS.K(null,"fk-target-field-id","fk-target-field-id",2019750287);Mka=new $CLJS.K("lib","external-remap","lib/external-remap",543358316);$CLJS.l0=new $CLJS.K(null,"selected?","selected?",-742502788);
Nka=new $CLJS.K("metabase.lib.schema.metadata","segment","metabase.lib.schema.metadata/segment",-393440267);Oka=new $CLJS.K("metabase.lib.schema.metadata","metric","metabase.lib.schema.metadata/metric",1984974682);$CLJS.m0=new $CLJS.K(null,"table-name","table-name",-2117866341);$CLJS.n0=new $CLJS.K("source","card","source/card",-139977973);$CLJS.o0=new $CLJS.K(null,"active","active",1895962068);$CLJS.p0=new $CLJS.K(null,"dataset-query","dataset-query",1851066427);
$CLJS.q0=new $CLJS.K(null,"result-metadata","result-metadata",2017934672);r0=new $CLJS.K("metabase.lib.schema.metadata","column.remapping.external","metabase.lib.schema.metadata/column.remapping.external",304054297);Pka=new $CLJS.K(null,"details","details",1956795411);$CLJS.s0=new $CLJS.K("source","fields","source/fields",-649667981);Qka=new $CLJS.K("metabase.lib.schema.metadata","card","metabase.lib.schema.metadata/card",147726027);$CLJS.t0=new $CLJS.K("source","native","source/native",-1444604147);
Rka=new $CLJS.K("metabase.lib.schema.metadata","table","metabase.lib.schema.metadata/table",-2128230385);u0=new $CLJS.K("metabase.lib.schema.metadata","persisted-info.definition","metabase.lib.schema.metadata/persisted-info.definition",496848961);$CLJS.v0=new $CLJS.K("lib","card-id","lib/card-id",-1770167062);Ska=new $CLJS.K("metadata.column.remapping","internal","metadata.column.remapping/internal",-1077920983);Tka=new $CLJS.w(null,"metadata-provider?","metadata-provider?",1669871524,null);
Uka=new $CLJS.K("lib","internal-remap","lib/internal-remap",-220033801);Vka=new $CLJS.K("metadata.column.remapping","external","metadata.column.remapping/external",-1813287755);$CLJS.w0=new $CLJS.K(null,"source-alias","source-alias",1652088724);$CLJS.x0=new $CLJS.K(null,"engine","engine",1459054265);$CLJS.Y(h0,new $CLJS.Q(null,11,5,$CLJS.R,[$CLJS.bk,$CLJS.n0,$CLJS.t0,$CLJS.X_,$CLJS.Z_,$CLJS.s0,$CLJS.KM,$CLJS.EM,$CLJS.g0,$CLJS.d0,$CLJS.f0],null));$CLJS.Y(r0,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ov,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ns,Vka],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Qi,$CLJS.xM],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.T,$CLJS.eG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.yM,$CLJS.IM],null)],null));
$CLJS.Y($_,new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ov,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ns,Ska],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Qi,$CLJS.xM],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.T,$CLJS.eG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.au,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.qj,$CLJS.zt],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[Fka,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.qj,$CLJS.zt],null)],null)],null));
$CLJS.Y($CLJS.a0,new $CLJS.Q(null,18,5,$CLJS.R,[$CLJS.Yj,new $CLJS.h(null,1,[$CLJS.wu,"Valid column metadata"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ov,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ns,$CLJS.pM],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.T,$CLJS.Pj],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.gD,$CLJS.mG],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),$CLJS.IM],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.aG,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,
2,5,$CLJS.R,[$CLJS.jt,$CLJS.Pj],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.xi,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jt,$CLJS.mG],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.BM,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jt,$CLJS.IM],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.k0,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jt,$CLJS.IM],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.w0,
new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jt,$CLJS.eG],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.rM,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,h0],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.v0,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jt,$CLJS.zM],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.W_,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jt,$CLJS.eG],
null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.j0,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Pj,new $CLJS.h(null,2,[$CLJS.Sn,1,$CLJS.Zj,60],null)],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.l0,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),$CLJS.yt],null),new $CLJS.Q(null,3,5,$CLJS.R,[Mka,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,r0],null)],null)],
null),new $CLJS.Q(null,3,5,$CLJS.R,[Uka,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$_],null)],null)],null)],null));
$CLJS.Y(u0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.m0,$CLJS.eG],null),new $CLJS.Q(null,2,5,$CLJS.R,[Jka,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.qj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,2,5,$CLJS.R,[Ika,$CLJS.eG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.gD,$CLJS.mG],null)],null)],null)],null)],null)],null));
$CLJS.Y(c0,new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.o0,$CLJS.yt],null),new $CLJS.Q(null,2,5,$CLJS.R,[Kka,$CLJS.eG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.m0,$CLJS.eG],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.e0,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,u0],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.oM,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jt,
$CLJS.eG],null)],null)],null));
$CLJS.Y(Qka,new $CLJS.Q(null,11,5,$CLJS.R,[$CLJS.Yj,new $CLJS.h(null,1,[$CLJS.wu,"Valid Card metadata"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ov,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ns,$CLJS.JM],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Qi,$CLJS.zM],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.T,$CLJS.eG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Y_,$CLJS.AM],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.p0,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),$CLJS.Yj],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.q0,
new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.qj,$CLJS.Yj],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.b0,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),$CLJS.yt],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.CM,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jt,$CLJS.uM],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[Dka,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jt,new $CLJS.Q(null,
2,5,$CLJS.R,[$CLJS.X,c0],null)],null)],null)],null));
$CLJS.Y(Nka,new $CLJS.Q(null,8,5,$CLJS.R,[$CLJS.Yj,new $CLJS.h(null,1,[$CLJS.wu,"Valid Segment metadata"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ov,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ns,$CLJS.tM],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Qi,$CLJS.sM],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.T,$CLJS.eG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.CM,$CLJS.uM],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.e0,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jt,$CLJS.Yj],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,
[$CLJS.Tu,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jt,$CLJS.eG],null)],null)],null));
$CLJS.Y(Oka,new $CLJS.Q(null,8,5,$CLJS.R,[$CLJS.Yj,new $CLJS.h(null,1,[$CLJS.wu,"Valid Metric metadata"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ov,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ns,$CLJS.vM],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Qi,$CLJS.qM],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.T,$CLJS.eG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.CM,$CLJS.uM],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.e0,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jt,$CLJS.Yj],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,
[$CLJS.Tu,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jt,$CLJS.eG],null)],null)],null));
$CLJS.Y(Rka,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.Yj,new $CLJS.h(null,1,[$CLJS.wu,"Valid Table metadata"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ov,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ns,$CLJS.wM],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Qi,$CLJS.uM],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.T,$CLJS.eG],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.aG,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jt,$CLJS.eG],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ck,new $CLJS.h(null,
1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jt,$CLJS.eG],null)],null)],null));
$CLJS.Y(Cka,new $CLJS.Q(null,10,5,$CLJS.R,[$CLJS.Yj,new $CLJS.h(null,1,[$CLJS.wu,"Valid Database metadata"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ov,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ns,$CLJS.GM],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Qi,$CLJS.AM],null),new $CLJS.Q(null,3,5,$CLJS.R,[Hka,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jt,$CLJS.Yj],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[Pka,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),$CLJS.Yj],null),new $CLJS.Q(null,
3,5,$CLJS.R,[$CLJS.x0,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),$CLJS.Oi],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.XB,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ri,$CLJS.Oi],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[Lka,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),$CLJS.yt],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.DM,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jt,$CLJS.Yj],null)],null)],null));
$CLJS.Y(i0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ht,new $CLJS.h(null,1,[$CLJS.wu,"Valid MetadataProvider"],null),new $CLJS.Fc(function(){return $CLJS.V_},Eka,$CLJS.Ag([$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,$CLJS.Ki,$CLJS.ej,$CLJS.nk,$CLJS.mi,$CLJS.U,$CLJS.ek],[Bka,Tka,"metabase/lib/metadata/protocols.cljc",25,1,78,78,$CLJS.W(new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.Tq],null)),"Whether `x` is a valid [[MetadataProvider]].",$CLJS.n($CLJS.V_)?$CLJS.V_.H:null]))],null));
$CLJS.Y(Gka,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.kt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,i0],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Yj,new $CLJS.h(null,1,[$CLJS.wu,"map with a MetadataProvider in the key :lib/metadata (i.e. a query)"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.XF,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,i0],null)],null)],null)],null));