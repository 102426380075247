var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.filter.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.schema.order_by.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.util.malli.registry.js");
'use strict';var b6,f6,g6,r6,t6,u6,$na,v6,E6,P6,Q6,R6;b6=new $CLJS.K("metabase.lib.schema.drill-thru","drill-thru.common","metabase.lib.schema.drill-thru/drill-thru.common",1573044895);$CLJS.c6=new $CLJS.K("metabase.lib.schema.drill-thru","drill-thru.pk","metabase.lib.schema.drill-thru/drill-thru.pk",339574481);$CLJS.d6=new $CLJS.K("drill-thru","quick-filter","drill-thru/quick-filter",-1465756422);$CLJS.e6=new $CLJS.K(null,"row","row",-570139521);
f6=new $CLJS.K("metabase.lib.schema.drill-thru","drill-thru.fk-details.fk-column","metabase.lib.schema.drill-thru/drill-thru.fk-details.fk-column",-380335831);g6=new $CLJS.K("metabase.lib.schema.drill-thru","context.row.value","metabase.lib.schema.drill-thru/context.row.value",1438731480);$CLJS.h6=new $CLJS.K("metabase.lib.schema.drill-thru","drill-thru.distribution","metabase.lib.schema.drill-thru/drill-thru.distribution",937770403);$CLJS.i6=new $CLJS.K(null,"pivots","pivots",90109371);
$CLJS.j6=new $CLJS.K("metabase.lib.schema.drill-thru","drill-thru.pivot","metabase.lib.schema.drill-thru/drill-thru.pivot",-1408664484);$CLJS.k6=new $CLJS.K("metabase.lib.schema.drill-thru","context","metabase.lib.schema.drill-thru/context",122725764);$CLJS.l6=new $CLJS.K(null,"location","location",1815599388);$CLJS.m6=new $CLJS.K("metabase.lib.schema.drill-thru","drill-thru.column-filter","metabase.lib.schema.drill-thru/drill-thru.column-filter",603928893);
$CLJS.n6=new $CLJS.K("metabase.lib.schema.drill-thru","drill-thru.summarize-column-by-time","metabase.lib.schema.drill-thru/drill-thru.summarize-column-by-time",1590982743);$CLJS.o6=new $CLJS.K(null,"initial-op","initial-op",918033121);$CLJS.p6=new $CLJS.K("metabase.lib.schema.drill-thru","drill-thru.summarize-column","metabase.lib.schema.drill-thru/drill-thru.summarize-column",1770935578);$CLJS.q6=new $CLJS.K("drill-thru","column-filter","drill-thru/column-filter",1535293733);
r6=new $CLJS.K("metabase.lib.schema.drill-thru","drill-thru.automatic-insights","metabase.lib.schema.drill-thru/drill-thru.automatic-insights",671448614);$CLJS.s6=new $CLJS.K("drill-thru","fk-filter","drill-thru/fk-filter",1598101197);t6=new $CLJS.K("drill-thru","automatic-insights","drill-thru/automatic-insights",10252211);u6=new $CLJS.K("metabase.lib.schema.drill-thru","drill-thru.zoom-in.timeseries.next-unit","metabase.lib.schema.drill-thru/drill-thru.zoom-in.timeseries.next-unit",-2018118498);
$na=new $CLJS.K("metabase.lib.schema.drill-thru","drill-thru","metabase.lib.schema.drill-thru/drill-thru",-124537581);v6=new $CLJS.K("metabase.lib.schema.drill-thru","drill-thru.object-details","metabase.lib.schema.drill-thru/drill-thru.object-details",-725614444);$CLJS.w6=new $CLJS.K("drill-thru","sort","drill-thru/sort",511059541);$CLJS.x6=new $CLJS.K("drill-thru","summarize-column","drill-thru/summarize-column",-156285576);$CLJS.y6=new $CLJS.K(null,"sort-directions","sort-directions",300459345);
$CLJS.z6=new $CLJS.K(null,"aggregations","aggregations",-1081114338);$CLJS.A6=new $CLJS.K("metabase.lib.schema.drill-thru","drill-thru.fk-details","metabase.lib.schema.drill-thru/drill-thru.fk-details",233147406);$CLJS.B6=new $CLJS.K("metabase.lib.schema.drill-thru","drill-thru.quick-filter","metabase.lib.schema.drill-thru/drill-thru.quick-filter",-1703184103);$CLJS.C6=new $CLJS.K(null,"row-count","row-count",1060167988);$CLJS.D6=new $CLJS.K("drill-thru","distribution","drill-thru/distribution",1480876450);
E6=new $CLJS.K("metabase.lib.schema.drill-thru","drill-thru.quick-filter.operator","metabase.lib.schema.drill-thru/drill-thru.quick-filter.operator",-1269703311);$CLJS.F6=new $CLJS.K("drill-thru","underlying-records","drill-thru/underlying-records",-1420917644);$CLJS.G6=new $CLJS.K("metabase.lib.schema.drill-thru","drill-thru.fk-filter","metabase.lib.schema.drill-thru/drill-thru.fk-filter",-1219793148);
$CLJS.H6=new $CLJS.K("metabase.lib.schema.drill-thru","drill-thru.zoom","metabase.lib.schema.drill-thru/drill-thru.zoom",-262809778);$CLJS.I6=new $CLJS.K(null,"next-unit","next-unit",166270362);$CLJS.J6=new $CLJS.K("drill-thru","summarize-column-by-time","drill-thru/summarize-column-by-time",-765720821);$CLJS.K6=new $CLJS.K("metabase.lib.schema.drill-thru","drill-thru.zoom-in.timeseries","metabase.lib.schema.drill-thru/drill-thru.zoom-in.timeseries",-890057316);
$CLJS.L6=new $CLJS.K("drill-thru","pivot","drill-thru/pivot",-1038336574);$CLJS.M6=new $CLJS.K("drill-thru","zoom-in.timeseries","drill-thru/zoom-in.timeseries",-168450975);$CLJS.N6=new $CLJS.K("metabase.lib.schema.drill-thru","drill-thru.underlying-records","metabase.lib.schema.drill-thru/drill-thru.underlying-records",1571614324);$CLJS.O6=new $CLJS.K("metabase.lib.schema.drill-thru","drill-thru.sort","metabase.lib.schema.drill-thru/drill-thru.sort",-920673199);
P6=new $CLJS.K("metabase.lib.schema.drill-thru","context.row","metabase.lib.schema.drill-thru/context.row",-1467255282);Q6=new $CLJS.K("metabase.lib.schema.drill-thru","drill-thru.summarize-column.aggregation-type","metabase.lib.schema.drill-thru/drill-thru.summarize-column.aggregation-type",1395206778);R6=new $CLJS.K("metabase.lib.schema.drill-thru","pivot-types","metabase.lib.schema.drill-thru/pivot-types",1174694312);$CLJS.Y(R6,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.bk,$CLJS.oI,$CLJS.l6,$CLJS.wA],null));$CLJS.Y(b6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.dj,$CLJS.ee],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ov,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ns,$CLJS.dN],null)],null)],null));
$CLJS.Y(v6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vu,b6,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ki,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.a0],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.XM,$CLJS.zt],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.WM,$CLJS.yt],null)],null)],null));
$CLJS.Y($CLJS.c6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vu,v6,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.dj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ns,$CLJS.aN],null)],null)],null)],null));$CLJS.Y(f6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vu,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.a0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.k0,$CLJS.IM],null)],null)],null));
$CLJS.Y($CLJS.A6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vu,v6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.dj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ns,$CLJS.bN],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ki,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,f6],null)],null)],null)],null));
$CLJS.Y($CLJS.H6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vu,v6,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.dj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ns,$CLJS.fN],null)],null)],null)],null));$CLJS.Y(E6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.T,$CLJS.eG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.xH,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.IG],null)],null)],null));
$CLJS.Y($CLJS.B6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vu,b6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.dj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ns,$CLJS.d6],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ZM,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.qj,E6],null)],null)],null)],null));
$CLJS.Y($CLJS.G6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vu,b6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.dj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ns,$CLJS.s6],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.xH,$CLJS.IG],null)],null)],null));
$CLJS.Y($CLJS.h6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vu,b6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.dj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ns,$CLJS.D6],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ki,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.a0],null)],null)],null)],null));
$CLJS.Y($CLJS.j6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vu,b6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.dj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ns,$CLJS.L6],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.i6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ot,R6,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.qj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.a0],null)],null)],null)],null)],null)],null));
$CLJS.Y($CLJS.O6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vu,b6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.dj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ns,$CLJS.w6],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.y6,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.qj,$CLJS.YM],null)],null)],null)],null));$CLJS.Y(Q6,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.bk,$CLJS.OH,$CLJS.yH,$CLJS.gI],null));
$CLJS.Y($CLJS.p6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vu,b6,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.dj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ns,$CLJS.x6],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ki,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.a0],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.z6,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.qj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,Q6],null)],null)],null)],null)],null));
$CLJS.Y($CLJS.n6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vu,b6,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.dj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ns,$CLJS.J6],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ki,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.a0],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.IH,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.a0],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ki,$CLJS.UF],null)],null)],null));
$CLJS.Y($CLJS.m6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vu,b6,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.dj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ns,$CLJS.q6],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ki,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.a0],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.o6,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jt,$CLJS.iI],null)],null)],null)],null));
$CLJS.Y($CLJS.N6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vu,b6,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.dj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ns,$CLJS.F6],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.C6,$CLJS.ul],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.m0,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jt,$CLJS.Va],null)],null)],null)],null));
$CLJS.Y(r6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vu,b6,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.dj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ns,t6],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ov,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ns,$CLJS.dN],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ki,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.a0],null)],null)],null)],null));
$CLJS.Y(u6,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.bk,$CLJS.hi,$CLJS.oj,$CLJS.fj,$CLJS.pv,$CLJS.gk,$CLJS.Li],null));
$CLJS.Y($CLJS.K6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vu,b6,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.dj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ns,$CLJS.M6],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ki,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.a0],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.uj,$CLJS.Ta],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.I6,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,u6],null)],null)],null)],null));
$CLJS.Y($na,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.qt,b6,new $CLJS.Q(null,16,5,$CLJS.R,[$CLJS.pt,new $CLJS.h(null,2,[$CLJS.Gi,$CLJS.dj,$CLJS.yu,function(a){a=$CLJS.O(a);a=$CLJS.I.g(a,$CLJS.uj);return["Invalid drill thru (unknown :type): ",$CLJS.Eh.l($CLJS.G([a]))].join("")}],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.aN,$CLJS.c6],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bN,$CLJS.A6],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fN,$CLJS.H6],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.d6,$CLJS.B6],null),new $CLJS.Q(null,
2,5,$CLJS.R,[$CLJS.s6,$CLJS.G6],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.D6,$CLJS.h6],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.L6,$CLJS.j6],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.w6,$CLJS.O6],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.x6,$CLJS.p6],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.J6,$CLJS.n6],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.q6,$CLJS.m6],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.F6,$CLJS.N6],null),new $CLJS.Q(null,2,5,$CLJS.R,[t6,r6],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.M6,
$CLJS.K6],null)],null)],null));$CLJS.Y(g6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.EH,$CLJS.Va],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.uj,$CLJS.zt],null)],null));$CLJS.Y(P6,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.qj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,g6],null)],null));
$CLJS.Y($CLJS.k6,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ki,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.a0],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.uj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jt,$CLJS.zt],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.e6,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,P6],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.$M,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jt,
new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,P6],null)],null)],null)],null));