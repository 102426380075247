var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./metabase.types.coercion_hierarchies.js");require("./metabase.util.malli.js");require("./metabase.util.js");
'use strict';var uca,vca,hE,iE,jE,wca,lE,nE,oE,pE,xca,rE,sE,tE,yca,kE,uE,zca,Aca,vE,wE,xE,yE,AE,DE,EE,GE,Bca,Cca,JE,LE,ME,OE,Dca,PE,Eca,Fca,Gca,Hca,QE,RE,Ica,TE,Jca,VE,WE,YE,$E,aF,bF,dF,eF,gF,hF,iF,jF,kF,lF,Lca,Mca,nF,Nca,oF,pF,qF,Oca,Pca,Qca,rF,sF,Rca,Sca,uF,vF,Tca,wF,xF,Uca,Vca,Wca,DF,Xca,Yca,Zca;uca=function(a){return function(b,c){b=a.g?a.g(b,c):a.call(null,b,c);return $CLJS.Sc(b)?$CLJS.Rc(b):b}};
$CLJS.eE=function(a,b,c){return function(){function d(m,t,u){var v=a.h?a.h(m):a.call(null,m);if($CLJS.n(v))return v;v=a.h?a.h(t):a.call(null,t);if($CLJS.n(v))return v;v=a.h?a.h(u):a.call(null,u);if($CLJS.n(v))return v;v=b.h?b.h(m):b.call(null,m);if($CLJS.n(v))return v;v=b.h?b.h(t):b.call(null,t);if($CLJS.n(v))return v;v=b.h?b.h(u):b.call(null,u);if($CLJS.n(v))return v;m=c.h?c.h(m):c.call(null,m);if($CLJS.n(m))return m;t=c.h?c.h(t):c.call(null,t);return $CLJS.n(t)?t:c.h?c.h(u):c.call(null,u)}function e(m,
t){var u=a.h?a.h(m):a.call(null,m);if($CLJS.n(u))return u;u=a.h?a.h(t):a.call(null,t);if($CLJS.n(u))return u;u=b.h?b.h(m):b.call(null,m);if($CLJS.n(u))return u;u=b.h?b.h(t):b.call(null,t);if($CLJS.n(u))return u;m=c.h?c.h(m):c.call(null,m);return $CLJS.n(m)?m:c.h?c.h(t):c.call(null,t)}function f(m){var t=a.h?a.h(m):a.call(null,m);if($CLJS.n(t))return t;t=b.h?b.h(m):b.call(null,m);return $CLJS.n(t)?t:c.h?c.h(m):c.call(null,m)}var k=null,l=function(){function m(u,v,x,A){var D=null;if(3<arguments.length){D=
0;for(var J=Array(arguments.length-3);D<J.length;)J[D]=arguments[D+3],++D;D=new $CLJS.y(J,0,null)}return t.call(this,u,v,x,D)}function t(u,v,x,A){u=k.j(u,v,x);return $CLJS.n(u)?u:$CLJS.Je(function(D){var J=a.h?a.h(D):a.call(null,D);if($CLJS.n(J))return J;J=b.h?b.h(D):b.call(null,D);return $CLJS.n(J)?J:c.h?c.h(D):c.call(null,D)},A)}m.A=3;m.B=function(u){var v=$CLJS.B(u);u=$CLJS.C(u);var x=$CLJS.B(u);u=$CLJS.C(u);var A=$CLJS.B(u);u=$CLJS.Kc(u);return t(v,x,A,u)};m.l=t;return m}();k=function(m,t,u,v){switch(arguments.length){case 0:return null;
case 1:return f.call(this,m);case 2:return e.call(this,m,t);case 3:return d.call(this,m,t,u);default:var x=null;if(3<arguments.length){x=0;for(var A=Array(arguments.length-3);x<A.length;)A[x]=arguments[x+3],++x;x=new $CLJS.y(A,0,null)}return l.l(m,t,u,x)}throw Error("Invalid arity: "+arguments.length);};k.A=3;k.B=l.B;k.o=function(){return null};k.h=f;k.g=e;k.j=d;k.l=l.l;return k}()};vca=function(a,b){return $CLJS.De($CLJS.I.g($CLJS.Kh.h(a),b))};
$CLJS.fE=function(a){var b=uca(a);return function(){function c(k,l){return $CLJS.ab(b,k,l)}function d(k){return a.h?a.h(k):a.call(null,k)}function e(){return a.o?a.o():a.call(null)}var f=null;f=function(k,l){switch(arguments.length){case 0:return e.call(this);case 1:return d.call(this,k);case 2:return c.call(this,k,l)}throw Error("Invalid arity: "+arguments.length);};f.o=e;f.h=d;f.g=c;return f}()};$CLJS.gE=function(a,b){return $CLJS.Oh($CLJS.q($CLJS.Nh()),a,b)};
hE=function(a){return $CLJS.n($CLJS.eE($CLJS.vd,$CLJS.ud,$CLJS.tl)(a))?a:new $CLJS.Q(null,1,5,$CLJS.R,[a],null)};iE=function(a,b,c){b=$CLJS.Yg(hE(b));$CLJS.Gh.v($CLJS.$D,$CLJS.S,a,b);$CLJS.Gh.v($CLJS.aE,$CLJS.S,a,c)};
jE=function(a){return $CLJS.ab(function(b,c){var d=$CLJS.H(c,0,null);c=$CLJS.H(c,1,null);return $CLJS.UC.j(b,d,c)},$CLJS.q(function(){var b=new $CLJS.Fc(function(){return $CLJS.Nh},$CLJS.ZD,$CLJS.Ag([$CLJS.lk,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,$CLJS.Ki,$CLJS.ej,$CLJS.nk,$CLJS.mi,$CLJS.U,$CLJS.ek],[!0,$CLJS.ei,$CLJS.XD,"cljs/core.cljs",28,1,11153,11153,$CLJS.W($CLJS.tf),null,$CLJS.n($CLJS.Nh)?$CLJS.Nh.H:null]));return b.o?b.o():b.call(null)}()),a)};
wca=function(){$CLJS.n($CLJS.q($CLJS.cE))||$CLJS.n($CLJS.q($CLJS.cE))||$CLJS.Pe($CLJS.cE,jE(function(){return function c(b){return new $CLJS.le(null,function(){for(var d=b;;){var e=$CLJS.z(d);if(e){var f=e,k=$CLJS.B(f),l=$CLJS.H(k,0,null),m=$CLJS.H(k,1,null);if(e=$CLJS.z(function(t,u,v,x,A,D){return function V(M){return new $CLJS.le(null,function(Z,fa,Da){return function(){for(;;){var Ha=$CLJS.z(M);if(Ha){if($CLJS.zd(Ha)){var mb=$CLJS.kc(Ha),Qb=$CLJS.E(mb),Ab=$CLJS.oe(Qb);a:for(var jc=0;;)if(jc<Qb){var Tb=
$CLJS.kd(mb,jc);Ab.add(new $CLJS.Q(null,2,5,$CLJS.R,[Tb,Da],null));jc+=1}else{mb=!0;break a}return mb?$CLJS.re($CLJS.te(Ab),V($CLJS.lc(Ha))):$CLJS.re($CLJS.te(Ab),null)}Ab=$CLJS.B(Ha);return $CLJS.ce(new $CLJS.Q(null,2,5,$CLJS.R,[Ab,Da],null),V($CLJS.Kc(Ha)))}return null}}}(t,u,v,x,A,D),null,null)}}(d,k,l,m,f,e)(m)))return $CLJS.Ye.g(e,c($CLJS.Kc(d)));d=$CLJS.Kc(d)}else return null}},null,null)}($CLJS.q($CLJS.$D))}()));return $CLJS.q($CLJS.cE)};
lE=function(a){var b=wca();$CLJS.n($CLJS.q($CLJS.dE))||$CLJS.n($CLJS.q($CLJS.dE))||$CLJS.Pe($CLJS.dE,jE($CLJS.z($CLJS.q($CLJS.aE))));var c=$CLJS.q($CLJS.dE);return $CLJS.De($CLJS.ab($CLJS.Me($CLJS.nz,$CLJS.qv),$CLJS.Yl($CLJS.q($CLJS.bE),new $CLJS.Q(null,1,5,$CLJS.R,[a],null)),function(){return function f(e){return new $CLJS.le(null,function(){for(var k=e;;){var l=$CLJS.z(k);if(l){var m=l,t=$CLJS.B(m);if($CLJS.gE(t,kE)){var u=$CLJS.Ph(c,t);if(l=$CLJS.z(function(v,x,A,D,J,M,V){return function Da(fa){return new $CLJS.le(null,
function(Ha,mb,Qb){return function(){for(var Ab=fa;;)if(Ab=$CLJS.z(Ab)){if($CLJS.zd(Ab)){var jc=$CLJS.kc(Ab),Tb=$CLJS.E(jc),rc=$CLJS.oe(Tb);a:for(var Vb=0;;)if(Vb<Tb){var Sb=$CLJS.kd(jc,Vb);$CLJS.gE(Sb,kE)||(Sb=$CLJS.Ce([Sb,$CLJS.Vg([Qb])]),rc.add(Sb));Vb+=1}else{jc=!0;break a}return jc?$CLJS.re($CLJS.te(rc),Da($CLJS.lc(Ab))):$CLJS.re($CLJS.te(rc),null)}rc=$CLJS.B(Ab);if($CLJS.gE(rc,kE))Ab=$CLJS.Kc(Ab);else return $CLJS.ce($CLJS.Ce([rc,$CLJS.Vg([Qb])]),Da($CLJS.Kc(Ab)))}else return null}}(v,x,A,D,
J,M,V),null,null)}}(k,u,t,m,l,b,c)(u)))return $CLJS.Ye.g(l,f($CLJS.Kc(k)))}k=$CLJS.Kc(k)}else return null}},null,null)}(vca(b,a))}()))};$CLJS.mE=function mE(a){switch(arguments.length){case 1:return mE.h(arguments[0]);case 2:return mE.g(arguments[0],arguments[1]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return mE.l(arguments[0],arguments[1],new $CLJS.y(c.slice(2),0,null))}};$CLJS.mE.h=function(){return!0};
$CLJS.mE.g=function(a,b){return!$CLJS.F.g(a,b)};$CLJS.mE.l=function(a,b,c){if($CLJS.F.g(a,b))return!1;a=$CLJS.Vg([a,b]);for(b=c;;){var d=$CLJS.B(b);c=$CLJS.C(b);if($CLJS.n(b)){if($CLJS.Gd(a,d))return!1;a=$CLJS.$d.g(a,d);b=c}else return!0}};$CLJS.mE.B=function(a){var b=$CLJS.B(a),c=$CLJS.C(a);a=$CLJS.B(c);c=$CLJS.C(c);return this.l(b,a,c)};$CLJS.mE.A=2;nE=new $CLJS.K("type","Location","type/Location",-1929284186);oE=new $CLJS.K("type","Score","type/Score",188189565);
pE=new $CLJS.K("type","CancelationTime","type/CancelationTime",1076177064);xca=new $CLJS.K("type","Company","type/Company",-1114287726);$CLJS.qE=new $CLJS.K("type","IPAddress","type/IPAddress",-808425343);rE=new $CLJS.K("Coercion","Number-\x3eTemporal","Coercion/Number-\x3eTemporal",-1061171580);sE=new $CLJS.K("type","CreationTimestamp","type/CreationTimestamp",-687400081);tE=new $CLJS.K("type","DateTimeWithLocalTZ","type/DateTimeWithLocalTZ",339704031);
yca=new $CLJS.K("type","PostgresEnum","type/PostgresEnum",-900722397);kE=new $CLJS.K("Coercion","*","Coercion/*",1713686116);uE=new $CLJS.K("Coercion","ISO8601-\x3eDateTime","Coercion/ISO8601-\x3eDateTime",-1255976090);zca=new $CLJS.K("type","Source","type/Source",1060815848);Aca=new $CLJS.K("entity","SubscriptionTable","entity/SubscriptionTable",1660366729);vE=new $CLJS.K("type","JoinTemporal","type/JoinTemporal",-1465575331);wE=new $CLJS.K("type","DeletionTimestamp","type/DeletionTimestamp",-935598691);
xE=new $CLJS.K("type","JoinTimestamp","type/JoinTimestamp",1554527110);yE=new $CLJS.K("type","JSON","type/JSON",-14729800);$CLJS.zE=new $CLJS.K("type","Currency","type/Currency",713609092);AE=new $CLJS.K("Coercion","String-\x3eTemporal","Coercion/String-\x3eTemporal",946586714);$CLJS.BE=new $CLJS.K("type","ZipCode","type/ZipCode",845484542);$CLJS.CE=new $CLJS.K("type","URL","type/URL",-1433976351);DE=new $CLJS.K("type","CreationTime","type/CreationTime",-1473681229);
EE=new $CLJS.K("Coercion","UNIXSeconds-\x3eDateTime","Coercion/UNIXSeconds-\x3eDateTime",-1635093627);$CLJS.FE=new $CLJS.K("type","Comment","type/Comment",-1406574403);GE=new $CLJS.K("Coercion","ISO8601-\x3eTemporal","Coercion/ISO8601-\x3eTemporal",410003391);Bca=new $CLJS.K("type","GrossMargin","type/GrossMargin",1961535799);$CLJS.HE=new $CLJS.K("type","Date","type/Date",-690428629);Cca=new $CLJS.K("type","Cost","type/Cost",363698341);$CLJS.IE=new $CLJS.K("type","Quantity","type/Quantity",-1936151227);
JE=new $CLJS.K("Coercion","UNIXMilliSeconds-\x3eDateTime","Coercion/UNIXMilliSeconds-\x3eDateTime",-1296453709);$CLJS.KE=new $CLJS.K("type","DateTimeWithTZ","type/DateTimeWithTZ",-1919106635);LE=new $CLJS.K("type","Share","type/Share",-1285033895);ME=new $CLJS.K("type","JoinDate","type/JoinDate",-793235819);$CLJS.NE=new $CLJS.K("type","Latitude","type/Latitude",-1080544141);OE=new $CLJS.K("type","Decimal","type/Decimal",-1657568790);Dca=new $CLJS.K("type","Dictionary","type/Dictionary",1352048818);
PE=new $CLJS.K("type","DeletionTime","type/DeletionTime",-1426373145);Eca=new $CLJS.K("type","TimeWithZoneOffset","type/TimeWithZoneOffset",959948573);Fca=new $CLJS.K("type","Product","type/Product",1803490713);Gca=new $CLJS.K("type","DateTimeWithZoneID","type/DateTimeWithZoneID",-1588069560);Hca=new $CLJS.K("entity","GoogleAnalyticsTable","entity/GoogleAnalyticsTable",910090965);QE=new $CLJS.K("type","UpdatedTimestamp","type/UpdatedTimestamp",-754878742);
RE=new $CLJS.K("type","CreationDate","type/CreationDate",-1102411433);$CLJS.SE=new $CLJS.K("type","Interval","type/Interval",-365323617);Ica=new $CLJS.K("type","Income","type/Income",-342566883);TE=new $CLJS.K("Coercion","Bytes-\x3eTemporal","Coercion/Bytes-\x3eTemporal",-2023274781);$CLJS.UE=new $CLJS.K(null,"base_type","base_type",1908272670);Jca=new $CLJS.K("type","Discount","type/Discount",109235331);VE=new $CLJS.K("type","User","type/User",832931932);
WE=new $CLJS.K("type","UpdatedTime","type/UpdatedTime",-1278858780);$CLJS.XE=new $CLJS.K("type","MongoBSONID","type/MongoBSONID",663302764);YE=new $CLJS.K("type","Instant","type/Instant",1168385286);$CLJS.ZE=new $CLJS.K("type","Email","type/Email",-1486863280);$E=new $CLJS.K("type","CancelationDate","type/CancelationDate",561287015);aF=new $CLJS.K("entity","GenericTable","entity/GenericTable",1152424804);
bF=new $CLJS.K("Coercion","UNIXMicroSeconds-\x3eDateTime","Coercion/UNIXMicroSeconds-\x3eDateTime",1299502399);$CLJS.cF=new $CLJS.K("type","City","type/City",420361040);dF=new $CLJS.K("type","CancelationTemporal","type/CancelationTemporal",-532782161);eF=new $CLJS.K("type","DeletionDate","type/DeletionDate",-2052285784);$CLJS.fF=new $CLJS.K("type","DateTime","type/DateTime",352113310);gF=new $CLJS.K("type","Birthdate","type/Birthdate",619594666);
hF=new $CLJS.K("type","CreationTemporal","type/CreationTemporal",-1324176405);iF=new $CLJS.K("Coercion","ISO8601-\x3eDate","Coercion/ISO8601-\x3eDate",-103814729);jF=new $CLJS.K("Coercion","YYYYMMDDHHMMSSBytes-\x3eTemporal","Coercion/YYYYMMDDHHMMSSBytes-\x3eTemporal",-624663946);$CLJS.Kca=new $CLJS.K(null,"effective_type","effective_type",1699478099);kF=new $CLJS.K("Coercion","UNIXNanoSeconds-\x3eDateTime","Coercion/UNIXNanoSeconds-\x3eDateTime",925800243);
lF=new $CLJS.K("type","Duration","type/Duration",1970868302);Lca=new $CLJS.K("type","TimeWithLocalTZ","type/TimeWithLocalTZ",-259094111);$CLJS.mF=new $CLJS.K("type","AvatarURL","type/AvatarURL",-425042887);Mca=new $CLJS.K("entity","UserTable","entity/UserTable",-1504290772);nF=new $CLJS.K("Coercion","UNIXTime-\x3eTemporal","Coercion/UNIXTime-\x3eTemporal",-968093468);Nca=new $CLJS.K("type","UUID","type/UUID",1767712212);oF=new $CLJS.K("Coercion","ISO8601-\x3eTime","Coercion/ISO8601-\x3eTime",-1197754849);
pF=new $CLJS.K("type","DeletionTemporal","type/DeletionTemporal",2136289994);qF=new $CLJS.K("type","TimeWithTZ","type/TimeWithTZ",-442869120);Oca=new $CLJS.K("type","DruidHyperUnique","type/DruidHyperUnique",-585863040);Pca=new $CLJS.K("type","Author","type/Author",-836053084);Qca=new $CLJS.K("type","DateTimeWithZoneOffset","type/DateTimeWithZoneOffset",1768393068);rF=new $CLJS.K("type","UpdatedDate","type/UpdatedDate",-1756161562);sF=new $CLJS.K("type","UpdatedTemporal","type/UpdatedTemporal",-596775941);
$CLJS.tF=new $CLJS.K("type","Description","type/Description",-680883950);Rca=new $CLJS.K("type","Enum","type/Enum",-1132893505);Sca=new $CLJS.K("type","Owner","type/Owner",1745970850);uF=new $CLJS.K("type","Title","type/Title",1977060721);vF=new $CLJS.K("type","JoinTime","type/JoinTime",1290040594);Tca=new $CLJS.K("entity","EventTable","entity/EventTable",-2132834802);wF=new $CLJS.K("type","Collection","type/Collection",1447925820);
xF=new $CLJS.K("type","CancelationTimestamp","type/CancelationTimestamp",-741584330);$CLJS.yF=new $CLJS.K("type","Country","type/Country",2058497652);$CLJS.zF=new $CLJS.K("type","Longitude","type/Longitude",-196788672);Uca=new $CLJS.K("type","Subscription","type/Subscription",-1076112474);Vca=new $CLJS.K("type","Price","type/Price",286577051);$CLJS.AF=new $CLJS.K("type","Array","type/Array",-2060534244);$CLJS.BF=new $CLJS.K("type","ImageURL","type/ImageURL",2081541690);
$CLJS.CF=new $CLJS.K("type","Float","type/Float",1261800143);Wca=new $CLJS.K("entity","CompanyTable","entity/CompanyTable",-1166853089);DF=new $CLJS.K("Coercion","YYYYMMDDHHMMSSString-\x3eTemporal","Coercion/YYYYMMDDHHMMSSString-\x3eTemporal",41716060);$CLJS.EF=new $CLJS.K("type","State","type/State",-154641657);Xca=new $CLJS.K("entity","TransactionTable","entity/TransactionTable",-888813059);Yca=new $CLJS.K("entity","ProductTable","entity/ProductTable",1692844366);
$CLJS.FF=new $CLJS.K("type","Time","type/Time",-814852413);Zca=new $CLJS.K("type","BigInteger","type/BigInteger",1152965666);$CLJS.UC.g(aF,new $CLJS.K("entity","*","entity/*",-2043291259));$CLJS.UC.g(Mca,aF);$CLJS.UC.g(Wca,aF);$CLJS.UC.g(Xca,aF);$CLJS.UC.g(Yca,aF);$CLJS.UC.g(Aca,aF);$CLJS.UC.g(Tca,aF);$CLJS.UC.g(Hca,aF);$CLJS.UC.g($CLJS.Fj,$CLJS.rj);$CLJS.UC.g($CLJS.nj,$CLJS.Fj);$CLJS.UC.g(Zca,$CLJS.nj);$CLJS.UC.g($CLJS.IE,$CLJS.jj);$CLJS.UC.g($CLJS.IE,$CLJS.nj);$CLJS.UC.g($CLJS.CF,$CLJS.Fj);$CLJS.UC.g(OE,$CLJS.CF);$CLJS.UC.g(LE,$CLJS.jj);$CLJS.UC.g(LE,$CLJS.CF);$CLJS.UC.g($CLJS.zE,OE);$CLJS.UC.g($CLJS.zE,$CLJS.jj);
$CLJS.UC.g(Ica,$CLJS.zE);$CLJS.UC.g(Jca,$CLJS.zE);$CLJS.UC.g(Vca,$CLJS.zE);$CLJS.UC.g(Bca,$CLJS.zE);$CLJS.UC.g(Cca,$CLJS.zE);$CLJS.UC.g(nE,$CLJS.jj);$CLJS.UC.g($CLJS.pj,nE);$CLJS.UC.g($CLJS.pj,$CLJS.CF);$CLJS.UC.g($CLJS.NE,$CLJS.pj);$CLJS.UC.g($CLJS.zF,$CLJS.pj);$CLJS.UC.g(oE,$CLJS.jj);$CLJS.UC.g(oE,$CLJS.Fj);$CLJS.UC.g(lF,$CLJS.jj);$CLJS.UC.g(lF,$CLJS.Fj);$CLJS.UC.g($CLJS.Uj,$CLJS.rj);$CLJS.UC.g(Nca,$CLJS.Uj);$CLJS.UC.g($CLJS.CE,$CLJS.jj);$CLJS.UC.g($CLJS.CE,$CLJS.Uj);$CLJS.UC.g($CLJS.BF,$CLJS.CE);
$CLJS.UC.g($CLJS.mF,$CLJS.BF);$CLJS.UC.g($CLJS.ZE,$CLJS.jj);$CLJS.UC.g($CLJS.ZE,$CLJS.Uj);$CLJS.UC.g($CLJS.Ij,$CLJS.jj);$CLJS.UC.g(Rca,$CLJS.jj);$CLJS.UC.g($CLJS.Ci,nE);$CLJS.UC.g($CLJS.cF,$CLJS.Ci);$CLJS.UC.g($CLJS.cF,$CLJS.Ij);$CLJS.UC.g($CLJS.cF,$CLJS.Uj);$CLJS.UC.g($CLJS.EF,$CLJS.Ci);$CLJS.UC.g($CLJS.EF,$CLJS.Ij);$CLJS.UC.g($CLJS.EF,$CLJS.Uj);$CLJS.UC.g($CLJS.yF,$CLJS.Ci);$CLJS.UC.g($CLJS.yF,$CLJS.Ij);$CLJS.UC.g($CLJS.yF,$CLJS.Uj);$CLJS.UC.g($CLJS.BE,$CLJS.Ci);$CLJS.UC.g($CLJS.BE,$CLJS.Uj);
$CLJS.UC.g($CLJS.bj,$CLJS.Ij);$CLJS.UC.g($CLJS.bj,$CLJS.Uj);$CLJS.UC.g(uF,$CLJS.Ij);$CLJS.UC.g(uF,$CLJS.Uj);$CLJS.UC.g($CLJS.tF,$CLJS.jj);$CLJS.UC.g($CLJS.tF,$CLJS.Uj);$CLJS.UC.g($CLJS.FE,$CLJS.jj);$CLJS.UC.g($CLJS.FE,$CLJS.Uj);$CLJS.UC.g(yca,$CLJS.Uj);$CLJS.UC.g($CLJS.Mj,$CLJS.rj);$CLJS.UC.g($CLJS.HE,$CLJS.Mj);$CLJS.UC.g($CLJS.FF,$CLJS.Mj);$CLJS.UC.g(qF,$CLJS.FF);$CLJS.UC.g(Lca,qF);$CLJS.UC.g(Eca,qF);$CLJS.UC.g($CLJS.fF,$CLJS.Mj);$CLJS.UC.g($CLJS.KE,$CLJS.fF);$CLJS.UC.g(tE,$CLJS.KE);
$CLJS.UC.g(Qca,$CLJS.KE);$CLJS.UC.g(Gca,$CLJS.KE);$CLJS.UC.g(YE,tE);$CLJS.UC.g(hF,$CLJS.jj);$CLJS.UC.g(sE,hF);$CLJS.UC.g(sE,$CLJS.fF);$CLJS.UC.g(DE,hF);$CLJS.UC.g(DE,$CLJS.FF);$CLJS.UC.g(RE,hF);$CLJS.UC.g(RE,$CLJS.HE);$CLJS.UC.g(vE,$CLJS.jj);$CLJS.UC.g(xE,vE);$CLJS.UC.g(xE,$CLJS.fF);$CLJS.UC.g(vF,vE);$CLJS.UC.g(vF,$CLJS.FF);$CLJS.UC.g(ME,vE);$CLJS.UC.g(ME,$CLJS.HE);$CLJS.UC.g(dF,$CLJS.jj);$CLJS.UC.g(xF,dF);$CLJS.UC.g(xF,$CLJS.fF);$CLJS.UC.g(pE,dF);$CLJS.UC.g(pE,$CLJS.HE);$CLJS.UC.g($E,dF);
$CLJS.UC.g($E,$CLJS.HE);$CLJS.UC.g(pF,$CLJS.jj);$CLJS.UC.g(wE,pF);$CLJS.UC.g(wE,$CLJS.fF);$CLJS.UC.g(PE,pF);$CLJS.UC.g(PE,$CLJS.FF);$CLJS.UC.g(eF,pF);$CLJS.UC.g(eF,$CLJS.HE);$CLJS.UC.g(sF,$CLJS.jj);$CLJS.UC.g(QE,sF);$CLJS.UC.g(QE,$CLJS.fF);$CLJS.UC.g(WE,sF);$CLJS.UC.g(WE,$CLJS.FF);$CLJS.UC.g(rF,sF);$CLJS.UC.g(rF,$CLJS.HE);$CLJS.UC.g(gF,$CLJS.jj);$CLJS.UC.g(gF,$CLJS.HE);$CLJS.UC.g($CLJS.SE,$CLJS.Mj);$CLJS.UC.g($CLJS.Bj,$CLJS.rj);$CLJS.UC.g(Oca,$CLJS.rj);$CLJS.UC.g($CLJS.gj,$CLJS.rj);
$CLJS.UC.g($CLJS.XE,$CLJS.gj);$CLJS.UC.g($CLJS.qE,$CLJS.gj);$CLJS.UC.g($CLJS.qE,$CLJS.jj);$CLJS.UC.g(wF,$CLJS.rj);$CLJS.UC.g($CLJS.Di,$CLJS.rj);$CLJS.UC.g(Dca,wF);$CLJS.UC.g($CLJS.AF,wF);$CLJS.UC.g(yE,$CLJS.Di);$CLJS.UC.g(yE,wF);$CLJS.UC.g($CLJS.dk,$CLJS.Di);$CLJS.UC.g($CLJS.dk,wF);$CLJS.UC.g($CLJS.Di,$CLJS.jj);$CLJS.UC.g($CLJS.Di,$CLJS.Uj);$CLJS.UC.g($CLJS.ti,$CLJS.Di);$CLJS.UC.g($CLJS.dk,$CLJS.Di);$CLJS.UC.g(VE,$CLJS.jj);$CLJS.UC.g(Pca,VE);$CLJS.UC.g(Sca,VE);$CLJS.UC.g(Fca,$CLJS.Ij);
$CLJS.UC.g(xca,$CLJS.Ij);$CLJS.UC.g(Uca,$CLJS.Ij);$CLJS.UC.g(zca,$CLJS.Ij);$CLJS.UC.g($CLJS.di,$CLJS.fi);$CLJS.UC.g($CLJS.gi,$CLJS.fi);$CLJS.UC.g(AE,kE);$CLJS.UC.g(GE,AE);$CLJS.UC.g(uE,GE);$CLJS.UC.g(oF,GE);$CLJS.UC.g(iF,GE);$CLJS.UC.g(DF,AE);$CLJS.UC.g(TE,kE);$CLJS.UC.g(jF,TE);$CLJS.UC.g(rE,kE);$CLJS.UC.g(nF,rE);$CLJS.UC.g(EE,nF);$CLJS.UC.g(JE,nF);$CLJS.UC.g(bF,nF);$CLJS.UC.g(kF,nF);
var $ca=$CLJS.Zk($CLJS.Wf.g($CLJS.N,function GF(a){return new $CLJS.le(null,function(){for(;;){var c=$CLJS.z(a);if(c){if($CLJS.zd(c)){var d=$CLJS.kc(c),e=$CLJS.E(d),f=$CLJS.oe(e);a:for(var k=0;;)if(k<e){var l=$CLJS.kd(d,k);l=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zg(l),$CLJS.NC(l)],null);f.add(l);k+=1}else{d=!0;break a}return d?$CLJS.re($CLJS.te(f),GF($CLJS.lc(c))):$CLJS.re($CLJS.te(f),null)}f=$CLJS.B(c);return $CLJS.ce(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zg(f),$CLJS.NC(f)],null),GF($CLJS.Kc(c)))}return null}},
null,null)}($CLJS.Yk.h($CLJS.$e($CLJS.$k,$CLJS.G([new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.rj,$CLJS.jj,$CLJS.fi],null)]))))));iE(kF,new $CLJS.Tg(null,new $CLJS.h(null,2,[$CLJS.nj,null,OE,null],null),null),YE);iE(bF,new $CLJS.Tg(null,new $CLJS.h(null,2,[$CLJS.nj,null,OE,null],null),null),YE);iE(JE,new $CLJS.Tg(null,new $CLJS.h(null,2,[$CLJS.nj,null,OE,null],null),null),YE);iE(EE,new $CLJS.Tg(null,new $CLJS.h(null,2,[$CLJS.nj,null,OE,null],null),null),YE);iE(iF,$CLJS.Uj,$CLJS.HE);iE(uE,$CLJS.Uj,$CLJS.fF);
iE(oF,$CLJS.Uj,$CLJS.FF);iE(DF,$CLJS.Uj,$CLJS.fF);$CLJS.Gh.j($CLJS.bE,$CLJS.Me($CLJS.nz,$CLJS.qv),$CLJS.Mv(hE($CLJS.rj),new $CLJS.Ue(null,-1,$CLJS.Vg([jF]),null)));$CLJS.Gh.v($CLJS.aE,$CLJS.S,jF,$CLJS.fF);
module.exports={isa:function(a,b){return $CLJS.gE($CLJS.rh.h(a),$CLJS.rh.h(b))},coercions_for_type:function(a){a=$CLJS.Wf.j($CLJS.Jc,$CLJS.Ek.g($CLJS.Yk.o(),$CLJS.fE),$CLJS.Rg(lE($CLJS.rh.h(a))));return $CLJS.Zk($CLJS.Ze.g(function(b){return[$CLJS.ge(b),"/",$CLJS.Zg(b)].join("")},a))},is_coerceable:function(a){return $CLJS.Dd($CLJS.De(lE($CLJS.rh.h(a))))},TYPE:$ca};