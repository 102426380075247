var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.walk.js");require("./medley.core.js");require("./metabase.lib.convert.js");require("./metabase.lib.core.js");require("./metabase.lib.equality.js");require("./metabase.lib.join.js");require("./metabase.lib.js.metadata.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.metadata.protocols.js");require("./metabase.lib.order_by.js");require("./metabase.lib.stage.js");require("./metabase.lib.util.js");require("./metabase.mbql.js.js");require("./metabase.mbql.normalize.js");require("./metabase.util.js");require("./metabase.util.log.js");
'use strict';var Pta=function(a){return $CLJS.ec($CLJS.ab(function(b,c){return $CLJS.Zf.j(b,c,$CLJS.I.j(b,c,0)+1)},$CLJS.cc($CLJS.N),a))},Qta=function(a,b,c,d){var e=$CLJS.Wf.j($CLJS.N,$CLJS.$l(function(f,k){return new $CLJS.Q(null,2,5,$CLJS.R,[k,f],null)}),d);return function l(k){return new $CLJS.le(null,function(){for(;;){var m=$CLJS.z(k);if(m){if($CLJS.zd(m)){var t=$CLJS.kc(m),u=$CLJS.E(t),v=$CLJS.oe(u);a:for(var x=0;;)if(x<u){var A=$CLJS.kd(t,x);A=$CLJS.W2.v(a,b,A,d);A=$CLJS.I.j(e,A,-1);v.add(A);
x+=1}else{t=!0;break a}return t?$CLJS.re($CLJS.te(v),l($CLJS.lc(m))):$CLJS.re($CLJS.te(v),null)}v=$CLJS.B(m);v=$CLJS.W2.v(a,b,v,d);return $CLJS.ce($CLJS.I.j(e,v,-1),l($CLJS.Kc(m)))}return null}},null,null)}(c)},D9=function(a,b){return new $CLJS.Bh(function(){try{return $CLJS.z9.g(a,b)}catch(e){if(e instanceof Error){var c=e,d=$CLJS.zC($CLJS.tA);$CLJS.n($CLJS.yC("metabase.lib.js.metadata",d))&&(c instanceof Error?$CLJS.xC("metabase.lib.js.metadata",d,$CLJS.WX("Error parsing %s objects: %s",$CLJS.G([a,
$CLJS.UW(c)])),c):$CLJS.xC("metabase.lib.js.metadata",d,$CLJS.WX(c,$CLJS.G(["Error parsing %s objects: %s",a,$CLJS.UW(c)])),null));return null}throw e;}})},Rta=function(a,b){return function e(d){return new $CLJS.le(null,function(){for(var f=d;;)if(f=$CLJS.z(f)){if($CLJS.zd(f)){var k=$CLJS.kc(f),l=$CLJS.E(k),m=$CLJS.oe(l);return function(){for(var x=0;;)if(x<l){var A=$CLJS.kd(k,x);$CLJS.H(A,0,null);var D=$CLJS.H(A,1,null),J=function(){var M=D;return null==M?null:$CLJS.q(M)}();$CLJS.n(function(){var M=
J;return $CLJS.n(M)?$CLJS.F.g($CLJS.CM.h(J),b):M}())&&m.add(J);x+=1}else return!0}()?$CLJS.re($CLJS.te(m),e($CLJS.lc(f))):$CLJS.re($CLJS.te(m),null)}var t=$CLJS.B(f);$CLJS.H(t,0,null);var u=$CLJS.H(t,1,null),v=function(){var x=u;return null==x?null:$CLJS.q(x)}();if($CLJS.n(function(){var x=v;return $CLJS.n(x)?$CLJS.F.g($CLJS.CM.h(v),b):x}()))return $CLJS.ce(v,e($CLJS.Kc(f)));f=$CLJS.Kc(f)}else return null},null,null)}(function(){var d=null==a?null:$CLJS.DG.h(a);return null==d?null:$CLJS.q(d)}())},
Sta=function(a,b){return function e(d){return new $CLJS.le(null,function(){for(var f=d;;)if(f=$CLJS.z(f)){if($CLJS.zd(f)){var k=$CLJS.kc(f),l=$CLJS.E(k),m=$CLJS.oe(l);return function(){for(var x=0;;)if(x<l){var A=$CLJS.kd(k,x);$CLJS.H(A,0,null);var D=$CLJS.H(A,1,null),J=function(){var M=D;return null==M?null:$CLJS.q(M)}();$CLJS.n(function(){var M=J;return $CLJS.n(M)?$CLJS.F.g($CLJS.CM.h(J),b):M}())&&m.add(J);x+=1}else return!0}()?$CLJS.re($CLJS.te(m),e($CLJS.lc(f))):$CLJS.re($CLJS.te(m),null)}var t=
$CLJS.B(f);$CLJS.H(t,0,null);var u=$CLJS.H(t,1,null),v=function(){var x=u;return null==x?null:$CLJS.q(x)}();if($CLJS.n(function(){var x=v;return $CLJS.n(x)?$CLJS.F.g($CLJS.CM.h(v),b):x}()))return $CLJS.ce(v,e($CLJS.Kc(f)));f=$CLJS.Kc(f)}else return null},null,null)}(function(){var d=null==a?null:$CLJS.u9.h(a);return null==d?null:$CLJS.q(d)}())},Tta=function(a,b){return function e(d){return new $CLJS.le(null,function(){for(var f=d;;)if(f=$CLJS.z(f)){if($CLJS.zd(f)){var k=$CLJS.kc(f),l=$CLJS.E(k),m=
$CLJS.oe(l);return function(){for(var x=0;;)if(x<l){var A=$CLJS.kd(k,x);$CLJS.H(A,0,null);var D=$CLJS.H(A,1,null),J=function(){var M=D;return null==M?null:$CLJS.q(M)}();$CLJS.n(function(){var M=J;return $CLJS.n(M)?$CLJS.F.g($CLJS.CM.h(J),b):M}())&&m.add(J);x+=1}else return!0}()?$CLJS.re($CLJS.te(m),e($CLJS.lc(f))):$CLJS.re($CLJS.te(m),null)}var t=$CLJS.B(f);$CLJS.H(t,0,null);var u=$CLJS.H(t,1,null),v=function(){var x=u;return null==x?null:$CLJS.q(x)}();if($CLJS.n(function(){var x=v;return $CLJS.n(x)?
$CLJS.F.g($CLJS.CM.h(v),b):x}()))return $CLJS.ce(v,e($CLJS.Kc(f)));f=$CLJS.Kc(f)}else return null},null,null)}(function(){var d=null==a?null:$CLJS.v9.h(a);return null==d?null:$CLJS.q(d)}())},E9=function(a,b,c,d){this.ag=a;this.Ci=b;this.rc=c;this.ei=d;this.C=393216;this.I=0},F9=function(a){return $CLJS.Gu($CLJS.EB(a),function(b){return $CLJS.Hk.j($CLJS.rC(b,$CLJS.rh),$CLJS.dj,$CLJS.rh)})},G9=function(a,b){if($CLJS.V_(b))a=b;else{var c=new $CLJS.h(null,6,[$CLJS.y9,D9($CLJS.vR,b),$CLJS.w9,D9($CLJS.oY,
b),$CLJS.DG,D9($CLJS.UH,b),$CLJS.t9,D9($CLJS.XO,b),$CLJS.u9,D9($CLJS.PH,b),$CLJS.v9,D9($CLJS.rH,b)],null),d=$CLJS.zC($CLJS.sA);$CLJS.n($CLJS.yC("metabase.lib.js.metadata",d))&&$CLJS.xC("metabase.lib.js.metadata",d,$CLJS.fy.l($CLJS.G(["Created metadata provider for metadata"])),null);a=new E9(a,b,c,$CLJS.N)}return a},H9=function(a,b){return $CLJS.tY($CLJS.tY((0,$CLJS.c_)($CLJS.C9($CLJS.EB(a))),$CLJS.KR,function(c){return $CLJS.Wk.l($CLJS.G([new $CLJS.h(null,1,[$CLJS.hS,$CLJS.N],null),c]))}),$CLJS.iO,
function(c){var d=function(){var e=$CLJS.DG.h(c);return $CLJS.n(e)?e:function l(k){return new $CLJS.le(null,function(){for(;;){var m=$CLJS.z(k);if(m){if($CLJS.zd(m)){var t=$CLJS.kc(m),u=$CLJS.E(t),v=$CLJS.oe(u);a:for(var x=0;;)if(x<u){var A=$CLJS.kd(t,x);v.add(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.UH,A,null],null));x+=1}else{t=!0;break a}return t?$CLJS.re($CLJS.te(v),l($CLJS.lc(m))):$CLJS.re($CLJS.te(v),null)}v=$CLJS.B(m);return $CLJS.ce(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.UH,v,null],null),l($CLJS.Kc(m)))}return null}},
null,null)}(b)}();return $CLJS.S.j(c,$CLJS.DG,Pta(d))})},I9=function(a){return $CLJS.x_.h($CLJS.Hk.j($CLJS.DB(a,$CLJS.G([$CLJS.si,!0])),0,$CLJS.rh))},Uta=function(a){var b=$CLJS.Ua(a)?a:null;return $CLJS.n(b)?(a=$CLJS.B9.h?$CLJS.B9.h(b):$CLJS.B9.call(null,b),$CLJS.F.g($CLJS.rM.h(a),$CLJS.KM)?(b=I9(b.field_ref),$CLJS.S.j(a,$CLJS.P1,$CLJS.id(b))):a):a},J9=function(a){return function(b){return new $CLJS.h(null,2,[$CLJS.EH,(a.h?a.h(b):a.call(null,b)).name,$CLJS.uj,b.value],null)}};$CLJS.g=E9.prototype;
$CLJS.g.P=function(a,b){return new E9(this.ag,this.Ci,this.rc,b)};$CLJS.g.O=function(){return this.ei};$CLJS.g.fi=$CLJS.r;$CLJS.g.qg=function(a,b){a=this.rc;a=null==a?null:$CLJS.u9.h(a);a=null==a?null:$CLJS.q(a);b=null==a?null:$CLJS.I.g(a,b);return null==b?null:$CLJS.q(b)};$CLJS.g.rg=function(a,b){return Sta(this.rc,b)};$CLJS.g.ug=function(a,b){a=this.rc;a=null==a?null:$CLJS.w9.h(a);a=null==a?null:$CLJS.q(a);b=null==a?null:$CLJS.I.g(a,b);return null==b?null:$CLJS.q(b)};
$CLJS.g.pg=function(a,b){return Rta(this.rc,b)};$CLJS.g.og=function(a,b){a=this.rc;a=null==a?null:$CLJS.DG.h(a);a=null==a?null:$CLJS.q(a);b=null==a?null:$CLJS.I.g(a,b);return null==b?null:$CLJS.q(b)};$CLJS.g.tg=function(a,b){return Tta(this.rc,b)};$CLJS.g.mg=function(a,b){a=this.rc;a=null==a?null:$CLJS.t9.h(a);a=null==a?null:$CLJS.q(a);b=null==a?null:$CLJS.I.g(a,b);return null==b?null:$CLJS.q(b)};
$CLJS.g.sg=function(a,b){a=this.rc;a=null==a?null:$CLJS.v9.h(a);a=null==a?null:$CLJS.q(a);b=null==a?null:$CLJS.I.g(a,b);return null==b?null:$CLJS.q(b)};$CLJS.g.ng=function(){var a=this.rc,b=this.ag;a=null==a?null:$CLJS.y9.h(a);a=null==a?null:$CLJS.q(a);b=null==a?null:$CLJS.I.g(a,b);return null==b?null:$CLJS.q(b)};var K9=function K9(a){switch(arguments.length){case 1:return K9.h(arguments[0]);case 2:return K9.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.ya("metabase.lib.js.extract_template_tags",K9);K9.h=function(a){return K9.g(a,$CLJS.N)};K9.g=function(a,b){return $CLJS.Zk($CLJS.Qsa.l($CLJS.G([a,F9(b)])))};K9.A=2;
var L9=function L9(a){return $CLJS.ke(a)?[$CLJS.ge(a),"/",$CLJS.Zg(a)].join(""):$CLJS.wd(a)?$CLJS.Gu(a,L9):$CLJS.vd(a)?$CLJS.Ze.g(L9,a):a},M9=function M9(a){return $CLJS.wd(a)?$CLJS.Gu($CLJS.rC(a,$CLJS.fca),M9):$CLJS.vd(a)?$CLJS.Ze.g(M9,a):a},N9=function N9(a){switch(arguments.length){case 1:return N9.h(arguments[0]);case 2:return N9.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.ya("metabase.lib.js.order_by_clause",N9);
N9.h=function(a){return N9.g(a,$CLJS.RH)};N9.g=function(a,b){return $CLJS.Tsa.l($CLJS.G([$CLJS.g9.l($CLJS.G([$CLJS.DB(a,$CLJS.G([$CLJS.si,!0]))])),$CLJS.rh.h(b)]))};N9.A=2;var O9=function O9(a){switch(arguments.length){case 2:return O9.g(arguments[0],arguments[1]);case 3:return O9.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.ya("metabase.lib.js.available_binning_strategies",O9);
O9.g=function(a,b){return $CLJS.ue($CLJS.f9.l($CLJS.G([a,b])))};O9.j=function(a,b,c){return $CLJS.ue($CLJS.f9.l($CLJS.G([a,b,c])))};O9.A=3;var P9=function P9(a){switch(arguments.length){case 2:return P9.g(arguments[0],arguments[1]);case 3:return P9.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.ya("metabase.lib.js.available_temporal_buckets",P9);P9.g=function(a,b){return $CLJS.ue($CLJS.i9.l($CLJS.G([a,b])))};
P9.j=function(a,b,c){return $CLJS.ue($CLJS.i9.l($CLJS.G([a,b,c])))};P9.A=3;var Q9=function Q9(a){switch(arguments.length){case 2:return Q9.g(arguments[0],arguments[1]);case 3:return Q9.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.ya("metabase.lib.js.query_EQ_",Q9);Q9.g=function(a,b){return Q9.j(a,b,null)};Q9.j=function(a,b,c){a=H9(a,c);b=H9(b,c);return $CLJS.F.g(a,b)};Q9.A=3;
var R9=function R9(a){switch(arguments.length){case 1:return R9.h(arguments[0]);case 2:return R9.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.ya("metabase.lib.js.aggregation_clause",R9);R9.h=function(a){return $CLJS.e9.l($CLJS.G([a]))};R9.g=function(a,b){return $CLJS.e9.l($CLJS.G([a,b]))};R9.A=2;
var S9=function S9(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return S9.l(arguments[0],arguments[1],2<c.length?new $CLJS.y(c.slice(2),0,null):null)};$CLJS.ya("metabase.lib.js.filter_clause",S9);S9.l=function(a,b,c){return $CLJS.P.v($CLJS.hsa,a,b,c)};S9.A=2;S9.B=function(a){var b=$CLJS.B(a),c=$CLJS.C(a);a=$CLJS.B(c);c=$CLJS.C(c);return this.l(b,a,c)};
var T9=function T9(a){switch(arguments.length){case 2:return T9.g(arguments[0],arguments[1]);case 3:return T9.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.ya("metabase.lib.js.expressionable_columns",T9);T9.g=function(a,b){return T9.g(a,b)};T9.j=function(a,b,c){return $CLJS.ue($CLJS.Ura.l($CLJS.G([a,b,c])))};T9.A=3;
var U9=function U9(a){switch(arguments.length){case 3:return U9.j(arguments[0],arguments[1],arguments[2]);case 4:return U9.v(arguments[0],arguments[1],arguments[2],arguments[3]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.ya("metabase.lib.js.with_different_database",U9);U9.j=function(a,b,c){return U9.v(a,b,c,null)};U9.v=function(a,b,c,d){return $CLJS.Osa.l($CLJS.G([a,G9(b,c),$CLJS.DB(d,$CLJS.G([$CLJS.si,!0]))]))};U9.A=4;
var Vta=J9(function(a){return a.col}),Wta=J9(function(a){return a.column}),V9=function V9(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return V9.l(arguments[0],arguments[1],arguments[2],3<c.length?new $CLJS.y(c.slice(3),0,null):null)};$CLJS.ya("metabase.lib.js.drill_thru",V9);V9.l=function(a,b,c,d){return $CLJS.P.N($CLJS.Ora,a,b,c,d)};V9.A=3;
V9.B=function(a){var b=$CLJS.B(a),c=$CLJS.C(a);a=$CLJS.B(c);var d=$CLJS.C(c);c=$CLJS.B(d);d=$CLJS.C(d);return this.l(b,a,c,d)};
module.exports={extract_template_tags:K9,engine:function(a){return $CLJS.Zg($CLJS.Jsa.l($CLJS.G([a])))},field_id:function(a){return $CLJS.Yra.l($CLJS.G([a]))},filter_operator:function(a,b,c){return $CLJS.isa.l($CLJS.G([a,b,c]))},binning:function(a){return $CLJS.Era.l($CLJS.G([a]))},query:function(a,b,c){c=$CLJS.DB(c,$CLJS.G([$CLJS.si,!0]));c=$CLJS.n($CLJS.dj.h(c))?c:$CLJS.S.j(c,$CLJS.dj,$CLJS.iO);c=(0,$CLJS.c_)(c);c=$CLJS.x_.h(c);var d=$CLJS.zC($CLJS.sA);$CLJS.n($CLJS.yC("metabase.lib.js",d))&&$CLJS.xC("metabase.lib.js",
d,$CLJS.WX("query map: %s",$CLJS.G([$CLJS.Eh.l($CLJS.G([c]))])),null);return $CLJS.Vsa.l($CLJS.G([G9(a,b),c]))},columns_group_columns:function(a){return $CLJS.ue($CLJS.Jra.l($CLJS.G([a])))},join_fields:function(a){a=$CLJS.tsa.l($CLJS.G([a]));return a instanceof $CLJS.K?$CLJS.NC(a):$CLJS.ue(a)},aggregate:function(a,b,c){return $CLJS.zra.l($CLJS.G([a,b,$CLJS.DB(c,$CLJS.G([$CLJS.si,!0]))]))},join_conditions:function(a){return $CLJS.ue($CLJS.ssa.l($CLJS.G([a])))},query_EQ_:Q9,temporal_bucket:function(a){return $CLJS.gta.l($CLJS.G([a]))},
available_drill_thrus:function(a,b,c,d,e,f){return $CLJS.ue($CLJS.Nra.l($CLJS.G([a,b,$CLJS.Wk.l($CLJS.G([new $CLJS.h(null,2,[$CLJS.Ki,$CLJS.B9.h?$CLJS.B9.h(c):$CLJS.B9.call(null,c),$CLJS.uj,void 0===d?null:null==d?$CLJS.V6:d],null),$CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.e6,$CLJS.Gk.g(Vta,e)],null):null,$CLJS.n($CLJS.De(f))?new $CLJS.h(null,1,[$CLJS.$M,$CLJS.Gk.g(Wta,f)],null):null]))])))},remove_clause:function(a,b,c){return $CLJS.Xsa.l($CLJS.G([a,b,$CLJS.g9.l($CLJS.G([$CLJS.DB(c,$CLJS.G([$CLJS.si,
!0]))]))]))},suggested_join_condition:function(a,b,c){return $CLJS.ysa.l($CLJS.G([a,b,c]))},replace_clause:function(a,b,c,d){return $CLJS.$sa.l($CLJS.G([a,b,$CLJS.g9.l($CLJS.G([$CLJS.DB(c,$CLJS.G([$CLJS.si,!0]))])),$CLJS.g9.l($CLJS.G([$CLJS.DB(d,$CLJS.G([$CLJS.si,!0]))]))]))},drop_stage:function(a){return $CLJS.cta.l($CLJS.G([a]))},join_lhs_display_name:function(a,b,c,d){return $CLJS.usa.l($CLJS.G([a,b,c,d]))},add_field:function(a,b,c){return $CLJS.Xra.l($CLJS.G([a,b,c]))},pivot_types:function(a){return $CLJS.ue($CLJS.Qra.l($CLJS.G([a])))},
native_extras:function(a){return $CLJS.Zk($CLJS.Msa.l($CLJS.G([a])))},available_temporal_buckets:P9,with_binning:function(a,b){return $CLJS.Fra.l($CLJS.G([a,b]))},append_stage:function(a){return $CLJS.bta.l($CLJS.G([a]))},join:function(a,b,c){return $CLJS.msa.l($CLJS.G([a,b,c]))},aggregations:function(a,b){return $CLJS.ue($CLJS.Bra.l($CLJS.G([a,b])))},drill_thru:V9,raw_native_query:function(a){return $CLJS.Gsa.l($CLJS.G([a]))},filterable_column_operators:function(a){return $CLJS.ue($CLJS.gsa.l($CLJS.G([a])))},
with_temporal_bucket:function(a,b){return $CLJS.hta.l($CLJS.G([a,b]))},with_join_conditions:function(a,b){return $CLJS.Bsa.l($CLJS.G([a,$CLJS.DB(b,$CLJS.G([$CLJS.si,!0]))]))},available_metrics:function(a){return $CLJS.ue($CLJS.Esa.l($CLJS.G([a])))},with_different_database:U9,with_fields:function(a,b,c){return $CLJS.csa.l($CLJS.G([a,b,c]))},is_column_metadata:function(a){return $CLJS.wd(a)&&$CLJS.F.g($CLJS.pM,$CLJS.ov.h(a))},join_clause:function(a,b){return $CLJS.nsa.l($CLJS.G([a,b]))},aggregation_clause:R9,
external_op:function(a){a=$CLJS.Lra.l($CLJS.G([a]));var b=$CLJS.O(a);a=$CLJS.I.g(b,$CLJS.hG);var c=$CLJS.I.g(b,$CLJS.Yi);b=$CLJS.I.g(b,$CLJS.Tt);return{operator:a,options:$CLJS.Zk(c),args:$CLJS.ue(b)}},with_join_fields:function(a,b){return $CLJS.zsa.l($CLJS.G([a,"string"===typeof b?$CLJS.rh.h(b):b]))},find_visible_column_for_legacy_ref:function(a,b,c){return $CLJS.asa.l($CLJS.G([a,b,c]))},visible_columns:function(a,b){var c=$CLJS.gY(a,b),d=$CLJS.b2.j(a,b,c);c=$CLJS.$1.j(a,b,c);return $CLJS.ue($CLJS.e4(a,
b,d,c))},available_binning_strategies:O9,find_column_indexes_from_legacy_refs:function(a,b,c,d){var e=$CLJS.JH.h($CLJS.gY(a,b)),f=$CLJS.Wf.j($CLJS.N,$CLJS.$l(function(u,v){$CLJS.H(v,0,null);v=$CLJS.H(v,1,null);v=$CLJS.O(v);v=$CLJS.I.g(v,$CLJS.kG);return new $CLJS.Q(null,2,5,$CLJS.R,[u,v],null)}),e);e=$CLJS.Wf.j($CLJS.N,$CLJS.$l(function(u,v){$CLJS.H(v,0,null);v=$CLJS.H(v,1,null);v=$CLJS.O(v);v=$CLJS.I.g(v,$CLJS.kG);return new $CLJS.Q(null,2,5,$CLJS.R,[v,u],null)}),e);var k=$CLJS.w_,l=$CLJS.v_;$CLJS.w_=
f;$CLJS.v_=e;try{var m=$CLJS.Gk.g(Uta,c),t=$CLJS.Ze.g(I9,d);return $CLJS.ue(Qta(a,b,t,m))}finally{$CLJS.v_=l,$CLJS.w_=k}},expression:function(a,b,c,d){return $CLJS.Sra.l($CLJS.G([a,b,c,d]))},describe_relative_datetime:function(a,b){a="string"===typeof a?$CLJS.rh.h(a):a;b="string"===typeof b?$CLJS.rh.h(b):b;return $CLJS.fta.l($CLJS.G([a,b]))},order_bys:function(a,b){return $CLJS.ue($CLJS.Usa.l($CLJS.G([a,b])))},display_info:function(a,b,c){return $CLJS.Zk.l(M9($CLJS.Csa.l($CLJS.G([$CLJS.z4(a,b),b,
c]))),$CLJS.G([$CLJS.kk,$CLJS.NC]))},fieldable_columns:function(a,b){return $CLJS.ue($CLJS.Zra.l($CLJS.G([a,b])))},template_tags:function(a){return $CLJS.Zk($CLJS.Isa.l($CLJS.G([a])))},selected_aggregation_operators:function(a,b){return $CLJS.ue($CLJS.Dra.l($CLJS.G([$CLJS.z(a),b])))},order_by_clause:N9,filterable_columns:function(a,b){return $CLJS.ue($CLJS.fsa.l($CLJS.G([a,b])))},join_condition_rhs_columns:function(a,b,c,d,e){return $CLJS.ue($CLJS.qsa.l($CLJS.G([a,b,c,d,e])))},join_condition_update_temporal_bucketing:function(a,
b,c,d){return $CLJS.rsa.l($CLJS.G([a,b,c,d]))},filters:function(a,b){return $CLJS.ue($CLJS.esa.l($CLJS.G([a,b])))},join_condition_lhs_columns:function(a,b,c,d,e){return $CLJS.ue($CLJS.osa.l($CLJS.G([a,b,c,d,e])))},table_or_card_metadata:function(a,b){return $CLJS.q2(a,b)},orderable_columns:function(a,b){return $CLJS.ue($CLJS.$6.g(a,b))},find_filter_for_legacy_filter:function(a,b,c){return $CLJS.jsa.l($CLJS.G([a,b,$CLJS.DB(c,$CLJS.G([$CLJS.si,!0]))]))},suggestedName:function(a){return $CLJS.Dsa.l($CLJS.G([a]))},
find_column_for_legacy_ref:function(a,b,c,d){return $CLJS.Rra.l($CLJS.G([a,b,c,d]))},has_write_permission:function(a){return $CLJS.Psa.l($CLJS.G([a]))},metadataProvider:G9,filter_clause:S9,fields:function(a,b){return $CLJS.ue($CLJS.$ra.l($CLJS.G([a,b])))},available_aggregation_operators:function(a,b){return $CLJS.ue($CLJS.Cra.l($CLJS.G([a,b])))},breakouts:function(a,b){return $CLJS.ue($CLJS.Ira.l($CLJS.G([a,b])))},joinable_columns:function(a,b,c){return $CLJS.ue($CLJS.wsa.l($CLJS.G([a,b,c])))},group_columns:function(a){return $CLJS.ue($CLJS.Kra.l($CLJS.G([a])))},
describe_temporal_unit:function(a,b){b="string"===typeof b?$CLJS.rh.h(b):b;return $CLJS.dta.l($CLJS.G([a,b]))},joins:function(a,b){return $CLJS.ue($CLJS.xsa.l($CLJS.G([a,b])))},native_query:function(a,b,c){return $CLJS.Fsa.l($CLJS.G([G9(a,b),c]))},available_join_strategies:function(a,b){return $CLJS.ue($CLJS.lsa.l($CLJS.G([a,b])))},legacy_query:function(a){return $CLJS.Zk.l(L9($CLJS.n_.h(a)),$CLJS.G([$CLJS.kk,$CLJS.NC]))},expression_clause:function(a,b,c){return $CLJS.Vra.l($CLJS.G([$CLJS.rh.h(a),
b,$CLJS.DB(c,$CLJS.G([$CLJS.si,!0]))]))},with_native_extras:function(a,b){return $CLJS.Nsa.l($CLJS.G([a,$CLJS.DB(b,$CLJS.G([$CLJS.si,!0]))]))},join_strategy:function(a){return $CLJS.vsa.l($CLJS.G([a]))},picker_info:function(a,b){var c=$CLJS.ov.h(b);switch(c instanceof $CLJS.K?c.T:null){case "metadata/table":return{databaseId:$CLJS.vR.h(a),tableId:$CLJS.Qi.h(b)};case "metadata/card":return{databaseId:$CLJS.vR.h(a),tableId:["card__",$CLJS.p.h($CLJS.Qi.h(b))].join(""),cardId:$CLJS.Qi.h(b),isModel:$CLJS.b0.h(b)};
default:return a=$CLJS.zC($CLJS.vA),$CLJS.n($CLJS.yC("metabase.lib.js",a))&&$CLJS.xC("metabase.lib.js",a,$CLJS.fy.l($CLJS.G(["Cannot provide picker-info for",$CLJS.ov.h(b)])),null),null}},expression_parts:function(a,b,c){a=$CLJS.Wra.l($CLJS.G([a,b,c]));return $CLJS.WW(function(d){if($CLJS.wd(d)&&$CLJS.F.g($CLJS.z8,$CLJS.ov.h(d))){var e=$CLJS.O(d);d=$CLJS.I.g(e,$CLJS.hG);var f=$CLJS.I.g(e,$CLJS.Yi);e=$CLJS.I.g(e,$CLJS.Tt);return{operator:$CLJS.Zg(d),options:$CLJS.Zk($CLJS.Yl(f,new $CLJS.Q(null,2,5,
$CLJS.R,[$CLJS.bI,$CLJS.jI],null))),args:$CLJS.ue($CLJS.Ze.g(function(k){return k instanceof $CLJS.K?$CLJS.NC(k):k},e))}}return d},a)},aggregation_operator_columns:function(a){return $CLJS.ue($CLJS.Ara.l($CLJS.G([a])))},find_filterable_column_for_legacy_ref:function(a,b,c){return $CLJS.ksa.l($CLJS.G([a,b,c]))},available_segments:function(a){return $CLJS.ue($CLJS.ata.l($CLJS.G([a])))},join_condition_operators:function(a,b,c,d){return $CLJS.ue($CLJS.psa.l($CLJS.G([a,b,c,d])))},expressionable_columns:T9,
filter:function(a,b,c){return $CLJS.dsa.l($CLJS.G([a,b,$CLJS.DB(c,$CLJS.G([$CLJS.si,!0]))]))},change_direction:function(a,b){return $CLJS.Rsa.l($CLJS.G([a,b]))},pivot_columns_for_type:function(a,b){return $CLJS.Pra.l($CLJS.G([a,b]))},legacy_field_ref:function(a){return $CLJS.Zk($CLJS.Hk.v($CLJS.n_.h($CLJS.h9.l($CLJS.G([a]))),2,$CLJS.Gu,function(b){return $CLJS.ke(b)?$CLJS.NC(b):b}))},remove_field:function(a,b,c){return $CLJS.bsa.l($CLJS.G([a,b,c]))},with_template_tags:function(a,b){return $CLJS.Ksa.l($CLJS.G([a,
F9(b)]))},expressions:function(a,b){return $CLJS.ue($CLJS.Tra.l($CLJS.G([a,b])))},with_join_strategy:function(a,b){return $CLJS.Asa.l($CLJS.G([a,b]))},order_by:function(a,b,c,d){return $CLJS.Ssa.l($CLJS.G([a,b,c,$CLJS.rh.h(d)]))},describe_temporal_interval:function(a,b){a="string"===typeof a?$CLJS.rh.h(a):a;b="string"===typeof b?$CLJS.rh.h(b):b;return $CLJS.eta.l($CLJS.G([a,b]))},breakout:function(a,b,c){return $CLJS.Gra.l($CLJS.G([a,b,$CLJS.h9.l($CLJS.G([c]))]))},database_id:function(a){return $CLJS.Mra.l($CLJS.G([a]))},
required_native_extras:function(a,b){return $CLJS.ue($CLJS.Ze.g($CLJS.NC,$CLJS.Lsa.l($CLJS.G([G9(a,b)]))))},remove_join:function(a,b,c){return $CLJS.Ysa.l($CLJS.G([a,b,c]))},joined_thing:function(a,b){return $CLJS.s4(a,b)},with_native_query:function(a,b){return $CLJS.Hsa.l($CLJS.G([a,b]))},breakoutable_columns:function(a,b){return $CLJS.ue($CLJS.Hra.l($CLJS.G([a,b])))},rename_join:function(a,b,c,d){return $CLJS.Zsa.l($CLJS.G([a,b,c,d]))},with_different_table:function(a,b){return $CLJS.Wsa.l($CLJS.G([a,
b]))}};