var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./malli.core.js");require("./malli.error.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.temporal.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.literal.js");require("./metabase.mbql.schema.helpers.js");require("./metabase.mbql.schema.macros.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.malli.registry.js");
'use strict';var qN,rN,sN,wfa,xfa,wN,yfa,zN,AN,BN,CN,zfa,DN,EN,GN,HN,tN,IN,Afa,JN,Bfa,LN,MN,NN,Cfa,ON,PN,Dfa,QN,Efa,RN,SN,Ffa,UN,Gfa,Hfa,Ifa,WN,XN,YN,ZN,$N,aO,bO,cO,Jfa,dO,eO,fO,hO,jO,Kfa,kO,lO,mO,nO,Lfa,oO,pO,qO,rO,Mfa,sO,tO,uO,vO,Nfa,wO,Ofa,xO,yO,zO,Pfa,AO,BO,CO,DO,FO,GO,Qfa,HO,IO,Rfa,JO,KO,LO,MO,NO,Sfa,OO,Tfa,PO,QO,SO,Ufa,TO,UO,VO,Vfa,YO,ZO,Wfa,Xfa,$O,bP,cP,dP,Yfa,gP,hP,Zfa,$fa,aga,iP,jP,kP,lP,mP,nP,bga,oP,cga,pP,qP,sP,uP,vP,wP,xP,dga,zP,ega,fga,AP,BP,CP,DP,EP,gga,FP,hga,iga,GP,HP,IP,JP,KP,jga,
kga,lga,LP,mga,MP,nga,OP,PP,oga,RP,SP,TP,UP,pga,XP,qga,rga,YP,ZP,$P,sga,aQ,bQ,cQ,dQ,tga,fQ,gQ,hQ,iQ,jQ,uga,kQ,lQ,vga,mQ,nQ,oQ,pQ,qQ,rQ,tQ,wga,uQ,xga,vQ,wQ,xQ,yga,zga,Aga,zQ,AQ,BQ,Bga,CQ,DQ,EQ,FQ,Cga,Dga,HQ,Ega,Fga,IQ,JQ,KQ,LQ,MQ,Gga,NQ,OQ,PQ,QQ,Hga,RQ,SQ,TQ,UQ,WQ,Iga,XQ,YQ,Jga,ZQ,$Q,aR,Kga,bR,Lga,dR,Mga,eR,fR,gR,hR,iR,jR,kR,Nga,Oga,Pga,lR,Qga,mR,nR,Rga,Sga,oR,pR,qR,Tga,rR,Uga,sR,Vga,Wga,tR,Xga,Yga,Zga,wR,xR,$ga,yR,zR,BR,aha,CR,bha,DR,ER,FR,cha,dha,GR,HR,IR,JR,eha,LR,MR,fha,NR,OR,PR,QR,RR,SR,TR,UR,
gha,VR,WR,hha,YR,iha,ZR,$R,aS,jha,bS,cS,kha,lha,dS,eS,fS,mha,nha,gS,oha,pha,qha,iS,rha,kS,sha,tha,lS,mS,uha,vha,wha,nS,oS,pS,qS,rS,sS,tS,xha,uS,vS,yha,wS,zha,Aha,Bha,xS,yS,zS,Cha,AS,BS,Dha,CS,Eha,DS,Fha,Gha,FS,GS,HS,IS,JS,Hha,KS,LS,Iha,MS,NS,OS,PS,QS,RS,SS,TS,Jha,US,VS,XS,YS,ZS,$S,aT,bT,cT,dT,eT,fT,Kha,gT,hT,iT,jT,kT,Lha,lT,mT,nT,oT,Mha,qT,Nha,Oha,sT,Pha,tT,Qha,uT,Rha,Sha,vT,Tha,wT,yT,zT,Uha,DT,ET,Vha,Wha,FT,GT,HT,IT,JT,KT,LT,NT,OT,Xha;
$CLJS.oN=function(a,b){return function(){function c(l,m,t){return $CLJS.Dd(function(){var u=a.h?a.h(l):a.call(null,l);return $CLJS.n(u)?(u=a.h?a.h(m):a.call(null,m),$CLJS.n(u)?(u=a.h?a.h(t):a.call(null,t),$CLJS.n(u)?(u=b.h?b.h(l):b.call(null,l),$CLJS.n(u)?(u=b.h?b.h(m):b.call(null,m),$CLJS.n(u)?b.h?b.h(t):b.call(null,t):u):u):u):u):u}())}function d(l,m){return $CLJS.Dd(function(){var t=a.h?a.h(l):a.call(null,l);return $CLJS.n(t)?(t=a.h?a.h(m):a.call(null,m),$CLJS.n(t)?(t=b.h?b.h(l):b.call(null,l),
$CLJS.n(t)?b.h?b.h(m):b.call(null,m):t):t):t}())}function e(l){var m=a.h?a.h(l):a.call(null,l);l=$CLJS.n(m)?b.h?b.h(l):b.call(null,l):m;return $CLJS.Dd(l)}var f=null,k=function(){function l(t,u,v,x){var A=null;if(3<arguments.length){A=0;for(var D=Array(arguments.length-3);A<D.length;)D[A]=arguments[A+3],++A;A=new $CLJS.y(D,0,null)}return m.call(this,t,u,v,A)}function m(t,u,v,x){return $CLJS.Dd(function(){var A=f.j(t,u,v);return $CLJS.n(A)?$CLJS.Ie(function(D){var J=a.h?a.h(D):a.call(null,D);return $CLJS.n(J)?
b.h?b.h(D):b.call(null,D):J},x):A}())}l.A=3;l.B=function(t){var u=$CLJS.B(t);t=$CLJS.C(t);var v=$CLJS.B(t);t=$CLJS.C(t);var x=$CLJS.B(t);t=$CLJS.Kc(t);return m(u,v,x,t)};l.l=m;return l}();f=function(l,m,t,u){switch(arguments.length){case 0:return!0;case 1:return e.call(this,l);case 2:return d.call(this,l,m);case 3:return c.call(this,l,m,t);default:var v=null;if(3<arguments.length){v=0;for(var x=Array(arguments.length-3);v<x.length;)x[v]=arguments[v+3],++v;v=new $CLJS.y(x,0,null)}return k.l(l,m,t,
v)}throw Error("Invalid arity: "+arguments.length);};f.A=3;f.B=k.B;f.o=function(){return!0};f.h=e;f.g=d;f.j=c;f.l=k.l;return f}()};
$CLJS.pN=function(a){var b=$CLJS.ro.g(a,null),c=$CLJS.ZG(b,$CLJS.Ht,function(d){return $CLJS.qn(d,$CLJS.tf)});return function(){function d(k,l,m){return(l=$CLJS.z(c.j?c.j(k,l,m):c.call(null,k,l,m)))?new $CLJS.h(null,3,[$CLJS.ck,b,$CLJS.uj,k,$CLJS.Ot,l],null):null}function e(k){return f.j(k,$CLJS.tf,$CLJS.tf)}var f=null;f=function(k,l,m){switch(arguments.length){case 1:return e.call(this,k);case 3:return d.call(this,k,l,m)}throw Error("Invalid arity: "+arguments.length);};f.h=e;f.j=d;return f}()};
qN=function(a,b){return $CLJS.wd(a)?$CLJS.I.g(a,b):a};rN=function(a,b,c,d){d=$CLJS.n(d)?d:$CLJS.tn($CLJS.ro.g($CLJS.ck.h(a),null));if($CLJS.n(b)){var e=qN($CLJS.yu.h(b),c);$CLJS.n(e)?(e=$CLJS.Aq(e,d),a=e.g?e.g(a,d):e.call(null,a,d)):a=null;return $CLJS.n(a)?a:qN($CLJS.wu.h(b),c)}return null};sN=function(a,b){return $CLJS.ud(a)||$CLJS.Cl(a)?$CLJS.I.g(a,b):$CLJS.vd(a)?$CLJS.I.g($CLJS.uf(a),b):null};
wfa=function(a,b){a=$CLJS.O(a);var c=$CLJS.I.g(a,$CLJS.ck),d=$CLJS.I.g(a,$CLJS.dj);b=$CLJS.O(b);var e=$CLJS.I.j(b,$CLJS.Ot,$CLJS.sca),f=$CLJS.I.j(b,$CLJS.nca,!0),k=$CLJS.I.g(b,$CLJS.SD),l=$CLJS.I.j(b,$CLJS.QD,$CLJS.OD),m=rN(a,$CLJS.Mq.h(c),k,b);if($CLJS.n(m))return m;m=rN(a,$CLJS.cu.h(c),k,b);if($CLJS.n(m))return m;m=rN(a,e.h?e.h(d):e.call(null,d),k,b);if($CLJS.n(m))return m;m=rN(a,function(){var t=$CLJS.Yn.h(c);return e.h?e.h(t):e.call(null,t)}(),k,b);if($CLJS.n(m))return m;m=rN(a,$CLJS.Mq.h(c),
l,b);if($CLJS.n(m))return m;m=rN(a,$CLJS.cu.h(c),l,b);if($CLJS.n(m))return m;d=rN(a,e.h?e.h(d):e.call(null,d),l,b);if($CLJS.n(d))return d;d=rN(a,function(){var t=$CLJS.Yn.h(c);return e.h?e.h(t):e.call(null,t)}(),l,b);if($CLJS.n(d))return d;k=$CLJS.n(f)?rN(a,e.h?e.h($CLJS.MD):e.call(null,$CLJS.MD),k,b):f;return $CLJS.n(k)?k:$CLJS.n(f)?rN(a,e.h?e.h($CLJS.MD):e.call(null,$CLJS.MD),l,b):f};
xfa=function(a,b,c){a=$CLJS.R;var d=$CLJS.Wf.g;var e=$CLJS.hl.h(b);var f=$CLJS.O(b);var k=$CLJS.I.g(f,$CLJS.ck),l=$CLJS.O(c);f=$CLJS.I.g(l,$CLJS.SD);l=$CLJS.I.j(l,$CLJS.QD,$CLJS.OD);k=$CLJS.Mq.h(k);f=qN($CLJS.PD.h(k),f);f=$CLJS.n(f)?f:qN($CLJS.PD.h(k),l);d=d.call($CLJS.Wf,e,f);return new $CLJS.Q(null,2,5,a,[d,wfa(b,c)],null)};
$CLJS.uN=function(a){var b=$CLJS.O(a),c=$CLJS.I.g(b,$CLJS.uj);a=$CLJS.I.g(b,$CLJS.Ot);var d=$CLJS.O(null),e=$CLJS.I.j(d,$CLJS.oca,$CLJS.Ii),f=$CLJS.I.j(d,$CLJS.qca,xfa);return $CLJS.n(a)?$CLJS.ab(function(k,l){var m=f.j?f.j(b,l,d):f.call(null,b,l,d),t=$CLJS.H(m,0,null);m=$CLJS.H(m,1,null);l=$CLJS.S.j(l,$CLJS.Ii,m);l=e.h?e.h(l):e.call(null,l);return tN(k,c,t,l)},null,a):null};
wN=function(a,b,c){var d=$CLJS.qC($CLJS.q($CLJS.QF),new $CLJS.Q(null,2,5,$CLJS.R,[a,b],null));if($CLJS.n(d))return d;c=c.o?c.o():c.call(null);$CLJS.Gh.v($CLJS.QF,$CLJS.vN,new $CLJS.Q(null,2,5,$CLJS.R,[a,b],null),c);return c};$CLJS.xN=function(a){return wN($CLJS.mk,a,function(){return $CLJS.$G.h(a)})};yfa=function(){var a=yN;return wN($CLJS.Ht,a,function(){var b=$CLJS.$G.h(a),c=$CLJS.pN(a);return function(d){return $CLJS.n(b.h?b.h(d):b.call(null,d))?null:c.h?c.h(d):c.call(null,d)}})};
zN=function(a){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ck,$CLJS.ke(a)?new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,a],null):a],null)};AN=function(a){if($CLJS.yd(a)){var b=$CLJS.H(a,0,null),c=$CLJS.H(a,1,null);switch(b instanceof $CLJS.K?b.T:null){case "optional":return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ss,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jt,zN(c)],null)],null);case "rest":return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Rs,zN(c)],null);default:return zN(a)}}else return zN(a)};
BN=function(a,b){var c=$CLJS.yd(b);return c?(c=$CLJS.B(b)instanceof $CLJS.K)?$CLJS.td(a)?(b=$CLJS.B(b),a=$CLJS.Yg(a),a.h?a.h(b):a.call(null,b)):$CLJS.F.g(a,$CLJS.B(b)):c:c};
CN=function(a,b){return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.qt,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ht,new $CLJS.h(null,1,[$CLJS.wu,["not a ",$CLJS.p.h(a)," clause"].join("")],null),$CLJS.Me(BN,a)],null),$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ai,new $CLJS.Q(null,2,5,$CLJS.R,["tag",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ns,a],null)],null)],null),function(){return function e(d){return new $CLJS.le(null,function(){for(;;){var f=$CLJS.z(d);if(f){if($CLJS.zd(f)){var k=$CLJS.kc(f),l=$CLJS.E(k),m=$CLJS.oe(l);
a:for(var t=0;;)if(t<l){var u=$CLJS.kd(k,t),v=$CLJS.H(u,0,null);u=$CLJS.H(u,1,null);v=new $CLJS.Q(null,2,5,$CLJS.R,[v,AN(u)],null);m.add(v);t+=1}else{k=!0;break a}return k?$CLJS.re($CLJS.te(m),e($CLJS.lc(f))):$CLJS.re($CLJS.te(m),null)}k=$CLJS.B(f);m=$CLJS.H(k,0,null);k=$CLJS.H(k,1,null);return $CLJS.ce(new $CLJS.Q(null,2,5,$CLJS.R,[m,AN(k)],null),e($CLJS.Kc(f)))}return null}},null,null)}($CLJS.fu(2,2,b))}())],null)};zfa=function(a){return $CLJS.yd(a)&&$CLJS.B(a)instanceof $CLJS.K?$CLJS.B(a):null};
DN=function(a){return $CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.pt,new $CLJS.h(null,2,[$CLJS.Gi,zfa,$CLJS.wu,["valid instance of one of these MBQL clauses: ",$CLJS.Hu(", ",$CLJS.Ze.g($CLJS.B,a))].join("")],null)],null),function(){return function d(c){return new $CLJS.le(null,function(){for(;;){var e=$CLJS.z(c);if(e){if($CLJS.zd(e)){var f=$CLJS.kc(e),k=$CLJS.E(f),l=$CLJS.oe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m),u=$CLJS.H(t,0,null);t=$CLJS.H(t,1,null);u=new $CLJS.Q(null,2,5,$CLJS.R,[u,
$CLJS.ke(t)?new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,t],null):t],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.re($CLJS.te(l),d($CLJS.lc(e))):$CLJS.re($CLJS.te(l),null)}f=$CLJS.B(e);l=$CLJS.H(f,0,null);f=$CLJS.H(f,1,null);return $CLJS.ce(new $CLJS.Q(null,2,5,$CLJS.R,[l,$CLJS.ke(f)?new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,f],null):f],null),d($CLJS.Kc(e)))}return null}},null,null)}(a)}())};
EN=function(a){return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.qt,a,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.nN],null)],null)};$CLJS.FN={};GN={};HN={};$CLJS.vN=function vN(a,b,c){var e=$CLJS.z(b);b=$CLJS.B(e);var f=$CLJS.C(e);if(f){e=$CLJS.S.j;var k=$CLJS.I.g(a,b);c=vN.j?vN.j(k,f,c):vN.call(null,k,f,c);a=e.call($CLJS.S,a,b,c)}else a=$CLJS.S.j(a,b,c);return a};
tN=function tN(a,b,c,d){var f=$CLJS.z(c);c=$CLJS.B(f);var k=$CLJS.C(f),l=sN(b,c);f=$CLJS.n(a)?a:$CLJS.vd(b)?$CLJS.tf:$CLJS.xd(b)?$CLJS.N:$CLJS.jd(b);return $CLJS.n($CLJS.n(c)?$CLJS.ND.h($CLJS.pd(f)):c)?a:$CLJS.n(c)?(b=sN(f,c),d=tN.v?tN.v(b,l,k,d):tN.call(null,b,l,k,d),$CLJS.Hl(c)&&$CLJS.vd(f)&&c>$CLJS.E(f)&&(b=$CLJS.Ve(c-$CLJS.E(f),null),b=$CLJS.Ye.g(f,b),f=null==f||$CLJS.Cd(f)?b:$CLJS.Wf.g($CLJS.jd(f),b)),null==f||$CLJS.Cl(f)?$CLJS.S.j(f,c,d):$CLJS.ud(f)?$CLJS.$d.g(f,d):$CLJS.P.g($CLJS.W,$CLJS.S.j($CLJS.uf(f),
c,d))):$CLJS.wd(a)?(c=new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.pca],null),tN.v?tN.v(f,b,c,d):tN.call(null,f,b,c,d)):$CLJS.n($CLJS.ND.h($CLJS.pd(f)))?$CLJS.$d.g(f,d):$CLJS.yd($CLJS.De(f))?f:$CLJS.od(new $CLJS.Q(null,1,5,$CLJS.R,[d],null),new $CLJS.h(null,1,[$CLJS.ND,!0],null))};IN=new $CLJS.K("metabase.mbql.schema","aggregation","metabase.mbql.schema/aggregation",-662824303);Afa=new $CLJS.w("metabase.mbql.schema","datetime-subtract","metabase.mbql.schema/datetime-subtract",-1242286173,null);
JN=new $CLJS.K("metabase.mbql.schema","ag:var","metabase.mbql.schema/ag:var",-345484364);$CLJS.KN=new $CLJS.K(null,"dimension","dimension",543254198);Bfa=new $CLJS.w("metabase.mbql.schema","time-interval","metabase.mbql.schema/time-interval",1275783375,null);LN=new $CLJS.K("metabase.mbql.schema","datetime-subtract","metabase.mbql.schema/datetime-subtract",1412149596);MN=new $CLJS.K("metabase.mbql.schema","datetime-add","metabase.mbql.schema/datetime-add",-985876836);
NN=new $CLJS.K("metabase.mbql.schema","substring","metabase.mbql.schema/substring",188871083);Cfa=new $CLJS.w("metabase.mbql.schema","replace","metabase.mbql.schema/replace",1269362826,null);ON=new $CLJS.K("metabase.mbql.schema","TimeLiteral","metabase.mbql.schema/TimeLiteral",-822346498);PN=new $CLJS.K("metabase.mbql.schema","get-month","metabase.mbql.schema/get-month",549586740);Dfa=new $CLJS.K(null,"disable-mbql-\x3enative?","disable-mbql-\x3enative?",1103196677);
QN=new $CLJS.K("metabase.mbql.schema","StringExpressionArg","metabase.mbql.schema/StringExpressionArg",596241960);Efa=new $CLJS.K(null,"from","from",1815293044);RN=new $CLJS.K("metabase.mbql.schema","and","metabase.mbql.schema/and",-86675456);SN=new $CLJS.w(null,"does-not-contain","does-not-contain",1365584674,null);Ffa=new $CLJS.K(null,"add-default-userland-constraints?","add-default-userland-constraints?",2039156190);$CLJS.TN=new $CLJS.K(null,"snippet-name","snippet-name",819240328);
UN=new $CLJS.K("metabase.mbql.schema","not-empty","metabase.mbql.schema/not-empty",560735368);Gfa=new $CLJS.w("metabase.mbql.schema","max","metabase.mbql.schema/max",-1397867614,null);Hfa=new $CLJS.w("metabase.mbql.schema","get-day","metabase.mbql.schema/get-day",-1600012951,null);$CLJS.VN=new $CLJS.K(null,"aggregation-options","aggregation-options",-1904917550);Ifa=new $CLJS.w("metabase.mbql.schema","cum-count","metabase.mbql.schema/cum-count",596526831,null);
WN=new $CLJS.K("location","zip_code","location/zip_code",1641155222);XN=new $CLJS.w(null,"ExpressionArg","ExpressionArg",1060743736,null);YN=new $CLJS.w(null,"get-hour","get-hour",-2031721710,null);ZN=new $CLJS.K("metabase.mbql.schema","dimension","metabase.mbql.schema/dimension",-140332301);$N=new $CLJS.w(null,"ag:var","ag:var",-1804440286,null);aO=new $CLJS.K("metabase.mbql.schema","IntGreaterThanZeroOrNumericExpression","metabase.mbql.schema/IntGreaterThanZeroOrNumericExpression",1485789132);
bO=new $CLJS.K("metabase.mbql.schema","exp","metabase.mbql.schema/exp",-1188751039);cO=new $CLJS.K("metabase.mbql.schema","time-interval","metabase.mbql.schema/time-interval",-364748152);Jfa=new $CLJS.K(null,"lon-max","lon-max",1590224717);dO=new $CLJS.K("metabase.mbql.schema","asc","metabase.mbql.schema/asc",-302489464);eO=new $CLJS.w(null,"stddev","stddev",775056588,null);fO=new $CLJS.K("metabase.mbql.schema","lower","metabase.mbql.schema/lower",-1954451840);
$CLJS.gO=new $CLJS.K(null,"snippet","snippet",953581994);hO=new $CLJS.w("metabase.mbql.schema","case","metabase.mbql.schema/case",2100482976,null);$CLJS.iO=new $CLJS.K(null,"query","query",-1288509510);jO=new $CLJS.K(null,"string-expression","string-expression",-1395337766);Kfa=new $CLJS.K(null,"lat-field","lat-field",-830652957);kO=new $CLJS.K("metabase.mbql.schema","now","metabase.mbql.schema/now",-975392912);lO=new $CLJS.K("metabase.mbql.schema","avg","metabase.mbql.schema/avg",1123586863);
mO=new $CLJS.K("metabase.mbql.schema","not","metabase.mbql.schema/not",-1753911131);nO=new $CLJS.K("metabase.mbql.schema","StringExpression","metabase.mbql.schema/StringExpression",-670335423);Lfa=new $CLJS.w("metabase.mbql.schema","min","metabase.mbql.schema/min",-1534510688,null);oO=new $CLJS.K("location","country","location/country",1666636202);pO=new $CLJS.K("metabase.mbql.schema","trim","metabase.mbql.schema/trim",350521664);
qO=new $CLJS.K("metabase.mbql.schema","Reference","metabase.mbql.schema/Reference",-770092616);rO=new $CLJS.K("metabase.mbql.schema","get-second","metabase.mbql.schema/get-second",-1080110117);Mfa=new $CLJS.w("metabase.mbql.schema","count-where","metabase.mbql.schema/count-where",-1224231786,null);sO=new $CLJS.K(null,"unary","unary",-989314568);tO=new $CLJS.K("metabase.mbql.schema","rtrim","metabase.mbql.schema/rtrim",297284410);
uO=new $CLJS.K("metabase.mbql.schema","relative-datetime","metabase.mbql.schema/relative-datetime",324961083);vO=new $CLJS.w(null,"ceil","ceil",-184398425,null);Nfa=new $CLJS.K(null,"lon-min","lon-min",-787291357);wO=new $CLJS.w(null,"NonBlankString","NonBlankString",-719244809,null);Ofa=new $CLJS.K(null,"match","match",1220059550);xO=new $CLJS.w(null,"count-where","count-where",2025939247,null);yO=new $CLJS.K("metabase.mbql.schema","get-hour","metabase.mbql.schema/get-hour",1844658706);
zO=new $CLJS.K(null,"max-results-bare-rows","max-results-bare-rows",2096475889);Pfa=new $CLJS.K(null,"embedded-question","embedded-question",-2146473954);AO=new $CLJS.K("metabase.mbql.schema","WidgetType","metabase.mbql.schema/WidgetType",1831406784);BO=new $CLJS.w(null,"sum","sum",1777518341,null);CO=new $CLJS.K("metabase.mbql.schema","datetime-diff","metabase.mbql.schema/datetime-diff",197727284);DO=new $CLJS.w("metabase.mbql.schema","!\x3d","metabase.mbql.schema/!\x3d",-807630752,null);
$CLJS.EO=new $CLJS.K("date","range","date/range",1647265776);FO=new $CLJS.w(null,"between","between",-1523336493,null);GO=new $CLJS.K(null,"clause-form","clause-form",1820463737);Qfa=new $CLJS.w("metabase.mbql.schema","log","metabase.mbql.schema/log",-899459908,null);HO=new $CLJS.K("metabase.mbql.schema","Query","metabase.mbql.schema/Query",1321874752);IO=new $CLJS.w(null,"field","field",338095027,null);Rfa=new $CLJS.K(null,"segment-id","segment-id",1810133590);
JO=new $CLJS.K("metabase.mbql.schema","stddev","metabase.mbql.schema/stddev",94927598);KO=new $CLJS.w(null,"not-null","not-null",313812992,null);LO=new $CLJS.K("metabase.mbql.schema","Joins","metabase.mbql.schema/Joins",1376278278);MO=new $CLJS.K("metabase.mbql.schema","ltrim","metabase.mbql.schema/ltrim",-1587636578);NO=new $CLJS.K("metabase.mbql.schema","\x3c\x3d","metabase.mbql.schema/\x3c\x3d",556298105);Sfa=new $CLJS.K(null,"template-tag","template-tag",310841038);
OO=new $CLJS.K(null,"invalid","invalid",412869516);Tfa=new $CLJS.w("metabase.mbql.schema","datetime-diff","metabase.mbql.schema/datetime-diff",1838258811,null);PO=new $CLJS.K("metabase.mbql.schema","absolute-datetime","metabase.mbql.schema/absolute-datetime",1473793750);QO=new $CLJS.w(null,"regex-match-first","regex-match-first",983231809,null);$CLJS.RO=new $CLJS.K(null,"context","context",-830191113);SO=new $CLJS.w(null,"get-year","get-year",704520253,null);
Ufa=new $CLJS.K(null,"format-rows?","format-rows?",992129486);TO=new $CLJS.K("metabase.mbql.schema","\x3e\x3d","metabase.mbql.schema/\x3e\x3d",336705322);UO=new $CLJS.K("metabase.mbql.schema","temporal-extract","metabase.mbql.schema/temporal-extract",829238608);VO=new $CLJS.K("metabase.mbql.schema","variable","metabase.mbql.schema/variable",672451083);$CLJS.WO=new $CLJS.K(null,"parameters","parameters",-1229919748);Vfa=new $CLJS.K(null,"json-download","json-download",-971130133);
$CLJS.XO=new $CLJS.K(null,"card","card",-1430355152);YO=new $CLJS.K(null,"string-or-field","string-or-field",-1970678542);ZO=new $CLJS.w(null,"!\x3d","!\x3d",-201205829,null);Wfa=new $CLJS.w("metabase.mbql.schema","ag:var","metabase.mbql.schema/ag:var",1295047163,null);Xfa=new $CLJS.w("metabase.mbql.schema","ceil","metabase.mbql.schema/ceil",-1404628626,null);$O=new $CLJS.w(null,"expression","expression",1842843403,null);$CLJS.aP=new $CLJS.K("date","month-year","date/month-year",1948031290);
bP=new $CLJS.K("metabase.mbql.schema","BooleanExpression","metabase.mbql.schema/BooleanExpression",-2109835363);cP=new $CLJS.w(null,"ends-with","ends-with",1183740516,null);dP=new $CLJS.K("metabase.mbql.schema","time","metabase.mbql.schema/time",727162193);$CLJS.eP=new $CLJS.K(null,"joins","joins",1033962699);Yfa=new $CLJS.K(null,"slug","slug",2029314850);$CLJS.fP=new $CLJS.K(null,"source-field","source-field",933829534);gP=new $CLJS.w(null,"Field","Field",430385967,null);
hP=new $CLJS.w(null,"convert-timezone","convert-timezone",1515728526,null);Zfa=new $CLJS.w("metabase.mbql.schema","absolute-datetime","metabase.mbql.schema/absolute-datetime",-1180642019,null);$fa=new $CLJS.K(null,"disable-max-results?","disable-max-results?",857693204);aga=new $CLJS.K(null,"items","items",1031954938);iP=new $CLJS.K(null,"datetime-expression","datetime-expression",391782195);jP=new $CLJS.K("metabase.mbql.schema","coalesce","metabase.mbql.schema/coalesce",1605865100);
kP=new $CLJS.K(null,"more","more",-2058821800);lP=new $CLJS.K(null,"first-clause","first-clause",-20953491);mP=new $CLJS.K("metabase.mbql.schema","sum-where","metabase.mbql.schema/sum-where",-671453147);nP=new $CLJS.w(null,"OrderComparable","OrderComparable",772072595,null);bga=new $CLJS.w("metabase.mbql.schema","ends-with","metabase.mbql.schema/ends-with",-2117292579,null);oP=new $CLJS.w(null,"contains","contains",-1977535957,null);
cga=new $CLJS.w("metabase.mbql.schema","floor","metabase.mbql.schema/floor",422926927,null);pP=new $CLJS.w("metabase.mbql.schema","expression","metabase.mbql.schema/expression",370334320,null);qP=new $CLJS.K("number","\x3d","number/\x3d",-2094581309);$CLJS.rP=new $CLJS.K(null,"widget-type","widget-type",1836256899);sP=new $CLJS.K("metabase.mbql.schema","max","metabase.mbql.schema/max",1256568155);$CLJS.tP=new $CLJS.K(null,"fingerprint","fingerprint",598613022);
uP=new $CLJS.w(null,"is-null","is-null",-356519403,null);vP=new $CLJS.K(null,"other-clauses","other-clauses",1570511021);wP=new $CLJS.K("metabase.mbql.schema","is-null","metabase.mbql.schema/is-null",-1003477115);xP=new $CLJS.K("metabase.mbql.schema","upper","metabase.mbql.schema/upper",-646839893);dga=new $CLJS.K(null,"map-tiles","map-tiles",1961865797);$CLJS.yP=new $CLJS.K(null,"required","required",1807647006);zP=new $CLJS.K(null,"unnamed-aggregation","unnamed-aggregation",-93854280);
ega=new $CLJS.K(null,"datetime-x","datetime-x",1519265947);fga=new $CLJS.K(null,"datetime-y","datetime-y",-1666955771);AP=new $CLJS.w(null,"CaseClauses","CaseClauses",-1749071354,null);BP=new $CLJS.K(null,"date-arithmetics","date-arithmetics",-1832808309);CP=new $CLJS.K("string","contains","string/contains",1602423827);DP=new $CLJS.K("metabase.mbql.schema","count-where","metabase.mbql.schema/count-where",1430203983);EP=new $CLJS.K("metabase.mbql.schema","or","metabase.mbql.schema/or",1154080514);
gga=new $CLJS.w("metabase.mbql.schema","metric","metabase.mbql.schema/metric",1030004285,null);FP=new $CLJS.K("string","!\x3d","string/!\x3d",-1083772573);hga=new $CLJS.w(null,"IntGreaterThanZeroOrNumericExpression","IntGreaterThanZeroOrNumericExpression",2098230228,null);iga=new $CLJS.w("metabase.mbql.schema","count","metabase.mbql.schema/count",678066683,null);GP=new $CLJS.K("metabase.mbql.schema","min","metabase.mbql.schema/min",1119925081);
HP=new $CLJS.w("metabase.mbql.schema","field","metabase.mbql.schema/field",1566852856,null);IP=new $CLJS.K("metabase.mbql.schema","convert-timezone","metabase.mbql.schema/convert-timezone",-11600894);JP=new $CLJS.K("metabase.mbql.schema","Join","metabase.mbql.schema/Join",-2100258965);KP=new $CLJS.w(null,"share","share",1051097594,null);jga=new $CLJS.w("metabase.mbql.schema","distinct","metabase.mbql.schema/distinct",-823840515,null);
kga=new $CLJS.w("metabase.mbql.schema","round","metabase.mbql.schema/round",314817788,null);lga=new $CLJS.w("metabase.mbql.schema","get-quarter","metabase.mbql.schema/get-quarter",2007639089,null);LP=new $CLJS.K("metabase.mbql.schema","distinct","metabase.mbql.schema/distinct",1830595254);mga=new $CLJS.K(null,"xlsx-download","xlsx-download",-1622892009);MP=new $CLJS.K("metabase.mbql.schema","contains","metabase.mbql.schema/contains",-241280695);
nga=new $CLJS.w("metabase.mbql.schema","contains","metabase.mbql.schema/contains",1399250832,null);$CLJS.NP=new $CLJS.K(null,"collection","collection",-683361892);OP=new $CLJS.K("metabase.mbql.schema","is-empty","metabase.mbql.schema/is-empty",45949435);PP=new $CLJS.K("metabase.mbql.schema","median","metabase.mbql.schema/median",414562044);$CLJS.QP=new $CLJS.K("metadata","dataset-metadata","metadata/dataset-metadata",-728376469);
oga=new $CLJS.w("metabase.mbql.schema","is-empty","metabase.mbql.schema/is-empty",1686480962,null);RP=new $CLJS.K("number","\x3c\x3d","number/\x3c\x3d",-1499316353);SP=new $CLJS.w(null,"metric","metric",2049329604,null);TP=new $CLJS.w(null,"concat","concat",-467652465,null);UP=new $CLJS.K(null,"variable","variable",-281346492);$CLJS.VP=new $CLJS.K("date","quarter-year","date/quarter-year",-1453950150);pga=new $CLJS.w(null,"TimeUnit","TimeUnit",1200517789,null);
$CLJS.WP=new $CLJS.K("date","relative","date/relative",25987732);XP=new $CLJS.K("location","city","location/city",-1746973325);qga=new $CLJS.K(null,"public-dashboard","public-dashboard",645968405);rga=new $CLJS.w("metabase.mbql.schema","desc","metabase.mbql.schema/desc",-2050113424,null);YP=new $CLJS.K("metabase.mbql.schema","get-day","metabase.mbql.schema/get-day",1054422818);ZP=new $CLJS.K("number","\x3e\x3d","number/\x3e\x3d",-1670691032);$P=new $CLJS.K("number","between","number/between",97700581);
sga=new $CLJS.w("metabase.mbql.schema","cum-sum","metabase.mbql.schema/cum-sum",-2078342061,null);aQ=new $CLJS.K("metabase.mbql.schema","starts-with","metabase.mbql.schema/starts-with",-576867936);bQ=new $CLJS.w(null,"sqrt","sqrt",370479598,null);cQ=new $CLJS.K("metabase.mbql.schema","get-minute","metabase.mbql.schema/get-minute",515738880);dQ=new $CLJS.K("metabase.mbql.schema","expression","metabase.mbql.schema/expression",-1270197207);$CLJS.eQ=new $CLJS.K(null,"semantic_type","semantic_type",272485089);
tga=new $CLJS.K(null,"metric-id","metric-id",-686486942);fQ=new $CLJS.w(null,"*","*",345799209,null);gQ=new $CLJS.w(null,"+","+",-740910886,null);hQ=new $CLJS.w(null,"-","-",-471816912,null);iQ=new $CLJS.w(null,"template-tag","template-tag",1951372565,null);jQ=new $CLJS.K(null,"allowed-for","allowed-for",122724334);uga=new $CLJS.K(null,"question","question",-1411720117);kQ=new $CLJS.w(null,"asc","asc",1997386096,null);
lQ=new $CLJS.K("metabase.mbql.schema","not-null","metabase.mbql.schema/not-null",-391950078);vga=new $CLJS.K(null,"pulse-id","pulse-id",1331432237);mQ=new $CLJS.K("metabase.mbql.schema","regex-match-first","metabase.mbql.schema/regex-match-first",277590849);nQ=new $CLJS.w(null,"\x3c\x3d","\x3c\x3d",1244895369,null);oQ=new $CLJS.w(null,"\x3c","\x3c",993667236,null);pQ=new $CLJS.w(null,"\x3e","\x3e",1085014381,null);qQ=new $CLJS.w(null,"\x3d","\x3d",-1501502141,null);
rQ=new $CLJS.K("metabase.mbql.schema","OrderComparable","metabase.mbql.schema/OrderComparable",1970325905);$CLJS.sQ=new $CLJS.K(null,"source-metadata","source-metadata",-477816085);tQ=new $CLJS.K("string","ends-with","string/ends-with",302681156);wga=new $CLJS.w("metabase.mbql.schema","get-year","metabase.mbql.schema/get-year",-265030428,null);uQ=new $CLJS.w(null,"RelativeDatetimeUnit","RelativeDatetimeUnit",-1157637501,null);xga=new $CLJS.w(null,"MetricID","MetricID",-2128635641,null);
vQ=new $CLJS.w(null,"and","and",668631710,null);wQ=new $CLJS.w(null,"\x3e\x3d","\x3e\x3d",1016916022,null);xQ=new $CLJS.w(null,"round","round",-645002441,null);yga=new $CLJS.K(null,"to","to",192099007);$CLJS.yQ=new $CLJS.K("date","single","date/single",1554682003);zga=new $CLJS.K(null,"action-id","action-id",-1727958578);Aga=new $CLJS.w("metabase.mbql.schema","not-null","metabase.mbql.schema/not-null",1248581449,null);zQ=new $CLJS.w(null,"exp","exp",1378825265,null);
AQ=new $CLJS.w(null,"Filter","Filter",-424893332,null);BQ=new $CLJS.w(null,"cum-count","cum-count",-323900016,null);Bga=new $CLJS.K(null,"dashboard-id","dashboard-id",1965414288);CQ=new $CLJS.K("metabase.mbql.schema","percentile","metabase.mbql.schema/percentile",-1731861963);DQ=new $CLJS.K("metabase.mbql.schema","abs","metabase.mbql.schema/abs",152348622);EQ=new $CLJS.K("metabase.mbql.schema","desc","metabase.mbql.schema/desc",604322345);
FQ=new $CLJS.K(null,"numeric-expression","numeric-expression",-1661337235);$CLJS.GQ=new $CLJS.K(null,"source-table","source-table",-225307692);Cga=new $CLJS.K(null,"embedded-dashboard","embedded-dashboard",-485078014);Dga=new $CLJS.w("metabase.mbql.schema","sqrt","metabase.mbql.schema/sqrt",-824662873,null);HQ=new $CLJS.w(null,"floor","floor",-772394748,null);Ega=new $CLJS.K(null,"middleware","middleware",1462115504);
Fga=new $CLJS.w("metabase.mbql.schema","share","metabase.mbql.schema/share",1977664161,null);IQ=new $CLJS.K(null,"requires-features","requires-features",-101116256);JQ=new $CLJS.K("metabase.mbql.schema","power","metabase.mbql.schema/power",2136530448);KQ=new $CLJS.K(null,"clause-name","clause-name",-996419059);LQ=new $CLJS.K("metabase.mbql.schema","get-year","metabase.mbql.schema/get-year",-1905561955);MQ=new $CLJS.w(null,"now","now",-9994004,null);
Gga=new $CLJS.w("metabase.mbql.schema","datetime-add","metabase.mbql.schema/datetime-add",654654691,null);NQ=new $CLJS.w(null,"not","not",1044554643,null);OQ=new $CLJS.w(null,"avg","avg",1837937727,null);PQ=new $CLJS.w(null,"sum-where","sum-where",-519087341,null);QQ=new $CLJS.K(null,"max-results","max-results",-32858165);Hga=new $CLJS.w("metabase.mbql.schema","get-second","metabase.mbql.schema/get-second",560421410,null);RQ=new $CLJS.w(null,"case","case",-1510733573,null);
SQ=new $CLJS.w(null,"distinct","distinct",-148347594,null);TQ=new $CLJS.w(null,"get-second","get-second",-425414791,null);UQ=new $CLJS.K("metabase.mbql.schema","ends-with","metabase.mbql.schema/ends-with",537143190);$CLJS.VQ=new $CLJS.K(null,"join-alias","join-alias",1454206794);WQ=new $CLJS.w(null,"is-empty","is-empty",600228619,null);Iga=new $CLJS.w("metabase.mbql.schema","segment","metabase.mbql.schema/segment",-512121472,null);
XQ=new $CLJS.w(null,"relative-datetime","relative-datetime",-1369266491,null);YQ=new $CLJS.K("number","!\x3d","number/!\x3d",-673025509);Jga=new $CLJS.K(null,"original","original",-445386197);ZQ=new $CLJS.K("metabase.mbql.schema","interval","metabase.mbql.schema/interval",-1491935720);$Q=new $CLJS.w(null,"abs","abs",1394505050,null);aR=new $CLJS.w(null,"datetime-diff","datetime-diff",-1521323614,null);Kga=new $CLJS.w("metabase.mbql.schema","get-hour","metabase.mbql.schema/get-hour",-809777063,null);
bR=new $CLJS.K("metabase.mbql.schema","cum-count","metabase.mbql.schema/cum-count",-1044004696);Lga=new $CLJS.w(null,"DatetimeDiffUnit","DatetimeDiffUnit",-1310225671,null);$CLJS.cR=new $CLJS.K(null,"date","date",-1463434462);dR=new $CLJS.K(null,"second-clause","second-clause",-461435645);Mga=new $CLJS.w("metabase.mbql.schema","get-day-of-week","metabase.mbql.schema/get-day-of-week",551389774,null);eR=new $CLJS.w(null,"rtrim","rtrim",979195078,null);
fR=new $CLJS.K("metabase.mbql.schema","sqrt","metabase.mbql.schema/sqrt",1829772896);gR=new $CLJS.K("metabase.mbql.schema","get-day-of-week","metabase.mbql.schema/get-day-of-week",-1089141753);hR=new $CLJS.w("metabase.mbql.schema","or","metabase.mbql.schema/or",-1500355255,null);iR=new $CLJS.K("metabase.mbql.schema","Filter","metabase.mbql.schema/Filter",1210108656);jR=new $CLJS.w(null,"CaseOptions","CaseOptions",1989286806,null);kR=new $CLJS.w(null,"or","or",1876275696,null);
Nga=new $CLJS.w("metabase.mbql.schema","ltrim","metabase.mbql.schema/ltrim",52894949,null);Oga=new $CLJS.K(null,"constraints","constraints",422775616);Pga=new $CLJS.w("metabase.mbql.schema","dimension","metabase.mbql.schema/dimension",1500199226,null);lR=new $CLJS.K("metabase.mbql.schema","Field","metabase.mbql.schema/Field",-551724627);Qga=new $CLJS.K(null,"csv-download","csv-download",2141432084);mR=new $CLJS.K("metabase.mbql.schema","get-quarter","metabase.mbql.schema/get-quarter",367107562);
nR=new $CLJS.w(null,"datetime-add","datetime-add",1850134938,null);Rga=new $CLJS.w("metabase.mbql.schema","percentile","metabase.mbql.schema/percentile",-91330436,null);Sga=new $CLJS.w("metabase.mbql.schema","get-month","metabase.mbql.schema/get-month",-2104849029,null);oR=new $CLJS.w("metabase.mbql.schema","\x3e","metabase.mbql.schema/\x3e",-1619862474,null);pR=new $CLJS.w("metabase.mbql.schema","\x3d","metabase.mbql.schema/\x3d",1850085384,null);
qR=new $CLJS.w("metabase.mbql.schema","\x3c","metabase.mbql.schema/\x3c",-1057391587,null);Tga=new $CLJS.w("metabase.mbql.schema","/","metabase.mbql.schema//",2004134796,null);rR=new $CLJS.w(null,"get-quarter","get-quarter",-1326657176,null);Uga=new $CLJS.w("metabase.mbql.schema","-","metabase.mbql.schema/-",-1440604423,null);sR=new $CLJS.w("metabase.mbql.schema","+","metabase.mbql.schema/+",-1802115711,null);Vga=new $CLJS.w("metabase.mbql.schema","*","metabase.mbql.schema/*",1842546528,null);
Wga=new $CLJS.w("metabase.mbql.schema","time","metabase.mbql.schema/time",-1927273576,null);tR=new $CLJS.w(null,"temporal-extract","temporal-extract",-872749364,null);Xga=new $CLJS.w("metabase.mbql.schema","sum","metabase.mbql.schema/sum",-1589890100,null);Yga=new $CLJS.w("metabase.mbql.schema","between","metabase.mbql.schema/between",-302972630,null);Zga=new $CLJS.K(null,"display_name","display_name",-1494335013);$CLJS.uR=new $CLJS.K(null,"snippet-id","snippet-id",1987785841);
$CLJS.vR=new $CLJS.K(null,"database","database",1849087575);wR=new $CLJS.w(null,"NumericExpressionArgOrInterval","NumericExpressionArgOrInterval",-151709337,null);xR=new $CLJS.w(null,"get-day-of-week","get-day-of-week",-644295017,null);$ga=new $CLJS.w("metabase.mbql.schema","starts-with","metabase.mbql.schema/starts-with",1063663591,null);yR=new $CLJS.K("metabase.mbql.schema","floor","metabase.mbql.schema/floor",-1217604600);
zR=new $CLJS.K("metabase.mbql.schema","NumericExpressionArgOrInterval","metabase.mbql.schema/NumericExpressionArgOrInterval",-596692841);$CLJS.AR=new $CLJS.K(null,"expressions","expressions",255689909);BR=new $CLJS.K("metabase.mbql.schema","ExpressionArg","metabase.mbql.schema/ExpressionArg",372701340);aha=new $CLJS.K(null,"ad-hoc","ad-hoc",-2033634036);CR=new $CLJS.K("metabase.mbql.schema","does-not-contain","metabase.mbql.schema/does-not-contain",912159158);
bha=new $CLJS.w("metabase.mbql.schema","concat","metabase.mbql.schema/concat",-580718778,null);DR=new $CLJS.K("metabase.mbql.schema","aggregation-options","metabase.mbql.schema/aggregation-options",-936374135);ER=new $CLJS.K("metabase.mbql.schema","log","metabase.mbql.schema/log",1754975861);FR=new $CLJS.w(null,"absolute-datetime","absolute-datetime",1080191062,null);cha=new $CLJS.w(null,"TimeIntervalOptions","TimeIntervalOptions",1566864026,null);dha=new $CLJS.K(null,"action","action",-811238024);
GR=new $CLJS.w(null,"get-day","get-day",1768100384,null);HR=new $CLJS.K("metabase.mbql.schema","MBQLQuery","metabase.mbql.schema/MBQLQuery",323823775);IR=new $CLJS.K("metabase.mbql.schema","between","metabase.mbql.schema/between",-1943504157);JR=new $CLJS.w(null,"not-empty","not-empty",2029453590,null);$CLJS.KR=new $CLJS.K(null,"native","native",-613060878);eha=new $CLJS.w("metabase.mbql.schema","variable","metabase.mbql.schema/variable",-1981984686,null);LR=new $CLJS.K(null,"page","page",849072397);
MR=new $CLJS.w(null,"length","length",-2065447907,null);fha=new $CLJS.K(null,"dashboard","dashboard",-631747508);NR=new $CLJS.w(null,"get-week","get-week",752472178,null);OR=new $CLJS.w(null,"get-month","get-month",1271156796,null);PR=new $CLJS.w(null,"dimension","dimension",-2111181571,null);QR=new $CLJS.K(null,"boolean-expression","boolean-expression",-394924008);RR=new $CLJS.K("metabase.mbql.schema","length","metabase.mbql.schema/length",1810214269);
SR=new $CLJS.w(null,"StringFilterOptions","StringFilterOptions",-847398229,null);TR=new $CLJS.K("metabase.mbql.schema","segment","metabase.mbql.schema/segment",2142314297);UR=new $CLJS.w("metabase.mbql.schema","not","metabase.mbql.schema/not",-113379604,null);gha=new $CLJS.w("metabase.mbql.schema","now","metabase.mbql.schema/now",665138615,null);VR=new $CLJS.K("metabase.mbql.schema","case","metabase.mbql.schema/case",459951449);WR=new $CLJS.w(null,"substring","substring",-1513569493,null);
hha=new $CLJS.w("metabase.mbql.schema","avg","metabase.mbql.schema/avg",-1530848906,null);YR=new $CLJS.K(null,"internal","internal",-854870097);iha=new $CLJS.w("metabase.mbql.schema","regex-match-first","metabase.mbql.schema/regex-match-first",1918122376,null);ZR=new $CLJS.K("metabase.mbql.schema","DateTimeExpressionArg","metabase.mbql.schema/DateTimeExpressionArg",1033762257);$R=new $CLJS.K("metabase.mbql.schema","TemplateTag","metabase.mbql.schema/TemplateTag",-1837740311);
aS=new $CLJS.K("metabase.mbql.schema","EqualityComparable","metabase.mbql.schema/EqualityComparable",-72675242);jha=new $CLJS.w("metabase.mbql.schema","substring","metabase.mbql.schema/substring",1829402610,null);bS=new $CLJS.K(null,"more-values-or-fields","more-values-or-fields",-886177554);cS=new $CLJS.w(null,"ltrim","ltrim",-1000166486,null);kha=new $CLJS.w("metabase.mbql.schema","rtrim","metabase.mbql.schema/rtrim",1937815937,null);
lha=new $CLJS.w("metabase.mbql.schema","length","metabase.mbql.schema/length",-844221500,null);dS=new $CLJS.K("metabase.mbql.schema","DatetimeExpression","metabase.mbql.schema/DatetimeExpression",1214423543);eS=new $CLJS.K("metabase.mbql.schema","template-tag","metabase.mbql.schema/template-tag",1296341515);fS=new $CLJS.w("metabase.mbql.schema","coalesce","metabase.mbql.schema/coalesce",-1048570669,null);
mha=new $CLJS.w("metabase.mbql.schema","not-empty","metabase.mbql.schema/not-empty",-2093700401,null);nha=new $CLJS.w("metabase.mbql.schema","lower","metabase.mbql.schema/lower",-313920313,null);gS=new $CLJS.w(null,"desc","desc",-560950005,null);$CLJS.hS=new $CLJS.K(null,"template-tags","template-tags",1853115685);oha=new $CLJS.K(null,"public-question","public-question",629369976);pha=new $CLJS.w("metabase.mbql.schema","temporal-extract","metabase.mbql.schema/temporal-extract",-1825197161,null);
qha=new $CLJS.K(null,"binary","binary",-1802232288);iS=new $CLJS.w(null,"time-interval","time-interval",-1949813754,null);rha=new $CLJS.K(null,"userland-query?","userland-query?",-123699383);$CLJS.jS=new $CLJS.K(null,"source-query","source-query",198004422);kS=new $CLJS.K("metabase.mbql.schema","inside","metabase.mbql.schema/inside",-1932527842);sha=new $CLJS.w(null,"SegmentID","SegmentID",-1773652704,null);
tha=new $CLJS.w("metabase.mbql.schema","get-minute","metabase.mbql.schema/get-minute",-2138696889,null);lS=new $CLJS.K("metabase.mbql.schema","UnnamedAggregation","metabase.mbql.schema/UnnamedAggregation",-196705529);mS=new $CLJS.K("metabase.mbql.schema","share","metabase.mbql.schema/share",337132634);uha=new $CLJS.K(null,"executed-by","executed-by",-739811161);vha=new $CLJS.w("metabase.mbql.schema","aggregation","metabase.mbql.schema/aggregation",977707224,null);
wha=new $CLJS.w("metabase.mbql.schema","median","metabase.mbql.schema/median",2055093571,null);nS=new $CLJS.K(null,"amount","amount",364489504);oS=new $CLJS.w(null,"percentile","percentile",1039342775,null);pS=new $CLJS.K("metabase.mbql.schema","metric","metabase.mbql.schema/metric",-610527242);qS=new $CLJS.w(null,"metabase.mbql.schema","metabase.mbql.schema",-408168902,null);rS=new $CLJS.K("metabase.mbql.schema","ceil","metabase.mbql.schema/ceil",1249807143);
sS=new $CLJS.w(null,"NumericExpressionArg","NumericExpressionArg",-1537445195,null);tS=new $CLJS.w(null,"trim","trim",-1880116002,null);xha=new $CLJS.w(null,"RawTimeLiteral","RawTimeLiteral",-2137224789,null);uS=new $CLJS.w(null,"coalesce","coalesce",-1999813740,null);vS=new $CLJS.K("metabase.mbql.schema","sum","metabase.mbql.schema/sum",1064545669);yha=new $CLJS.K("metabase.mbql.schema","DateTimeValue","metabase.mbql.schema/DateTimeValue",1983295553);
wS=new $CLJS.K("string","\x3d","string/\x3d",983744235);zha=new $CLJS.K(null,"skip-results-metadata?","skip-results-metadata?",251010463);Aha=new $CLJS.w("metabase.mbql.schema","asc","metabase.mbql.schema/asc",1338042063,null);Bha=new $CLJS.w("metabase.mbql.schema","inside","metabase.mbql.schema/inside",-291996315,null);xS=new $CLJS.w(null,"StringExpressionArg","StringExpressionArg",-1131357258,null);yS=new $CLJS.K(null,"value-or-field","value-or-field",-1387286309);
zS=new $CLJS.w(null,"FieldOrExpressionDef","FieldOrExpressionDef",-788269541,null);Cha=new $CLJS.K(null,"lat-min","lat-min",1630784161);AS=new $CLJS.w(null,"ArithmeticDateTimeUnit","ArithmeticDateTimeUnit",-838492507,null);BS=new $CLJS.w(null,"inside","inside",-681932758,null);Dha=new $CLJS.w("metabase.mbql.schema","stddev","metabase.mbql.schema/stddev",1735459125,null);CS=new $CLJS.K("metabase.mbql.schema","NumericExpression","metabase.mbql.schema/NumericExpression",1794540739);
Eha=new $CLJS.w("metabase.mbql.schema","convert-timezone","metabase.mbql.schema/convert-timezone",1628930633,null);DS=new $CLJS.w(null,"starts-with","starts-with",2007034536,null);Fha=new $CLJS.K(null,"process-viz-settings?","process-viz-settings?",-173592315);Gha=new $CLJS.w("metabase.mbql.schema","power","metabase.mbql.schema/power",-517905321,null);$CLJS.ES=new $CLJS.K(null,"card-id","card-id",-1770060179);FS=new $CLJS.K(null,"variadic","variadic",882626057);
GS=new $CLJS.w(null,"upper","upper",1886775433,null);HS=new $CLJS.K(null,"field-or-expression","field-or-expression",-1409494368);IS=new $CLJS.K("metabase.mbql.schema","Aggregation","metabase.mbql.schema/Aggregation",746170880);JS=new $CLJS.w(null,"optional","optional",-600484260,null);Hha=new $CLJS.w("metabase.mbql.schema","exp","metabase.mbql.schema/exp",451780488,null);KS=new $CLJS.K("metabase.mbql.schema","FieldOptions","metabase.mbql.schema/FieldOptions",-2115604229);
LS=new $CLJS.K(null,"sugar","sugar",-73788488);Iha=new $CLJS.K(null,"lat-max","lat-max",841568226);MS=new $CLJS.w(null,"power","power",702679448,null);NS=new $CLJS.w("metabase.mbql.schema","and","metabase.mbql.schema/and",1553856071,null);OS=new $CLJS.K("metabase.mbql.schema","replace","metabase.mbql.schema/replace",-371168701);PS=new $CLJS.K("metabase.mbql.schema","NumericExpressionArg","metabase.mbql.schema/NumericExpressionArg",-1965874261);
QS=new $CLJS.K("metabase.mbql.schema","Fields","metabase.mbql.schema/Fields",1872461942);RS=new $CLJS.w(null,"median","median",-2084869638,null);SS=new $CLJS.K("metabase.mbql.schema","ParameterType","metabase.mbql.schema/ParameterType",1268586893);TS=new $CLJS.w(null,"cum-sum","cum-sum",-589533420,null);Jha=new $CLJS.w("metabase.mbql.schema","trim","metabase.mbql.schema/trim",1991053191,null);US=new $CLJS.K(null,"y","y",-1757859776);VS=new $CLJS.w(null,"aggregation","aggregation",-1056959073,null);
$CLJS.WS=new $CLJS.K(null,"binning","binning",1709835866);XS=new $CLJS.w(null,"TimezoneId","TimezoneId",-1406242397,null);YS=new $CLJS.K("metabase.mbql.schema","\x3e","metabase.mbql.schema/\x3e",1034573295);ZS=new $CLJS.K("metabase.mbql.schema","\x3c","metabase.mbql.schema/\x3c",1597044182);$S=new $CLJS.K("metabase.mbql.schema","\x3d","metabase.mbql.schema/\x3d",209553857);aT=new $CLJS.w(null,"Reference","Reference",2024574086,null);bT=new $CLJS.K(null,"b","b",1482224470);
cT=new $CLJS.K(null,"a","a",-2123407586);dT=new $CLJS.K("metabase.mbql.schema","!\x3d","metabase.mbql.schema/!\x3d",1846805017);eT=new $CLJS.w(null,"replace","replace",853943757,null);fT=new $CLJS.K("metabase.mbql.schema","cum-sum","metabase.mbql.schema/cum-sum",576093708);Kha=new $CLJS.w("metabase.mbql.schema","upper","metabase.mbql.schema/upper",993691634,null);gT=new $CLJS.K("metabase.mbql.schema","/","metabase.mbql.schema//",363603269);
hT=new $CLJS.K("metabase.mbql.schema","-","metabase.mbql.schema/-",1213831346);iT=new $CLJS.K("metabase.mbql.schema","*","metabase.mbql.schema/*",202015001);jT=new $CLJS.K("metabase.mbql.schema","+","metabase.mbql.schema/+",852320058);kT=new $CLJS.w(null,"segment","segment",675610331,null);Lha=new $CLJS.w("metabase.mbql.schema","get-week","metabase.mbql.schema/get-week",1436319181,null);lT=new $CLJS.K("metabase.mbql.schema","field","metabase.mbql.schema/field",-73678671);
mT=new $CLJS.w("metabase.mbql.schema","\x3e\x3d","metabase.mbql.schema/\x3e\x3d",1977236849,null);nT=new $CLJS.K("metabase.mbql.schema","count","metabase.mbql.schema/count",-962464844);oT=new $CLJS.K("string","starts-with","string/starts-with",1266861170);Mha=new $CLJS.w(null,"TemporalExtractUnit","TemporalExtractUnit",1691102108,null);$CLJS.pT=new $CLJS.K(null,"order-by","order-by",1527318070);qT=new $CLJS.K(null,"advanced-math-expressions","advanced-math-expressions",1076581757);
Nha=new $CLJS.w("metabase.mbql.schema","is-null","metabase.mbql.schema/is-null",637054412,null);Oha=new $CLJS.K("alias","escaped-\x3eoriginal","alias/escaped-\x3eoriginal",-1334024182);$CLJS.rT=new $CLJS.K(null,"condition","condition",1668437652);sT=new $CLJS.K("string","does-not-contain","string/does-not-contain",-1536178964);Pha=new $CLJS.K(null,"report-timezone","report-timezone",-2053796389);tT=new $CLJS.w("metabase.mbql.schema","\x3c\x3d","metabase.mbql.schema/\x3c\x3d",-2098137664,null);
Qha=new $CLJS.K(null,"card-name","card-name",-2035606807);uT=new $CLJS.K("metabase.mbql.schema","value","metabase.mbql.schema/value",772933314);Rha=new $CLJS.w("metabase.mbql.schema","sum-where","metabase.mbql.schema/sum-where",969078380,null);Sha=new $CLJS.w("metabase.mbql.schema","does-not-contain","metabase.mbql.schema/does-not-contain",-1742276611,null);vT=new $CLJS.w(null,"log","log",45015523,null);Tha=new $CLJS.w("metabase.mbql.schema","abs","metabase.mbql.schema/abs",1792880149,null);
wT=new $CLJS.w(null,"time","time",-1268547887,null);$CLJS.xT=new $CLJS.K(null,"database_type","database_type",-54700895);yT=new $CLJS.w(null,"EqualityComparable","EqualityComparable",-2076258858,null);zT=new $CLJS.w(null,"get-minute","get-minute",-1186349426,null);$CLJS.AT=new $CLJS.K(null,"clause","clause",1479668060);$CLJS.BT=new $CLJS.K(null,"expression-name","expression-name",-1798450709);$CLJS.CT=new $CLJS.K("date","all-options","date/all-options",549325958);
Uha=new $CLJS.w("metabase.mbql.schema","relative-datetime","metabase.mbql.schema/relative-datetime",1965492610,null);DT=new $CLJS.K("location","state","location/state",-114378652);ET=new $CLJS.w(null,"datetime-subtract","datetime-subtract",1915839224,null);Vha=new $CLJS.K(null,"aggregation-clause-index","aggregation-clause-index",-1510292261);Wha=new $CLJS.K(null,"lon-field","lon-field",517872067);FT=new $CLJS.K("metabase.mbql.schema","TemporalLiteral","metabase.mbql.schema/TemporalLiteral",-904081026);
GT=new $CLJS.w(null,"DateTimeExpressionArg","DateTimeExpressionArg",-1507653823,null);HT=new $CLJS.K(null,"numeric","numeric",-1495594714);IT=new $CLJS.w(null,"variable","variable",1359185035,null);JT=new $CLJS.w(null,"lower","lower",-1534114948,null);KT=new $CLJS.K("metabase.mbql.schema","concat","metabase.mbql.schema/concat",2073716991);LT=new $CLJS.K("metabase.mbql.schema","get-week","metabase.mbql.schema/get-week",-204212346);$CLJS.MT=new $CLJS.K(null,"limit","limit",-1355822363);
NT=new $CLJS.K("metabase.mbql.schema","round","metabase.mbql.schema/round",-1325713739);OT=new $CLJS.w(null,"ExtractWeekMode","ExtractWeekMode",397218079,null);Xha=new $CLJS.K(null,"pulse","pulse",-244494476);var PT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.eG],null),QT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.mG],null),RT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.bG],null),ST=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.YF],null),TT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.iG],null),UT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.IM],null),Yha=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.zM],null),Zha=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.uM],null),VT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,
$CLJS.fK],null),WT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.jK],null),XT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.aK],null),YT=new $CLJS.Tg(null,new $CLJS.h(null,12,[$CLJS.hi,null,$CLJS.pv,null,$CLJS.fj,null,$CLJS.Wh,null,$CLJS.dl,null,$CLJS.oj,null,$CLJS.gl,null,$CLJS.fl,null,$CLJS.ci,null,$CLJS.vj,null,$CLJS.ii,null,$CLJS.cl,null],null),null),ZT=new $CLJS.Tg(null,new $CLJS.h(null,7,[$CLJS.gk,null,$CLJS.HI,null,$CLJS.Wh,null,$CLJS.bl,null,$CLJS.KI,null,$CLJS.el,null,$CLJS.Li,null],null),null),
$T=$CLJS.qv.g(YT,ZT),$ha=$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bk,new $CLJS.h(null,1,[$CLJS.wu,"date bucketing unit"],null)],null),YT),aia=$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bk,new $CLJS.h(null,1,[$CLJS.wu,"time bucketing unit"],null)],null),ZT),aU=$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bk,new $CLJS.h(null,1,[$CLJS.wu,"datetime bucketing unit"],null)],null),$T),bU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.PK],null),bia=new $CLJS.Q(null,13,5,$CLJS.R,[$CLJS.bk,new $CLJS.h(null,
1,[$CLJS.wu,"temporal extract unit"],null),$CLJS.LI,$CLJS.cl,$CLJS.gl,$CLJS.AK,$CLJS.VK,$CLJS.WK,$CLJS.fl,$CLJS.dl,$CLJS.bl,$CLJS.el,$CLJS.II],null),cia=new $CLJS.Q(null,10,5,$CLJS.R,[$CLJS.bk,new $CLJS.h(null,1,[$CLJS.wu,"datetime-diff unit"],null),$CLJS.HI,$CLJS.Li,$CLJS.gk,$CLJS.pv,$CLJS.fj,$CLJS.oj,$CLJS.hi,$CLJS.ci],null),cU=new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.bk,new $CLJS.h(null,1,[$CLJS.wu,"temporal-extract week extraction mode"],null),$CLJS.uK,$CLJS.DK,$CLJS.IK],null),dU=new $CLJS.Q(null,
10,5,$CLJS.R,[$CLJS.bk,new $CLJS.h(null,1,[$CLJS.wu,"relative-datetime unit"],null),$CLJS.Wh,$CLJS.Li,$CLJS.gk,$CLJS.pv,$CLJS.fj,$CLJS.oj,$CLJS.hi,$CLJS.ci],null),kU,eia,sU,tU,uU,vU,wU,xU,yU,jW,gia,kW,hia,iia,lW,jia,kia,lia;$CLJS.Y(uO,CN($CLJS.EK,$CLJS.G(["n",new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.kt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ns,$CLJS.vx],null),$CLJS.pi],null),"unit",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ft,dU],null)])));var eU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,uO],null);
$CLJS.Y(ZQ,CN($CLJS.CK,$CLJS.G(["n",$CLJS.pi,"unit",dU])));var dia=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,ZQ],null);
$CLJS.Y(PO,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.pt,new $CLJS.h(null,2,[$CLJS.wu,"valid :absolute-datetime clause",$CLJS.Gi,function(a){if($CLJS.Sa(BN($CLJS.yK,a)))a=OO;else{a=$CLJS.hd(a);var b=$CLJS.xN(VT);a=b.h?b.h(a):b.call(null,a);a=$CLJS.n(a)?$CLJS.cR:$CLJS.FK}return a}],null),new $CLJS.Q(null,2,5,$CLJS.R,[OO,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ht,new $CLJS.h(null,1,[$CLJS.wu,"not an :absolute-datetime clause"],null),$CLJS.Le(!1)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cR,CN($CLJS.yK,
$CLJS.G(["date",VT,"unit",$ha]))],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.FK,CN($CLJS.yK,$CLJS.G(["datetime",WT,"unit",aU]))],null)],null));var fU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,PO],null);$CLJS.Y(dP,CN($CLJS.wA,$CLJS.G(["time",XT,"unit",aia])));var gU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,dP],null),hU=new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.kt,new $CLJS.h(null,1,[$CLJS.wu,"date or datetime literal"],null),fU,WT,VT],null);
$CLJS.Y(ON,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.kt,new $CLJS.h(null,1,[$CLJS.wu,"time literal"],null),gU,XT],null));$CLJS.Y(FT,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.kt,new $CLJS.h(null,1,[$CLJS.wu,"temporal literal"],null),hU,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,ON],null)],null));var iU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,FT],null);
$CLJS.Y(yha,DN($CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof fU?new $CLJS.Fc(function(){return fU},$CLJS.od(Zfa,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,YR,$CLJS.ej,$CLJS.nk,$CLJS.mi,$CLJS.U,$CLJS.ek],[$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),FR,"metabase/mbql/schema.cljc",69,$CLJS.yK,1,!0,168,168,$CLJS.Jc,"Schema for an `:absolute-datetime` clause.",$CLJS.n(fU)?fU.H:null])):null));return $CLJS.n(a)?a:FR}(),fU],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof eU?new $CLJS.Fc(function(){return eU},$CLJS.od(Uha,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,
$CLJS.nk,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),XQ,"metabase/mbql/schema.cljc",29,$CLJS.EK,1,131,131,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.EK,$CLJS.My,$CLJS.W($CLJS.Gj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.kt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ns,$CLJS.vx],null),$CLJS.pi],null)),$CLJS.ki,$CLJS.W($CLJS.Gj,$CLJS.W(JS,uQ))],null),$CLJS.Jc,"Schema for a valid relative-datetime clause.",
$CLJS.n(eU)?eU.H:null])):null));return $CLJS.n(a)?a:XQ}(),eU],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof gU?new $CLJS.Fc(function(){return gU},$CLJS.od(Wga,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,YR,$CLJS.ej,$CLJS.nk,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),wT,"metabase/mbql/schema.cljc",27,$CLJS.wA,1,!0,175,175,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.wA,$CLJS.wA,$CLJS.W($CLJS.Gj,xha),$CLJS.ki,$CLJS.W($CLJS.Gj,pga)],null),$CLJS.Jc,"Schema for a valid time clause.",$CLJS.n(gU)?gU.H:null])):null));return $CLJS.n(a)?a:wT}(),gU],null)])));
$CLJS.Y(uT,CN($CLJS.uj,$CLJS.G(["value",$CLJS.zt,"type-info",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jt,new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.xT,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jt,PT],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.UE,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jt,QT],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.eQ,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,
2,5,$CLJS.R,[$CLJS.jt,RT],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ki,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jt,aU],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jt,PT],null)],null)],null)],null)])));var jU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,uT],null);$CLJS.Y(dQ,CN($CLJS.dD,$CLJS.G(["expression-name",PT,"options",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ft,$CLJS.Yj],null)])));
kU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,dQ],null);
eia=function(a){return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.qt,a,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ht,new $CLJS.h(null,1,[$CLJS.wu,"You must specify :num-bins when using the :num-bins strategy."],null),function(b){var c=$CLJS.O(b);b=$CLJS.I.g(c,$CLJS.oG);c=$CLJS.I.g(c,$CLJS.ZF);return $CLJS.F.g(b,$CLJS.ZF)?c:!0}],null)],null)}(function(a){return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.qt,a,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ht,new $CLJS.h(null,1,[$CLJS.wu,"You must specify :bin-width when using the :bin-width strategy."],
null),function(b){var c=$CLJS.O(b);b=$CLJS.I.g(c,$CLJS.oG);c=$CLJS.I.g(c,$CLJS.nG);return $CLJS.F.g(b,$CLJS.nG)?c:!0}],null)],null)}(new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Yj,new $CLJS.h(null,1,[$CLJS.wu,"binning options"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.oG,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.bk,new $CLJS.h(null,1,[$CLJS.wu,"binning strategy"],null),$CLJS.ZF,$CLJS.nG,$CLJS.Wh],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ZF,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),ST],null),new $CLJS.Q(null,
3,5,$CLJS.R,[$CLJS.nG,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.qt,$CLJS.ul,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ht,new $CLJS.h(null,1,[$CLJS.wu,"bin width must be \x3e\x3d 0."],null),$CLJS.Ke($CLJS.Pl)],null)],null)],null)],null)));$CLJS.lU=function lU(a){switch(arguments.length){case 1:return lU.h(arguments[0]);case 2:return lU.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};
$CLJS.lU.h=function(a){var b=$CLJS.O(a);a=$CLJS.I.g(b,$CLJS.gD);b=$CLJS.I.g(b,$CLJS.$H);return $CLJS.lU.g(a,b)};$CLJS.lU.g=function(a,b){a=$CLJS.n($CLJS.n(b)?a:b)?$CLJS.gE(a,$CLJS.HE)?YT:$CLJS.gE(a,$CLJS.FF)?ZT:$CLJS.gE(a,$CLJS.fF)?$T:null:null;return $CLJS.n(a)?$CLJS.Gd(a,b):!0};$CLJS.lU.A=2;
$CLJS.Y(KS,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.qt,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.qt,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.Yj,new $CLJS.h(null,1,[$CLJS.wu,"field options"],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gD,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jt,QT],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.fP,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jt,UT],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.$H,new $CLJS.h(null,
1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jt,aU],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.VQ,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jt,PT],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.WS,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jt,eia],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ht,new $CLJS.h(null,1,[$CLJS.wu,"Invalid :temporal-unit for the specified :base-type."],null),$CLJS.lU],null)],
null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ht,new $CLJS.h(null,1,[$CLJS.wu,"Found :binning keys at the top level of :field options. binning-related options belong under the :binning key."],null),$CLJS.Ke($CLJS.oG)],null)],null));
$CLJS.Y(lT,function(a){return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.qt,a,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ht,new $CLJS.h(null,1,[$CLJS.wu,":field clauses using a string field name must specify :base-type."],null),function(b){$CLJS.H(b,0,null);var c=$CLJS.H(b,1,null);b=$CLJS.H(b,2,null);b=$CLJS.O(b);b=$CLJS.I.g(b,$CLJS.gD);return"string"===typeof c?b:!0}],null)],null)}(CN($CLJS.UH,$CLJS.G(["id-or-name",new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.kt,UT,PT],null),"options",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jt,
new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,KS],null)],null)]))));var mU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,lT],null);
$CLJS.Y(lR,DN($CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof kU?new $CLJS.Fc(function(){return kU},$CLJS.od(pP,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([IQ,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.AR,"null"],null),null),$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),$O,"metabase/mbql/schema.cljc",60,$CLJS.dD,1,249,249,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.dD,$CLJS.BT,$CLJS.W($CLJS.Gj,wO),$CLJS.Yi,$CLJS.W($CLJS.Gj,$CLJS.W(JS,$CLJS.Yj))],null),$CLJS.Jc,"Schema for a valid expression clause.",$CLJS.n(kU)?kU.H:null])):null));return $CLJS.n(a)?a:$O}(),kU],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof mU?new $CLJS.Fc(function(){return mU},
$CLJS.od(HP,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([$CLJS.Pr,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,$CLJS.mi,$CLJS.U,$CLJS.ek],["0.39.0",$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),IO,"metabase/mbql/schema.cljc",51,$CLJS.UH,1,382,382,$CLJS.Jc,"Schema for a `:field` clause.",$CLJS.n(mU)?mU.H:null])):null));return $CLJS.n(a)?a:IO}(),mU],null)])));
$CLJS.nU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,lR],null);$CLJS.Y(IN,CN($CLJS.JH,$CLJS.G(["aggregation-clause-index",$CLJS.pi,"options",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ft,$CLJS.Yj],null)])));var oU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,IN],null);
$CLJS.Y(qO,DN($CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof oU?new $CLJS.Fc(function(){return oU},$CLJS.od(vha,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),VS,"metabase/mbql/schema.cljc",23,$CLJS.JH,1,418,418,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.JH,Vha,$CLJS.W($CLJS.Gj,$CLJS.pi),$CLJS.Yi,$CLJS.W($CLJS.Gj,$CLJS.W(JS,$CLJS.Yj))],null),$CLJS.Jc,"Schema for a valid aggregation clause.",$CLJS.n(oU)?oU.H:null])):null));return $CLJS.n(a)?a:VS}(),oU],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof kU?new $CLJS.Fc(function(){return kU},
$CLJS.od(pP,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([IQ,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.AR,"null"],null),null),$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$O,"metabase/mbql/schema.cljc",60,$CLJS.dD,1,249,249,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.dD,$CLJS.BT,$CLJS.W($CLJS.Gj,wO),$CLJS.Yi,
$CLJS.W($CLJS.Gj,$CLJS.W(JS,$CLJS.Yj))],null),$CLJS.Jc,"Schema for a valid expression clause.",$CLJS.n(kU)?kU.H:null])):null));return $CLJS.n(a)?a:$O}(),kU],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof mU?new $CLJS.Fc(function(){return mU},$CLJS.od(HP,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([$CLJS.Pr,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,$CLJS.mi,
$CLJS.U,$CLJS.ek],["0.39.0",$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),IO,"metabase/mbql/schema.cljc",51,$CLJS.UH,1,382,382,$CLJS.Jc,"Schema for a `:field` clause.",$CLJS.n(mU)?mU.H:null])):null));return $CLJS.n(a)?a:IO}(),mU],null)])));
var pU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,qO],null),qU=new $CLJS.Tg(null,new $CLJS.h(null,11,[$CLJS.Ku,null,$CLJS.nI,null,$CLJS.nH,null,$CLJS.tI,null,$CLJS.sH,null,$CLJS.XH,null,$CLJS.CH,null,$CLJS.Su,null,$CLJS.lI,null,$CLJS.WH,null,$CLJS.uI,null],null),null),rU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,nO],null);
$CLJS.Y(QN,new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.pt,new $CLJS.h(null,1,[$CLJS.Gi,function(a){return"string"===typeof a?$CLJS.Pj:$CLJS.n(BN(qU,a))?jO:$CLJS.n(BN($CLJS.uj,a))?$CLJS.uj:$CLJS.Mu}],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Pj,$CLJS.Pj],null),new $CLJS.Q(null,2,5,$CLJS.R,[jO,rU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.uj,jU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Mu,$CLJS.nU],null)],null));sU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,QN],null);
tU=new $CLJS.Tg(null,new $CLJS.h(null,26,[$CLJS.pH,null,$CLJS.UK,null,$CLJS.Rs,null,$CLJS.QK,null,$CLJS.NK,null,$CLJS.HH,null,$CLJS.mu,null,$CLJS.mH,null,$CLJS.RK,null,$CLJS.KK,null,$CLJS.XH,null,$CLJS.qI,null,$CLJS.OK,null,$CLJS.ZH,null,$CLJS.qH,null,$CLJS.wK,null,$CLJS.Qs,null,$CLJS.tH,null,$CLJS.CH,null,$CLJS.qK,null,$CLJS.Gz,null,$CLJS.xK,null,$CLJS.JK,null,$CLJS.BK,null,$CLJS.sI,null,$CLJS.SH,null],null),null);
uU=new $CLJS.Tg(null,new $CLJS.h(null,9,[$CLJS.Ms,null,$CLJS.Gs,null,$CLJS.kt,null,$CLJS.mt,null,$CLJS.Is,null,$CLJS.mI,null,$CLJS.qt,null,$CLJS.Ns,null,$CLJS.Ks,null],null),null);vU=new $CLJS.Tg(null,new $CLJS.h(null,17,[$CLJS.Sn,null,$CLJS.kI,null,$CLJS.cI,null,$CLJS.VH,null,$CLJS.pI,null,$CLJS.rI,null,$CLJS.yH,null,$CLJS.FH,null,$CLJS.VN,null,$CLJS.zH,null,$CLJS.hI,null,$CLJS.aI,null,$CLJS.Zj,null,$CLJS.Iz,null,$CLJS.OH,null,$CLJS.PH,null,$CLJS.gI,null],null),null);
wU=new $CLJS.Tg(null,new $CLJS.h(null,5,[$CLJS.GK,null,$CLJS.sK,null,$CLJS.SK,null,$CLJS.Qs,null,$CLJS.MK,null],null),null);xU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,CS],null);yU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,bP],null);$CLJS.zU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,dS],null);$CLJS.AU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,IS],null);
$CLJS.Y(PS,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.pt,new $CLJS.h(null,2,[$CLJS.wu,"numeric expression argument",$CLJS.Gi,function(a){return"number"===typeof a?$CLJS.fv:$CLJS.n(BN(tU,a))?FQ:$CLJS.n(BN(vU,a))?$CLJS.JH:$CLJS.n(BN($CLJS.uj,a))?$CLJS.uj:$CLJS.UH}],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fv,$CLJS.ul],null),new $CLJS.Q(null,2,5,$CLJS.R,[FQ,xU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.JH,$CLJS.AU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.uj,jU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.UH,
$CLJS.nU],null)],null));var BU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,PS],null);
$CLJS.Y(ZR,new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.pt,new $CLJS.h(null,2,[$CLJS.wu,"datetime expression argument",$CLJS.Gi,function(a){return $CLJS.n(BN(vU,a))?$CLJS.JH:$CLJS.n(BN($CLJS.uj,a))?$CLJS.uj:$CLJS.n(BN(wU,a))?iP:$CLJS.Mu}],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.JH,$CLJS.AU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.uj,jU],null),new $CLJS.Q(null,2,5,$CLJS.R,[iP,$CLJS.zU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Mu,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.kt,hU,$CLJS.nU],null)],null)],null));
var CU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,ZR],null);
$CLJS.Y(BR,new $CLJS.Q(null,11,5,$CLJS.R,[$CLJS.pt,new $CLJS.h(null,2,[$CLJS.wu,"expression argument",$CLJS.Gi,function(a){return"number"===typeof a?$CLJS.fv:$CLJS.Bd(a)?$CLJS.yt:$CLJS.n(BN(uU,a))?QR:$CLJS.n(BN(tU,a))?FQ:$CLJS.n(BN(wU,a))?iP:"string"===typeof a?$CLJS.Pj:$CLJS.n(BN(qU,a))?jO:$CLJS.n(BN($CLJS.uj,a))?$CLJS.uj:$CLJS.Mu}],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fv,$CLJS.ul],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.yt,$CLJS.yt],null),new $CLJS.Q(null,2,5,$CLJS.R,[QR,yU],null),new $CLJS.Q(null,
2,5,$CLJS.R,[FQ,xU],null),new $CLJS.Q(null,2,5,$CLJS.R,[iP,$CLJS.zU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Pj,$CLJS.Pj],null),new $CLJS.Q(null,2,5,$CLJS.R,[jO,rU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.uj,jU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Mu,$CLJS.nU],null)],null));var DU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,BR],null);$CLJS.Y(zR,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.kt,new $CLJS.h(null,1,[$CLJS.wu,"numeric expression arg or interval"],null),dia,BU],null));
var EU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,zR],null);$CLJS.Y(aO,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.pt,new $CLJS.h(null,2,[$CLJS.wu,"int greater than zero or numeric expression",$CLJS.Gi,function(a){return"number"===typeof a?$CLJS.fv:$CLJS.Mu}],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fv,ST],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Mu,xU],null)],null));var fia=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,aO],null);
$CLJS.Y(jP,CN($CLJS.XH,$CLJS.G(["a",DU,"b",DU,"more",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Hi,DU],null)])));var FU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,jP],null);$CLJS.Y(NN,CN($CLJS.nH,$CLJS.G(["s",sU,"start",fia,"length",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ft,BU],null)])));var GU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,NN],null);$CLJS.Y(RR,CN($CLJS.Gz,$CLJS.G(["s",sU])));var HU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,RR],null);$CLJS.Y(pO,CN($CLJS.lI,$CLJS.G(["s",sU])));
var IU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,pO],null);$CLJS.Y(tO,CN($CLJS.uI,$CLJS.G(["s",sU])));var JU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,tO],null);$CLJS.Y(MO,CN($CLJS.nI,$CLJS.G(["s",sU])));var KU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,MO],null);$CLJS.Y(xP,CN($CLJS.Ku,$CLJS.G(["s",sU])));var LU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,xP],null);$CLJS.Y(fO,CN($CLJS.Su,$CLJS.G(["s",sU])));var MU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,fO],null);
$CLJS.Y(OS,CN($CLJS.tI,$CLJS.G(["s",sU,"match",$CLJS.Pj,"replacement",$CLJS.Pj])));var NU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,OS],null);$CLJS.Y(KT,CN($CLJS.sH,$CLJS.G(["a",sU,"b",sU,"more",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Hi,sU],null)])));var OU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,KT],null);$CLJS.Y(mQ,CN($CLJS.WH,$CLJS.G(["s",sU,"pattern",$CLJS.Pj])));var PU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,mQ],null);
$CLJS.Y(jT,CN($CLJS.Qs,$CLJS.G(["x",EU,"y",EU,"more",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Hi,EU],null)])));var QU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,jT],null);$CLJS.Y(hT,CN($CLJS.mu,$CLJS.G(["x",BU,"y",EU,"more",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Hi,EU],null)])));var RU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,hT],null);$CLJS.Y(gT,CN($CLJS.qI,$CLJS.G(["x",BU,"y",BU,"more",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Hi,BU],null)])));var SU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,gT],null);
$CLJS.Y(iT,CN($CLJS.Rs,$CLJS.G(["x",BU,"y",BU,"more",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Hi,BU],null)])));var TU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,iT],null);$CLJS.Y(yR,CN($CLJS.SH,$CLJS.G(["x",BU])));var UU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,yR],null);$CLJS.Y(rS,CN($CLJS.pH,$CLJS.G(["x",BU])));var VU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,rS],null);$CLJS.Y(NT,CN($CLJS.ZH,$CLJS.G(["x",BU])));var WU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,NT],null);$CLJS.Y(DQ,CN($CLJS.tH,$CLJS.G(["x",BU])));
var XU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,DQ],null);$CLJS.Y(JQ,CN($CLJS.qH,$CLJS.G(["x",BU,"y",BU])));var YU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,JQ],null);$CLJS.Y(fR,CN($CLJS.HH,$CLJS.G(["x",BU])));var ZU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,fR],null);$CLJS.Y(bO,CN($CLJS.mH,$CLJS.G(["x",BU])));var $U=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,bO],null);$CLJS.Y(ER,CN($CLJS.sI,$CLJS.G(["x",BU])));var aV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,ER],null);
$CLJS.Y(CO,CN($CLJS.BK,$CLJS.G(["datetime-x",CU,"datetime-y",CU,"unit",cia])));var bV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,CO],null);$CLJS.Y(UO,CN($CLJS.QK,$CLJS.G(["datetime",CU,"unit",bia,"mode",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ft,cU],null)])));var cV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,UO],null);$CLJS.Y(LQ,CN($CLJS.xK,$CLJS.G(["date",CU])));var dV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,LQ],null);$CLJS.Y(mR,CN($CLJS.UK,$CLJS.G(["date",CU])));
var eV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,mR],null);$CLJS.Y(PN,CN($CLJS.qK,$CLJS.G(["date",CU])));var fV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,PN],null);$CLJS.Y(LT,CN($CLJS.KK,$CLJS.G(["date",CU,"mode",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ft,cU],null)])));var gV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,LT],null);$CLJS.Y(YP,CN($CLJS.JK,$CLJS.G(["date",CU])));var hV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,YP],null);$CLJS.Y(gR,CN($CLJS.OK,$CLJS.G(["date",CU])));
var iV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,gR],null);$CLJS.Y(yO,CN($CLJS.RK,$CLJS.G(["datetime",CU])));var jV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,yO],null);$CLJS.Y(cQ,CN($CLJS.NK,$CLJS.G(["datetime",CU])));var kV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,cQ],null);$CLJS.Y(rO,CN($CLJS.wK,$CLJS.G(["datetime",CU])));var lV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,rO],null);$CLJS.Y(IP,CN($CLJS.sK,$CLJS.G(["datetime",CU,"to",bU,"from",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ft,bU],null)])));
var mV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,IP],null),nV=new $CLJS.Q(null,11,5,$CLJS.R,[$CLJS.bk,new $CLJS.h(null,1,[$CLJS.wu,"datetime arithmetic unit"],null),$CLJS.KI,$CLJS.HI,$CLJS.Li,$CLJS.gk,$CLJS.pv,$CLJS.fj,$CLJS.oj,$CLJS.hi,$CLJS.ci],null);$CLJS.Y(MN,CN($CLJS.MK,$CLJS.G(["datetime",CU,"amount",BU,"unit",nV])));var oV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,MN],null);
$CLJS.Y(kO,function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return CN(arguments[0],1<b.length?new $CLJS.y(b.slice(1),0,null):null)}($CLJS.GK));var pV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,kO],null);$CLJS.Y(LN,CN($CLJS.SK,$CLJS.G(["datetime",CU,"amount",BU,"unit",nV])));var qV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,LN],null);
$CLJS.Y(dS,DN($CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof QU?new $CLJS.Fc(function(){return QU},$CLJS.od(sR,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([IQ,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.AR,"null"],null),null),$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),gQ,"metabase/mbql/schema.cljc",51,$CLJS.Qs,1,612,612,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.Qs,$CLJS.lG,$CLJS.W($CLJS.Gj,wR),US,$CLJS.W($CLJS.Gj,wR),kP,$CLJS.W($CLJS.Gj,$CLJS.W($CLJS.Ei,wR))],null),$CLJS.Jc,"Schema for a valid + clause.",$CLJS.n(QU)?QU.H:null])):null));return $CLJS.n(a)?a:gQ}(),QU],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof oV?new $CLJS.Fc(function(){return oV},
$CLJS.od(Gga,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([IQ,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[BP,"null"],null),null),$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),nR,"metabase/mbql/schema.cljc",67,$CLJS.MK,1,703,703,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.MK,$CLJS.FK,$CLJS.W($CLJS.Gj,GT),nS,$CLJS.W($CLJS.Gj,
sS),$CLJS.ki,$CLJS.W($CLJS.Gj,AS)],null),$CLJS.Jc,"Schema for a valid datetime-add clause.",$CLJS.n(oV)?oV.H:null])):null));return $CLJS.n(a)?a:nR}(),oV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof qV?new $CLJS.Fc(function(){return qV},$CLJS.od(Afa,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([IQ,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,GO,$CLJS.mi,
$CLJS.U,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[BP,"null"],null),null),$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),ET,"metabase/mbql/schema.cljc",72,$CLJS.SK,1,710,710,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.SK,$CLJS.FK,$CLJS.W($CLJS.Gj,GT),nS,$CLJS.W($CLJS.Gj,sS),$CLJS.ki,$CLJS.W($CLJS.Gj,AS)],null),$CLJS.Jc,"Schema for a valid datetime-subtract clause.",$CLJS.n(qV)?qV.H:null])):null));
return $CLJS.n(a)?a:ET}(),qV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof mV?new $CLJS.Fc(function(){return mV},$CLJS.od(Eha,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([IQ,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.sK,"null"],null),null),$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),hP,"metabase/mbql/schema.cljc",71,$CLJS.sK,1,695,695,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.sK,$CLJS.FK,$CLJS.W($CLJS.Gj,GT),yga,$CLJS.W($CLJS.Gj,XS),Efa,$CLJS.W($CLJS.Gj,$CLJS.W(JS,XS))],null),$CLJS.Jc,"Schema for a valid convert-timezone clause.",$CLJS.n(mV)?mV.H:null])):null));return $CLJS.n(a)?a:hP}(),mV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&
"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof pV?new $CLJS.Fc(function(){return pV},$CLJS.od(gha,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([IQ,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.GK,"null"],null),null),$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),MQ,"metabase/mbql/schema.cljc",
45,$CLJS.GK,1,708,708,new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.GK],null),$CLJS.Jc,"Schema for a valid now clause.",$CLJS.n(pV)?pV.H:null])):null));return $CLJS.n(a)?a:MQ}(),pV],null)])));$CLJS.rV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,iR],null);$CLJS.Y(RN,CN($CLJS.qt,$CLJS.G(["first-clause",$CLJS.rV,"second-clause",$CLJS.rV,"other-clauses",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Hi,$CLJS.rV],null)])));var sV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,RN],null);
$CLJS.Y(EP,CN($CLJS.kt,$CLJS.G(["first-clause",$CLJS.rV,"second-clause",$CLJS.rV,"other-clauses",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Hi,$CLJS.rV],null)])));var tV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,EP],null);$CLJS.Y(mO,CN($CLJS.mt,$CLJS.G(["clause",$CLJS.rV])));
var uV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,mO],null),vV=new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.pt,new $CLJS.h(null,3,[$CLJS.wu,":field or :expression reference or :relative-datetime",$CLJS.yu,$CLJS.Le(":field or :expression reference or :relative-datetime"),$CLJS.Gi,function(a){return $CLJS.n(BN($CLJS.EK,a))?$CLJS.EK:$CLJS.Mu}],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.EK,eU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Mu,$CLJS.nU],null)],null);
$CLJS.Y(aS,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.jt,new $CLJS.h(null,1,[$CLJS.wu,"equality comparable"],null),new $CLJS.Q(null,8,5,$CLJS.R,[$CLJS.kt,$CLJS.yt,$CLJS.ul,$CLJS.Pj,iU,vV,DU,jU],null)],null));var wV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,aS],null);
$CLJS.Y(rQ,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.pt,new $CLJS.h(null,2,[$CLJS.wu,"order comparable",$CLJS.Gi,function(a){return $CLJS.n(BN($CLJS.uj,a))?$CLJS.uj:$CLJS.Mu}],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.uj,jU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Mu,new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.kt,$CLJS.ul,$CLJS.Pj,iU,DU,vV],null)],null)],null));var xV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,rQ],null);
$CLJS.Y($S,CN($CLJS.Ns,$CLJS.G(["field",wV,"value-or-field",wV,"more-values-or-fields",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Hi,wV],null)])));var yV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$S],null);$CLJS.Y(dT,CN($CLJS.mI,$CLJS.G(["field",wV,"value-or-field",wV,"more-values-or-fields",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Hi,wV],null)])));var zV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,dT],null);$CLJS.Y(ZS,CN($CLJS.Ks,$CLJS.G(["field",xV,"value-or-field",xV])));
var AV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,ZS],null);$CLJS.Y(YS,CN($CLJS.Gs,$CLJS.G(["field",xV,"value-or-field",xV])));var BV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,YS],null);$CLJS.Y(NO,CN($CLJS.Ms,$CLJS.G(["field",xV,"value-or-field",xV])));var CV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,NO],null);$CLJS.Y(TO,CN($CLJS.Is,$CLJS.G(["field",xV,"value-or-field",xV])));var DV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,TO],null);$CLJS.Y(IR,CN($CLJS.vH,$CLJS.G(["field",xV,"min",xV,"max",xV])));
var EV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,IR],null);$CLJS.Y(kS,CN($CLJS.BH,$CLJS.G(["lat-field",xV,"lon-field",xV,"lat-max",xV,"lon-min",xV,"lat-min",xV,"lon-max",xV])));var FV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,kS],null);$CLJS.Y(wP,CN($CLJS.uH,$CLJS.G(["field",$CLJS.nU])));var GV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,wP],null);$CLJS.Y(lQ,CN($CLJS.GH,$CLJS.G(["field",$CLJS.nU])));var HV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,lQ],null);$CLJS.Y(OP,CN($CLJS.wH,$CLJS.G(["field",$CLJS.nU])));
var IV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,OP],null);$CLJS.Y(UN,CN($CLJS.DH,$CLJS.G(["field",$CLJS.nU])));var JV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,UN],null),KV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.bI,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),$CLJS.yt],null)],null);$CLJS.Y(aQ,CN($CLJS.LH,$CLJS.G(["field",sU,"string-or-field",sU,"options",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ft,KV],null)])));var LV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,aQ],null);
$CLJS.Y(UQ,CN($CLJS.eI,$CLJS.G(["field",sU,"string-or-field",sU,"options",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ft,KV],null)])));var MV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,UQ],null);$CLJS.Y(MP,CN($CLJS.TH,$CLJS.G(["field",sU,"string-or-field",sU,"options",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ft,KV],null)])));var NV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,MP],null);$CLJS.Y(CR,CN($CLJS.MH,$CLJS.G(["field",sU,"string-or-field",sU,"options",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ft,KV],null)])));
var OV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,CR],null);$CLJS.Y(cO,CN($CLJS.fI,$CLJS.G(["field",$CLJS.nU,"n",new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.kt,$CLJS.pi,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.bk,$CLJS.vx,$CLJS.Mz,$CLJS.AH],null)],null),"unit",dU,"options",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ft,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.jI,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),$CLJS.yt],null)],null)],null)])));var PV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,cO],null);
$CLJS.Y(TR,CN($CLJS.rH,$CLJS.G(["segment-id",new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.kt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.sM],null),PT],null)])));var QV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,TR],null);
$CLJS.Y(bP,DN($CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof sV?new $CLJS.Fc(function(){return sV},$CLJS.od(NS,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),vQ,"metabase/mbql/schema.cljc",15,$CLJS.qt,1,724,724,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.qt,lP,$CLJS.W($CLJS.Gj,AQ),dR,$CLJS.W($CLJS.Gj,AQ),vP,$CLJS.W($CLJS.Gj,$CLJS.W($CLJS.Ei,AQ))],null),$CLJS.Jc,"Schema for a valid and clause.",$CLJS.n(sV)?sV.H:null])):null));return $CLJS.n(a)?a:vQ}(),sV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof tV?new $CLJS.Fc(function(){return tV},
$CLJS.od(hR,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),kR,"metabase/mbql/schema.cljc",14,$CLJS.kt,1,729,729,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.kt,lP,$CLJS.W($CLJS.Gj,AQ),dR,$CLJS.W($CLJS.Gj,AQ),vP,$CLJS.W($CLJS.Gj,$CLJS.W($CLJS.Ei,AQ))],null),$CLJS.Jc,
"Schema for a valid or clause.",$CLJS.n(tV)?tV.H:null])):null));return $CLJS.n(a)?a:kR}(),tV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof uV?new $CLJS.Fc(function(){return uV},$CLJS.od(UR,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),NQ,"metabase/mbql/schema.cljc",15,$CLJS.mt,1,734,734,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.mt,$CLJS.AT,$CLJS.W($CLJS.Gj,AQ)],null),$CLJS.Jc,"Schema for a valid not clause.",$CLJS.n(uV)?uV.H:null])):null));return $CLJS.n(a)?a:NQ}(),uV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof AV?new $CLJS.Fc(function(){return AV},$CLJS.od(qR,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([$CLJS.Ni,
$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),oQ,"metabase/mbql/schema.cljc",13,$CLJS.Ks,1,798,798,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Ks,$CLJS.UH,$CLJS.W($CLJS.Gj,nP),yS,$CLJS.W($CLJS.Gj,nP)],null),$CLJS.Jc,"Schema for a valid \x3c clause.",$CLJS.n(AV)?AV.H:null])):null));return $CLJS.n(a)?a:oQ}(),AV],null),
new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof CV?new $CLJS.Fc(function(){return CV},$CLJS.od(tT,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),nQ,"metabase/mbql/schema.cljc",14,$CLJS.Ms,1,800,800,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Ms,$CLJS.UH,$CLJS.W($CLJS.Gj,nP),yS,$CLJS.W($CLJS.Gj,nP)],null),$CLJS.Jc,"Schema for a valid \x3c\x3d clause.",$CLJS.n(CV)?CV.H:null])):null));return $CLJS.n(a)?a:nQ}(),CV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof BV?new $CLJS.Fc(function(){return BV},$CLJS.od(oR,new $CLJS.h(null,
1,[$CLJS.Rj,!0],null)),$CLJS.Ag([$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),pQ,"metabase/mbql/schema.cljc",13,$CLJS.Gs,1,799,799,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Gs,$CLJS.UH,$CLJS.W($CLJS.Gj,nP),yS,$CLJS.W($CLJS.Gj,nP)],null),$CLJS.Jc,"Schema for a valid \x3e clause.",$CLJS.n(BV)?BV.H:null])):null));
return $CLJS.n(a)?a:pQ}(),BV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof DV?new $CLJS.Fc(function(){return DV},$CLJS.od(mT,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),wQ,"metabase/mbql/schema.cljc",14,$CLJS.Is,1,801,801,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Is,$CLJS.UH,$CLJS.W($CLJS.Gj,nP),yS,$CLJS.W($CLJS.Gj,nP)],null),$CLJS.Jc,"Schema for a valid \x3e\x3d clause.",$CLJS.n(DV)?DV.H:null])):null));return $CLJS.n(a)?a:wQ}(),DV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof yV?new $CLJS.Fc(function(){return yV},$CLJS.od(pR,new $CLJS.h(null,
1,[$CLJS.Rj,!0],null)),$CLJS.Ag([$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),qQ,"metabase/mbql/schema.cljc",13,$CLJS.Ns,1,795,795,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.Ns,$CLJS.UH,$CLJS.W($CLJS.Gj,yT),yS,$CLJS.W($CLJS.Gj,yT),bS,$CLJS.W($CLJS.Gj,$CLJS.W($CLJS.Ei,yT))],null),$CLJS.Jc,"Schema for a valid \x3d clause.",
$CLJS.n(yV)?yV.H:null])):null));return $CLJS.n(a)?a:qQ}(),yV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof zV?new $CLJS.Fc(function(){return zV},$CLJS.od(DO,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),ZO,"metabase/mbql/schema.cljc",14,$CLJS.mI,1,796,796,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.mI,$CLJS.UH,$CLJS.W($CLJS.Gj,yT),yS,$CLJS.W($CLJS.Gj,yT),bS,$CLJS.W($CLJS.Gj,$CLJS.W($CLJS.Ei,yT))],null),$CLJS.Jc,"Schema for a valid !\x3d clause.",$CLJS.n(zV)?zV.H:null])):null));return $CLJS.n(a)?a:ZO}(),zV],null)])));
$CLJS.Y(iR,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.pt,new $CLJS.h(null,2,[$CLJS.wu,"valid filter expression",$CLJS.Gi,function(a){return $CLJS.n(BN(wU,a))?$CLJS.FK:$CLJS.n(BN(tU,a))?HT:$CLJS.n(BN(qU,a))?$CLJS.Pj:$CLJS.n(BN(uU,a))?$CLJS.yt:$CLJS.Mu}],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.FK,$CLJS.zU],null),new $CLJS.Q(null,2,5,$CLJS.R,[HT,xU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Pj,rU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.yt,yU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Mu,DN($CLJS.G([new $CLJS.Q(null,
2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof sV?new $CLJS.Fc(function(){return sV},$CLJS.od(NS,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),vQ,"metabase/mbql/schema.cljc",
15,$CLJS.qt,1,724,724,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.qt,lP,$CLJS.W($CLJS.Gj,AQ),dR,$CLJS.W($CLJS.Gj,AQ),vP,$CLJS.W($CLJS.Gj,$CLJS.W($CLJS.Ei,AQ))],null),$CLJS.Jc,"Schema for a valid and clause.",$CLJS.n(sV)?sV.H:null])):null));return $CLJS.n(a)?a:vQ}(),sV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof tV?new $CLJS.Fc(function(){return tV},$CLJS.od(hR,new $CLJS.h(null,1,[$CLJS.Rj,
!0],null)),$CLJS.Ag([$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),kR,"metabase/mbql/schema.cljc",14,$CLJS.kt,1,729,729,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.kt,lP,$CLJS.W($CLJS.Gj,AQ),dR,$CLJS.W($CLJS.Gj,AQ),vP,$CLJS.W($CLJS.Gj,$CLJS.W($CLJS.Ei,AQ))],null),$CLJS.Jc,"Schema for a valid or clause.",$CLJS.n(tV)?
tV.H:null])):null));return $CLJS.n(a)?a:kR}(),tV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof uV?new $CLJS.Fc(function(){return uV},$CLJS.od(UR,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),NQ,"metabase/mbql/schema.cljc",15,$CLJS.mt,1,734,734,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.mt,$CLJS.AT,$CLJS.W($CLJS.Gj,AQ)],null),$CLJS.Jc,"Schema for a valid not clause.",$CLJS.n(uV)?uV.H:null])):null));return $CLJS.n(a)?a:NQ}(),uV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof yV?new $CLJS.Fc(function(){return yV},$CLJS.od(pR,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([$CLJS.Ni,
$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),qQ,"metabase/mbql/schema.cljc",13,$CLJS.Ns,1,795,795,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.Ns,$CLJS.UH,$CLJS.W($CLJS.Gj,yT),yS,$CLJS.W($CLJS.Gj,yT),bS,$CLJS.W($CLJS.Gj,$CLJS.W($CLJS.Ei,yT))],null),$CLJS.Jc,"Schema for a valid \x3d clause.",$CLJS.n(yV)?yV.H:null])):null));
return $CLJS.n(a)?a:qQ}(),yV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof zV?new $CLJS.Fc(function(){return zV},$CLJS.od(DO,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),ZO,"metabase/mbql/schema.cljc",14,$CLJS.mI,1,796,796,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.mI,$CLJS.UH,$CLJS.W($CLJS.Gj,yT),yS,$CLJS.W($CLJS.Gj,yT),bS,$CLJS.W($CLJS.Gj,$CLJS.W($CLJS.Ei,yT))],null),$CLJS.Jc,"Schema for a valid !\x3d clause.",$CLJS.n(zV)?zV.H:null])):null));return $CLJS.n(a)?a:ZO}(),zV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof AV?new $CLJS.Fc(function(){return AV},
$CLJS.od(qR,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),oQ,"metabase/mbql/schema.cljc",13,$CLJS.Ks,1,798,798,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Ks,$CLJS.UH,$CLJS.W($CLJS.Gj,nP),yS,$CLJS.W($CLJS.Gj,nP)],null),$CLJS.Jc,"Schema for a valid \x3c clause.",
$CLJS.n(AV)?AV.H:null])):null));return $CLJS.n(a)?a:oQ}(),AV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof BV?new $CLJS.Fc(function(){return BV},$CLJS.od(oR,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),pQ,"metabase/mbql/schema.cljc",13,$CLJS.Gs,1,799,799,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Gs,$CLJS.UH,$CLJS.W($CLJS.Gj,nP),yS,$CLJS.W($CLJS.Gj,nP)],null),$CLJS.Jc,"Schema for a valid \x3e clause.",$CLJS.n(BV)?BV.H:null])):null));return $CLJS.n(a)?a:pQ}(),BV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof CV?new $CLJS.Fc(function(){return CV},$CLJS.od(tT,new $CLJS.h(null,
1,[$CLJS.Rj,!0],null)),$CLJS.Ag([$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),nQ,"metabase/mbql/schema.cljc",14,$CLJS.Ms,1,800,800,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Ms,$CLJS.UH,$CLJS.W($CLJS.Gj,nP),yS,$CLJS.W($CLJS.Gj,nP)],null),$CLJS.Jc,"Schema for a valid \x3c\x3d clause.",$CLJS.n(CV)?CV.H:null])):
null));return $CLJS.n(a)?a:nQ}(),CV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof DV?new $CLJS.Fc(function(){return DV},$CLJS.od(mT,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),wQ,"metabase/mbql/schema.cljc",14,$CLJS.Is,1,801,801,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Is,$CLJS.UH,$CLJS.W($CLJS.Gj,nP),yS,$CLJS.W($CLJS.Gj,nP)],null),$CLJS.Jc,"Schema for a valid \x3e\x3d clause.",$CLJS.n(DV)?DV.H:null])):null));return $CLJS.n(a)?a:wQ}(),DV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof EV?new $CLJS.Fc(function(){return EV},$CLJS.od(Yga,new $CLJS.h(null,
1,[$CLJS.Rj,!0],null)),$CLJS.Ag([$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),FO,"metabase/mbql/schema.cljc",19,$CLJS.vH,1,804,804,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.vH,$CLJS.UH,$CLJS.W($CLJS.Gj,nP),$CLJS.Sn,$CLJS.W($CLJS.Gj,nP),$CLJS.Zj,$CLJS.W($CLJS.Gj,nP)],null),$CLJS.Jc,"Schema for a valid between clause.",
$CLJS.n(EV)?EV.H:null])):null));return $CLJS.n(a)?a:FO}(),EV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof LV?new $CLJS.Fc(function(){return LV},$CLJS.od($ga,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),DS,"metabase/mbql/schema.cljc",23,$CLJS.LH,1,829,829,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.LH,$CLJS.UH,$CLJS.W($CLJS.Gj,xS),YO,$CLJS.W($CLJS.Gj,xS),$CLJS.Yi,$CLJS.W($CLJS.Gj,$CLJS.W(JS,SR))],null),$CLJS.Jc,"Schema for a valid starts-with clause.",$CLJS.n(LV)?LV.H:null])):null));return $CLJS.n(a)?a:DS}(),LV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof MV?new $CLJS.Fc(function(){return MV},
$CLJS.od(bga,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),cP,"metabase/mbql/schema.cljc",21,$CLJS.eI,1,830,830,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.eI,$CLJS.UH,$CLJS.W($CLJS.Gj,xS),YO,$CLJS.W($CLJS.Gj,xS),$CLJS.Yi,$CLJS.W($CLJS.Gj,$CLJS.W(JS,SR))],null),
$CLJS.Jc,"Schema for a valid ends-with clause.",$CLJS.n(MV)?MV.H:null])):null));return $CLJS.n(a)?a:cP}(),MV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof NV?new $CLJS.Fc(function(){return NV},$CLJS.od(nga,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[$CLJS.od(qS,new $CLJS.h(null,
1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),oP,"metabase/mbql/schema.cljc",20,$CLJS.TH,1,831,831,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.TH,$CLJS.UH,$CLJS.W($CLJS.Gj,xS),YO,$CLJS.W($CLJS.Gj,xS),$CLJS.Yi,$CLJS.W($CLJS.Gj,$CLJS.W(JS,SR))],null),$CLJS.Jc,"Schema for a valid contains clause.",$CLJS.n(NV)?NV.H:null])):null));return $CLJS.n(a)?a:oP}(),NV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==
typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof OV?new $CLJS.Fc(function(){return OV},$CLJS.od(Sha,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,LS,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),SN,"metabase/mbql/schema.cljc",36,$CLJS.MH,1,834,834,!0,new $CLJS.Q(null,
7,5,$CLJS.R,[$CLJS.MH,$CLJS.UH,$CLJS.W($CLJS.Gj,xS),YO,$CLJS.W($CLJS.Gj,xS),$CLJS.Yi,$CLJS.W($CLJS.Gj,$CLJS.W(JS,SR))],null),$CLJS.Jc,"Schema for a valid does-not-contain clause.",$CLJS.n(OV)?OV.H:null])):null));return $CLJS.n(a)?a:SN}(),OV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof FV?new $CLJS.Fc(function(){return FV},$CLJS.od(Bha,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),
$CLJS.Ag([$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,LS,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),BS,"metabase/mbql/schema.cljc",26,$CLJS.BH,1,807,807,!0,new $CLJS.Q(null,13,5,$CLJS.R,[$CLJS.BH,Kfa,$CLJS.W($CLJS.Gj,nP),Wha,$CLJS.W($CLJS.Gj,nP),Iha,$CLJS.W($CLJS.Gj,nP),Nfa,$CLJS.W($CLJS.Gj,nP),Cha,$CLJS.W($CLJS.Gj,nP),Jfa,$CLJS.W($CLJS.Gj,
nP)],null),$CLJS.Jc,"Schema for a valid inside clause.",$CLJS.n(FV)?FV.H:null])):null));return $CLJS.n(a)?a:BS}(),FV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof IV?new $CLJS.Fc(function(){return IV},$CLJS.od(oga,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,LS,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[$CLJS.od(qS,new $CLJS.h(null,
1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),WQ,"metabase/mbql/schema.cljc",28,$CLJS.wH,1,821,821,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.wH,$CLJS.UH,$CLJS.W($CLJS.Gj,gP)],null),$CLJS.Jc,"Schema for a valid is-empty clause.",$CLJS.n(IV)?IV.H:null])):null));return $CLJS.n(a)?a:WQ}(),IV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&
"undefined"!==typeof JV?new $CLJS.Fc(function(){return JV},$CLJS.od(mha,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,LS,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),JR,"metabase/mbql/schema.cljc",29,$CLJS.DH,1,822,822,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.DH,$CLJS.UH,$CLJS.W($CLJS.Gj,gP)],null),
$CLJS.Jc,"Schema for a valid not-empty clause.",$CLJS.n(JV)?JV.H:null])):null));return $CLJS.n(a)?a:JR}(),JV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof GV?new $CLJS.Fc(function(){return GV},$CLJS.od(Nha,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,LS,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[$CLJS.od(qS,new $CLJS.h(null,
1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),uP,"metabase/mbql/schema.cljc",27,$CLJS.uH,1,816,816,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uH,$CLJS.UH,$CLJS.W($CLJS.Gj,gP)],null),$CLJS.Jc,"Schema for a valid is-null clause.",$CLJS.n(GV)?GV.H:null])):null));return $CLJS.n(a)?a:uP}(),GV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&
"undefined"!==typeof HV?new $CLJS.Fc(function(){return HV},$CLJS.od(Aga,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,LS,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),KO,"metabase/mbql/schema.cljc",28,$CLJS.GH,1,817,817,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.GH,$CLJS.UH,$CLJS.W($CLJS.Gj,gP)],null),
$CLJS.Jc,"Schema for a valid not-null clause.",$CLJS.n(HV)?HV.H:null])):null));return $CLJS.n(a)?a:KO}(),HV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof PV?new $CLJS.Fc(function(){return PV},$CLJS.od(Bfa,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,LS,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[$CLJS.od(qS,new $CLJS.h(null,
1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),iS,"metabase/mbql/schema.cljc",33,$CLJS.fI,1,856,856,!0,new $CLJS.Q(null,9,5,$CLJS.R,[$CLJS.fI,$CLJS.UH,$CLJS.W($CLJS.Gj,gP),$CLJS.My,$CLJS.W($CLJS.Gj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.kt,$CLJS.pi,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.bk,$CLJS.vx,$CLJS.Mz,$CLJS.AH],null)],null)),$CLJS.ki,$CLJS.W($CLJS.Gj,uQ),$CLJS.Yi,$CLJS.W($CLJS.Gj,$CLJS.W(JS,cha))],null),$CLJS.Jc,"Schema for a valid time-interval clause.",
$CLJS.n(PV)?PV.H:null])):null));return $CLJS.n(a)?a:iS}(),PV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof QV?new $CLJS.Fc(function(){return QV},$CLJS.od(Iga,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,LS,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),kT,"metabase/mbql/schema.cljc",27,$CLJS.rH,1,872,872,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.rH,Rfa,$CLJS.W($CLJS.Gj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.kt,sha,wO],null))],null),$CLJS.Jc,"Schema for a valid segment clause.",$CLJS.n(QV)?QV.H:null])):null));return $CLJS.n(a)?a:kT}(),QV],null)]))],null)],null));
$CLJS.Y(VR,CN($CLJS.CH,$CLJS.G(["clauses",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.qj,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.qi,new $CLJS.h(null,1,[$CLJS.wu,":case subclause"],null),$CLJS.rV,DU],null)],null)],null),"options",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ft,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Yj,new $CLJS.h(null,1,[$CLJS.wu,":case options"],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Wh,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),DU],null)],null)],null)])));
var RV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,VR],null);
$CLJS.Y(CS,DN($CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof QU?new $CLJS.Fc(function(){return QU},$CLJS.od(sR,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([IQ,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.AR,"null"],null),null),$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),gQ,"metabase/mbql/schema.cljc",51,$CLJS.Qs,1,612,612,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.Qs,$CLJS.lG,$CLJS.W($CLJS.Gj,wR),US,$CLJS.W($CLJS.Gj,wR),kP,$CLJS.W($CLJS.Gj,$CLJS.W($CLJS.Ei,wR))],null),$CLJS.Jc,"Schema for a valid + clause.",$CLJS.n(QU)?QU.H:null])):null));return $CLJS.n(a)?a:gQ}(),QU],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof RU?new $CLJS.Fc(function(){return RU},
$CLJS.od(Uga,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([IQ,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.AR,"null"],null),null),$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),hQ,"metabase/mbql/schema.cljc",51,$CLJS.mu,1,615,615,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.mu,$CLJS.lG,$CLJS.W($CLJS.Gj,sS),
US,$CLJS.W($CLJS.Gj,wR),kP,$CLJS.W($CLJS.Gj,$CLJS.W($CLJS.Ei,wR))],null),$CLJS.Jc,"Schema for a valid - clause.",$CLJS.n(RU)?RU.H:null])):null));return $CLJS.n(a)?a:hQ}(),RU],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN?new $CLJS.Fc(function(){return SU},$CLJS.od(Tga,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([IQ,$CLJS.Ni,$CLJS.T,$CLJS.fk,KQ,$CLJS.Ki,$CLJS.ej,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[new $CLJS.Tg(null,
new $CLJS.h(null,1,[$CLJS.AR,"null"],null),null),$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.VB,"metabase/mbql/schema.cljc",$CLJS.qI,1,618,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.qI,$CLJS.lG,$CLJS.W($CLJS.Gj,sS),US,$CLJS.W($CLJS.Gj,sS),kP,$CLJS.W($CLJS.Gj,$CLJS.W($CLJS.Ei,sS))],null),$CLJS.Jc,"Schema for a valid / clause.",$CLJS.n(SU)?SU.H:null])):null));return $CLJS.n(a)?a:$CLJS.VB}(),SU],
null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof TU?new $CLJS.Fc(function(){return TU},$CLJS.od(Vga,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([IQ,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.AR,"null"],null),null),$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),fQ,"metabase/mbql/schema.cljc",51,$CLJS.Rs,1,620,620,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.Rs,$CLJS.lG,$CLJS.W($CLJS.Gj,sS),US,$CLJS.W($CLJS.Gj,sS),kP,$CLJS.W($CLJS.Gj,$CLJS.W($CLJS.Ei,sS))],null),$CLJS.Jc,"Schema for a valid * clause.",$CLJS.n(TU)?TU.H:null])):null));return $CLJS.n(a)?a:fQ}(),TU],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof FU?new $CLJS.Fc(function(){return FU},
$CLJS.od(fS,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([IQ,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.AR,"null"],null),null),$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),uS,"metabase/mbql/schema.cljc",58,$CLJS.XH,1,579,579,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.XH,cT,$CLJS.W($CLJS.Gj,XN),bT,$CLJS.W($CLJS.Gj,
XN),kP,$CLJS.W($CLJS.Gj,$CLJS.W($CLJS.Ei,XN))],null),$CLJS.Jc,"Schema for a valid coalesce clause.",$CLJS.n(FU)?FU.H:null])):null));return $CLJS.n(a)?a:uS}(),FU],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof HU?new $CLJS.Fc(function(){return HU},$CLJS.od(lha,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([IQ,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,GO,
$CLJS.mi,$CLJS.U,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.AR,"null"],null),null),$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),MR,"metabase/mbql/schema.cljc",56,$CLJS.Gz,1,585,585,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Gz,$CLJS.cA,$CLJS.W($CLJS.Gj,xS)],null),$CLJS.Jc,"Schema for a valid length clause.",$CLJS.n(HU)?HU.H:null])):null));return $CLJS.n(a)?a:MR}(),HU],null),new $CLJS.Q(null,
2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof UU?new $CLJS.Fc(function(){return UU},$CLJS.od(cga,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([IQ,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.AR,"null"],null),null),$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),HQ,"metabase/mbql/schema.cljc",55,$CLJS.SH,1,622,622,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.SH,$CLJS.lG,$CLJS.W($CLJS.Gj,sS)],null),$CLJS.Jc,"Schema for a valid floor clause.",$CLJS.n(UU)?UU.H:null])):null));return $CLJS.n(a)?a:HQ}(),UU],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof VU?new $CLJS.Fc(function(){return VU},$CLJS.od(Xfa,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),
$CLJS.Ag([IQ,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.AR,"null"],null),null),$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),vO,"metabase/mbql/schema.cljc",54,$CLJS.pH,1,625,625,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.pH,$CLJS.lG,$CLJS.W($CLJS.Gj,sS)],null),$CLJS.Jc,"Schema for a valid ceil clause.",$CLJS.n(VU)?
VU.H:null])):null));return $CLJS.n(a)?a:vO}(),VU],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof WU?new $CLJS.Fc(function(){return WU},$CLJS.od(kga,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([IQ,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.AR,"null"],null),null),$CLJS.od(qS,new $CLJS.h(null,
1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),xQ,"metabase/mbql/schema.cljc",55,$CLJS.ZH,1,628,628,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ZH,$CLJS.lG,$CLJS.W($CLJS.Gj,sS)],null),$CLJS.Jc,"Schema for a valid round clause.",$CLJS.n(WU)?WU.H:null])):null));return $CLJS.n(a)?a:xQ}(),WU],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&
"undefined"!==typeof XU?new $CLJS.Fc(function(){return XU},$CLJS.od(Tha,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([IQ,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.AR,"null"],null),null),$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$Q,"metabase/mbql/schema.cljc",53,$CLJS.tH,1,631,631,new $CLJS.Q(null,
3,5,$CLJS.R,[$CLJS.tH,$CLJS.lG,$CLJS.W($CLJS.Gj,sS)],null),$CLJS.Jc,"Schema for a valid abs clause.",$CLJS.n(XU)?XU.H:null])):null));return $CLJS.n(a)?a:$Q}(),XU],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof YU?new $CLJS.Fc(function(){return YU},$CLJS.od(Gha,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([IQ,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,
GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[qT,"null"],null),null),$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),MS,"metabase/mbql/schema.cljc",69,$CLJS.qH,1,634,634,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.qH,$CLJS.lG,$CLJS.W($CLJS.Gj,sS),US,$CLJS.W($CLJS.Gj,sS)],null),$CLJS.Jc,"Schema for a valid power clause.",$CLJS.n(YU)?YU.H:null])):null));return $CLJS.n(a)?a:MS}(),YU],
null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof ZU?new $CLJS.Fc(function(){return ZU},$CLJS.od(Dga,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([IQ,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[qT,"null"],null),null),$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),bQ,"metabase/mbql/schema.cljc",68,$CLJS.HH,1,637,637,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.HH,$CLJS.lG,$CLJS.W($CLJS.Gj,sS)],null),$CLJS.Jc,"Schema for a valid sqrt clause.",$CLJS.n(ZU)?ZU.H:null])):null));return $CLJS.n(a)?a:bQ}(),ZU],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof $U?new $CLJS.Fc(function(){return $U},$CLJS.od(Hha,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),
$CLJS.Ag([IQ,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[qT,"null"],null),null),$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),zQ,"metabase/mbql/schema.cljc",67,$CLJS.mH,1,640,640,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.mH,$CLJS.lG,$CLJS.W($CLJS.Gj,sS)],null),$CLJS.Jc,"Schema for a valid exp clause.",$CLJS.n($U)?
$U.H:null])):null));return $CLJS.n(a)?a:zQ}(),$U],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof aV?new $CLJS.Fc(function(){return aV},$CLJS.od(Qfa,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([IQ,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[qT,"null"],null),null),$CLJS.od(qS,new $CLJS.h(null,
1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),vT,"metabase/mbql/schema.cljc",67,$CLJS.sI,1,643,643,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.sI,$CLJS.lG,$CLJS.W($CLJS.Gj,sS)],null),$CLJS.Jc,"Schema for a valid log clause.",$CLJS.n(aV)?aV.H:null])):null));return $CLJS.n(a)?a:vT}(),aV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&
"undefined"!==typeof RV?new $CLJS.Fc(function(){return RV},$CLJS.od(hO,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([IQ,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.oH,"null"],null),null),$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),RQ,"metabase/mbql/schema.cljc",61,$CLJS.CH,1,909,909,new $CLJS.Q(null,
5,5,$CLJS.R,[$CLJS.CH,$CLJS.Py,$CLJS.W($CLJS.Gj,AP),$CLJS.Yi,$CLJS.W($CLJS.Gj,$CLJS.W(JS,jR))],null),$CLJS.Jc,"Schema for a valid case clause.",$CLJS.n(RV)?RV.H:null])):null));return $CLJS.n(a)?a:RQ}(),RV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof bV?new $CLJS.Fc(function(){return bV},$CLJS.od(Tfa,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([IQ,$CLJS.Ni,$CLJS.T,$CLJS.fk,
$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.BK,"null"],null),null),$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),aR,"metabase/mbql/schema.cljc",65,$CLJS.BK,1,656,656,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.BK,ega,$CLJS.W($CLJS.Gj,GT),fga,$CLJS.W($CLJS.Gj,GT),$CLJS.ki,$CLJS.W($CLJS.Gj,Lga)],null),$CLJS.Jc,"Schema for a valid datetime-diff clause.",
$CLJS.n(bV)?bV.H:null])):null));return $CLJS.n(a)?a:aR}(),bV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof cV?new $CLJS.Fc(function(){return cV},$CLJS.od(pha,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([IQ,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.QK,"null"],null),null),$CLJS.od(qS,
new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),tR,"metabase/mbql/schema.cljc",71,$CLJS.QK,1,661,661,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.QK,$CLJS.FK,$CLJS.W($CLJS.Gj,GT),$CLJS.ki,$CLJS.W($CLJS.Gj,Mha),$CLJS.ri,$CLJS.W($CLJS.Gj,$CLJS.W(JS,OT))],null),$CLJS.Jc,"Schema for a valid temporal-extract clause.",$CLJS.n(cV)?cV.H:null])):null));return $CLJS.n(a)?a:tR}(),cV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=
KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof dV?new $CLJS.Fc(function(){return dV},$CLJS.od(wga,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([IQ,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,LS,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.QK,"null"],null),null),$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),SO,"metabase/mbql/schema.cljc",71,$CLJS.xK,1,667,667,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.xK,$CLJS.cR,$CLJS.W($CLJS.Gj,GT)],null),$CLJS.Jc,"Schema for a valid get-year clause.",$CLJS.n(dV)?dV.H:null])):null));return $CLJS.n(a)?a:SO}(),dV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof eV?new $CLJS.Fc(function(){return eV},$CLJS.od(lga,new $CLJS.h(null,1,[$CLJS.Rj,!0],
null)),$CLJS.Ag([IQ,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,LS,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.QK,"null"],null),null),$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),rR,"metabase/mbql/schema.cljc",74,$CLJS.UK,1,670,670,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.UK,$CLJS.cR,$CLJS.W($CLJS.Gj,GT)],null),$CLJS.Jc,"Schema for a valid get-quarter clause.",
$CLJS.n(eV)?eV.H:null])):null));return $CLJS.n(a)?a:rR}(),eV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof fV?new $CLJS.Fc(function(){return fV},$CLJS.od(Sga,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([IQ,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,LS,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.QK,"null"],null),null),
$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),OR,"metabase/mbql/schema.cljc",72,$CLJS.qK,1,673,673,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.qK,$CLJS.cR,$CLJS.W($CLJS.Gj,GT)],null),$CLJS.Jc,"Schema for a valid get-month clause.",$CLJS.n(fV)?fV.H:null])):null));return $CLJS.n(a)?a:OR}(),fV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==
typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof gV?new $CLJS.Fc(function(){return gV},$CLJS.od(Lha,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([IQ,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,LS,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.QK,"null"],null),null),$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),NR,"metabase/mbql/schema.cljc",
71,$CLJS.KK,1,676,676,!0,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.KK,$CLJS.cR,$CLJS.W($CLJS.Gj,GT),$CLJS.ri,$CLJS.W($CLJS.Gj,$CLJS.W(JS,OT))],null),$CLJS.Jc,"Schema for a valid get-week clause.",$CLJS.n(gV)?gV.H:null])):null));return $CLJS.n(a)?a:NR}(),gV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof hV?new $CLJS.Fc(function(){return hV},$CLJS.od(Hfa,new $CLJS.h(null,1,[$CLJS.Rj,
!0],null)),$CLJS.Ag([IQ,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,LS,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.QK,"null"],null),null),$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),GR,"metabase/mbql/schema.cljc",70,$CLJS.JK,1,680,680,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.JK,$CLJS.cR,$CLJS.W($CLJS.Gj,GT)],null),$CLJS.Jc,"Schema for a valid get-day clause.",
$CLJS.n(hV)?hV.H:null])):null));return $CLJS.n(a)?a:GR}(),hV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof iV?new $CLJS.Fc(function(){return iV},$CLJS.od(Mga,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([IQ,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,LS,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.QK,"null"],null),null),
$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),xR,"metabase/mbql/schema.cljc",78,$CLJS.OK,1,683,683,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.OK,$CLJS.cR,$CLJS.W($CLJS.Gj,GT)],null),$CLJS.Jc,"Schema for a valid get-day-of-week clause.",$CLJS.n(iV)?iV.H:null])):null));return $CLJS.n(a)?a:xR}(),iV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==
typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof jV?new $CLJS.Fc(function(){return jV},$CLJS.od(Kga,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([IQ,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,LS,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.QK,"null"],null),null),$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),YN,"metabase/mbql/schema.cljc",
71,$CLJS.RK,1,686,686,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.RK,$CLJS.FK,$CLJS.W($CLJS.Gj,GT)],null),$CLJS.Jc,"Schema for a valid get-hour clause.",$CLJS.n(jV)?jV.H:null])):null));return $CLJS.n(a)?a:YN}(),jV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof kV?new $CLJS.Fc(function(){return kV},$CLJS.od(tha,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([IQ,$CLJS.Ni,$CLJS.T,$CLJS.fk,
$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,LS,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.QK,"null"],null),null),$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),zT,"metabase/mbql/schema.cljc",73,$CLJS.NK,1,689,689,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.NK,$CLJS.FK,$CLJS.W($CLJS.Gj,GT)],null),$CLJS.Jc,"Schema for a valid get-minute clause.",$CLJS.n(kV)?kV.H:null])):null));
return $CLJS.n(a)?a:zT}(),kV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof lV?new $CLJS.Fc(function(){return lV},$CLJS.od(Hga,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([IQ,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,LS,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.QK,"null"],null),null),$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),TQ,"metabase/mbql/schema.cljc",73,$CLJS.wK,1,692,692,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.wK,$CLJS.FK,$CLJS.W($CLJS.Gj,GT)],null),$CLJS.Jc,"Schema for a valid get-second clause.",$CLJS.n(lV)?lV.H:null])):null));return $CLJS.n(a)?a:TQ}(),lV],null)])));
$CLJS.Y(nO,DN($CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof GU?new $CLJS.Fc(function(){return GU},$CLJS.od(jha,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([IQ,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.AR,"null"],null),null),$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),WR,"metabase/mbql/schema.cljc",59,$CLJS.nH,1,582,582,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.nH,$CLJS.cA,$CLJS.W($CLJS.Gj,xS),$CLJS.ax,$CLJS.W($CLJS.Gj,hga),$CLJS.Gz,$CLJS.W($CLJS.Gj,$CLJS.W(JS,sS))],null),$CLJS.Jc,"Schema for a valid substring clause.",$CLJS.n(GU)?GU.H:null])):null));return $CLJS.n(a)?a:WR}(),GU],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof IU?new $CLJS.Fc(function(){return IU},
$CLJS.od(Jha,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([IQ,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.AR,"null"],null),null),$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),tS,"metabase/mbql/schema.cljc",54,$CLJS.lI,1,588,588,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.lI,$CLJS.cA,$CLJS.W($CLJS.Gj,xS)],
null),$CLJS.Jc,"Schema for a valid trim clause.",$CLJS.n(IU)?IU.H:null])):null));return $CLJS.n(a)?a:tS}(),IU],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof KU?new $CLJS.Fc(function(){return KU},$CLJS.od(Nga,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([IQ,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,
1,[$CLJS.AR,"null"],null),null),$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),cS,"metabase/mbql/schema.cljc",55,$CLJS.nI,1,594,594,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.nI,$CLJS.cA,$CLJS.W($CLJS.Gj,xS)],null),$CLJS.Jc,"Schema for a valid ltrim clause.",$CLJS.n(KU)?KU.H:null])):null));return $CLJS.n(a)?a:cS}(),KU],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&
"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof JU?new $CLJS.Fc(function(){return JU},$CLJS.od(kha,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([IQ,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.AR,"null"],null),null),$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),eR,"metabase/mbql/schema.cljc",
55,$CLJS.uI,1,591,591,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uI,$CLJS.cA,$CLJS.W($CLJS.Gj,xS)],null),$CLJS.Jc,"Schema for a valid rtrim clause.",$CLJS.n(JU)?JU.H:null])):null));return $CLJS.n(a)?a:eR}(),JU],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof NU?new $CLJS.Fc(function(){return NU},$CLJS.od(Cfa,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([IQ,$CLJS.Ni,$CLJS.T,$CLJS.fk,
$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.AR,"null"],null),null),$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),eT,"metabase/mbql/schema.cljc",57,$CLJS.tI,1,603,603,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.tI,$CLJS.cA,$CLJS.W($CLJS.Gj,xS),Ofa,$CLJS.W($CLJS.Gj,$CLJS.Pj),$CLJS.bC,$CLJS.W($CLJS.Gj,$CLJS.Pj)],null),$CLJS.Jc,"Schema for a valid replace clause.",
$CLJS.n(NU)?NU.H:null])):null));return $CLJS.n(a)?a:eT}(),NU],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof MU?new $CLJS.Fc(function(){return MU},$CLJS.od(nha,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([IQ,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.AR,"null"],null),null),$CLJS.od(qS,
new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),JT,"metabase/mbql/schema.cljc",55,$CLJS.Su,1,600,600,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Su,$CLJS.cA,$CLJS.W($CLJS.Gj,xS)],null),$CLJS.Jc,"Schema for a valid lower clause.",$CLJS.n(MU)?MU.H:null])):null));return $CLJS.n(a)?a:JT}(),MU],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==
typeof HN&&"undefined"!==typeof LU?new $CLJS.Fc(function(){return LU},$CLJS.od(Kha,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([IQ,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.AR,"null"],null),null),$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),GS,"metabase/mbql/schema.cljc",55,$CLJS.Ku,1,597,597,
new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Ku,$CLJS.cA,$CLJS.W($CLJS.Gj,xS)],null),$CLJS.Jc,"Schema for a valid upper clause.",$CLJS.n(LU)?LU.H:null])):null));return $CLJS.n(a)?a:GS}(),LU],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof OU?new $CLJS.Fc(function(){return OU},$CLJS.od(bha,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([IQ,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,
$CLJS.ej,$CLJS.nk,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.AR,"null"],null),null),$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),TP,"metabase/mbql/schema.cljc",56,$CLJS.sH,1,606,606,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.sH,cT,$CLJS.W($CLJS.Gj,xS),bT,$CLJS.W($CLJS.Gj,xS),kP,$CLJS.W($CLJS.Gj,$CLJS.W($CLJS.Ei,xS))],null),$CLJS.Jc,"Schema for a valid concat clause.",$CLJS.n(OU)?
OU.H:null])):null));return $CLJS.n(a)?a:TP}(),OU],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof PU?new $CLJS.Fc(function(){return PU},$CLJS.od(iha,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([IQ,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,2,[$CLJS.AR,"null",$CLJS.Tba,"null"],null),null),
$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),QO,"metabase/mbql/schema.cljc",74,$CLJS.WH,1,609,609,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.WH,$CLJS.cA,$CLJS.W($CLJS.Gj,xS),$CLJS.aC,$CLJS.W($CLJS.Gj,$CLJS.Pj)],null),$CLJS.Jc,"Schema for a valid regex-match-first clause.",$CLJS.n(PU)?PU.H:null])):null));return $CLJS.n(a)?a:QO}(),PU],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==
typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof FU?new $CLJS.Fc(function(){return FU},$CLJS.od(fS,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([IQ,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.AR,"null"],null),null),$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),
uS,"metabase/mbql/schema.cljc",58,$CLJS.XH,1,579,579,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.XH,cT,$CLJS.W($CLJS.Gj,XN),bT,$CLJS.W($CLJS.Gj,XN),kP,$CLJS.W($CLJS.Gj,$CLJS.W($CLJS.Ei,XN))],null),$CLJS.Jc,"Schema for a valid coalesce clause.",$CLJS.n(FU)?FU.H:null])):null));return $CLJS.n(a)?a:uS}(),FU],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof RV?new $CLJS.Fc(function(){return RV},
$CLJS.od(hO,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([IQ,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.oH,"null"],null),null),$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),RQ,"metabase/mbql/schema.cljc",61,$CLJS.CH,1,909,909,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.CH,$CLJS.Py,$CLJS.W($CLJS.Gj,AP),$CLJS.Yi,
$CLJS.W($CLJS.Gj,$CLJS.W(JS,jR))],null),$CLJS.Jc,"Schema for a valid case clause.",$CLJS.n(RV)?RV.H:null])):null));return $CLJS.n(a)?a:RQ}(),RV],null)])));
$CLJS.SV=new $CLJS.Q(null,8,5,$CLJS.R,[$CLJS.pt,new $CLJS.h(null,2,[$CLJS.wu,":field or :expression reference or expression",$CLJS.Gi,function(a){return $CLJS.n(BN(tU,a))?HT:$CLJS.n(BN(qU,a))?$CLJS.Pj:$CLJS.n(BN(uU,a))?$CLJS.yt:$CLJS.n(BN(wU,a))?$CLJS.FK:$CLJS.n(BN($CLJS.CH,a))?$CLJS.CH:$CLJS.Mu}],null),new $CLJS.Q(null,2,5,$CLJS.R,[HT,xU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Pj,rU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.yt,yU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.FK,$CLJS.zU],null),
new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.CH,RV],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Mu,$CLJS.nU],null)],null);$CLJS.Y(nT,CN($CLJS.Iz,$CLJS.G(["field",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ft,$CLJS.nU],null)])));var TV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,nT],null);$CLJS.Y(bR,CN($CLJS.VH,$CLJS.G(["field",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ft,$CLJS.nU],null)])));var UV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,bR],null);$CLJS.Y(lO,CN($CLJS.OH,$CLJS.G(["field-or-expression",$CLJS.SV])));
var VV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,lO],null);$CLJS.Y(fT,CN($CLJS.rI,$CLJS.G(["field-or-expression",$CLJS.SV])));var WV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,fT],null);$CLJS.Y(LP,CN($CLJS.yH,$CLJS.G(["field-or-expression",$CLJS.SV])));var XV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,LP],null);$CLJS.Y(vS,CN($CLJS.gI,$CLJS.G(["field-or-expression",$CLJS.SV])));var YV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,vS],null);$CLJS.Y(GP,CN($CLJS.Sn,$CLJS.G(["field-or-expression",$CLJS.SV])));
var ZV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,GP],null);$CLJS.Y(sP,CN($CLJS.Zj,$CLJS.G(["field-or-expression",$CLJS.SV])));var $V=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,sP],null);$CLJS.Y(mP,CN($CLJS.pI,$CLJS.G(["field-or-expression",$CLJS.SV,"pred",$CLJS.rV])));var aW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,mP],null);$CLJS.Y(DP,CN($CLJS.cI,$CLJS.G(["pred",$CLJS.rV])));var bW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,DP],null);$CLJS.Y(mS,CN($CLJS.aI,$CLJS.G(["pred",$CLJS.rV])));
var cW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,mS],null);$CLJS.Y(JO,CN($CLJS.kI,$CLJS.G(["field-or-expression",$CLJS.SV])));var dW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,JO],null);$CLJS.Y(JN,CN($CLJS.zH,$CLJS.G(["field-or-expression",$CLJS.SV])));var eW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,JN],null);$CLJS.Y(PP,CN($CLJS.hI,$CLJS.G(["field-or-expression",$CLJS.SV])));var fW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,PP],null);$CLJS.Y(CQ,CN($CLJS.FH,$CLJS.G(["field-or-expression",$CLJS.SV,"percentile",BU])));
var gW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,CQ],null);$CLJS.Y(pS,CN($CLJS.PH,$CLJS.G(["metric-id",new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.kt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.qM],null),PT],null)])));var hW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,pS],null);
$CLJS.Y(lS,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.pt,new $CLJS.h(null,2,[$CLJS.wu,"unnamed aggregation clause or numeric expression",$CLJS.Gi,function(a){return $CLJS.n(BN(tU,a))?FQ:$CLJS.Mu}],null),new $CLJS.Q(null,2,5,$CLJS.R,[FQ,xU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Mu,DN($CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof VV?new $CLJS.Fc(function(){return VV},$CLJS.od(hha,
new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([IQ,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.oH,"null"],null),null),$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),OQ,"metabase/mbql/schema.cljc",60,$CLJS.OH,1,957,957,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.OH,HS,$CLJS.W($CLJS.Gj,zS)],null),$CLJS.Jc,"Schema for a valid avg clause.",
$CLJS.n(VV)?VV.H:null])):null));return $CLJS.n(a)?a:OQ}(),VV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof WV?new $CLJS.Fc(function(){return WV},$CLJS.od(sga,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([IQ,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.oH,"null"],null),null),$CLJS.od(qS,
new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),TS,"metabase/mbql/schema.cljc",64,$CLJS.rI,1,958,958,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.rI,HS,$CLJS.W($CLJS.Gj,zS)],null),$CLJS.Jc,"Schema for a valid cum-sum clause.",$CLJS.n(WV)?WV.H:null])):null));return $CLJS.n(a)?a:TS}(),WV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==
typeof HN&&"undefined"!==typeof XV?new $CLJS.Fc(function(){return XV},$CLJS.od(jga,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([IQ,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.oH,"null"],null),null),$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),SQ,"metabase/mbql/schema.cljc",65,$CLJS.yH,1,959,959,
new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.yH,HS,$CLJS.W($CLJS.Gj,zS)],null),$CLJS.Jc,"Schema for a valid distinct clause.",$CLJS.n(XV)?XV.H:null])):null));return $CLJS.n(a)?a:SQ}(),XV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof dW?new $CLJS.Fc(function(){return dW},$CLJS.od(Dha,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([IQ,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,
$CLJS.nk,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.dI,"null"],null),null),$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),eO,"metabase/mbql/schema.cljc",76,$CLJS.kI,1,973,973,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.kI,HS,$CLJS.W($CLJS.Gj,zS)],null),$CLJS.Jc,"Schema for a valid stddev clause.",$CLJS.n(dW)?dW.H:null])):null));return $CLJS.n(a)?a:eO}(),dW],null),new $CLJS.Q(null,
2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof YV?new $CLJS.Fc(function(){return YV},$CLJS.od(Xga,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([IQ,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.oH,"null"],null),null),$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),BO,"metabase/mbql/schema.cljc",60,$CLJS.gI,1,960,960,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gI,HS,$CLJS.W($CLJS.Gj,zS)],null),$CLJS.Jc,"Schema for a valid sum clause.",$CLJS.n(YV)?YV.H:null])):null));return $CLJS.n(a)?a:BO}(),YV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof ZV?new $CLJS.Fc(function(){return ZV},$CLJS.od(Lfa,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([IQ,
$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.oH,"null"],null),null),$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.Ut,"metabase/mbql/schema.cljc",60,$CLJS.Sn,1,961,961,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Sn,HS,$CLJS.W($CLJS.Gj,zS)],null),$CLJS.Jc,"Schema for a valid min clause.",$CLJS.n(ZV)?ZV.H:
null])):null));return $CLJS.n(a)?a:$CLJS.Ut}(),ZV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof $V?new $CLJS.Fc(function(){return $V},$CLJS.od(Gfa,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([IQ,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.oH,"null"],null),null),$CLJS.od(qS,new $CLJS.h(null,
1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.Zt,"metabase/mbql/schema.cljc",60,$CLJS.Zj,1,962,962,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Zj,HS,$CLJS.W($CLJS.Gj,zS)],null),$CLJS.Jc,"Schema for a valid max clause.",$CLJS.n($V)?$V.H:null])):null));return $CLJS.n(a)?a:$CLJS.Zt}(),$V],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&
"undefined"!==typeof hW?new $CLJS.Fc(function(){return hW},$CLJS.od(gga,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),SP,"metabase/mbql/schema.cljc",18,$CLJS.PH,1,994,994,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.PH,tga,$CLJS.W($CLJS.Gj,new $CLJS.Q(null,3,5,
$CLJS.R,[$CLJS.kt,xga,wO],null))],null),$CLJS.Jc,"Schema for a valid metric clause.",$CLJS.n(hW)?hW.H:null])):null));return $CLJS.n(a)?a:SP}(),hW],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof cW?new $CLJS.Fc(function(){return cW},$CLJS.od(Fga,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([IQ,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,GO,$CLJS.mi,$CLJS.U,
$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.oH,"null"],null),null),$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),KP,"metabase/mbql/schema.cljc",62,$CLJS.aI,1,970,970,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.aI,$CLJS.ko,$CLJS.W($CLJS.Gj,AQ)],null),$CLJS.Jc,"Schema for a valid share clause.",$CLJS.n(cW)?cW.H:null])):null));return $CLJS.n(a)?a:KP}(),cW],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=
KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof bW?new $CLJS.Fc(function(){return bW},$CLJS.od(Mfa,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([IQ,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.oH,"null"],null),null),$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),xO,"metabase/mbql/schema.cljc",68,$CLJS.cI,1,967,967,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.cI,$CLJS.ko,$CLJS.W($CLJS.Gj,AQ)],null),$CLJS.Jc,"Schema for a valid count-where clause.",$CLJS.n(bW)?bW.H:null])):null));return $CLJS.n(a)?a:xO}(),bW],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof aW?new $CLJS.Fc(function(){return aW},$CLJS.od(Rha,new $CLJS.h(null,1,[$CLJS.Rj,!0],
null)),$CLJS.Ag([IQ,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.oH,"null"],null),null),$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),PQ,"metabase/mbql/schema.cljc",66,$CLJS.pI,1,964,964,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.pI,HS,$CLJS.W($CLJS.Gj,zS),$CLJS.ko,$CLJS.W($CLJS.Gj,AQ)],null),$CLJS.Jc,"Schema for a valid sum-where clause.",
$CLJS.n(aW)?aW.H:null])):null));return $CLJS.n(a)?a:PQ}(),aW],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof RV?new $CLJS.Fc(function(){return RV},$CLJS.od(hO,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([IQ,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.oH,"null"],null),null),$CLJS.od(qS,
new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),RQ,"metabase/mbql/schema.cljc",61,$CLJS.CH,1,909,909,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.CH,$CLJS.Py,$CLJS.W($CLJS.Gj,AP),$CLJS.Yi,$CLJS.W($CLJS.Gj,$CLJS.W(JS,jR))],null),$CLJS.Jc,"Schema for a valid case clause.",$CLJS.n(RV)?RV.H:null])):null));return $CLJS.n(a)?a:RQ}(),RV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&
"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof fW?new $CLJS.Fc(function(){return fW},$CLJS.od(wha,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([IQ,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.QH,"null"],null),null),$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),RS,"metabase/mbql/schema.cljc",
68,$CLJS.hI,1,979,979,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.hI,HS,$CLJS.W($CLJS.Gj,zS)],null),$CLJS.Jc,"Schema for a valid median clause.",$CLJS.n(fW)?fW.H:null])):null));return $CLJS.n(a)?a:RS}(),fW],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof gW?new $CLJS.Fc(function(){return gW},$CLJS.od(Rga,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([IQ,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,
KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.QH,"null"],null),null),$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),oS,"metabase/mbql/schema.cljc",72,$CLJS.FH,1,982,982,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.FH,HS,$CLJS.W($CLJS.Gj,zS),$CLJS.FH,$CLJS.W($CLJS.Gj,sS)],null),$CLJS.Jc,"Schema for a valid percentile clause.",$CLJS.n(gW)?gW.H:null])):
null));return $CLJS.n(a)?a:oS}(),gW],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof eW?new $CLJS.Fc(function(){return eW},$CLJS.od(Wfa,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),$N,"metabase/mbql/schema.cljc",77,$CLJS.zH,1,976,976,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.zH,HS,$CLJS.W($CLJS.Gj,zS)],null),$CLJS.Jc,"Schema for a valid var clause.",$CLJS.n(eW)?eW.H:null])):null));return $CLJS.n(a)?a:$N}(),eW],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof UV?new $CLJS.Fc(function(){return UV},$CLJS.od(Ifa,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([IQ,
$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,LS,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.oH,"null"],null),null),$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),BQ,"metabase/mbql/schema.cljc",74,$CLJS.VH,1,947,947,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.VH,$CLJS.UH,$CLJS.W($CLJS.Gj,$CLJS.W(JS,gP))],null),$CLJS.Jc,"Schema for a valid cum-count clause.",
$CLJS.n(UV)?UV.H:null])):null));return $CLJS.n(a)?a:BQ}(),UV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof TV?new $CLJS.Fc(function(){return TV},$CLJS.od(iga,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([IQ,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,LS,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.oH,"null"],null),null),
$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.oi,"metabase/mbql/schema.cljc",70,$CLJS.Iz,1,946,946,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Iz,$CLJS.UH,$CLJS.W($CLJS.Gj,$CLJS.W(JS,gP))],null),$CLJS.Jc,"Schema for a valid count clause.",$CLJS.n(TV)?TV.H:null])):null));return $CLJS.n(a)?a:$CLJS.oi}(),TV],null)]))],null)],null));
$CLJS.Y(DR,CN($CLJS.VN,$CLJS.G(["aggregation",lS,"options",new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Yj,new $CLJS.h(null,1,[$CLJS.wu,":aggregation-options options"],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),PT],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.aG,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),PT],null)],null)])));
$CLJS.Y(IS,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.pt,new $CLJS.h(null,2,[$CLJS.wu,"aggregation clause or numeric expression",$CLJS.Gi,function(a){return $CLJS.n(BN($CLJS.VN,a))?$CLJS.VN:zP}],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.VN,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,DR],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[zP,lS],null)],null));$CLJS.Y(dO,CN($CLJS.RH,$CLJS.G(["field",pU])));var iW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,dO],null);$CLJS.Y(EQ,CN($CLJS.KH,$CLJS.G(["field",pU])));
jW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,EQ],null);
gia=DN($CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof iW?new $CLJS.Fc(function(){return iW},$CLJS.od(Aha,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),
kQ,"metabase/mbql/schema.cljc",15,$CLJS.RH,1,1049,1049,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.RH,$CLJS.UH,$CLJS.W($CLJS.Gj,aT)],null),$CLJS.Jc,"Schema for a valid asc clause.",$CLJS.n(iW)?iW.H:null])):null));return $CLJS.n(a)?a:kQ}(),iW],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof jW?new $CLJS.Fc(function(){return jW},$CLJS.od(rga,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([$CLJS.Ni,
$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),gS,"metabase/mbql/schema.cljc",16,$CLJS.KH,1,1050,1050,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.KH,$CLJS.UH,$CLJS.W($CLJS.Gj,aT)],null),$CLJS.Jc,"Schema for a valid desc clause.",$CLJS.n(jW)?jW.H:null])):null));return $CLJS.n(a)?a:gS}(),jW],null)]));
kW=new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.dj,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.bk,$CLJS.gO,$CLJS.XO,$CLJS.KN,$CLJS.fv,$CLJS.kD,$CLJS.cR],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.T,PT],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.aG,PT],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),PT],null)],null);
hia=new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vu,kW,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.dj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ns,$CLJS.gO],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.TN,PT],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.uR,ST],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vR,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),ST],null)],null)],null);
iia=new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vu,kW,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.dj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ns,$CLJS.XO],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ES,ST],null)],null)],null);
lW=new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vu,kW,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Wh,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),$CLJS.zt],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.yP,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),$CLJS.yt],null)],null)],null);jia=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,SS],null);
kia=new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vu,lW,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.dj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ns,$CLJS.KN],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.KN,mU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.rP,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,AO],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Yi,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ot,$CLJS.Oi,$CLJS.zt],null)],null)],null)],null);
$CLJS.mW=new $CLJS.Tg(null,new $CLJS.h(null,4,[$CLJS.cR,null,$CLJS.fv,null,$CLJS.yt,null,$CLJS.kD,null],null),null);lia=$CLJS.Wf.g(new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.bk],null),$CLJS.mW);
$CLJS.Y($R,new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.pt,new $CLJS.h(null,1,[$CLJS.Gi,$CLJS.dj],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.KN,kia],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.gO,hia],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.XO,iia],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.To,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vu,lW,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.dj,lia],null)],null)],null)],null)],null));
var nW=new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.hS,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.qt,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ot,PT,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$R],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ht,new $CLJS.h(null,1,[$CLJS.wu,"keys in template tag map must match the :name of their values"],null),function(a){return $CLJS.Ie(function(b){var c=$CLJS.H(b,0,null);b=$CLJS.H(b,1,null);return $CLJS.F.g(c,
$CLJS.T.h(b))},a)}],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.NP,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jt,PT],null)],null)],null),mia=new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vu,nW,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.iO,$CLJS.zt],null)],null)],null),oW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,HR],null),pW=new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.pt,new $CLJS.h(null,1,[$CLJS.Gi,function(a){return $CLJS.n($CLJS.oN($CLJS.wd,
$CLJS.KR)(a))?$CLJS.KR:$CLJS.$F}],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.KR,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vu,nW,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.KR,$CLJS.zt],null)],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$F,oW],null)],null),qW=new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.T,PT],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.UE,QT],null),new $CLJS.Q(null,2,5,$CLJS.R,[Zga,PT],null),new $CLJS.Q(null,3,5,$CLJS.R,
[$CLJS.eQ,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jt,RT],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.tP,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jt,$CLJS.Yj],null)],null)],null),rW=new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.kt,Zha,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.lt,new $CLJS.h(null,1,[$CLJS.wu,"'card__\x3cid\x3e' string Table ID"],null),/^card__[1-9]\d*$/],null)],null),nia=$CLJS.Wf.g(new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.bk],null),
new $CLJS.Tg(null,new $CLJS.h(null,4,[$CLJS.UG,null,$CLJS.CG,null,$CLJS.NG,null,$CLJS.RG,null],null),null)),sW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,QS],null);
$CLJS.Y(JP,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.qt,new $CLJS.Q(null,9,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.GQ,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),rW],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.jS,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),pW],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.rT,$CLJS.rV],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.oG,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),nia],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.DG,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,
4,5,$CLJS.R,[$CLJS.kt,new $CLJS.h(null,1,[$CLJS.wu,"Valid join `:fields`: `:all`, `:none`, or a sequence of `:field` clauses that have `:join-alias`."],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.bk,$CLJS.nA,$CLJS.WB],null),sW],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.HG,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),PT],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.BM,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jt,UT],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.sQ,new $CLJS.h(null,
1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.qj,qW],null)],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ht,new $CLJS.h(null,1,[$CLJS.wu,"Joins must have either a `source-table` or `source-query`, but not both."],null),$CLJS.oN($CLJS.pC($CLJS.GQ,$CLJS.jS),$CLJS.Ke($CLJS.oN($CLJS.GQ,$CLJS.jS)))],null)],null));var oia=$CLJS.R,tW;var uW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.qj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,JP],null)],null);
if($CLJS.vd(uW)&&$CLJS.F.g($CLJS.B(uW),$CLJS.qj)){var vW=$CLJS.z(uW);$CLJS.B(vW);var wW=$CLJS.C(vW),pia=$CLJS.wd($CLJS.B(wW))?wW:$CLJS.ce(null,wW),xW=$CLJS.z(pia),qia=$CLJS.B(xW),ria=$CLJS.C(xW);tW=$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.qj,$CLJS.S.j(qia,$CLJS.Sn,1)],null),ria)}else tW=new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.qt,uW,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ht,new $CLJS.h(null,1,[$CLJS.wu,"non-empty"],null),$CLJS.z],null)],null);
$CLJS.Y(LO,new $CLJS.Q(null,3,5,oia,[$CLJS.qt,tW,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ht,new $CLJS.h(null,1,[$CLJS.wu,"All join aliases must be unique."],null),function(a){return $CLJS.mN($CLJS.bf($CLJS.Ta,$CLJS.Ze.g($CLJS.HG,a)))}],null)],null));var sia=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,LO],null);
$CLJS.Y(QS,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ck,new $CLJS.h(null,1,[$CLJS.wu,"Distinct, non-empty sequence of Field clauses"],null),EN(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.qj,new $CLJS.h(null,1,[$CLJS.Sn,1],null),$CLJS.nU],null))],null));
$CLJS.Y(HR,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.qt,new $CLJS.Q(null,13,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.jS,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),pW],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.GQ,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),rW],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.JH,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.qj,new $CLJS.h(null,1,[$CLJS.Sn,1],null),$CLJS.AU],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.IH,new $CLJS.h(null,1,
[$CLJS.Ft,!0],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.qj,new $CLJS.h(null,1,[$CLJS.Sn,1],null),$CLJS.nU],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.AR,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ot,PT,$CLJS.SV],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.DG,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),sW],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.xH,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),$CLJS.rV],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.MT,new $CLJS.h(null,
1,[$CLJS.Ft,!0],null),TT],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.pT,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),EN(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.qj,new $CLJS.h(null,1,[$CLJS.Sn,1],null),gia],null))],null),new $CLJS.Q(null,3,5,$CLJS.R,[LR,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,2,5,$CLJS.R,[LR,ST],null),new $CLJS.Q(null,2,5,$CLJS.R,[aga,ST],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.eP,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),sia],
null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.sQ,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.qj,qW],null)],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ht,new $CLJS.h(null,1,[$CLJS.wu,"Query must specify either `:source-table` or `:source-query`, but not both."],null),function(a){return $CLJS.F.g(1,$CLJS.E($CLJS.Yl(a,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jS,$CLJS.GQ],null))))}],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ht,
new $CLJS.h(null,1,[$CLJS.wu,"Fields specified in `:breakout` should not be specified in `:fields`; this is implied."],null),function(a){var b=$CLJS.O(a);a=$CLJS.I.g(b,$CLJS.IH);b=$CLJS.I.g(b,$CLJS.DG);return $CLJS.sd($CLJS.zv.g($CLJS.Yg(a),$CLJS.Yg(b)))}],null)],null));
$CLJS.yW=$CLJS.Ag([$CLJS.oI,$CLJS.cR,FP,qP,tQ,DT,$P,$CLJS.CT,$CLJS.fv,ZP,oO,wS,sT,$CLJS.EO,oT,CP,$CLJS.yQ,XP,$CLJS.Qi,$CLJS.WP,WN,$CLJS.aP,$CLJS.VP,YQ,$CLJS.yt,$CLJS.kD,RP],[new $CLJS.h(null,1,[jQ,new $CLJS.Tg(null,new $CLJS.h(null,5,[$CLJS.oI,null,$CLJS.cR,null,$CLJS.fv,null,$CLJS.yt,null,$CLJS.kD,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.dj,$CLJS.cR,jQ,new $CLJS.Tg(null,new $CLJS.h(null,5,[$CLJS.oI,null,$CLJS.cR,null,$CLJS.CT,null,$CLJS.yQ,null,$CLJS.Qi,null],null),null)],null),new $CLJS.h(null,
3,[$CLJS.dj,$CLJS.Pj,$CLJS.hG,FS,jQ,new $CLJS.Tg(null,new $CLJS.h(null,1,[FP,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.dj,HT,$CLJS.hG,FS,jQ,new $CLJS.Tg(null,new $CLJS.h(null,5,[$CLJS.oI,null,qP,null,$CLJS.fv,null,$CLJS.Qi,null,WN,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.dj,$CLJS.Pj,$CLJS.hG,sO,jQ,new $CLJS.Tg(null,new $CLJS.h(null,1,[tQ,null],null),null)],null),new $CLJS.h(null,1,[jQ,new $CLJS.Tg(null,new $CLJS.h(null,1,[DT,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.dj,
HT,$CLJS.hG,qha,jQ,new $CLJS.Tg(null,new $CLJS.h(null,1,[$P,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.dj,$CLJS.cR,jQ,new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.CT,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.dj,HT,jQ,new $CLJS.Tg(null,new $CLJS.h(null,5,[$CLJS.oI,null,qP,null,$CLJS.fv,null,$CLJS.Qi,null,WN,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.dj,HT,$CLJS.hG,sO,jQ,new $CLJS.Tg(null,new $CLJS.h(null,1,[ZP,null],null),null)],null),new $CLJS.h(null,1,[jQ,new $CLJS.Tg(null,
new $CLJS.h(null,1,[oO,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.dj,$CLJS.Pj,$CLJS.hG,FS,jQ,new $CLJS.Tg(null,new $CLJS.h(null,8,[$CLJS.oI,null,DT,null,oO,null,wS,null,XP,null,$CLJS.Qi,null,WN,null,$CLJS.kD,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.dj,$CLJS.Pj,$CLJS.hG,sO,jQ,new $CLJS.Tg(null,new $CLJS.h(null,1,[sT,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.dj,$CLJS.cR,jQ,new $CLJS.Tg(null,new $CLJS.h(null,2,[$CLJS.CT,null,$CLJS.EO,null],null),null)],null),new $CLJS.h(null,
3,[$CLJS.dj,$CLJS.Pj,$CLJS.hG,sO,jQ,new $CLJS.Tg(null,new $CLJS.h(null,1,[oT,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.dj,$CLJS.Pj,$CLJS.hG,sO,jQ,new $CLJS.Tg(null,new $CLJS.h(null,1,[CP,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.dj,$CLJS.cR,jQ,new $CLJS.Tg(null,new $CLJS.h(null,5,[$CLJS.oI,null,$CLJS.cR,null,$CLJS.CT,null,$CLJS.yQ,null,$CLJS.Qi,null],null),null)],null),new $CLJS.h(null,1,[jQ,new $CLJS.Tg(null,new $CLJS.h(null,1,[XP,null],null),null)],null),new $CLJS.h(null,1,[jQ,
new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.Qi,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.dj,$CLJS.cR,jQ,new $CLJS.Tg(null,new $CLJS.h(null,2,[$CLJS.CT,null,$CLJS.WP,null],null),null)],null),new $CLJS.h(null,1,[jQ,new $CLJS.Tg(null,new $CLJS.h(null,1,[WN,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.dj,$CLJS.cR,jQ,new $CLJS.Tg(null,new $CLJS.h(null,2,[$CLJS.CT,null,$CLJS.aP,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.dj,$CLJS.cR,jQ,new $CLJS.Tg(null,new $CLJS.h(null,2,[$CLJS.CT,
null,$CLJS.VP,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.dj,HT,$CLJS.hG,FS,jQ,new $CLJS.Tg(null,new $CLJS.h(null,1,[YQ,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.dj,$CLJS.yt,jQ,new $CLJS.Tg(null,new $CLJS.h(null,3,[$CLJS.oI,null,$CLJS.Qi,null,$CLJS.yt,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.dj,$CLJS.Pj,jQ,new $CLJS.Tg(null,new $CLJS.h(null,8,[$CLJS.oI,null,DT,null,oO,null,wS,null,XP,null,$CLJS.Qi,null,WN,null,$CLJS.kD,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.dj,
HT,$CLJS.hG,sO,jQ,new $CLJS.Tg(null,new $CLJS.h(null,1,[RP,null],null),null)],null)]);$CLJS.Y(SS,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bk,new $CLJS.h(null,1,[$CLJS.wu,"valid parameter type"],null)],null),$CLJS.Pg($CLJS.yW)));$CLJS.Y(AO,$CLJS.Wf.g(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.bk,new $CLJS.h(null,1,[$CLJS.wu,"valid template tag widget type"],null),$CLJS.WB],null),$CLJS.Pg($CLJS.yW)));
$CLJS.Y(eS,CN(Sfa,$CLJS.G(["tag-name",new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.kt,PT,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Qi,PT],null)],null)],null)])));var zW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,eS],null);$CLJS.Y(ZN,CN($CLJS.KN,$CLJS.G(["target",new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.kt,$CLJS.nU,zW],null)])));var AW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,ZN],null);$CLJS.Y(VO,CN(UP,$CLJS.G(["target",zW])));
var BW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,VO],null),tia=new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.kt,$CLJS.nU,DN($CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof AW?new $CLJS.Fc(function(){return AW},$CLJS.od(Pga,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[$CLJS.od(qS,new $CLJS.h(null,
1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),PR,"metabase/mbql/schema.cljc",21,$CLJS.KN,1,1612,1612,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.KN,$CLJS.XK,$CLJS.W($CLJS.Gj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.kt,gP,iQ],null))],null),$CLJS.Jc,"Schema for a valid dimension clause.",$CLJS.n(AW)?AW.H:null])):null));return $CLJS.n(a)?a:PR}(),AW],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=KQ.h($CLJS.pd("undefined"!==typeof $CLJS.FN&&
"undefined"!==typeof GN&&"undefined"!==typeof HN&&"undefined"!==typeof BW?new $CLJS.Fc(function(){return BW},$CLJS.od(eha,new $CLJS.h(null,1,[$CLJS.Rj,!0],null)),$CLJS.Ag([$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,KQ,$CLJS.Ki,$CLJS.ej,$CLJS.nk,GO,$CLJS.mi,$CLJS.U,$CLJS.ek],[$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),IT,"metabase/mbql/schema.cljc",20,UP,1,1615,1615,new $CLJS.Q(null,3,5,$CLJS.R,[UP,$CLJS.XK,
$CLJS.W($CLJS.Gj,iQ)],null),$CLJS.Jc,"Schema for a valid variable clause.",$CLJS.n(BW)?BW.H:null])):null));return $CLJS.n(a)?a:IT}(),BW],null)]))],null),yN=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,HO],null);
$CLJS.Y(HO,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.qt,function(a){return new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.qt,a,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ht,new $CLJS.h(null,1,[$CLJS.wu,"Query must specify either `:native` or `:query`, but not both."],null),$CLJS.oN($CLJS.pC($CLJS.KR,$CLJS.iO),$CLJS.Ke($CLJS.oN($CLJS.KR,$CLJS.iO)))],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ht,new $CLJS.h(null,1,[$CLJS.wu,"Native queries must specify `:native`; MBQL queries must specify `:query`."],null),function(b){var c=
$CLJS.O(b);b=$CLJS.I.g(c,$CLJS.KR);var d=$CLJS.I.g(c,$CLJS.iO);c=$CLJS.I.g(c,$CLJS.dj);c=c instanceof $CLJS.K?c.T:null;switch(c){case "native":return b;case "query":return d;default:throw Error(["No matching clause: ",$CLJS.p.h(c)].join(""));}}],null)],null)}(new $CLJS.Q(null,10,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.vR,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.kt,new $CLJS.h(null,1,[$CLJS.wu,"valid Database ID"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.FM],null),new $CLJS.Q(null,
2,5,$CLJS.R,[$CLJS.X,$CLJS.AM],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.dj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.bk,$CLJS.iO,$CLJS.KR],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.KR,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),mia],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.iO,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),oW],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.WO,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.qj,new $CLJS.Q(null,
8,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.dj,jia],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),PT],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.XK,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),tia],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uj,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),$CLJS.zt],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),PT],null),new $CLJS.Q(null,3,5,$CLJS.R,[Yfa,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),
PT],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Wh,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),$CLJS.zt],null)],null)],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.DM,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,3,5,$CLJS.R,[Pha,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),bU],null)],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[Oga,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jt,
new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.qt,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,3,5,$CLJS.R,[QQ,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),TT],null),new $CLJS.Q(null,3,5,$CLJS.R,[zO,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),TT],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ht,new $CLJS.h(null,1,[$CLJS.wu,"max-results-bare-rows must be less or equal to than max-results"],null),function(a){var b=$CLJS.O(a);a=$CLJS.I.g(b,QQ);b=$CLJS.I.g(b,zO);return $CLJS.Sa($CLJS.n(a)?b:a)?!0:a>=b}],null)],
null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[Ega,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jt,new $CLJS.Q(null,8,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,3,5,$CLJS.R,[zha,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),$CLJS.yt],null),new $CLJS.Q(null,3,5,$CLJS.R,[Ufa,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),$CLJS.yt],null),new $CLJS.Q(null,3,5,$CLJS.R,[Dfa,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),$CLJS.yt],null),new $CLJS.Q(null,3,5,$CLJS.R,[$fa,new $CLJS.h(null,1,[$CLJS.Ft,
!0],null),$CLJS.yt],null),new $CLJS.Q(null,3,5,$CLJS.R,[rha,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jt,$CLJS.yt],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[Ffa,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jt,$CLJS.yt],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[Fha,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jt,$CLJS.yt],null)],null)],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.xA,new $CLJS.h(null,
1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jt,new $CLJS.Q(null,11,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.RO,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jt,new $CLJS.Q(null,15,5,$CLJS.R,[$CLJS.bk,dha,aha,$CLJS.NP,Qga,fha,Cga,Pfa,Vfa,dga,qga,oha,Xha,uga,mga],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[uha,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jt,ST],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[zga,
new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jt,ST],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ES,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jt,Yha],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[Qha,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jt,PT],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[Bga,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jt,ST],null)],null),new $CLJS.Q(null,
3,5,$CLJS.R,[Oha,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jt,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ot,$CLJS.zt,$CLJS.zt],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[vga,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jt,ST],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.QP,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.qj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ot,$CLJS.zt,
$CLJS.zt],null)],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.oM,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jt,$CLJS.zt],null)],null)],null)],null)],null)],null)),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ht,new $CLJS.h(null,1,[$CLJS.wu,"`:source-metadata` should be added in the same level as `:source-query` (i.e., the 'inner' MBQL query.)"],null),$CLJS.Ke($CLJS.sQ)],null)],null));var CW=$CLJS.xN(yN);
(function(){var a=yfa();return function(b){if($CLJS.n(CW.h?CW.h(b):CW.call(null,b)))return b;b=a.h?a.h(b):a.call(null,b);var c=$CLJS.uN(b);throw $CLJS.ai($CLJS.WF("Invalid query: {0}",$CLJS.G([$CLJS.Eh.l($CLJS.G([c]))])),new $CLJS.h(null,2,[$CLJS.tA,c,Jga,b],null));}})();