var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./clojure.walk.js");require("./no.en.core.js");
'use strict';var A2,mla,B2,F2,H2;A2=function(a,b,c,d,e){this.pattern=a;this.Dc=b;this.S=c;this.G=d;this.J=e;this.C=2230716170;this.I=139264};mla=function(a,b){return new A2(a,b,null,null,null)};B2=function(a,b){$CLJS.Gd($CLJS.Yg($CLJS.q(a)),b)||$CLJS.Gh.j(a,$CLJS.$d,b)};$CLJS.C2=function(a){if(null==a||"string"===typeof a)return a;if(a instanceof $CLJS.K||a instanceof $CLJS.w){var b=$CLJS.ge(a);return $CLJS.n(b)?[b,"/",$CLJS.Zg(a)].join(""):$CLJS.Zg(a)}return null};
F2=function(a){a=$CLJS.z($CLJS.P.g(D2,a));for(var b=null,c=0,d=0;;)if(d<c){var e=b.X(null,d);B2($CLJS.E2,e);d+=1}else if(a=$CLJS.z(a))b=a,$CLJS.zd(b)?(a=$CLJS.kc(b),c=$CLJS.lc(b),b=a,e=$CLJS.E(a),a=c,c=e):(e=$CLJS.B(b),B2($CLJS.E2,e),a=$CLJS.C(b),b=null,c=0),d=0;else break};
H2=function(a){a=$CLJS.z($CLJS.P.g(D2,a));for(var b=null,c=0,d=0;;)if(d<c){var e=b.X(null,d);B2(G2,e);d+=1}else if(a=$CLJS.z(a))b=a,$CLJS.zd(b)?(a=$CLJS.kc(b),c=$CLJS.lc(b),b=a,e=$CLJS.E(a),a=c,c=e):(e=$CLJS.B(b),B2(G2,e),a=$CLJS.C(b),b=null,c=0),d=0;else break};var G2,J2;$CLJS.g=A2.prototype;$CLJS.g.na=function(a,b){return this.$(null,b,null)};$CLJS.g.$=function(a,b,c){switch(b instanceof $CLJS.K?b.T:null){case "pattern":return this.pattern;case "replacement":return this.Dc;default:return $CLJS.I.j(this.G,b,c)}};$CLJS.g.Fb=function(a,b,c){return $CLJS.ab(function(d,e){var f=$CLJS.H(e,0,null);e=$CLJS.H(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.kh(b,function(d){return $CLJS.kh(b,$CLJS.qh,""," ","",c,d)},"#inflections.core.Rule{",", ","}",c,$CLJS.Ye.g(new $CLJS.Q(null,2,5,$CLJS.R,[new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.aC,this.pattern],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bC,this.Dc],null)],null),this.G))};$CLJS.g.Ca=function(){return new $CLJS.Fu(this,2,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.aC,$CLJS.bC],null),$CLJS.n(this.G)?$CLJS.qc(this.G):$CLJS.Fe())};$CLJS.g.O=function(){return this.S};
$CLJS.g.ha=function(){return 2+$CLJS.E(this.G)};$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=-1327743444^$CLJS.Pc(this)};$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.g(this.pattern,b.pattern)&&$CLJS.F.g(this.Dc,b.Dc)&&$CLJS.F.g(this.G,b.G)};
$CLJS.g.Gb=function(a,b){return $CLJS.Gd(new $CLJS.Tg(null,new $CLJS.h(null,2,[$CLJS.bC,null,$CLJS.aC,null],null),null),b)?$CLJS.wk.g($CLJS.Jb($CLJS.Wf.g($CLJS.N,this),this.S),b):new A2(this.pattern,this.Dc,this.S,$CLJS.De($CLJS.wk.g(this.G,b)),null)};$CLJS.g.Va=function(a,b){switch(b instanceof $CLJS.K?b.T:null){case "pattern":case "replacement":return!0;default:return $CLJS.Gd(this.G,b)}};
$CLJS.g.ma=function(a,b,c){return $CLJS.n($CLJS.fe.g?$CLJS.fe.g($CLJS.aC,b):$CLJS.fe.call(null,$CLJS.aC,b))?new A2(c,this.Dc,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g($CLJS.bC,b):$CLJS.fe.call(null,$CLJS.bC,b))?new A2(this.pattern,c,this.S,this.G,null):new A2(this.pattern,this.Dc,this.S,$CLJS.S.j(this.G,b,c),null)};$CLJS.g.ga=function(){return $CLJS.z($CLJS.Ye.g(new $CLJS.Q(null,2,5,$CLJS.R,[new $CLJS.Pf($CLJS.aC,this.pattern),new $CLJS.Pf($CLJS.bC,this.Dc)],null),this.G))};
$CLJS.g.P=function(a,b){return new A2(this.pattern,this.Dc,b,this.G,this.J)};$CLJS.g.ja=function(a,b){return $CLJS.yd(b)?this.ma(null,$CLJS.kd(b,0),$CLJS.kd(b,1)):$CLJS.ab($CLJS.jb,this,b)};var D2=function D2(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return D2.l(0<c.length?new $CLJS.y(c.slice(0),0,null):null)};D2.l=function(a){return $CLJS.Ze.g(function(b){return $CLJS.P.g(mla,b)},$CLJS.fu(2,2,a))};D2.A=0;D2.B=function(a){return this.l($CLJS.z(a))};
$CLJS.I2=$CLJS.Oe(new $CLJS.Tg(null,new $CLJS.h(null,81,["gossip",null,"sadness",null,"space",null,"happiness",null,"blood",null,"silver",null,"cotton",null,"species",null,"mist",null,"paper",null,"education",null,"wine",null,"up",null,"food",null,"sugar",null,"gold",null,"literature",null,"pork",null,"lightning",null,"sheep",null,"shopping",null,"fresh",null,"news",null,"pepper",null,"experience",null,"milk",null,"honey",null,"oxygen",null,"fiction",null,"luck",null,"vinegar",null,"coffee",null,
"ground",null,"peanut",null,"confusion",null,"tennis",null,"power",null,"meat",null,"butter",null,"music",null,"luggage",null,"love",null,"grass",null,"knowledge",null,"time",null,"cheese",null,"jam",null,"thunder",null,"electricity",null,"gum",null,"sunshine",null,"history",null,"snow",null,"money",null,"wool",null,"rice",null,"series",null,"liquid",null,"equipment",null,"pressure",null,"oil",null,"information",null,"steam",null,"chewing",null,"petrol",null,"research",null,"patience",null,"toothpaste",
null,"speed",null,"entertainment",null,"wood",null,"tea",null,"art",null,"washing",null,"forgiveness",null,"traffic",null,"alcohol",null,"ice",null,"homework",null,"fish",null,"air",null],null),null));$CLJS.E2=$CLJS.Oe($CLJS.tf);G2=$CLJS.Oe($CLJS.tf);J2=$CLJS.Oe(function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return $CLJS.ab($CLJS.jb,$CLJS.Xk,0<b.length?new $CLJS.y(b.slice(0),0,null):null)}());
F2($CLJS.G([/$/i,"s",/s$/i,"s",/(ax|test)is$/i,"$1es",/(octop|vir)us$/i,"$1i",/(alias|status)$/i,"$1es",/(bu)s$/i,"$1ses",/(buffal|tomat)o$/i,"$1oes",/([ti])um$/i,"$1a",/sis$/i,"ses",/(?:([^f])fe|([lr])f)$/i,"$1$2ves",/(hive)$/i,"$1s",/([^aeiouy]|qu)y$/i,"$1ies",/(x|ch|ss|sh)$/i,"$1es",/(matr|vert|ind)(?:ix|ex)$/i,"$1ices",/([m|l])ouse$/i,"$1ice",/^(ox)$/i,"$1en",/(quiz)$/i,"$1zes"]));
H2($CLJS.G([/s$/i,"",/(ss)$/i,"$1",/(n)ews$/i,"$1ews",/([ti])a$/i,"$1um",/((a)naly|(b)a|(d)iagno|(p)arenthe|(p)rogno|(s)ynop|(t)he)(sis|ses)$/i,"$1$2sis",/(^analy)(sis|ses)$/i,"$1sis",/([^f])ves$/i,"$1fe",/(hive)s$/i,"$1",/(tive)s$/i,"$1",/([lr])ves$/i,"$1f",/([^aeiouy]|qu)ies$/i,"$1y",/(s)eries$/i,"$1eries",/(m)ovies$/i,"$1ovie",/(x|ch|ss|sh)es$/i,"$1",/([m|l])ice$/i,"$1ouse",/(bus)(es)?$/i,"$1",/(o)es$/i,"$1",/(shoe)s$/i,"$1",/(cris|ax|test)(is|es)$/i,"$1is",/(octop|vir)(us|i)$/i,"$1us",/(alias|status)(es)?$/i,
"$1",/^(ox)en/i,"$1",/(vert|ind)ices$/i,"$1ex",/(matr)ices$/i,"$1ix",/(quiz)zes$/i,"$1",/(database)s$/i,"$1"]));
$CLJS.eh($CLJS.Ze.g(function(a){var b=$CLJS.B(a);a=$CLJS.hd(a);b=$CLJS.C2(b).toLowerCase();a=$CLJS.C2(a).toLowerCase();$CLJS.Gh.j($CLJS.I2,$CLJS.xk,$CLJS.C2(b).toLowerCase());$CLJS.Gh.j($CLJS.I2,$CLJS.xk,$CLJS.C2(a).toLowerCase());H2($CLJS.G([$CLJS.hh(["^",a,"$"].join("")),b]));F2($CLJS.G([$CLJS.hh(["^",b,"$"].join("")),a]));$CLJS.Gh.j(J2,$CLJS.$d,b);return $CLJS.Gh.j(J2,$CLJS.$d,a)},new $CLJS.Q(null,14,5,$CLJS.R,[new $CLJS.Q(null,2,5,$CLJS.R,["amenity","amenities"],null),new $CLJS.Q(null,2,5,$CLJS.R,
["child","children"],null),new $CLJS.Q(null,2,5,$CLJS.R,["cow","kine"],null),new $CLJS.Q(null,2,5,$CLJS.R,["foot","feet"],null),new $CLJS.Q(null,2,5,$CLJS.R,["louse","lice"],null),new $CLJS.Q(null,2,5,$CLJS.R,["mailman","mailmen"],null),new $CLJS.Q(null,2,5,$CLJS.R,["man","men"],null),new $CLJS.Q(null,2,5,$CLJS.R,["mouse","mice"],null),new $CLJS.Q(null,2,5,$CLJS.R,["move","moves"],null),new $CLJS.Q(null,2,5,$CLJS.R,["ox","oxen"],null),new $CLJS.Q(null,2,5,$CLJS.R,["person","people"],null),new $CLJS.Q(null,
2,5,$CLJS.R,["sex","sexes"],null),new $CLJS.Q(null,2,5,$CLJS.R,["tooth","teeth"],null),new $CLJS.Q(null,2,5,$CLJS.R,["woman","women"],null)],null)));