var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.aggregation.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var N4,P4,Q4,R4,bna;N4=function(a,b,c){var d=$CLJS.gY(a,b);d=$CLJS.O(d);d=$CLJS.I.g(d,$CLJS.JH);d=$CLJS.fJ($CLJS.Ek.j($CLJS.Vg([c]),$CLJS.kG,$CLJS.hd),d);if(!$CLJS.n(d))throw $CLJS.ai($CLJS.WF("No aggregation with uuid {0}",$CLJS.G([c])),new $CLJS.h(null,3,[$CLJS.Tj,c,$CLJS.iO,a,$CLJS.mY,b],null));return d};$CLJS.O4=function(a){return $CLJS.e3($CLJS.gI,new $CLJS.Q(null,1,5,$CLJS.R,[a],null))};$CLJS.ana=new $CLJS.K(null,"aggregation-index","aggregation-index",-1057045587);
P4=new $CLJS.K("metabase.lib.aggregation","unary-aggregation","metabase.lib.aggregation/unary-aggregation",1064133592);Q4=new $CLJS.K("metabase.lib.aggregation","count-aggregation","metabase.lib.aggregation/count-aggregation",-21314018);R4=new $CLJS.K("metabase.lib.aggregation","aggregation","metabase.lib.aggregation/aggregation",1701573815);bna=new $CLJS.K(null,"aggregation-operator","aggregation-operator",-1978700834);$CLJS.U1.m(null,$CLJS.JH,function(a,b){var c=$CLJS.De($CLJS.JH.h($CLJS.gY(a,b)));return $CLJS.n(c)?$CLJS.f3($CLJS.vG("and"),function(){return function f(e){return new $CLJS.le(null,function(){for(;;){var k=$CLJS.z(e);if(k){if($CLJS.zd(k)){var l=$CLJS.kc(k),m=$CLJS.E(l),t=$CLJS.oe(m);a:for(var u=0;;)if(u<m){var v=$CLJS.kd(l,u);v=$CLJS.t1.v(a,b,v,$CLJS.u1);t.add(v);u+=1}else{l=!0;break a}return l?$CLJS.re($CLJS.te(t),f($CLJS.lc(k))):$CLJS.re($CLJS.te(t),null)}t=$CLJS.B(k);return $CLJS.ce($CLJS.t1.v(a,
b,t,$CLJS.u1),f($CLJS.Kc(k)))}return null}},null,null)}(c)}()):null});$CLJS.X1.m(null,$CLJS.JH,function(a,b,c){$CLJS.H(c,0,null);var d=$CLJS.H(c,1,null),e=$CLJS.O(d);d=$CLJS.I.g(e,$CLJS.gD);e=$CLJS.I.g(e,$CLJS.xi);c=$CLJS.H(c,2,null);c=N4(a,b,c);return $CLJS.Wk.l($CLJS.G([$CLJS.s1.j(a,b,c),new $CLJS.h(null,2,[$CLJS.rM,$CLJS.KM,$CLJS.P1,$CLJS.kG.h($CLJS.hd(c))],null),$CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.gD,d],null):null,$CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.xi,e],null):null]))});
$CLJS.R1.m(null,$CLJS.JH,function(a,b,c,d){$CLJS.H(c,0,null);$CLJS.H(c,1,null);c=$CLJS.H(c,2,null);return $CLJS.t1.v(a,b,N4(a,b,c),d)});$CLJS.cH(Q4,R4);
for(var S4=$CLJS.z(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Iz,$CLJS.VH],null)),T4=null,U4=0,V4=0;;)if(V4<U4){var cna=T4.X(null,V4);$CLJS.cH(cna,Q4);V4+=1}else{var W4=$CLJS.z(S4);if(W4){var X4=W4;if($CLJS.zd(X4)){var Y4=$CLJS.kc(X4),dna=$CLJS.lc(X4),ena=Y4,fna=$CLJS.E(Y4);S4=dna;T4=ena;U4=fna}else{var gna=$CLJS.B(X4);$CLJS.cH(gna,Q4);S4=$CLJS.C(X4);T4=null;U4=0}V4=0}else break}
$CLJS.R1.m(null,Q4,function(a,b,c,d){var e=$CLJS.H(c,0,null);$CLJS.H(c,1,null);c=$CLJS.H(c,2,null);if($CLJS.n(c))switch(a=$CLJS.t1.v(a,b,c,d),e=e instanceof $CLJS.K?e.T:null,e){case "count":return $CLJS.WF("Count of {0}",$CLJS.G([a]));case "cum-count":return $CLJS.WF("Cumulative count of {0}",$CLJS.G([a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}else switch(e=e instanceof $CLJS.K?e.T:null,e){case "count":return $CLJS.vG("Count");case "cum-count":return $CLJS.vG("Cumulative count");
default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});$CLJS.S1.m(null,Q4,function(a,b,c){a=$CLJS.H(c,0,null);a=a instanceof $CLJS.K?a.T:null;switch(a){case "count":return"count";case "cum-count":return"cum_count";default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}});$CLJS.X1.m(null,Q4,function(a,b,c){var d=$CLJS.S.j,e=$CLJS.Uh($CLJS.X1,R4);a=e.j?e.j(a,b,c):e.call(null,a,b,c);return d.call($CLJS.S,a,$CLJS.Lj,$CLJS.IE)});$CLJS.R1.m(null,$CLJS.CH,function(){return $CLJS.vG("Case")});
$CLJS.S1.m(null,$CLJS.CH,function(){return"case"});$CLJS.cH(P4,R4);for(var Z4=$CLJS.z(new $CLJS.Q(null,9,5,$CLJS.R,[$CLJS.OH,$CLJS.rI,$CLJS.yH,$CLJS.Zj,$CLJS.hI,$CLJS.Sn,$CLJS.kI,$CLJS.gI,$CLJS.zH],null)),$4=null,a5=0,b5=0;;)if(b5<a5){var hna=$4.X(null,b5);$CLJS.cH(hna,P4);b5+=1}else{var c5=$CLJS.z(Z4);if(c5){var d5=c5;if($CLJS.zd(d5)){var e5=$CLJS.kc(d5),ina=$CLJS.lc(d5),jna=e5,kna=$CLJS.E(e5);Z4=ina;$4=jna;a5=kna}else{var lna=$CLJS.B(d5);$CLJS.cH(lna,P4);Z4=$CLJS.C(d5);$4=null;a5=0}b5=0}else break}
$CLJS.S1.m(null,P4,function(a,b,c){a=$CLJS.H(c,0,null);$CLJS.H(c,1,null);$CLJS.H(c,2,null);c=a instanceof $CLJS.K?a.T:null;switch(c){case "avg":return"avg";case "cum-sum":return"sum";case "distinct":return"count";case "max":return"max";case "median":return"median";case "min":return"min";case "stddev":return"stddev";case "sum":return"sum";case "var":return"var";default:throw Error(["No matching clause: ",$CLJS.p.h(c)].join(""));}});
$CLJS.R1.m(null,P4,function(a,b,c,d){var e=$CLJS.H(c,0,null);$CLJS.H(c,1,null);c=$CLJS.H(c,2,null);a=$CLJS.t1.v(a,b,c,d);e=e instanceof $CLJS.K?e.T:null;switch(e){case "avg":return $CLJS.WF("Average of {0}",$CLJS.G([a]));case "cum-sum":return $CLJS.WF("Cumulative sum of {0}",$CLJS.G([a]));case "distinct":return $CLJS.WF("Distinct values of {0}",$CLJS.G([a]));case "max":return $CLJS.WF("Max of {0}",$CLJS.G([a]));case "median":return $CLJS.WF("Median of {0}",$CLJS.G([a]));case "min":return $CLJS.WF("Min of {0}",
$CLJS.G([a]));case "stddev":return $CLJS.WF("Standard deviation of {0}",$CLJS.G([a]));case "sum":return $CLJS.WF("Sum of {0}",$CLJS.G([a]));case "var":return $CLJS.WF("Variance of {0}",$CLJS.G([a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});$CLJS.R1.m(null,$CLJS.FH,function(a,b,c,d){$CLJS.H(c,0,null);$CLJS.H(c,1,null);var e=$CLJS.H(c,2,null);c=$CLJS.H(c,3,null);return $CLJS.WF("{0}th percentile of {1}",$CLJS.G([c,$CLJS.t1.v(a,b,e,d)]))});$CLJS.S1.m(null,$CLJS.FH,function(){return"percentile"});
$CLJS.cH($CLJS.FH,R4);$CLJS.R1.m(null,$CLJS.pI,function(a,b,c,d){$CLJS.H(c,0,null);$CLJS.H(c,1,null);var e=$CLJS.H(c,2,null);$CLJS.H(c,3,null);return $CLJS.WF("Sum of {0} matching condition",$CLJS.G([$CLJS.t1.v(a,b,e,d)]))});$CLJS.S1.m(null,$CLJS.pI,function(a,b,c){$CLJS.H(c,0,null);$CLJS.H(c,1,null);var d=$CLJS.H(c,2,null);$CLJS.H(c,3,null);return["sum_where_",$CLJS.p.h($CLJS.T1.j(a,b,d))].join("")});$CLJS.cH($CLJS.pI,R4);$CLJS.R1.m(null,$CLJS.aI,function(){return $CLJS.vG("Share of rows matching condition")});
$CLJS.S1.m(null,$CLJS.aI,function(){return"share"});$CLJS.cH($CLJS.aI,R4);$CLJS.R1.m(null,$CLJS.cI,function(){return $CLJS.vG("Count of rows matching condition")});$CLJS.S1.m(null,$CLJS.cI,function(){return"count-where"});$CLJS.cH($CLJS.cI,R4);
$CLJS.X1.m(null,R4,function(a,b,c){$CLJS.H(c,0,null);$CLJS.H(c,1,null);var d=$CLJS.H(c,2,null),e=$CLJS.Wk.l;d=$CLJS.n(d)?$CLJS.Yl($CLJS.s1.j(a,b,d),new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.DM],null)):null;var f=$CLJS.Uh($CLJS.X1,$CLJS.Wh);a=f.j?f.j(a,b,c):f.call(null,a,b,c);return e.call($CLJS.Wk,$CLJS.G([d,a]))});
$CLJS.mna=function(){function a(d){return $CLJS.e3($CLJS.Iz,new $CLJS.Q(null,1,5,$CLJS.R,[d],null))}function b(){return $CLJS.e3($CLJS.Iz,$CLJS.tf)}var c=null;c=function(d){switch(arguments.length){case 0:return b.call(this);case 1:return a.call(this,d)}throw Error("Invalid arity: "+arguments.length);};c.o=b;c.h=a;return c}();$CLJS.QX.m(null,$CLJS.JH,function(a){return a});
$CLJS.f5=function(){function a(d,e,f){for(;;)if($CLJS.F.g($CLJS.TD(f),$CLJS.vM))f=$CLJS.SX(f);else return $CLJS.O2(d,e,$CLJS.JH,f)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.g5=function(){function a(d,e){return $CLJS.De($CLJS.JH.h($CLJS.gY(d,e)))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.h5=function(){function a(d,e){var f=$CLJS.De($CLJS.JH.h($CLJS.gY(d,e)));return null==f?null:$CLJS.Wf.j($CLJS.tf,$CLJS.Ze.h(function(k){var l=$CLJS.s1.j(d,e,k),m=$CLJS.S.l,t=$CLJS.gD.h(l);return m.call($CLJS.S,$CLJS.c3(l,$CLJS.xi,$CLJS.n(t)?t:$CLJS.rj),$CLJS.rM,$CLJS.KM,$CLJS.G([$CLJS.P1,$CLJS.kG.h($CLJS.hd(k))]))}),f)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+
arguments.length);};c.h=b;c.g=a;return c}();$CLJS.R1.m(null,$CLJS.DI,function(a,b,c){a=$CLJS.O(c);a=$CLJS.I.g(a,$CLJS.zI);return $CLJS.aG.h(a.o?a.o():a.call(null))});$CLJS.Y1.m(null,$CLJS.DI,function(a,b,c){var d=$CLJS.O(c);a=$CLJS.I.g(d,$CLJS.NH);b=$CLJS.I.g(d,$CLJS.zI);c=$CLJS.I.g(d,$CLJS.yI);d=$CLJS.I.g(d,$CLJS.l0);a=$CLJS.S.l(b.o?b.o():b.call(null),$CLJS.O1,$CLJS.NC(a),$CLJS.G([$CLJS.M1,c]));return null!=d?$CLJS.S.j(a,$CLJS.B1,d):a});
$CLJS.nna=function(){function a(d,e){var f=function(){var m=$CLJS.XB.h($CLJS.i2($CLJS.o1(d)));return $CLJS.n(m)?m:$CLJS.Ug}(),k=$CLJS.gY(d,e),l=$CLJS.b2.j(d,e,k);return $CLJS.De($CLJS.Wf.j($CLJS.tf,$CLJS.Ek.j($CLJS.af(function(m){m=$CLJS.BI.h(m);var t=null==m;return t?t:f.h?f.h(m):f.call(null,m)}),$CLJS.Ql(function(m){m=$CLJS.O(m);var t=$CLJS.I.g(m,$CLJS.yI),u=$CLJS.I.g(m,$CLJS.wI);if($CLJS.Sa(t))return m;if($CLJS.F.g(u,$CLJS.zt))return $CLJS.S.j(m,$CLJS.dY,l);t=$CLJS.De($CLJS.b3(function(v){return $CLJS.W0(u,
v)},l));return $CLJS.n(t)?$CLJS.S.j(m,$CLJS.dY,t):null}),$CLJS.Ze.h(function(m){return $CLJS.S.j(m,$CLJS.ov,$CLJS.DI)})),$CLJS.EI))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.ona=function(){function a(d,e){return $CLJS.RX(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.NH.h(d),$CLJS.N,$CLJS.TX.h(e)],null))}function b(d){if($CLJS.Sa($CLJS.yI.h(d)))return $CLJS.RX(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.NH.h(d),$CLJS.N],null));var e=$CLJS.NH.h(d);e=$CLJS.ha.g?$CLJS.ha.g("aggregation operator %s requires an argument",e):$CLJS.ha.call(null,"aggregation operator %s requires an argument",e);throw $CLJS.ai(e,new $CLJS.h(null,1,[bna,d],null));}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,
d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();