var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./malli.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.literal.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.util.malli.registry.js");require("./shadow.js.shim.module$moment.js");require("./shadow.js.shim.module$moment_timezone.js");
'use strict';var rK,tK,vK,HK,LK,TK;$CLJS.qK=new $CLJS.K(null,"get-month","get-month",-369374731);rK=new $CLJS.K("metabase.lib.schema.expression.temporal","absolute-datetime.options","metabase.lib.schema.expression.temporal/absolute-datetime.options",-925669633);$CLJS.sK=new $CLJS.K(null,"convert-timezone","convert-timezone",-124803001);tK=new $CLJS.K("metabase.lib.schema.expression.temporal","temporal-extract.unit","metabase.lib.schema.expression.temporal/temporal-extract.unit",339336486);
$CLJS.uK=new $CLJS.K(null,"iso","iso",-1366207543);vK=new $CLJS.K("metabase.lib.schema.expression.temporal","absolute-datetime.base-type","metabase.lib.schema.expression.temporal/absolute-datetime.base-type",-904703365);$CLJS.wK=new $CLJS.K(null,"get-second","get-second",-2065946318);$CLJS.xK=new $CLJS.K(null,"get-year","get-year",-936011274);$CLJS.yK=new $CLJS.K(null,"absolute-datetime","absolute-datetime",-560340465);
$CLJS.zK=new $CLJS.K("lib.type-of","type-is-temporal-type-of-first-arg","lib.type-of/type-is-temporal-type-of-first-arg",76272405);$CLJS.AK=new $CLJS.K(null,"week-of-year-iso","week-of-year-iso",-1827744293);$CLJS.BK=new $CLJS.K(null,"datetime-diff","datetime-diff",1133112155);$CLJS.CK=new $CLJS.K(null,"interval","interval",1708495417);$CLJS.DK=new $CLJS.K(null,"us","us",746429226);$CLJS.EK=new $CLJS.K(null,"relative-datetime","relative-datetime",1285169278);
$CLJS.FK=new $CLJS.K(null,"datetime","datetime",494675702);$CLJS.GK=new $CLJS.K(null,"now","now",-1650525531);HK=new $CLJS.K("metabase.lib.schema.expression.temporal","temporal-extract.week-mode","metabase.lib.schema.expression.temporal/temporal-extract.week-mode",430590916);$CLJS.IK=new $CLJS.K(null,"instance","instance",-2121349050);$CLJS.JK=new $CLJS.K(null,"get-day","get-day",127568857);$CLJS.KK=new $CLJS.K(null,"get-week","get-week",-888059349);
LK=new $CLJS.K("metabase.lib.schema.expression.temporal","relative-datetime.amount","metabase.lib.schema.expression.temporal/relative-datetime.amount",7216442);$CLJS.MK=new $CLJS.K(null,"datetime-add","datetime-add",209603411);$CLJS.NK=new $CLJS.K(null,"get-minute","get-minute",1468086343);$CLJS.OK=new $CLJS.K(null,"get-day-of-week","get-day-of-week",2010140752);$CLJS.PK=new $CLJS.K("metabase.lib.schema.expression.temporal","timezone-id","metabase.lib.schema.expression.temporal/timezone-id",-1909635091);
$CLJS.QK=new $CLJS.K(null,"temporal-extract","temporal-extract",1781686405);$CLJS.RK=new $CLJS.K(null,"get-hour","get-hour",622714059);$CLJS.SK=new $CLJS.K(null,"datetime-subtract","datetime-subtract",275307697);TK=new $CLJS.K("metabase.lib.schema.expression.temporal","get-week-mode","metabase.lib.schema.expression.temporal/get-week-mode",1489967652);$CLJS.UK=new $CLJS.K(null,"get-quarter","get-quarter",1327778593);$CLJS.VK=new $CLJS.K(null,"week-of-year-us","week-of-year-us",849423511);
$CLJS.WK=new $CLJS.K(null,"week-of-year-instance","week-of-year-instance",2047974624);$CLJS.XK=new $CLJS.K(null,"target","target",253001721);$CLJS.jH($CLJS.CK,$CLJS.G([$CLJS.mu,$CLJS.SE,$CLJS.pi,$CLJS.PI]));$CLJS.xG.m(null,$CLJS.zK,function(a){$CLJS.H(a,0,null);$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);a=$CLJS.yG(a);return $CLJS.ud(a)?(a=$CLJS.zv.g(a,new $CLJS.Tg(null,new $CLJS.h(null,2,[$CLJS.HE,null,$CLJS.fF,null],null),null)),$CLJS.F.g($CLJS.E(a),1)?$CLJS.B(a):a):a});$CLJS.cH($CLJS.zK,$CLJS.PG);
for(var YK=$CLJS.z(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.MK,$CLJS.SK],null)),ZK=null,$K=0,aL=0;;)if(aL<$K){var bL=ZK.X(null,aL);$CLJS.jH(bL,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.GG],null),$CLJS.pi,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.PI],null)]));$CLJS.cH(bL,$CLJS.zK);aL+=1}else{var cL=$CLJS.z(YK);if(cL){var dL=cL;if($CLJS.zd(dL)){var eL=$CLJS.kc(dL),Gea=$CLJS.lc(dL),Hea=eL,Iea=$CLJS.E(eL);YK=Gea;ZK=Hea;$K=Iea}else{var fL=$CLJS.B(dL);$CLJS.jH(fL,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,
[$CLJS.X,$CLJS.GG],null),$CLJS.pi,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.PI],null)]));$CLJS.cH(fL,$CLJS.zK);YK=$CLJS.C(dL);ZK=null;$K=0}aL=0}else break}
for(var gL=$CLJS.z(new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.xK,$CLJS.qK,$CLJS.JK,$CLJS.RK,$CLJS.NK,$CLJS.wK,$CLJS.UK],null)),hL=null,iL=0,jL=0;;)if(jL<iL){var Jea=hL.X(null,jL);$CLJS.jH(Jea,$CLJS.G([$CLJS.mu,$CLJS.nj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.GG],null)],null)]));jL+=1}else{var kL=$CLJS.z(gL);if(kL){var lL=kL;if($CLJS.zd(lL)){var mL=$CLJS.kc(lL),Kea=$CLJS.lc(lL),Lea=mL,Mea=$CLJS.E(mL);gL=Kea;hL=Lea;iL=Mea}else{var Nea=$CLJS.B(lL);$CLJS.jH(Nea,$CLJS.G([$CLJS.mu,
$CLJS.nj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.GG],null)],null)]));gL=$CLJS.C(lL);hL=null;iL=0}jL=0}else break}$CLJS.jH($CLJS.BK,$CLJS.G([$CLJS.mu,$CLJS.nj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.GG],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.GG],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.MI],null)]));
for(var nL=$CLJS.z(new $CLJS.Tg(null,new $CLJS.h(null,8,[$CLJS.UK,null,$CLJS.NK,null,$CLJS.RK,null,$CLJS.OK,null,$CLJS.wK,null,$CLJS.qK,null,$CLJS.xK,null,$CLJS.JK,null],null),null)),oL=null,pL=0,qL=0;;)if(qL<pL){var Oea=oL.X(null,qL);$CLJS.jH(Oea,$CLJS.G([$CLJS.mu,$CLJS.nj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.GG],null)],null)]));qL+=1}else{var rL=$CLJS.z(nL);if(rL){var sL=rL;if($CLJS.zd(sL)){var tL=$CLJS.kc(sL),Pea=$CLJS.lc(sL),Qea=tL,Rea=$CLJS.E(tL);
nL=Pea;oL=Qea;pL=Rea}else{var Sea=$CLJS.B(sL);$CLJS.jH(Sea,$CLJS.G([$CLJS.mu,$CLJS.nj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.GG],null)],null)]));nL=$CLJS.C(sL);oL=null;pL=0}qL=0}else break}$CLJS.Y(TK,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.bk,$CLJS.uK,$CLJS.DK,$CLJS.IK],null));
$CLJS.lH($CLJS.KK,$CLJS.G([$CLJS.mu,$CLJS.nj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.FK,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.GG],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ri,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ss,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,TK],null)],null)],null)],null)]));
$CLJS.Y($CLJS.PK,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.qt,$CLJS.eG,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.kt,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bk,new $CLJS.h(null,2,[$CLJS.wu,"valid timezone ID",$CLJS.yu,function(a){a=$CLJS.O(a);a=$CLJS.I.g(a,$CLJS.uj);return["invalid timezone ID: ",$CLJS.Eh.l($CLJS.G([a]))].join("")}],null)],null),$CLJS.GI($CLJS.Hd,$CLJS.pK.tz.names())),$CLJS.bK],null)],null));
$CLJS.lH($CLJS.sK,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.FK,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.GG],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.XK,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.PK],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.RB,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ss,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.PK],null)],null)],null)],
null)]));$CLJS.cH($CLJS.sK,$CLJS.zK);$CLJS.jH($CLJS.GK,$CLJS.G([$CLJS.mu,$CLJS.KE]));$CLJS.Y(vK,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.qt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.mG],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ht,new $CLJS.h(null,1,[$CLJS.wu,":absolute-datetime base-type must derive from :type/Date or :type/DateTime"],null),function(a){return $CLJS.Je(function(b){return $CLJS.gE(a,b)},new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.HE,$CLJS.fF],null))}],null)],null));
$CLJS.Y(rK,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vu,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.jG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gD,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,vK],null)],null)],null)],null));
$CLJS.vI.g($CLJS.yK,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.wj,new $CLJS.h(null,1,[$CLJS.wu,"valid :absolute-datetime clause"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ns,$CLJS.yK],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,rK],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.at,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.wj,new $CLJS.h(null,1,[$CLJS.wu,":absolute-datetime literal and unit for :type/Date"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,
4,5,$CLJS.R,[$CLJS.kt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.fK],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.hK],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.$J],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.kt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ns,$CLJS.Wh],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.OI],null)],null)],null)],null),new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.wj,new $CLJS.h(null,1,[$CLJS.wu,":absolute-datetime literal and unit for :type/DateTime"],
null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.kt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ns,$CLJS.vx],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.jK],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.kt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ns,$CLJS.Wh],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.JI],null)],null)],null)],null)],null)],null));
$CLJS.xG.m(null,$CLJS.yK,function(a){$CLJS.H(a,0,null);$CLJS.H(a,1,null);var b=$CLJS.H(a,2,null);a=$CLJS.H(a,3,null);a=$CLJS.F.g(b,$CLJS.vx)?$CLJS.F.g(a,$CLJS.Wh)?$CLJS.fF:$CLJS.n($CLJS.bH($CLJS.OI,a))?$CLJS.HE:$CLJS.fF:null;if($CLJS.n(a))return a;a="string"===typeof b?$CLJS.n($CLJS.fh($CLJS.nK,b))?$CLJS.HE:$CLJS.n($CLJS.fh($CLJS.oK,b))?$CLJS.HE:null:null;if($CLJS.n(a))return a;b=$CLJS.yG(b);b=$CLJS.ud(b)?$CLJS.Wf.j($CLJS.Ug,$CLJS.af(function(c){return $CLJS.gE(c,$CLJS.Mj)}),b):b;return $CLJS.ud(b)&&
$CLJS.F.g($CLJS.E(b),1)?$CLJS.B(b):b});$CLJS.Y(LK,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.kt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ns,$CLJS.vx],null),$CLJS.pi],null));
$CLJS.lH($CLJS.EK,$CLJS.G([$CLJS.mu,$CLJS.fF,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.My,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,LK],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ki,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ss,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.PI],null)],null)],null)],null)]));
$CLJS.jH($CLJS.wA,$CLJS.G([$CLJS.mu,$CLJS.FF,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.TG],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.NI],null)]));$CLJS.Y(tK,new $CLJS.Q(null,12,5,$CLJS.R,[$CLJS.bk,$CLJS.LI,$CLJS.cl,$CLJS.gl,$CLJS.AK,$CLJS.VK,$CLJS.WK,$CLJS.fl,$CLJS.dl,$CLJS.bl,$CLJS.el,$CLJS.II],null));$CLJS.Y(HK,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.bk,$CLJS.uK,$CLJS.DK,$CLJS.IK],null));
$CLJS.lH($CLJS.QK,$CLJS.G([$CLJS.mu,$CLJS.nj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.FK,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.GG],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ki,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,tK],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ri,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ss,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,HK],null)],null)],
null)],null)]));