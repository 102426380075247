var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.data.js");require("./clojure.set.js");require("./clojure.string.js");require("./malli.core.js");require("./malli.error.js");require("./medley.core.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.util.js");require("./metabase.mbql.normalize.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var e_,Pja,f_,Qja,Rja,Sja,g_,Uja,Vja,Wja,Xja,j_,k_,l_,m_,o_,Zja,p_,q_,$ja,aka,bka,i_,Tja,r_,s_,Yja,u_;e_=function(a,b){var c=$CLJS.z(b);for(a=$CLJS.z($CLJS.Te(a,b));;)if(a)c=$CLJS.C(c),a=$CLJS.C(a);else return c};
Pja=function(){return function(){function a(k,l,m){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.dj.j?$CLJS.dj.j(k,l,m):$CLJS.dj.call(null,k,l,m),$CLJS.hl.j?$CLJS.hl.j(k,l,m):$CLJS.hl.call(null,k,l,m)],null)}function b(k,l){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.dj.g?$CLJS.dj.g(k,l):$CLJS.dj.call(null,k,l),$CLJS.hl.g?$CLJS.hl.g(k,l):$CLJS.hl.call(null,k,l)],null)}function c(k){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.dj.h?$CLJS.dj.h(k):$CLJS.dj.call(null,k),$CLJS.hl.h?$CLJS.hl.h(k):$CLJS.hl.call(null,
k)],null)}function d(){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.dj.o?$CLJS.dj.o():$CLJS.dj.call(null),$CLJS.hl.o?$CLJS.hl.o():$CLJS.hl.call(null)],null)}var e=null,f=function(){function k(m,t,u,v){var x=null;if(3<arguments.length){x=0;for(var A=Array(arguments.length-3);x<A.length;)A[x]=arguments[x+3],++x;x=new $CLJS.y(A,0,null)}return l.call(this,m,t,u,x)}function l(m,t,u,v){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.P.N($CLJS.dj,m,t,u,v),$CLJS.P.N($CLJS.hl,m,t,u,v)],null)}k.A=3;k.B=function(m){var t=
$CLJS.B(m);m=$CLJS.C(m);var u=$CLJS.B(m);m=$CLJS.C(m);var v=$CLJS.B(m);m=$CLJS.Kc(m);return l(t,u,v,m)};k.l=l;return k}();e=function(k,l,m,t){switch(arguments.length){case 0:return d.call(this);case 1:return c.call(this,k);case 2:return b.call(this,k,l);case 3:return a.call(this,k,l,m);default:var u=null;if(3<arguments.length){u=0;for(var v=Array(arguments.length-3);u<v.length;)v[u]=arguments[u+3],++u;u=new $CLJS.y(v,0,null)}return f.l(k,l,m,u)}throw Error("Invalid arity: "+arguments.length);};e.A=
3;e.B=f.B;e.o=d;e.h=c;e.g=b;e.j=a;e.l=f.l;return e}()};f_=function(a){return $CLJS.pN($CLJS.NX)(a,$CLJS.tf,$CLJS.tf)};Qja=function(a,b,c){return $CLJS.ab(function(d,e){var f=$CLJS.H(e,0,null);e=$CLJS.H(e,1,null);return null==e?d:$CLJS.S.j(d,f,e)},null==b?a:$CLJS.S.j(a,$CLJS.JH,b),$CLJS.fu(2,2,c))};Rja=function(a,b){var c=$CLJS.Zl(a,$CLJS.hS);if($CLJS.n(c)){var d=$CLJS.S.j;c=$CLJS.zb(c);b=$CLJS.Gu.g?$CLJS.Gu.g(c,b):$CLJS.Gu.call(null,c,b);a=d.call($CLJS.S,a,$CLJS.hS,b)}return a};
Sja=function(a){return $CLJS.Ye.g($CLJS.mX(a),$CLJS.nX(a))};g_=function(a,b,c){var d=new $CLJS.Tg(null,new $CLJS.h(null,2,[$CLJS.Kt,null,$CLJS.ml,null],null),null),e=$CLJS.n(d.h?d.h(b):d.call(null,b))?$CLJS.Pv(2,c):$CLJS.Pv(1,c);b=$CLJS.n(d.h?d.h(b):d.call(null,b))?e_(2,c):e_(1,c);var f=$CLJS.H(b,0,null);return $CLJS.z(e)?$CLJS.WY.j(a,e,function(k){var l=$CLJS.S.j(k,f,null);return $CLJS.yd(k)?$CLJS.Wf.j($CLJS.tf,$CLJS.Fk.h($CLJS.tl),l):$CLJS.wd(k)?$CLJS.wC($CLJS.Ta,l):l}):$CLJS.wk.g(a,f)};
Uja=function(a){return $CLJS.ab(function(b,c){var d=$CLJS.H(c,0,null);$CLJS.H(c,1,null);return g_(b,Tja,d)},a,Sja(a))};
Vja=function(a){for(var b=0;;){var c=$CLJS.bd($CLJS.RM.h(a),b);a:{var d=c;for(var e=$CLJS.tf;;){var f=$CLJS.B($CLJS.Ze.g(Pja(),$CLJS.bf($CLJS.Ek.j(h_,$CLJS.B,$CLJS.hl),$CLJS.Ot.h(f_(d)))));if($CLJS.n(f)){var k=f;f=$CLJS.H(k,0,null);k=$CLJS.H(k,1,null);var l=g_(d,f,k),m=$CLJS.zC($CLJS.vA);if($CLJS.n($CLJS.yC("metabase.lib.convert",m))){var t=$CLJS.pD.l($CLJS.G([$CLJS.qB,$CLJS.Eh.l($CLJS.G([k]))])),u=$CLJS.pD,v=u.l,x=$CLJS.Eh,A=x.l;var D=f;D=$CLJS.n(D)?D:$CLJS.uN(f_(d));$CLJS.xC("metabase.lib.convert",
m,$CLJS.WX("Clean: Removing bad clause in %s due to error %s:\n%s",$CLJS.G([t,v.call(u,$CLJS.G([$CLJS.qB,A.call(x,$CLJS.G([D]))])),$CLJS.pD.l($CLJS.G([$CLJS.fB,$CLJS.XX($CLJS.B($CLJS.uv(d,l)))]))])),null)}if($CLJS.F.g(l,d))break a;d=l;e=$CLJS.$d.g(e,new $CLJS.Q(null,2,5,$CLJS.R,[f,k],null))}else break a}}d=Uja(d);if($CLJS.F.g(c,d)){if($CLJS.F.g(b,$CLJS.E($CLJS.RM.h(a))-1))return a;b+=1}else a=$CLJS.Hk.N(a,$CLJS.RM,$CLJS.S,b,d)}};
Wja=function(a){var b=$CLJS.jY();return $CLJS.Gk.g(function(c){return $CLJS.F.g($CLJS.HG.h(c),"__join")?$CLJS.Hk.j(c,$CLJS.HG,b):c},a)};Xja=function(a){return"string"===typeof $CLJS.GQ.h(a)?$CLJS.wk.g($CLJS.S.j(a,$CLJS.oX,$CLJS.hY($CLJS.GQ.h(a))),$CLJS.GQ):a};j_=function(a,b){var c=$CLJS.I.j(a,b,i_);if($CLJS.F.g(c,i_))throw $CLJS.ai(["Unable to find ",$CLJS.Eh.l($CLJS.G([b]))," in map."].join(""),new $CLJS.h(null,2,[Yja,a,$CLJS.Fy,b],null));return c};
k_=function(){return $CLJS.Fk.h(function(a){var b=$CLJS.H(a,0,null);$CLJS.H(a,1,null);(a=$CLJS.ke(b))?(b=$CLJS.ge(b),b=$CLJS.n(b)?$CLJS.F.g(b,"lib")||0==b.lastIndexOf("metabase.lib.",0):null):b=a;return b})};l_=function(a){return $CLJS.Wf.j($CLJS.N,k_(),a)};m_=function(a){return $CLJS.De($CLJS.Wf.j($CLJS.N,$CLJS.Ek.g(k_(),$CLJS.Fk.h(function(b){var c=$CLJS.H(b,0,null);$CLJS.H(b,1,null);return $CLJS.F.g(c,$CLJS.xi)})),a))};
o_=function(a){var b=$CLJS.z(a);a=$CLJS.B(b);var c=$CLJS.C(b);b=$CLJS.B(c);c=$CLJS.C(c);var d=$CLJS.Wf.j(new $CLJS.Q(null,1,5,$CLJS.R,[a],null),$CLJS.Ze.h($CLJS.n_),c);a=$CLJS.F.g(a,$CLJS.CH)&&$CLJS.C(c)?$CLJS.$d.g($CLJS.rd(d),new $CLJS.h(null,1,[$CLJS.Wh,$CLJS.qd(d)],null)):d;b=$CLJS.De(m_(b));return $CLJS.n(b)?new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.VN,a,b],null):a};Zja=function(a){return $CLJS.Wf.j($CLJS.tf,$CLJS.Ek.g($CLJS.Ze.h(function(b){return $CLJS.rC(b,$CLJS.uD)}),$CLJS.Ze.h($CLJS.n_)),$CLJS.dY.h(a))};
p_=function(a){a=$CLJS.O(a);a=$CLJS.I.g(a,$CLJS.RM);a=$CLJS.B($CLJS.ab(function(b,c){var d=$CLJS.H(b,0,null),e=$CLJS.H(b,1,null);b=$CLJS.R;var f=$CLJS.n_.h(c);d=$CLJS.n(d)?$CLJS.S.j(f,$CLJS.jS,d):f;d=$CLJS.n(e)?$CLJS.S.j(d,$CLJS.sQ,Zja(e)):d;return new $CLJS.Q(null,2,5,b,[d,$CLJS.OW.h(c)],null)},null,a));return $CLJS.n($CLJS.KR.h(a))?$CLJS.aX(a,new $CLJS.h(null,1,[$CLJS.KR,$CLJS.iO],null)):a};
q_=function(a,b,c){var d=$CLJS.F.g($CLJS.E($CLJS.I.g(a,b)),1)?$CLJS.tY(a,b,$CLJS.Ek.g($CLJS.n_,$CLJS.B)):a;a=1<$CLJS.E($CLJS.I.g(a,b))?$CLJS.tY(d,b,function(e){return $CLJS.Wf.j(new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.qt],null),$CLJS.Ze.h($CLJS.n_),e)}):d;return $CLJS.aX(a,$CLJS.Ce([b,c]))};$ja=function(a){var b=$CLJS.oX.h(a);return $CLJS.n(b)?$CLJS.S.j($CLJS.wk.g(a,$CLJS.oX),$CLJS.GQ,["card__",$CLJS.p.h(b)].join("")):a};
aka=new $CLJS.K(null,"legacy-index-\x3epMBQL-uuid","legacy-index-\x3epMBQL-uuid",-192973336);bka=new $CLJS.K(null,"legacy-ref","legacy-ref",1686404700);i_=new $CLJS.K("metabase.lib.convert","not-found","metabase.lib.convert/not-found",967996550);Tja=new $CLJS.K("metabase.lib.schema","invalid-ref","metabase.lib.schema/invalid-ref",-2010445475);r_=new $CLJS.K("metabase.lib.convert","aggregation-or-expression","metabase.lib.convert/aggregation-or-expression",1924029672);
s_=new $CLJS.K("metabase.lib.convert","expression","metabase.lib.convert/expression",1648102782);$CLJS.t_=new $CLJS.K("lib.convert","converted?","lib.convert/converted?",1937433683);Yja=new $CLJS.K(null,"m","m",1632677161);u_=new $CLJS.K("metabase.lib.convert","aggregation","metabase.lib.convert/aggregation",108290790);var h_,cka,dka,eka,fka,gka,hka,ika,jka,kka,lka;$CLJS.v_=$CLJS.N;$CLJS.w_=$CLJS.N;h_=new $CLJS.Tg(null,new $CLJS.h(null,7,[$CLJS.KX,null,$CLJS.DG,null,$CLJS.eP,null,$CLJS.AR,null,$CLJS.IH,null,$CLJS.pT,null,$CLJS.JH,null],null),null);cka=$CLJS.Oe($CLJS.N);dka=$CLJS.Oe($CLJS.N);eka=$CLJS.Oe($CLJS.N);fka=$CLJS.Oe($CLJS.N);gka=$CLJS.I.j(new $CLJS.h(null,1,[$CLJS.$i,$CLJS.VD],null),$CLJS.$i,$CLJS.Nh.o?$CLJS.Nh.o():$CLJS.Nh.call(null));
$CLJS.x_=new $CLJS.Xh($CLJS.xh.g("metabase.lib.convert","-\x3epMBQL"),$CLJS.TD,gka,cka,dka,eka,fka);$CLJS.x_.m(null,$CLJS.Wh,function(a){if($CLJS.yd(a)&&$CLJS.B(a)instanceof $CLJS.K){var b=$CLJS.qd(a);var c=$CLJS.wd(b);a=c?$CLJS.rd(a):a;var d=$CLJS.z(a);a=$CLJS.B(d);d=$CLJS.C(d);b=$CLJS.RX($CLJS.Wf.j(new $CLJS.Q(null,2,5,$CLJS.R,[a,c?b:$CLJS.N],null),$CLJS.Ze.h($CLJS.x_),d))}else b=a;return b});$CLJS.x_.m(null,$CLJS.IX,function(a){return a});
$CLJS.x_.m(null,$CLJS.vX,function(a){var b=$CLJS.x_.h($CLJS.JH.h(a)),c=$CLJS.De($CLJS.Gk.g(function(t){var u=$CLJS.H(t,0,null);t=$CLJS.H(t,1,null);return $CLJS.aY($CLJS.x_.h(t),u)},$CLJS.AR.h(a))),d=$CLJS.Wf.j($CLJS.N,$CLJS.$l(function(t,u){$CLJS.H(u,0,null);u=$CLJS.H(u,1,null);u=$CLJS.O(u);u=$CLJS.I.g(u,$CLJS.kG);return new $CLJS.Q(null,2,5,$CLJS.R,[t,u],null)}),b),e=$CLJS.Wf.j($CLJS.N,$CLJS.$l(function(t,u){$CLJS.H(u,0,null);u=$CLJS.H(u,1,null);u=$CLJS.O(u);u=$CLJS.I.g(u,$CLJS.kG);return new $CLJS.Q(null,
2,5,$CLJS.R,[u,t],null)}),b),f=$CLJS.w_,k=$CLJS.v_;$CLJS.w_=d;$CLJS.v_=e;try{var l=Qja(Xja(a),b,$CLJS.G([$CLJS.AR,c])),m=$CLJS.ab(function(t,u){return $CLJS.Sa($CLJS.I.g(t,u))?t:$CLJS.Hk.j(t,u,$CLJS.x_)},l,$CLJS.xk.l(h_,$CLJS.JH,$CLJS.G([$CLJS.AR])));return $CLJS.n($CLJS.eP.h(m))?$CLJS.Hk.j(m,$CLJS.eP,Wja):m}finally{$CLJS.v_=k,$CLJS.w_=f}});$CLJS.x_.m(null,$CLJS.CX,function(a){return Rja(a,function(b){return $CLJS.tY(b,$CLJS.KN,$CLJS.x_)})});
$CLJS.x_.m(null,$CLJS.MM,function(a){a=$CLJS.Hk.j($CLJS.Hk.j(a,$CLJS.UM,$CLJS.x_),$CLJS.RM,$CLJS.x_);var b=$CLJS.n($CLJS.DG.h(a))?$CLJS.Hk.j(a,$CLJS.DG,function(c){return $CLJS.Fl(c)?$CLJS.Gk.g($CLJS.x_,c):$CLJS.rh.h(c)}):a;return $CLJS.Sa($CLJS.HG.h(a))?$CLJS.S.j(b,$CLJS.HG,"__join"):b});$CLJS.x_.m(null,$CLJS.aD,function(a){return $CLJS.Gk.g($CLJS.x_,a)});
$CLJS.x_.m(null,$CLJS.cD,function(a){return $CLJS.n($CLJS.dj.h(a))?Vja($CLJS.S.j($CLJS.Hk.j($CLJS.eY(a),$CLJS.RM,function(b){return $CLJS.Gk.g($CLJS.x_,b)}),$CLJS.t_,!0)):$CLJS.Gu(a,$CLJS.x_)});$CLJS.x_.m(null,$CLJS.UH,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);a=$CLJS.wd(b)?new $CLJS.Q(null,2,5,$CLJS.R,[a,b],null):new $CLJS.Q(null,2,5,$CLJS.R,[b,a],null);b=$CLJS.H(a,0,null);a=$CLJS.H(a,1,null);return $CLJS.RX(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.UH,a,b],null))});
$CLJS.x_.m(null,$CLJS.uj,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);a=$CLJS.aX(a,new $CLJS.h(null,3,[$CLJS.UE,$CLJS.gD,$CLJS.eQ,$CLJS.Lj,$CLJS.xT,$CLJS.gG],null));var c=$CLJS.S.j;var d=$CLJS.xi.h(a);$CLJS.n(d)||(d=$CLJS.gD.h(a),d=$CLJS.n(d)?d:$CLJS.yG(b));a=c.call($CLJS.S,a,$CLJS.xi,d);return $CLJS.RX(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uj,a,b],null))});
$CLJS.x_.m(null,$CLJS.CH,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null),c=$CLJS.H(a,2,null);a=$CLJS.Wh.h(c);b=new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.CH,$CLJS.wk.g(c,$CLJS.Wh),$CLJS.Gk.g($CLJS.x_,b)],null);b=$CLJS.RX(b);return null!=a?$CLJS.$d.g(b,$CLJS.x_.h(a)):b});$CLJS.x_.m(null,$CLJS.dD,function(a){var b=$CLJS.H(a,0,null),c=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return $CLJS.RX(new $CLJS.Q(null,3,5,$CLJS.R,[b,a,c],null))});
$CLJS.x_.m(null,$CLJS.JH,function(a){var b=$CLJS.H(a,0,null),c=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return $CLJS.RX(new $CLJS.Q(null,3,5,$CLJS.R,[b,a,j_($CLJS.w_,c)],null))});$CLJS.x_.m(null,$CLJS.VN,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);b=$CLJS.x_.h(b);var c=$CLJS.z(b);b=$CLJS.B(c);var d=$CLJS.C(c);c=$CLJS.B(d);d=$CLJS.C(d);return $CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[b,$CLJS.Wk.l($CLJS.G([c,a]))],null),d)});hka=$CLJS.Oe($CLJS.N);ika=$CLJS.Oe($CLJS.N);
jka=$CLJS.Oe($CLJS.N);kka=$CLJS.Oe($CLJS.N);lka=$CLJS.I.j(new $CLJS.h(null,1,[$CLJS.$i,$CLJS.VD],null),$CLJS.$i,$CLJS.Nh.o?$CLJS.Nh.o():$CLJS.Nh.call(null));$CLJS.n_=new $CLJS.Xh($CLJS.xh.g("metabase.lib.convert","-\x3elegacy-MBQL"),$CLJS.TD,lka,hka,ika,jka,kka);
$CLJS.n_.m(null,$CLJS.Wh,function(a){if($CLJS.yd(a)&&$CLJS.B(a)instanceof $CLJS.K){a=$CLJS.z(a);var b=$CLJS.B(a),c=$CLJS.C(a);a=$CLJS.B(c);var d=$CLJS.C(c);if($CLJS.wd(a)){c=$CLJS.Wf.g;b=new $CLJS.Q(null,1,5,$CLJS.R,[b],null);var e=$CLJS.Ye.g;d=$CLJS.Ze.g($CLJS.n_,d);a=m_(a);a=c.call($CLJS.Wf,b,e.call($CLJS.Ye,d,$CLJS.n(a)?new $CLJS.Q(null,1,5,$CLJS.R,[a],null):null))}else a=$CLJS.Wf.g(new $CLJS.Q(null,1,5,$CLJS.R,[b],null),$CLJS.Ze.g($CLJS.n_,$CLJS.ce(a,d)))}else a=$CLJS.wd(a)?$CLJS.Gu(l_(a),$CLJS.n_):
a;return a});for(var y_=$CLJS.z(new $CLJS.Q(null,2,5,$CLJS.R,[u_,s_],null)),z_=null,A_=0,B_=0;;)if(B_<A_){var mka=z_.X(null,B_);$CLJS.cH(mka,r_);B_+=1}else{var C_=$CLJS.z(y_);if(C_){var D_=C_;if($CLJS.zd(D_)){var E_=$CLJS.kc(D_),nka=$CLJS.lc(D_),oka=E_,pka=$CLJS.E(E_);y_=nka;z_=oka;A_=pka}else{var qka=$CLJS.B(D_);$CLJS.cH(qka,r_);y_=$CLJS.C(D_);z_=null;A_=0}B_=0}else break}
for(var F_=$CLJS.z(new $CLJS.Q(null,12,5,$CLJS.R,[$CLJS.Iz,$CLJS.OH,$CLJS.cI,$CLJS.yH,$CLJS.Zj,$CLJS.hI,$CLJS.Sn,$CLJS.FH,$CLJS.aI,$CLJS.kI,$CLJS.gI,$CLJS.pI],null)),G_=null,H_=0,I_=0;;)if(I_<H_){var rka=G_.X(null,I_);$CLJS.cH(rka,u_);I_+=1}else{var J_=$CLJS.z(F_);if(J_){var K_=J_;if($CLJS.zd(K_)){var L_=$CLJS.kc(K_),ska=$CLJS.lc(K_),tka=L_,uka=$CLJS.E(L_);F_=ska;G_=tka;H_=uka}else{var vka=$CLJS.B(K_);$CLJS.cH(vka,u_);F_=$CLJS.C(K_);G_=null;H_=0}I_=0}else break}
for(var M_=$CLJS.z($CLJS.pf([$CLJS.Qs,$CLJS.mu,$CLJS.Rs,$CLJS.qI,$CLJS.CH,$CLJS.XH,$CLJS.tH,$CLJS.sI,$CLJS.mH,$CLJS.HH,$CLJS.pH,$CLJS.SH,$CLJS.ZH,$CLJS.qH,$CLJS.CK,$CLJS.EK,$CLJS.wA,$CLJS.yK,$CLJS.GK,$CLJS.sK,$CLJS.KK,$CLJS.xK,$CLJS.qK,$CLJS.JK,$CLJS.RK,$CLJS.NK,$CLJS.wK,$CLJS.UK,$CLJS.MK,$CLJS.SK,$CLJS.sH,$CLJS.nH,$CLJS.tI,$CLJS.JJ,$CLJS.WH,$CLJS.Gz,$CLJS.lI,$CLJS.nI,$CLJS.uI,$CLJS.Ku,$CLJS.Su],!0)),N_=null,O_=0,P_=0;;)if(P_<O_){var wka=N_.X(null,P_);$CLJS.cH(wka,s_);P_+=1}else{var Q_=$CLJS.z(M_);
if(Q_){var R_=Q_;if($CLJS.zd(R_)){var S_=$CLJS.kc(R_),xka=$CLJS.lc(R_),yka=S_,zka=$CLJS.E(S_);M_=xka;N_=yka;O_=zka}else{var Aka=$CLJS.B(R_);$CLJS.cH(Aka,s_);M_=$CLJS.C(R_);N_=null;O_=0}P_=0}else break}$CLJS.n_.m(null,r_,function(a){return o_(a)});$CLJS.n_.m(null,$CLJS.cD,function(a){return $CLJS.Wf.j($CLJS.N,$CLJS.Ek.g(k_(),$CLJS.Ze.h(function(b){var c=$CLJS.H(b,0,null);b=$CLJS.H(b,1,null);return new $CLJS.Q(null,2,5,$CLJS.R,[c,$CLJS.n_.h(b)],null)})),a)});
$CLJS.n_.m(null,$CLJS.JH,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null),c=$CLJS.H(a,2,null);if($CLJS.wd(b))try{var d=m_(b),e=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.JH,j_($CLJS.v_,c)],null);return $CLJS.n(d)?$CLJS.$d.g(e,d):e}catch(f){throw b=f,c=$CLJS.UW(b),c=$CLJS.ha.g?$CLJS.ha.g("Error converting aggregation reference to pMBQL: %s",c):$CLJS.ha.call(null,"Error converting aggregation reference to pMBQL: %s",c),$CLJS.$h(c,new $CLJS.h(null,1,[$CLJS.X,a],null),b);}else return a});
$CLJS.n_.m(null,$CLJS.aD,function(a){return $CLJS.Gk.g($CLJS.n_,a)});$CLJS.n_.m(null,$CLJS.UH,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);a=null==b||$CLJS.wd(b)?new $CLJS.Q(null,2,5,$CLJS.R,[b,a],null):new $CLJS.Q(null,2,5,$CLJS.R,[a,b],null);b=$CLJS.H(a,0,null);a=$CLJS.H(a,1,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.UH,$CLJS.n_.h(a),m_(b)],null)});
$CLJS.n_.m(null,$CLJS.uj,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);b=m_($CLJS.aX(b,new $CLJS.h(null,3,[$CLJS.gD,$CLJS.UE,$CLJS.Lj,$CLJS.eQ,$CLJS.gG,$CLJS.xT],null)));return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uj,a,b],null)});
$CLJS.n_.m(null,$CLJS.MM,function(a){var b=l_(a);a=0==$CLJS.HG.h(a).lastIndexOf("__join",0)?$CLJS.wk.g(b,$CLJS.HG):b;return $CLJS.Wk.l($CLJS.G([$CLJS.Gu($CLJS.wk.l(a,$CLJS.RM,$CLJS.G([$CLJS.UM])),$CLJS.n_),q_($CLJS.Yl(a,new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.UM],null)),$CLJS.UM,$CLJS.rT),p_(a)]))});
$CLJS.n_.m(null,$CLJS.vX,function(a){var b=$CLJS.JH.h(a),c=$CLJS.Wf.j($CLJS.N,$CLJS.$l(function(f,k){$CLJS.H(k,0,null);k=$CLJS.H(k,1,null);k=$CLJS.O(k);k=$CLJS.I.g(k,$CLJS.kG);return new $CLJS.Q(null,2,5,$CLJS.R,[f,k],null)}),b);b=$CLJS.Wf.j($CLJS.N,$CLJS.$l(function(f,k){$CLJS.H(k,0,null);k=$CLJS.H(k,1,null);k=$CLJS.O(k);k=$CLJS.I.g(k,$CLJS.kG);return new $CLJS.Q(null,2,5,$CLJS.R,[k,f],null)}),b);var d=$CLJS.w_,e=$CLJS.v_;$CLJS.w_=c;$CLJS.v_=b;try{return $CLJS.ab(function(f,k){return $CLJS.tY(f,
k,$CLJS.n_)},q_($CLJS.tY($CLJS.tY($ja(l_(a)),$CLJS.JH,function(f){return $CLJS.Gk.g(o_,f)}),$CLJS.AR,function(f){return $CLJS.Wf.g($CLJS.N,function(){return function m(l){return new $CLJS.le(null,function(){for(;;){var t=$CLJS.z(l);if(t){if($CLJS.zd(t)){var u=$CLJS.kc(t),v=$CLJS.E(u),x=$CLJS.oe(v);a:for(var A=0;;)if(A<v){var D=$CLJS.kd(u,A),J=$CLJS.n_.h(D);D=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$X(D),$CLJS.F.g($CLJS.uj,$CLJS.B(J))?$CLJS.hd(J):J],null);x.add(D);A+=1}else{u=!0;break a}return u?$CLJS.re($CLJS.te(x),
m($CLJS.lc(t))):$CLJS.re($CLJS.te(x),null)}x=$CLJS.B(t);u=$CLJS.n_.h(x);return $CLJS.ce(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$X(x),$CLJS.F.g($CLJS.uj,$CLJS.B(u))?$CLJS.hd(u):u],null),m($CLJS.Kc(t)))}return null}},null,null)}(f)}())}),$CLJS.KX,$CLJS.xH),$CLJS.xk.l(h_,$CLJS.JH,$CLJS.G([$CLJS.KX,$CLJS.AR])))}finally{$CLJS.v_=e,$CLJS.w_=d}});$CLJS.n_.m(null,$CLJS.CX,function(a){return $CLJS.Gu(l_(a),$CLJS.n_)});
$CLJS.n_.m(null,$CLJS.IX,function(a){try{var b=l_(a),c=$CLJS.WO.h(b),d=p_(b),e=$CLJS.F.g($CLJS.ov.h($CLJS.id($CLJS.RM.h(a))),$CLJS.CX)?$CLJS.KR:$CLJS.iO;return $CLJS.Wk.l($CLJS.G([$CLJS.Gu($CLJS.wk.l(b,$CLJS.RM,$CLJS.G([$CLJS.WO,$CLJS.t_])),$CLJS.n_),function(){var k=$CLJS.Ce([$CLJS.dj,e,e,d]);return $CLJS.z(c)?$CLJS.S.j(k,$CLJS.WO,c):k}()]))}catch(k){var f=k;throw $CLJS.$h(function(){var l=$CLJS.UW(f);return $CLJS.ha.g?$CLJS.ha.g("Error converting MLv2 query to legacy query: %s",l):$CLJS.ha.call(null,
"Error converting MLv2 query to legacy query: %s",l)}(),new $CLJS.h(null,1,[$CLJS.iO,a],null),f);}});
$CLJS.T_=function(){function a(d,e,f){f=$CLJS.d_(null,$CLJS.DB(f,$CLJS.G([$CLJS.si,!0])));var k=$CLJS.gY(d,e);k=$CLJS.O(k);var l=$CLJS.I.g(k,$CLJS.JH);k=$CLJS.Wf.j($CLJS.N,$CLJS.$l(function(v,x){$CLJS.H(x,0,null);x=$CLJS.H(x,1,null);x=$CLJS.O(x);x=$CLJS.I.g(x,$CLJS.kG);return new $CLJS.Q(null,2,5,$CLJS.R,[v,x],null)}),l);l=$CLJS.Wf.j($CLJS.N,$CLJS.$l(function(v,x){$CLJS.H(x,0,null);x=$CLJS.H(x,1,null);x=$CLJS.O(x);x=$CLJS.I.g(x,$CLJS.kG);return new $CLJS.Q(null,2,5,$CLJS.R,[x,v],null)}),l);var m=
$CLJS.w_,t=$CLJS.v_;$CLJS.w_=k;$CLJS.v_=l;try{try{return $CLJS.x_.h(f)}catch(v){var u=v;throw $CLJS.$h(function(){var x=$CLJS.UW(u);return $CLJS.ha.g?$CLJS.ha.g("Error converting legacy ref to pMBQL: %s",x):$CLJS.ha.call(null,"Error converting legacy ref to pMBQL: %s",x)}(),new $CLJS.h(null,4,[$CLJS.iO,d,$CLJS.mY,e,bka,f,aka,$CLJS.w_],null),u);}}finally{$CLJS.v_=t,$CLJS.w_=m}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();