var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.util.malli.registry.js");
'use strict';var YJ,ZJ,uea,vea,wea,cK,dK,eK,gK,xea,iK;YJ=function(a){switch(arguments.length){case 2:return $CLJS.bH(arguments[0],arguments[1]);case 3:return $CLJS.aH(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};ZJ=function(a){return["(?:",$CLJS.p.h($CLJS.P.g($CLJS.p,a)),")?"].join("")};uea=new $CLJS.K("metabase.lib.schema.literal","temporal","metabase.lib.schema.literal/temporal",1227892305);
vea=new $CLJS.K("metabase.lib.schema.literal","non-integer-real","metabase.lib.schema.literal/non-integer-real",1396696695);$CLJS.$J=new $CLJS.K("metabase.lib.schema.literal","string.year","metabase.lib.schema.literal/string.year",-936788792);wea=new $CLJS.K("metabase.lib.schema.literal","string","metabase.lib.schema.literal/string",-1434211409);$CLJS.aK=new $CLJS.K("metabase.lib.schema.literal","time","metabase.lib.schema.literal/time",1928421901);
$CLJS.bK=new $CLJS.K("metabase.lib.schema.literal","string.zone-offset","metabase.lib.schema.literal/string.zone-offset",-437074263);cK=new $CLJS.K("metabase.lib.schema.literal","string.time","metabase.lib.schema.literal/string.time",1501090167);dK=new $CLJS.K("metabase.lib.schema.literal","string.date","metabase.lib.schema.literal/string.date",1716358402);eK=new $CLJS.K("metabase.lib.schema.literal","string.datetime","metabase.lib.schema.literal/string.datetime",-1597850940);
$CLJS.fK=new $CLJS.K("metabase.lib.schema.literal","date","metabase.lib.schema.literal/date",-906463835);gK=new $CLJS.K("metabase.lib.schema.literal","value.options","metabase.lib.schema.literal/value.options",-1918568845);xea=new $CLJS.K("metabase.lib.schema.literal","integer","metabase.lib.schema.literal/integer",-1120986283);$CLJS.hK=new $CLJS.K("metabase.lib.schema.literal","string.year-month","metabase.lib.schema.literal/string.year-month",-1014149016);
iK=new $CLJS.K("metabase.lib.schema.literal","boolean","metabase.lib.schema.literal/boolean",1793502815);$CLJS.jK=new $CLJS.K("metabase.lib.schema.literal","datetime","metabase.lib.schema.literal/datetime",985830761);$CLJS.xG.m(null,$CLJS.jD,function(){return $CLJS.rj});$CLJS.Y(iK,$CLJS.yt);$CLJS.xG.m(null,$CLJS.nD,function(){return $CLJS.Bj});$CLJS.Y(iK,$CLJS.yt);$CLJS.Y(xea,$CLJS.pi);$CLJS.xG.m(null,$CLJS.ZC,function(){return $CLJS.nj});$CLJS.Y(vea,$CLJS.vt);$CLJS.xG.m(null,$CLJS.XC,function(){return $CLJS.CF});$CLJS.Y(wea,$CLJS.Pj);
var yea=[":\\d{2}",ZJ($CLJS.G(["\\.\\d{1,6}"]))].join(""),kK=["\\d{2}:\\d{2}",ZJ($CLJS.G([yea]))].join(""),lK=["\\d{4}-\\d{2}-\\d{2}T",kK].join(""),mK=["(?:Z|(?:[+-]",kK,"))"].join(""),zea=$CLJS.hh(["(?:Z|(?:[+-]",kK,"))"].join("")),Aea=$CLJS.hh("^\\d{4}-\\d{2}-\\d{2}$"),Bea=$CLJS.hh(["^",kK,"$"].join("")),Cea=$CLJS.hh(["^",kK,mK,"$"].join("")),Dea=$CLJS.hh(["^",lK,"$"].join("")),Eea=$CLJS.hh(["^",lK,mK,"$"].join(""));
$CLJS.Y(dK,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.lt,new $CLJS.h(null,1,[$CLJS.wu,"date string literal"],null),Aea],null));$CLJS.Y($CLJS.bK,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.lt,new $CLJS.h(null,1,[$CLJS.wu,"timezone offset string literal"],null),zea],null));
$CLJS.Y(cK,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.kt,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.lt,new $CLJS.h(null,1,[$CLJS.wu,"local time string literal"],null),Bea],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.lt,new $CLJS.h(null,1,[$CLJS.wu,"offset time string literal"],null),Cea],null)],null));
$CLJS.Y(eK,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.kt,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.lt,new $CLJS.h(null,1,[$CLJS.wu,"local date time string literal"],null),Dea],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.lt,new $CLJS.h(null,1,[$CLJS.wu,"offset date time string literal"],null),Eea],null)],null));
$CLJS.xG.m(null,$CLJS.YC,function(a){return $CLJS.n($CLJS.bH?$CLJS.bH(eK,a):YJ.call(null,eK,a))?new $CLJS.Tg(null,new $CLJS.h(null,2,[$CLJS.Uj,null,$CLJS.fF,null],null),null):$CLJS.n($CLJS.bH?$CLJS.bH(dK,a):YJ.call(null,dK,a))?new $CLJS.Tg(null,new $CLJS.h(null,2,[$CLJS.HE,null,$CLJS.Uj,null],null),null):$CLJS.n($CLJS.bH?$CLJS.bH(cK,a):YJ.call(null,cK,a))?new $CLJS.Tg(null,new $CLJS.h(null,2,[$CLJS.FF,null,$CLJS.Uj,null],null),null):$CLJS.Uj});$CLJS.Y($CLJS.fK,dK);$CLJS.Y($CLJS.aK,cK);
$CLJS.Y($CLJS.jK,eK);$CLJS.Y(uea,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.kt,$CLJS.fK,$CLJS.aK,$CLJS.jK],null));$CLJS.nK=$CLJS.hh("^\\d{4}-\\d{2}$");$CLJS.Y($CLJS.hK,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.lt,new $CLJS.h(null,1,[$CLJS.wu,"year-month string literal"],null),$CLJS.nK],null));$CLJS.oK=$CLJS.hh("^\\d{4}$");$CLJS.Y($CLJS.$J,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.lt,new $CLJS.h(null,1,[$CLJS.wu,"year string literal"],null),$CLJS.oK],null));
$CLJS.Y(gK,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vu,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.jG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.xi,$CLJS.mG],null)],null)],null));$CLJS.vI.g($CLJS.uj,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.qi,new $CLJS.h(null,1,[$CLJS.wu,"Value :value clause"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ns,$CLJS.uj],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,gK],null),$CLJS.wl],null));