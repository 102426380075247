var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./metabase.util.malli.registry.js");
'use strict';var Ada,Bda,Cda,Dda,Eda,Fda,Gda,Hda,Ida;Ada=function(a,b){a.sort(b||$CLJS.Ca)};Bda=function(a,b){const c=Array(a.length);for(let e=0;e<a.length;e++)c[e]={index:e,value:a[e]};const d=b||$CLJS.Ca;Ada(c,function(e,f){return d(e.value,f.value)||e.index-f.index});for(b=0;b<a.length;b++)a[b]=c[b].value};
$CLJS.FI=function(a){return $CLJS.F.g(a,$CLJS.Hd)?$CLJS.Hd:function(b,c){var d=a.g?a.g(b,c):a.call(null,b,c);return"number"===typeof d?d:$CLJS.n(d)?-1:$CLJS.n(a.g?a.g(c,b):a.call(null,c,b))?1:0}};$CLJS.GI=function(a,b){if($CLJS.z(b)){var c=$CLJS.ue.h?$CLJS.ue.h(b):$CLJS.ue.call(null,b);Bda(c,$CLJS.FI(a));return $CLJS.od($CLJS.z(c),$CLJS.pd(b))}return $CLJS.Jc};$CLJS.HI=new $CLJS.K(null,"second","second",-444702010);$CLJS.II=new $CLJS.K(null,"second-of-minute","second-of-minute",222734326);
$CLJS.JI=new $CLJS.K("metabase.lib.schema.temporal-bucketing","unit.date-time","metabase.lib.schema.temporal-bucketing/unit.date-time",1924841408);$CLJS.KI=new $CLJS.K(null,"millisecond","millisecond",-540123566);$CLJS.LI=new $CLJS.K(null,"year-of-era","year-of-era",682445876);Cda=new $CLJS.K("metabase.lib.schema.temporal-bucketing","unit.date-time.extract","metabase.lib.schema.temporal-bucketing/unit.date-time.extract",1230935001);
Dda=new $CLJS.K("metabase.lib.schema.temporal-bucketing","unit.time","metabase.lib.schema.temporal-bucketing/unit.time",713582593);$CLJS.MI=new $CLJS.K("metabase.lib.schema.temporal-bucketing","unit.date-time.truncate","metabase.lib.schema.temporal-bucketing/unit.date-time.truncate",686665771);$CLJS.NI=new $CLJS.K("metabase.lib.schema.temporal-bucketing","unit.time.interval","metabase.lib.schema.temporal-bucketing/unit.time.interval",-718964325);
$CLJS.OI=new $CLJS.K("metabase.lib.schema.temporal-bucketing","unit.date","metabase.lib.schema.temporal-bucketing/unit.date",1876937510);Eda=new $CLJS.K("metabase.lib.schema.temporal-bucketing","unit.time.extract","metabase.lib.schema.temporal-bucketing/unit.time.extract",-456342719);Fda=new $CLJS.K("metabase.lib.schema.temporal-bucketing","unit.date.truncate","metabase.lib.schema.temporal-bucketing/unit.date.truncate",1199064479);
Gda=new $CLJS.K("metabase.lib.schema.temporal-bucketing","unit.date.extract","metabase.lib.schema.temporal-bucketing/unit.date.extract",1595055425);Hda=new $CLJS.K("metabase.lib.schema.temporal-bucketing","option","metabase.lib.schema.temporal-bucketing/option",-1756789401);$CLJS.PI=new $CLJS.K("metabase.lib.schema.temporal-bucketing","unit.date-time.interval","metabase.lib.schema.temporal-bucketing/unit.date-time.interval",659301368);
$CLJS.QI=new $CLJS.K("option","temporal-bucketing","option/temporal-bucketing",-108914562);$CLJS.RI=new $CLJS.K("metabase.lib.schema.temporal-bucketing","unit.date.interval","metabase.lib.schema.temporal-bucketing/unit.date.interval",-1463811525);Ida=new $CLJS.K("metabase.lib.schema.temporal-bucketing","unit.time.truncate","metabase.lib.schema.temporal-bucketing/unit.time.truncate",-948820335);var SI=new $CLJS.Q(null,8,5,$CLJS.R,[$CLJS.dl,$CLJS.fl,$CLJS.vj,$CLJS.ii,$CLJS.gl,$CLJS.cl,$CLJS.ci,$CLJS.LI],null),TI=$CLJS.Yg(SI),Jda,Kda,Lda;$CLJS.Y(Gda,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bk,new $CLJS.h(null,1,[$CLJS.wu,"Valid date extraction unit"],null)],null),TI));var UI=new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.pv,$CLJS.fj,$CLJS.oj,$CLJS.hi,$CLJS.ci],null),VI=$CLJS.Yg(UI);
$CLJS.Y(Fda,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bk,new $CLJS.h(null,1,[$CLJS.wu,"Valid date truncation unit"],null)],null),VI));$CLJS.WI=$CLJS.Wf.j($CLJS.tf,$CLJS.Yk.o(),$CLJS.Ye.g(UI,SI));Jda=$CLJS.Yg($CLJS.WI);$CLJS.Y($CLJS.OI,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bk,new $CLJS.h(null,1,[$CLJS.wu,"Valid date bucketing unit"],null)],null),Jda));var XI=new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.II,$CLJS.el,$CLJS.bl],null),YI=$CLJS.Yg(XI);
$CLJS.Y(Eda,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bk,new $CLJS.h(null,1,[$CLJS.wu,"Valid time extraction unit"],null)],null),YI));var ZI=new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.KI,$CLJS.HI,$CLJS.Li,$CLJS.gk],null),$I=$CLJS.Yg(ZI);$CLJS.Y(Ida,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bk,new $CLJS.h(null,1,[$CLJS.wu,"Valid time truncation unit"],null)],null),$I));$CLJS.aJ=$CLJS.Wf.j($CLJS.tf,$CLJS.Yk.o(),$CLJS.Ye.g(ZI,XI));Kda=$CLJS.Yg($CLJS.aJ);
$CLJS.Y(Dda,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bk,new $CLJS.h(null,1,[$CLJS.wu,"Valid time bucketing unit"],null)],null),Kda));$CLJS.bJ=$CLJS.Wf.j($CLJS.tf,$CLJS.Yk.o(),$CLJS.Ye.l(ZI,UI,$CLJS.G([XI,SI])));Lda=$CLJS.Yg($CLJS.bJ);$CLJS.Y($CLJS.JI,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bk,new $CLJS.h(null,1,[$CLJS.wu,"Valid datetime bucketing unit"],null)],null),$CLJS.bJ));var Mda=$CLJS.$d.g(Lda,$CLJS.Wh);
$CLJS.Y($CLJS.UF,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bk,new $CLJS.h(null,1,[$CLJS.wu,"Valid temporal bucketing unit"],null)],null),Mda));var Nda=$CLJS.qv.g(VI,$I);$CLJS.Y($CLJS.MI,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bk,new $CLJS.h(null,1,[$CLJS.wu,"Valid datetime truncation unit"],null)],null),Nda));$CLJS.cJ=$CLJS.qv.g(TI,YI);$CLJS.Y(Cda,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bk,new $CLJS.h(null,1,[$CLJS.wu,"Valid datetime extraction unit"],null)],null),$CLJS.cJ));
var dJ=$CLJS.$d.g(VI,$CLJS.ci);$CLJS.Y($CLJS.RI,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bk,new $CLJS.h(null,1,[$CLJS.wu,"Valid date interval unit"],null)],null),dJ));$CLJS.Y($CLJS.NI,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bk,new $CLJS.h(null,1,[$CLJS.wu,"Valid time interval unit"],null)],null),$I));var Oda=$CLJS.qv.g(dJ,$I);$CLJS.Y($CLJS.PI,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bk,new $CLJS.h(null,1,[$CLJS.wu,"Valid datetime interval unit"],null)],null),Oda));
$CLJS.Y(Hda,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ov,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ns,$CLJS.QI],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ki,$CLJS.UF],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Wh,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),$CLJS.yt],null)],null));