var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.binning.js");require("./metabase.lib.card.js");require("./metabase.lib.convert.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.expression.js");require("./metabase.lib.join.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.normalize.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.remove_replace.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.shared.util.time.js");require("./metabase.util.js");require("./metabase.util.humanization.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var R7,S7,T7,mpa,npa,Z7,$7,b8,c8,opa,d8,e8,ppa,qpa;$CLJS.Q7=function(a){return $CLJS.S5.h(a)};R7=function(a){return $CLJS.S.j(a,$CLJS.ov,$CLJS.j5)};S7=function(a,b){return $CLJS.F.g($CLJS.$F.h(a),$CLJS.Yl(b,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.oG,$CLJS.ZF,$CLJS.nG],null)))};T7=function(a){var b=new $CLJS.h(null,3,[$CLJS.kG,$CLJS.p.h($CLJS.uG()),$CLJS.gD,$CLJS.gD.h(a),$CLJS.xi,$CLJS.pC($CLJS.xi,$CLJS.gD)(a)],null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.dD,b,$CLJS.T.h(a)],null)};
mpa=function(a){return $CLJS.e2(a,new $CLJS.h(null,1,[$CLJS.oG,$CLJS.rh],null))};
$CLJS.U7=function(a,b){var c=$CLJS.Je(function(e){return $CLJS.fJ(function(f){return $CLJS.F.g($CLJS.I.g(f,e),a)},b)},new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.j0,$CLJS.T],null));if($CLJS.n(c))return c;c=$CLJS.zC($CLJS.vA);if($CLJS.n($CLJS.yC("metabase.lib.field",c))){var d=$CLJS.WF("Invalid :field clause: column {0} does not exist. Found: {1}",$CLJS.G([$CLJS.Eh.l($CLJS.G([a])),$CLJS.Eh.l($CLJS.G([$CLJS.Gk.g($CLJS.j0,b)]))]));d instanceof Error?$CLJS.xC("metabase.lib.field",c,$CLJS.fy(),d):$CLJS.xC("metabase.lib.field",
c,$CLJS.fy.l($CLJS.G([d])),null)}return null};
npa=function(a,b,c){if($CLJS.n(V7))return null;var d=V7;V7=!0;try{var e=$CLJS.N2(a,b),f=$CLJS.n(e)?$CLJS.gY(a,e):$CLJS.gY(a,b),k=function(){var m=$CLJS.W7.h(f);if($CLJS.n(m))return m;m=$CLJS.qC(f,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.OW,$CLJS.dY],null));if($CLJS.n(m))return m;m=$CLJS.n(function(){var u=$CLJS.oX.h(f);if($CLJS.n(u))return u;u=$CLJS.GQ.h(f);if($CLJS.n(u))return u;u=$CLJS.AR.h(f);return $CLJS.n(u)?u:$CLJS.DG.h(f)}())?$CLJS.b2.j(a,b,f):null;if($CLJS.n(m))return m;m=$CLJS.zC($CLJS.vA);if($CLJS.n($CLJS.yC("metabase.lib.field",
m))){var t=$CLJS.WF("Cannot resolve column {0}: stage has no metadata",$CLJS.G([$CLJS.Eh.l($CLJS.G([c]))]));return t instanceof Error?$CLJS.xC("metabase.lib.field",m,$CLJS.fy(),t):$CLJS.xC("metabase.lib.field",m,$CLJS.fy.l($CLJS.G([t])),null)}return null}(),l=function(){var m=$CLJS.z(k);return m?$CLJS.U7(c,k):m}();return $CLJS.n(l)?$CLJS.n(e)?$CLJS.S.j($CLJS.S.j($CLJS.h4($CLJS.wk.l(l,$CLJS.Qi,$CLJS.G([$CLJS.CM,$CLJS.X7,$CLJS.Y7])),null),$CLJS.T,function(){var m=$CLJS.j0.h(l);return $CLJS.n(m)?m:$CLJS.T.h(l)}()),
$CLJS.rM,$CLJS.X_):l:null}finally{V7=d}};
Z7=function(a,b,c){$CLJS.H(c,0,null);var d=$CLJS.H(c,1,null),e=$CLJS.O(d);d=$CLJS.I.g(e,$CLJS.VQ);var f=$CLJS.H(c,2,null);c=$CLJS.Wk.l($CLJS.G([function(){var k=$CLJS.gD.h(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.gD,k],null):null}(),function(){var k=$CLJS.pC($CLJS.xi,$CLJS.gD)(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.xi,k],null):null}(),function(){var k=$CLJS.WS.h(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.X7,k],null):null}(),function(){var k=$CLJS.$H.h(e);return $CLJS.n(k)?new $CLJS.h(null,
1,[$CLJS.Y7,k],null):null}(),$CLJS.Ed(f)?function(){var k=$CLJS.R2(a,b,f);return $CLJS.n(k)?k:new $CLJS.h(null,2,[$CLJS.ov,$CLJS.pM,$CLJS.T,f],null)}():$CLJS.n(d)?new $CLJS.h(null,2,[$CLJS.ov,$CLJS.pM,$CLJS.T,f],null):function(){var k=npa(a,b,f);return $CLJS.n(k)?k:new $CLJS.h(null,2,[$CLJS.ov,$CLJS.pM,$CLJS.T,f],null)}()]));return $CLJS.n(d)?$CLJS.h4(c,d):c};$7=function(a){a=$CLJS.O(a);var b=$CLJS.I.g(a,$CLJS.Y7);return $CLJS.n($CLJS.n(b)?$CLJS.Gd($CLJS.cJ,b):b)?$CLJS.nj:$CLJS.pC($CLJS.xi,$CLJS.gD)(a)};
$CLJS.a8=function(a,b,c,d){$CLJS.H(d,0,null);var e=$CLJS.H(d,1,null),f=$CLJS.O(e),k=$CLJS.I.g(f,$CLJS.kG),l=$CLJS.I.g(f,$CLJS.gD),m=$CLJS.I.g(f,$CLJS.WS),t=$CLJS.I.g(f,$CLJS.xi);e=$CLJS.I.g(f,$CLJS.VQ);var u=$CLJS.I.g(f,$CLJS.fP),v=$CLJS.I.g(f,$CLJS.$H),x=$CLJS.Wk.l;k=new $CLJS.h(null,2,[$CLJS.ov,$CLJS.pM,$CLJS.P1,k],null);f=$CLJS.aG.h(f);a=$CLJS.n(f)?f:$CLJS.t1.j(a,b,d);c=x.call($CLJS.Wk,$CLJS.G([k,c,new $CLJS.h(null,1,[$CLJS.aG,a],null)]));c=$CLJS.n(t)?$CLJS.S.j(c,$CLJS.xi,t):c;l=$CLJS.n(l)?$CLJS.S.j(c,
$CLJS.gD,l):c;v=$CLJS.n(v)?$CLJS.S.j(l,$CLJS.Y7,v):l;m=$CLJS.n(m)?$CLJS.S.j(v,$CLJS.X7,m):v;u=$CLJS.n(u)?$CLJS.S.j(m,$CLJS.BM,u):m;return $CLJS.n(e)?$CLJS.h4(u,e):u};b8=function(a,b,c){return $CLJS.n($CLJS.Je(function(d){return $CLJS.F.g($CLJS.ki.h(d),c)},a))?$CLJS.Gk.g(function(d){var e=$CLJS.wk.g(d,b);return $CLJS.F.g($CLJS.ki.h(d),c)?$CLJS.S.j(e,b,!0):e},a):a};
c8=function(a){var b=$CLJS.n($CLJS.y2.h(a))?null:function(){var d=$CLJS.rM.h(a),e=new $CLJS.Tg(null,new $CLJS.h(null,3,[$CLJS.n0,null,$CLJS.t0,null,$CLJS.X_,null],null),null);return e.h?e.h(d):e.call(null,d)}(),c=$CLJS.Wk.l($CLJS.G([new $CLJS.h(null,3,[$CLJS.kG,$CLJS.p.h($CLJS.uG()),$CLJS.gD,$CLJS.gD.h(a),$CLJS.xi,$7(a)],null),function(){var d=$CLJS.r2(a);return $CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.VQ,d],null):null}(),function(){var d=$CLJS.Y7.h(a);return $CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.$H,d],
null):null}(),function(){var d=$CLJS.X7.h(a);return $CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.WS,d],null):null}(),function(){var d=$CLJS.BM.h(a);return $CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.fP,d],null):null}()]));b=($CLJS.n(b)?$CLJS.pC($CLJS.j0,$CLJS.T):$CLJS.pC($CLJS.Qi,$CLJS.T))(a);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.UH,c,b],null)};
opa=function(a,b){return $CLJS.bf(function(c){return $CLJS.F.g($CLJS.rM.h(c),$CLJS.d0)},$CLJS.b2.v(a,b,$CLJS.gY(a,b),new $CLJS.h(null,3,[$CLJS.J1,!1,$CLJS.Q1,!0,$CLJS.G1,!1],null)))};$CLJS.Y7=new $CLJS.K("metabase.lib.field","temporal-unit","metabase.lib.field/temporal-unit",602312727);d8=new $CLJS.K(null,"parent-id","parent-id",-1400729131);$CLJS.W7=new $CLJS.K("metabase.lib.stage","cached-metadata","metabase.lib.stage/cached-metadata",-1406479151);
e8=new $CLJS.K("metabase.lib.field","original-effective-type","metabase.lib.field/original-effective-type",34164372);ppa=new $CLJS.K(null,"earliest","earliest",-1928154382);$CLJS.X7=new $CLJS.K("metabase.lib.field","binning","metabase.lib.field/binning",-222751823);qpa=new $CLJS.K(null,"latest","latest",24323665);var tpa;$CLJS.f2.m(null,$CLJS.UH,function(a){var b=$CLJS.H(a,0,null),c=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.rh.h(b),$CLJS.e2(c,new $CLJS.h(null,2,[$CLJS.$H,$CLJS.rh,$CLJS.WS,mpa],null)),a],null)});var V7=!1,f8=function f8(a,b){var d=$CLJS.k2(a,d8.h(b));a=$CLJS.n(d8.h(d))?f8.g?f8.g(a,d):f8.call(null,a,d):d;a=$CLJS.O(a);var e=$CLJS.I.g(a,$CLJS.T);return $CLJS.Hk.j(b,$CLJS.T,function(f){return[$CLJS.p.h(e),".",$CLJS.p.h(f)].join("")})};
$CLJS.V1.m(null,$CLJS.pM,function(a,b,c){return $7(c)});$CLJS.V1.m(null,$CLJS.UH,function(a,b,c){$CLJS.H(c,0,null);var d=$CLJS.H(c,1,null);d=$CLJS.O(d);d=$CLJS.I.g(d,$CLJS.$H);$CLJS.H(c,2,null);c=Z7(a,b,c);c=$CLJS.n(d)?$CLJS.S.j(c,$CLJS.Y7,d):c;return $CLJS.W1.j(a,b,c)});$CLJS.X1.m(null,$CLJS.pM,function(a,b,c){a=$CLJS.O(c);b=$CLJS.I.g(a,$CLJS.T);return $CLJS.S.j(a,$CLJS.T,b)});$CLJS.X1.m(null,$CLJS.UH,function(a,b,c){var d=Z7(a,b,c);b=$CLJS.a8(a,b,d,c);return $CLJS.n(d8.h(b))?f8(a,b):b});
$CLJS.R1.m(null,$CLJS.pM,function(a,b,c,d){c=$CLJS.O(c);var e=$CLJS.I.g(c,$CLJS.aG),f=$CLJS.I.g(c,$CLJS.T),k=$CLJS.I.g(c,$CLJS.ki),l=$CLJS.I.g(c,$CLJS.X7),m=$CLJS.I.g(c,$CLJS.w0),t=$CLJS.I.g(c,$CLJS.BM),u=$CLJS.I.g(c,$CLJS.CM);e=$CLJS.n(e)?e:"string"===typeof f?$CLJS.p2.g($CLJS.lD,f):$CLJS.p.h(f);$CLJS.F.g(d,$CLJS.u1)&&-1==e.indexOf(" → ")?($CLJS.n(t)?(t=$CLJS.k2(a,t),$CLJS.n(t)?a=(0,$CLJS.ma)($CLJS.tC($CLJS.aG.h($CLJS.w1.j(a,b,t)),$CLJS.qY,"")):(u=$CLJS.q2(a,u),a=$CLJS.t1.v(a,b,u,d))):a=null,m=$CLJS.n(a)?
a:$CLJS.n(m)?m:$CLJS.r2(c)):m=null;m=$CLJS.n(m)?[$CLJS.p.h(m)," → ",$CLJS.p.h(e)].join(""):e;return $CLJS.n(k)?(c=$CLJS.QC($CLJS.tC($CLJS.Zg(k),"-"," ")),$CLJS.ha.j?$CLJS.ha.j("%s: %s",m,c):$CLJS.ha.call(null,"%s: %s",m,c)):$CLJS.n(l)?(c=$CLJS.P5(l,c),$CLJS.ha.j?$CLJS.ha.j("%s: %s",m,c):$CLJS.ha.call(null,"%s: %s",m,c)):m});
$CLJS.R1.m(null,$CLJS.UH,function(a,b,c,d){$CLJS.H(c,0,null);var e=$CLJS.H(c,1,null),f=$CLJS.O(e);e=$CLJS.I.g(f,$CLJS.WS);var k=$CLJS.I.g(f,$CLJS.VQ),l=$CLJS.I.g(f,$CLJS.$H);f=$CLJS.I.g(f,$CLJS.fP);$CLJS.H(c,2,null);c=Z7(a,b,c);c=$CLJS.n(k)?$CLJS.S.j(c,$CLJS.w0,k):c;l=$CLJS.n(l)?$CLJS.S.j(c,$CLJS.ki,l):c;e=$CLJS.n(e)?$CLJS.S.j(l,$CLJS.X7,e):l;e=$CLJS.n(f)?$CLJS.S.j(e,$CLJS.BM,f):e;return $CLJS.n(e)?$CLJS.t1.v(a,b,e,d):$CLJS.vG("[Unknown Field]")});
$CLJS.S1.m(null,$CLJS.pM,function(a,b,c){a=$CLJS.O(c);return $CLJS.I.g(a,$CLJS.T)});$CLJS.S1.m(null,$CLJS.UH,function(a,b,c){$CLJS.H(c,0,null);$CLJS.H(c,1,null);c=Z7(a,b,c);return $CLJS.n(c)?$CLJS.T1.j(a,b,c):"unknown_field"});
$CLJS.Y1.m(null,$CLJS.pM,function(a,b,c){return $CLJS.Wk.l($CLJS.G([function(){var d=$CLJS.Uh($CLJS.Y1,$CLJS.Wh);return d.j?d.j(a,b,c):d.call(null,a,b,c)}(),$CLJS.F.g($CLJS.rM.h(c),$CLJS.n0)?function(){var d=$CLJS.v0.h(c);return $CLJS.n(d)?(d=$CLJS.r1(a,d),$CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.oY,new $CLJS.h(null,2,[$CLJS.T,$CLJS.T.h(d),$CLJS.aG,$CLJS.T.h(d)],null)],null):null):null}():null]))});$CLJS.j3.m(null,$CLJS.UH,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);$CLJS.H(a,2,null);return $CLJS.$H.h(b)});
$CLJS.j3.m(null,$CLJS.pM,function(a){return $CLJS.Y7.h(a)});$CLJS.g3.m(null,$CLJS.UH,function(a,b){$CLJS.H(a,0,null);var c=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);if($CLJS.n(b)){var d=$CLJS.Gd($CLJS.cJ,b),e=$CLJS.eE(e8,$CLJS.xi,$CLJS.gD)(c);c=$CLJS.S.l(c,$CLJS.$H,b,$CLJS.G([$CLJS.xi,d?$CLJS.nj:e,e8,e]));return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.UH,c,a],null)}b=e8.h(c);c=$CLJS.n(b)?$CLJS.wk.g($CLJS.S.j(c,$CLJS.xi,b),e8):c;c=$CLJS.wk.g(c,$CLJS.$H);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.UH,c,a],null)});
$CLJS.g3.m(null,$CLJS.pM,function(a,b){return $CLJS.n(b)?$CLJS.S.j(a,$CLJS.Y7,b):$CLJS.wk.g(a,$CLJS.Y7)});$CLJS.l3.m(null,$CLJS.UH,function(a,b,c){return $CLJS.m3.j(a,b,Z7(a,b,c))});
$CLJS.l3.m(null,$CLJS.pM,function(a,b,c){if($CLJS.Ck.g($CLJS.rM.h(c),$CLJS.d0)){a=$CLJS.pC($CLJS.xi,$CLJS.gD)(c);b=null==c?null:$CLJS.tP.h(c);if(null==b)var d=null;else try{var e=$CLJS.fF.h($CLJS.dj.h(b));if($CLJS.n(e)){var f=$CLJS.O(e),k=$CLJS.I.g(f,ppa),l=$CLJS.I.g(f,qpa),m=$CLJS.lpa.l($CLJS.G([$CLJS.P7.h(k),$CLJS.P7.h(l)]));d=isNaN(m)?null:$CLJS.n($CLJS.Hs.g?$CLJS.Hs.g(1,m):$CLJS.Hs.call(null,1,m))?$CLJS.Li:$CLJS.n($CLJS.Hs.g?$CLJS.Hs.g(31,m):$CLJS.Hs.call(null,31,m))?$CLJS.pv:$CLJS.n($CLJS.Hs.g?
$CLJS.Hs.g(365,m):$CLJS.Hs.call(null,365,m))?$CLJS.fj:$CLJS.oj}else d=null}catch(t){if(t instanceof Error)d=null;else throw t;}e=$CLJS.gE(a,$CLJS.fF)?$CLJS.Sla:$CLJS.gE(a,$CLJS.HE)?$CLJS.Rla:$CLJS.gE(a,$CLJS.FF)?$CLJS.Qla:$CLJS.tf;d=$CLJS.n(d)?b8(e,$CLJS.Wh,d):e;return $CLJS.n($CLJS.Y7.h(c))?b8(d,$CLJS.B1,$CLJS.Y7.h(c)):d}return $CLJS.tf});
$CLJS.S5.m(null,$CLJS.UH,function(a){var b=null==a?null:$CLJS.rG(a);b=null==b?null:$CLJS.WS.h(b);return null==b?null:$CLJS.S.l(b,$CLJS.ov,$CLJS.R5,$CLJS.G([$CLJS.Q5,function(c,d){return Z7(c,d,a)}]))});$CLJS.S5.m(null,$CLJS.pM,function(a){var b=null==a?null:$CLJS.X7.h(a);return null==b?null:$CLJS.S.l(b,$CLJS.ov,$CLJS.R5,$CLJS.G([$CLJS.Q5,$CLJS.Le(a)]))});$CLJS.M5.m(null,$CLJS.UH,function(a,b){return $CLJS.sG(a,$CLJS.qG,$CLJS.G([$CLJS.WS,b]))});
$CLJS.M5.m(null,$CLJS.pM,function(a,b){return $CLJS.qG(a,$CLJS.X7,b)});$CLJS.T5.m(null,$CLJS.UH,function(a,b,c){return $CLJS.U5.j(a,b,Z7(a,b,c))});
$CLJS.T5.m(null,$CLJS.pM,function(a,b,c){b=$CLJS.O(c);c=$CLJS.I.g(b,$CLJS.xi);var d=$CLJS.I.g(b,$CLJS.tP),e=$CLJS.I.g(b,$CLJS.Lj);if($CLJS.Ck.g($CLJS.rM.h(b),$CLJS.d0)){var f=function(){var m=null==a?null:$CLJS.i2($CLJS.o1(a));m=null==m?null:$CLJS.XB.h(m);return null==m?null:$CLJS.Gd(m,$CLJS.WS)}(),k=$CLJS.qC(d,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.dj,$CLJS.Fj],null)),l=$CLJS.Q7(b);return function u(t){return new $CLJS.le(null,function(){for(;;){var v=$CLJS.z(t);if(v){if($CLJS.zd(v)){var x=$CLJS.kc(v),
A=$CLJS.E(x),D=$CLJS.oe(A);return function(){for(var M=0;;)if(M<A){var V=$CLJS.kd(x,M),Z=D,fa=V;V=S7(V,l)?$CLJS.S.j(fa,$CLJS.B1,!0):fa;Z.add(V);M+=1}else return!0}()?$CLJS.re($CLJS.te(D),u($CLJS.lc(v))):$CLJS.re($CLJS.te(D),null)}var J=$CLJS.B(v);return $CLJS.ce(function(){var M=J;return S7(J,l)?$CLJS.S.j(M,$CLJS.B1,!0):M}(),u($CLJS.Kc(v)))}return null}},null,null)}($CLJS.Sa(function(){if($CLJS.n(f)){var t=$CLJS.Sn.h(k);return $CLJS.n(t)?$CLJS.Zj.h(k):t}return f}())?null:$CLJS.gE(e,$CLJS.pj)?$CLJS.Gk.g(R7,
new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.O5(),new $CLJS.h(null,2,[$CLJS.aG,$CLJS.vG("Bin every 0.1 degrees"),$CLJS.$F,new $CLJS.h(null,2,[$CLJS.oG,$CLJS.nG,$CLJS.nG,.1],null)],null),new $CLJS.h(null,2,[$CLJS.aG,$CLJS.vG("Bin every 1 degree"),$CLJS.$F,new $CLJS.h(null,2,[$CLJS.oG,$CLJS.nG,$CLJS.nG,1],null)],null),new $CLJS.h(null,2,[$CLJS.aG,$CLJS.vG("Bin every 10 degrees"),$CLJS.$F,new $CLJS.h(null,2,[$CLJS.oG,$CLJS.nG,$CLJS.nG,10],null)],null),new $CLJS.h(null,2,[$CLJS.aG,$CLJS.vG("Bin every 20 degrees"),
$CLJS.$F,new $CLJS.h(null,2,[$CLJS.oG,$CLJS.nG,$CLJS.nG,20],null)],null)],null)):$CLJS.gE(c,$CLJS.Fj)&&!$CLJS.gE(e,$CLJS.fi)?$CLJS.Gk.g(R7,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.O5(),new $CLJS.h(null,2,[$CLJS.aG,$CLJS.vG("10 bins"),$CLJS.$F,new $CLJS.h(null,2,[$CLJS.oG,$CLJS.ZF,$CLJS.ZF,10],null)],null),new $CLJS.h(null,2,[$CLJS.aG,$CLJS.vG("50 bins"),$CLJS.$F,new $CLJS.h(null,2,[$CLJS.oG,$CLJS.ZF,$CLJS.ZF,50],null)],null),new $CLJS.h(null,2,[$CLJS.aG,$CLJS.vG("100 bins"),$CLJS.$F,new $CLJS.h(null,2,
[$CLJS.oG,$CLJS.ZF,$CLJS.ZF,100],null)],null)],null)):null)}return $CLJS.tf});$CLJS.QX.m(null,$CLJS.UH,function(a){return a});
$CLJS.QX.m(null,$CLJS.pM,function(a){a=$CLJS.O(a);var b=$CLJS.I.g(a,$CLJS.rM);switch(b instanceof $CLJS.K?b.T:null){case "source/aggregations":return b=new $CLJS.h(null,2,[$CLJS.kG,$CLJS.p.h($CLJS.uG()),$CLJS.xi,$CLJS.pC($CLJS.xi,$CLJS.gD)(a)],null),a=$CLJS.P1.h(a),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.JH,b,a],null);case "source/expressions":return T7(a);case "source/breakouts":return $CLJS.Gd(a,$CLJS.LG)?T7(a):c8(a);default:return c8(a)}});
$CLJS.rpa=function(){function a(e,f,k){k=$CLJS.De($CLJS.Gk.g($CLJS.SX,k));var l=opa(e,f),m=$CLJS.Wf.j($CLJS.Ug,$CLJS.Ql(function(t){return $CLJS.W2.v(e,f,t,l)}),$CLJS.n(k)?k:$CLJS.tf);m=$CLJS.Fk.g(m,l);k=$CLJS.n(k)?$CLJS.Wf.j(k,$CLJS.Ze.h($CLJS.SX),m):null;return $CLJS.pY.l(e,f,$CLJS.qG,$CLJS.G([$CLJS.DG,k]))}function b(e,f){return d.j?d.j(e,-1,f):d.call(null,e,-1,f)}function c(e){return function(f,k){return d.j?d.j(f,k,e):d.call(null,f,k,e)}}var d=null;d=function(e,f,k){switch(arguments.length){case 1:return c.call(this,
e);case 2:return b.call(this,e,f);case 3:return a.call(this,e,f,k)}throw Error("Invalid arity: "+arguments.length);};d.h=c;d.g=b;d.j=a;return d}();$CLJS.g8=function(){function a(d,e){return $CLJS.DG.h($CLJS.gY(d,e))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.spa=function(){function a(d,e){var f=$CLJS.b2.v(d,e,$CLJS.gY(d,e),new $CLJS.h(null,3,[$CLJS.J1,!1,$CLJS.Q1,!1,$CLJS.G1,!1],null)),k=$CLJS.g8.g(d,e);return $CLJS.sd(k)?$CLJS.Gk.g(function(l){return $CLJS.S.j(l,$CLJS.l0,!0)},f):$CLJS.e4(d,e,f,k)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
tpa=function(){function a(d,e,f){var k=$CLJS.gY(d,e),l=$CLJS.F.g($CLJS.TD(f),$CLJS.JH)?$CLJS.$1:$CLJS.b2;k=l.j?l.j(d,e,k):l.call(null,d,e,k);return $CLJS.W2.v(d,e,f,k)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.upa=function(){function a(d,e,f){f=$CLJS.T_.j(d,e,f);return tpa.j(d,e,f)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();