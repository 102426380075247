var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var bda,cG,fG,pG;$CLJS.VF=function(a){return $CLJS.Ek.g($CLJS.Ze.h(a),$CLJS.fE)};$CLJS.WF=function(a,b){a=$CLJS.dv($CLJS.tC(a,/''/,"'"),/\{\d+\}/);return $CLJS.P.j($CLJS.Bu.t,$CLJS.Zk(a),$CLJS.Zk(b))};$CLJS.XF=new $CLJS.K("lib","metadata","lib/metadata",1798917220);$CLJS.YF=new $CLJS.K("metabase.lib.schema.common","positive-int","metabase.lib.schema.common/positive-int",-733946956);$CLJS.ZF=new $CLJS.K(null,"num-bins","num-bins",847672055);$CLJS.$F=new $CLJS.K(null,"mbql","mbql",69346710);
$CLJS.aG=new $CLJS.K(null,"display-name","display-name",694513143);bda=new $CLJS.K("metabase.lib.schema.common","external-op","metabase.lib.schema.common/external-op",1918684841);$CLJS.bG=new $CLJS.K("metabase.lib.schema.common","semantic-or-relation-type","metabase.lib.schema.common/semantic-or-relation-type",-158604052);cG=new $CLJS.K("metabase.lib.schema.common","relation-type","metabase.lib.schema.common/relation-type",1412287664);$CLJS.dG=new $CLJS.K("lib","external-op","lib/external-op",-1470923877);
$CLJS.eG=new $CLJS.K("metabase.lib.schema.common","non-blank-string","metabase.lib.schema.common/non-blank-string",-2032366619);fG=new $CLJS.K("metabase.lib.schema.common","semantic-type","metabase.lib.schema.common/semantic-type",-1623124128);$CLJS.gG=new $CLJS.K(null,"database-type","database-type",-426840562);$CLJS.hG=new $CLJS.K(null,"operator","operator",-1860875338);
$CLJS.iG=new $CLJS.K("metabase.lib.schema.common","int-greater-than-or-equal-to-zero","metabase.lib.schema.common/int-greater-than-or-equal-to-zero",-1646435681);$CLJS.jG=new $CLJS.K("metabase.lib.schema.common","options","metabase.lib.schema.common/options",-1078359294);$CLJS.kG=new $CLJS.K("lib","uuid","lib/uuid",-2145250720);$CLJS.lG=new $CLJS.K(null,"x","x",2099068185);$CLJS.mG=new $CLJS.K("metabase.lib.schema.common","base-type","metabase.lib.schema.common/base-type",999524206);
$CLJS.nG=new $CLJS.K(null,"bin-width","bin-width",1377922579);$CLJS.oG=new $CLJS.K(null,"strategy","strategy",-1471631918);pG=new $CLJS.K("metabase.lib.schema.common","uuid","metabase.lib.schema.common/uuid",1172938098);$CLJS.Y($CLJS.eG,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.qt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Pj,new $CLJS.h(null,1,[$CLJS.Sn,1],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ht,new $CLJS.h(null,1,[$CLJS.wu,"non-blank string"],null),$CLJS.Ke($CLJS.uC)],null)],null));$CLJS.Y($CLJS.iG,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.pi,new $CLJS.h(null,1,[$CLJS.Sn,0],null)],null));$CLJS.Y($CLJS.YF,$CLJS.Il);$CLJS.Y(pG,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Pj,new $CLJS.h(null,2,[$CLJS.Sn,36,$CLJS.Zj,36],null)],null));
$CLJS.Y(fG,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ht,new $CLJS.h(null,2,[$CLJS.wu,"valid semantic type",$CLJS.yu,function(a){a=$CLJS.O(a);a=$CLJS.I.g(a,$CLJS.uj);return["Not a valid semantic type: ",$CLJS.Eh.l($CLJS.G([a]))].join("")}],null),function(a){return $CLJS.gE(a,$CLJS.jj)}],null));
$CLJS.Y(cG,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ht,new $CLJS.h(null,2,[$CLJS.wu,"valid relation type",$CLJS.yu,function(a){a=$CLJS.O(a);a=$CLJS.I.g(a,$CLJS.uj);return["Not a valid relation type: ",$CLJS.Eh.l($CLJS.G([a]))].join("")}],null),function(a){return $CLJS.gE(a,$CLJS.fi)}],null));$CLJS.Y($CLJS.bG,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.kt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,fG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,cG],null)],null));
$CLJS.Y($CLJS.mG,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ht,new $CLJS.h(null,2,[$CLJS.wu,"valid base type",$CLJS.yu,function(a){a=$CLJS.O(a);a=$CLJS.I.g(a,$CLJS.uj);return["Not a valid base type: ",$CLJS.Eh.l($CLJS.G([a]))].join("")}],null),function(a){return $CLJS.gE(a,$CLJS.rj)}],null));
$CLJS.Y($CLJS.jG,new $CLJS.Q(null,8,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kG,pG],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gD,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jt,$CLJS.mG],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.xi,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jt,$CLJS.mG],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Lj,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jt,
$CLJS.bG],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gG,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jt,$CLJS.eG],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jt,$CLJS.eG],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.aG,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jt,$CLJS.eG],null)],null)],null));
$CLJS.Y(bda,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ov,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ns,$CLJS.dG],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hG,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.kt,$CLJS.Pj,$CLJS.Oi],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Tt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.qj,$CLJS.zt],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Yi,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),$CLJS.jG],null)],null));