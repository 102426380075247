var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.schema.aggregation.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.expression.arithmetic.js");require("./metabase.lib.schema.expression.conditional.js");require("./metabase.lib.schema.expression.string.js");require("./metabase.lib.schema.expression.temporal.js");require("./metabase.lib.schema.filter.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.join.js");require("./metabase.lib.schema.literal.js");require("./metabase.lib.schema.order_by.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.schema.template_tag.js");require("./metabase.lib.schema.util.js");require("./metabase.mbql.util.js");require("./metabase.mbql.util.match.js");require("./metabase.util.malli.registry.js");
'use strict';var iX,kX,lX,Dia,pX,qX,rX,Fia,sX,tX,uX,wX,xX,yX,zX,AX,BX,DX,EX,FX,GX,HX,LX,MX,Eia;iX=function(a){return $CLJS.Wf.j($CLJS.N,$CLJS.Fk.h(function(b){var c=$CLJS.H(b,0,null);$CLJS.H(b,1,null);return $CLJS.ke(c)}),a)};
$CLJS.jX=function(a){var b=2>$CLJS.E(a);return b?b:$CLJS.P.g($CLJS.mE,function(){return function e(d){return new $CLJS.le(null,function(){for(;;){var f=$CLJS.z(d);if(f){var k=f;if($CLJS.zd(k)){var l=$CLJS.kc(k),m=$CLJS.E(l),t=$CLJS.oe(m);return function(){for(var v=0;;)if(v<m){var x=$CLJS.kd(l,v);$CLJS.se(t,$CLJS.tG(x,function(){return function(A){return $CLJS.wk.l(iX(A),$CLJS.gD,$CLJS.G([$CLJS.xi]))}}(v,x,l,m,t,k,f,b)));v+=1}else return!0}()?$CLJS.re($CLJS.te(t),e($CLJS.lc(k))):$CLJS.re($CLJS.te(t),
null)}var u=$CLJS.B(k);return $CLJS.ce($CLJS.tG(u,function(){return function(v){return $CLJS.wk.l(iX(v),$CLJS.gD,$CLJS.G([$CLJS.xi]))}}(u,k,f,b)),e($CLJS.Kc(k)))}return null}},null,null)}(a)}())};
kX=function(a,b){a=new $CLJS.Q(null,1,5,$CLJS.R,[new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.tf,a],null)],null);for(var c=$CLJS.tf;;){var d=$CLJS.qd(a);if($CLJS.n(d)){var e=d,f=$CLJS.H(e,0,null),k=$CLJS.H(e,1,null),l=e,m=$CLJS.rd(a);a=function(t,u,v,x,A,D,J,M){return function(V){return $CLJS.Wf.j(v,$CLJS.Ze.h(function(Z,fa,Da,Ha,mb){return function(Qb){var Ab=$CLJS.H(Qb,0,null);Qb=$CLJS.H(Qb,1,null);return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$d.g(mb,Ab),Qb],null)}}(t,u,v,x,A,D,J,M)),V)}}(a,c,m,e,f,k,l,d);
$CLJS.n(b.h?b.h(k):b.call(null,k))?(a=m,c=$CLJS.$d.g(c,l)):a=$CLJS.wd(k)?a(k):$CLJS.vd(k)?a($CLJS.Xl($CLJS.Ct,k)):m}else return c}};lX=function(a,b,c){return $CLJS.yd(c)&&$CLJS.F.g(a,$CLJS.B(c))&&!$CLJS.Gd(b,$CLJS.I.g(c,2))};$CLJS.mX=function(a){var b=$CLJS.Wf.j($CLJS.Ug,$CLJS.Ze.h($CLJS.Ek.g($CLJS.LG,$CLJS.hd)),$CLJS.AR.h(a));return kX($CLJS.wk.l(a,$CLJS.eP,$CLJS.G([$CLJS.OW])),function(c){return lX($CLJS.dD,b,c)})};
$CLJS.nX=function(a){var b=$CLJS.Wf.j($CLJS.Ug,$CLJS.Ze.h($CLJS.Ek.g($CLJS.kG,$CLJS.hd)),$CLJS.JH.h(a));return kX($CLJS.wk.l(a,$CLJS.eP,$CLJS.G([$CLJS.OW])),function(c){return lX($CLJS.JH,b,c)})};Dia=function(a){function b(d){return $CLJS.$e(c,$CLJS.G([$CLJS.eP.h(d)]))}function c(d){return $CLJS.ce($CLJS.HG.h(d),$CLJS.$e(b,$CLJS.G([$CLJS.RM.h(d)])))}return $CLJS.n($CLJS.oX.h(a))?$CLJS.Le(!0):$CLJS.Yg(b(a))};
pX=function(a){if($CLJS.vd(a))for(var b=$CLJS.Le(!1),c=$CLJS.z(a),d=$CLJS.B(c),e=$CLJS.C(c),f=b,k=0,l=a;;){var m=f,t=k,u=l,v=$CLJS.z(u),x=$CLJS.B(v),A=$CLJS.C(v),D=x,J=A,M=$CLJS.pC(m,Dia(D));f=$CLJS.B($CLJS.z($CLJS.bf($CLJS.Ta,function(V,Z,fa,Da,Ha,mb){return function Tb(Ab,jc){try{if($CLJS.yd(jc)&&3===$CLJS.E(jc))try{var rc=$CLJS.bd(jc,0);if($CLJS.fe(rc,$CLJS.UH))try{var Vb=$CLJS.bd(jc,1);if($CLJS.n($CLJS.VQ.h(Vb)))try{var Sb=$CLJS.bd(jc,1);if(null!=Sb?Sb.C&256||$CLJS.r===Sb.kf||(Sb.C?0:$CLJS.Wa($CLJS.rb,
Sb)):$CLJS.Wa($CLJS.rb,Sb))try{var Vd=$CLJS.I.j(Sb,$CLJS.VQ,$CLJS.dX);if($CLJS.n($CLJS.Ke(Da)(Vd))){var Iw=$CLJS.I.g(Sb,$CLJS.VQ);$CLJS.bd(jc,2);return new $CLJS.Q(null,1,5,$CLJS.R,[["Invalid :field reference in stage ",$CLJS.p.h(mb),": no join named ",$CLJS.Eh.l($CLJS.G([Iw]))].join("")],null)}throw $CLJS.TW;}catch(og){if(og instanceof Error){var Vi=og;if(Vi===$CLJS.TW)throw $CLJS.TW;throw Vi;}throw og;}else throw $CLJS.TW;}catch(og){if(og instanceof Error){Vi=og;if(Vi===$CLJS.TW)throw $CLJS.TW;
throw Vi;}throw og;}else throw $CLJS.TW;}catch(og){if(og instanceof Error){Vi=og;if(Vi===$CLJS.TW)throw $CLJS.TW;throw Vi;}throw og;}else throw $CLJS.TW;}catch(og){if(og instanceof Error){Vi=og;if(Vi===$CLJS.TW)throw $CLJS.TW;throw Vi;}throw og;}else throw $CLJS.TW;}catch(og){if(og instanceof Error){Vi=og;if(Vi===$CLJS.TW)return $CLJS.bX(Tb,Ab,jc);throw Vi;}throw og;}}}(f,k,l,M,m,t,u,v,x,A,D,J,b,0,a,a,c,d,e,d,e)($CLJS.tf,$CLJS.wk.l(D,$CLJS.eP,$CLJS.G([Eia]))))));if($CLJS.n(f))return f;if($CLJS.z(J))k=
t+1,f=M,l=J;else return null}else return null};qX=new $CLJS.K("metabase.lib.schema","breakouts","metabase.lib.schema/breakouts",1354104361);rX=new $CLJS.w("metabase.lib.schema.util","distinct-refs?","metabase.lib.schema.util/distinct-refs?",-262561159,null);Fia=new $CLJS.K("metabase.lib.schema","stage","metabase.lib.schema/stage",1626908550);sX=new $CLJS.w(null,"distinct-refs?","distinct-refs?",-2065255505,null);
tX=new $CLJS.K("metabase.lib.schema","stage.mbql.with-source-table","metabase.lib.schema/stage.mbql.with-source-table",-2119049176);uX=new $CLJS.K("metabase.lib.schema","stage.mbql.with-source-card","metabase.lib.schema/stage.mbql.with-source-card",-1292215799);$CLJS.vX=new $CLJS.K("mbql.stage","mbql","mbql.stage/mbql",1578747798);wX=new $CLJS.K("metabase.lib.schema","stage.additional","metabase.lib.schema/stage.additional",1347605157);
xX=new $CLJS.K("metabase.lib.schema","stage.mbql.with-source","metabase.lib.schema/stage.mbql.with-source",1947556064);yX=new $CLJS.w(null,"refs","refs",80480079,null);zX=new $CLJS.K("metabase.lib.schema","stage.native","metabase.lib.schema/stage.native",-1206212320);AX=new $CLJS.K("metabase.lib.schema","stage.mbql.without-source","metabase.lib.schema/stage.mbql.without-source",1507072886);BX=new $CLJS.K("metabase.lib.schema","stage.type","metabase.lib.schema/stage.type",1362996639);
$CLJS.CX=new $CLJS.K("mbql.stage","native","mbql.stage/native",359422194);DX=new $CLJS.K("metabase.lib.schema","filters","metabase.lib.schema/filters",889389881);EX=new $CLJS.K("metabase.lib.schema","breakout","metabase.lib.schema/breakout",-921298600);FX=new $CLJS.K("metabase.lib.schema","fields","metabase.lib.schema/fields",-2010307316);GX=new $CLJS.K("metabase.lib.schema","filterable","metabase.lib.schema/filterable",-1640076631);
HX=new $CLJS.K("metabase.lib.schema","stage.valid-refs","metabase.lib.schema/stage.valid-refs",-383625241);$CLJS.IX=new $CLJS.K("mbql","query","mbql/query",-1285688662);$CLJS.oX=new $CLJS.K(null,"source-card","source-card",-1580820390);$CLJS.JX=new $CLJS.K("metabase.lib.schema","query","metabase.lib.schema/query",-1207387140);$CLJS.KX=new $CLJS.K(null,"filters","filters",974726919);LX=new $CLJS.K("metabase.lib.schema","stages.valid-refs","metabase.lib.schema/stages.valid-refs",-193458245);
MX=new $CLJS.K("metabase.lib.schema","stage.initial","metabase.lib.schema/stage.initial",-1483255395);$CLJS.NX=new $CLJS.K("metabase.lib.schema","stage.mbql","metabase.lib.schema/stage.mbql",1051351388);Eia=new $CLJS.K("stage","metadata","stage/metadata",1707239131);$CLJS.Y(zX,new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ov,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ns,$CLJS.CX],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.KR,$CLJS.Ta],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Tt,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.qj,$CLJS.wl],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.NP,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),$CLJS.eG],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.hS,new $CLJS.h(null,1,[$CLJS.Ft,
!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.GW],null)],null)],null));$CLJS.Y(EX,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.YH],null));
$CLJS.Y(qX,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.qt,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.qj,new $CLJS.h(null,1,[$CLJS.Sn,1],null),EX],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ht,new $CLJS.h(null,1,[$CLJS.wu,"Breakouts must be distinct"],null),new $CLJS.Fc(function(){return $CLJS.jX},rX,$CLJS.Ag([$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,$CLJS.Ki,$CLJS.ej,$CLJS.nk,$CLJS.mi,$CLJS.U,$CLJS.ek],[$CLJS.SW,sX,"metabase/lib/schema/util.cljc",21,1,64,64,$CLJS.W(new $CLJS.Q(null,1,5,$CLJS.R,[yX],null)),"Is a sequence of `refs` distinct for the purposes of appearing in `:fields` or `:breakouts` (ignoring keys that\n  aren't important such as namespaced keys and type info)?",
$CLJS.n($CLJS.jX)?$CLJS.jX.H:null]))],null)],null));
$CLJS.Y(FX,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.qt,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.qj,new $CLJS.h(null,1,[$CLJS.Sn,1],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.YH],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ht,new $CLJS.h(null,1,[$CLJS.wu,":fields must be distinct"],null),new $CLJS.Fc(function(){return $CLJS.jX},rX,$CLJS.Ag([$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.ni,$CLJS.Ki,$CLJS.ej,$CLJS.nk,$CLJS.mi,$CLJS.U,$CLJS.ek],[$CLJS.SW,sX,"metabase/lib/schema/util.cljc",21,1,64,64,$CLJS.W(new $CLJS.Q(null,
1,5,$CLJS.R,[yX],null)),"Is a sequence of `refs` distinct for the purposes of appearing in `:fields` or `:breakouts` (ignoring keys that\n  aren't important such as namespaced keys and type info)?",$CLJS.n($CLJS.jX)?$CLJS.jX.H:null]))],null)],null));$CLJS.Y(GX,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.kt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.IG],null),new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.qi,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ns,$CLJS.rH],null),$CLJS.Yj,$CLJS.Pj],null)],null));
$CLJS.Y(DX,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.qj,new $CLJS.h(null,1,[$CLJS.Sn,1],null),GX],null));
var OX=$CLJS.pC(function(a){a=$CLJS.B($CLJS.mX(a));if($CLJS.n(a)){var b=$CLJS.qC(a,new $CLJS.Q(null,2,5,$CLJS.R,[1,2],null));return $CLJS.n(b)?["Invalid :expression reference: no expression named ",$CLJS.Eh.l($CLJS.G([b]))].join(""):["Invalid :expression reference: ",$CLJS.p.h($CLJS.I.g(a,1))].join("")}return null},function(a){a=$CLJS.B($CLJS.nX(a));if($CLJS.n(a)){var b=$CLJS.qC(a,new $CLJS.Q(null,2,5,$CLJS.R,[1,2],null));return $CLJS.n(b)?["Invalid :aggregation reference: no aggregation with uuid ",
$CLJS.p.h(b)].join(""):["Invalid :aggregation reference: ",$CLJS.p.h($CLJS.I.g(a,1))].join("")}return null});$CLJS.Y(HX,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ht,new $CLJS.h(null,2,[$CLJS.wu,"Valid references for a single query stage",$CLJS.yu,function(a){a=$CLJS.O(a);a=$CLJS.I.g(a,$CLJS.uj);return OX(a)}],null),$CLJS.Ke(OX)],null));
$CLJS.Y($CLJS.NX,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.qt,new $CLJS.Q(null,11,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ov,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ns,$CLJS.vX],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.eP,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.PM],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.AR,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.FG],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,
[$CLJS.IH,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),qX],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.JH,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.xI],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.DG,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),FX],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.KX,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),DX],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.pT,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.cN],null)],
null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.GQ,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.uM],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.oX,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.zM],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ht,new $CLJS.h(null,1,[$CLJS.wu,":source-query is not allowed in pMBQL queries."],null),function(a){return!$CLJS.Gd(a,$CLJS.jS)}],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ht,
new $CLJS.h(null,1,[$CLJS.wu,"A query cannot have both a :source-table and a :source-card."],null),$CLJS.Ke($CLJS.oN($CLJS.GQ,$CLJS.oX))],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,HX],null)],null));$CLJS.Y(tX,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vu,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.NX],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.GQ,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.uM],null)],null)],null)],null));
$CLJS.Y(uX,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vu,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.NX],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.oX,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.zM],null)],null)],null)],null));$CLJS.Y(xX,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.kt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,tX],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,uX],null)],null));
$CLJS.Y(AX,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.qt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.NX],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ht,new $CLJS.h(null,1,[$CLJS.wu,"Only the initial stage of a query can have a :source-table or :source-card."],null),$CLJS.Ke($CLJS.pC($CLJS.GQ,$CLJS.oX))],null)],null));$CLJS.Y(BX,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.bk,$CLJS.CX,$CLJS.vX],null));
$CLJS.Y(Fia,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.qt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ov,BX],null)],null),new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.pt,new $CLJS.h(null,1,[$CLJS.Gi,$CLJS.ov],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.CX,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,zX],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.vX,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.NX],null)],null)],null)],null));
$CLJS.Y(MX,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.qt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ov,BX],null)],null),new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.pt,new $CLJS.h(null,1,[$CLJS.Gi,$CLJS.ov],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.CX,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,zX],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.vX,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,xX],null)],null)],null)],null));$CLJS.Y(wX,AX);
$CLJS.Y(LX,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ht,new $CLJS.h(null,2,[$CLJS.wu,"Valid references for all query stages",$CLJS.yu,function(a){a=$CLJS.O(a);a=$CLJS.I.g(a,$CLJS.uj);return pX.h?pX.h(a):pX.call(null,a)}],null),$CLJS.Ke(pX)],null));
$CLJS.Y($CLJS.NM,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.qt,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,MX],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Rs,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,wX],null)],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,LX],null)],null));
$CLJS.Y($CLJS.JX,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.qt,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ov,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ns,$CLJS.IX],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.vR,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.kt,$CLJS.AM,$CLJS.FM],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.RM,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.NM],null)],null)],null),$CLJS.wia],null));