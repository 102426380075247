var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.malli.registry.js");
'use strict';var kda,lda,mda,nda,oda,pda,qda,AI,rda,sda,tda,uda,vda,wda,xda,yda,CI,zda;$CLJS.wI=new $CLJS.K(null,"supported-field","supported-field",-2061545519);kda=new $CLJS.K("mbql.clause","distinct","mbql.clause/distinct",843025748);$CLJS.xI=new $CLJS.K("metabase.lib.schema.aggregation","aggregations","metabase.lib.schema.aggregation/aggregations",-203649172);lda=new $CLJS.K("mbql.clause","count-where","mbql.clause/count-where",2010666417);
mda=new $CLJS.K("mbql.clause","sum-where","mbql.clause/sum-where",-1599647617);$CLJS.yI=new $CLJS.K(null,"requires-column?","requires-column?",1623131448);nda=new $CLJS.K("metabase.lib.schema.aggregation","operator","metabase.lib.schema.aggregation/operator",2070038764);$CLJS.zI=new $CLJS.K(null,"display-info","display-info",-816930907);oda=new $CLJS.K("mbql.clause","max","mbql.clause/max",1615385533);pda=new $CLJS.K("mbql.clause","cum-count","mbql.clause/cum-count",-1408720642);
qda=new $CLJS.K("mbql.clause","median","mbql.clause/median",-1026386338);AI=new $CLJS.K("metabase.lib.schema.aggregation","percentile.percentile","metabase.lib.schema.aggregation/percentile.percentile",-144637711);rda=new $CLJS.K("mbql.clause","min","mbql.clause/min",2074448531);sda=new $CLJS.K("mbql.clause","percentile","mbql.clause/percentile",2064253651);tda=new $CLJS.K("mbql.clause","stddev","mbql.clause/stddev",659381076);uda=new $CLJS.K("mbql.clause","var","mbql.clause/var",809434920);
vda=new $CLJS.K("mbql.clause","share","mbql.clause/share",-37921592);wda=new $CLJS.K("mbql.clause","count","mbql.clause/count",-454225910);xda=new $CLJS.K("mbql.clause","cum-sum","mbql.clause/cum-sum",468410018);$CLJS.BI=new $CLJS.K(null,"driver-feature","driver-feature",1905324248);yda=new $CLJS.K("mbql.clause","avg","mbql.clause/avg",1826863173);CI=new $CLJS.K("metabase.lib.schema.aggregation","aggregation","metabase.lib.schema.aggregation/aggregation",-736862750);
$CLJS.DI=new $CLJS.K("operator","aggregation","operator/aggregation",-1961510924);zda=new $CLJS.K("mbql.clause","sum","mbql.clause/sum",1691012107);$CLJS.lH($CLJS.Iz,$CLJS.G([$CLJS.mu,$CLJS.nj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.dD,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ss,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.JG],null)],null)],null)],null)]));$CLJS.lH($CLJS.VH,$CLJS.G([$CLJS.mu,$CLJS.nj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.dD,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ss,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.JG],null)],null)],null)],null)]));
$CLJS.jH($CLJS.OH,$CLJS.G([$CLJS.mu,$CLJS.CF,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.VG],null)],null)]));$CLJS.jH($CLJS.yH,$CLJS.G([$CLJS.mu,$CLJS.nj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.JG],null)],null)]));$CLJS.jH($CLJS.cI,$CLJS.G([$CLJS.mu,$CLJS.nj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.IG],null)],null)]));
$CLJS.jH($CLJS.Zj,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.QG],null)],null)]));$CLJS.cH($CLJS.Zj,$CLJS.PG);$CLJS.jH($CLJS.hI,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.VG],null)],null)]));$CLJS.cH($CLJS.hI,$CLJS.PG);$CLJS.jH($CLJS.Sn,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.QG],null)],null)]));$CLJS.cH($CLJS.Sn,$CLJS.PG);
$CLJS.Y(AI,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.qt,new $CLJS.h(null,1,[$CLJS.wu,"valid percentile"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.VG],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ht,new $CLJS.h(null,1,[$CLJS.wu,"percentile must be between zero and one"],null),function(a){return 0<=a&&1>=a}],null)],null));$CLJS.jH($CLJS.FH,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.VG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,AI],null)]));$CLJS.cH($CLJS.FH,$CLJS.PG);
$CLJS.jH($CLJS.aI,$CLJS.G([$CLJS.mu,$CLJS.CF,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.IG],null)],null)]));$CLJS.jH($CLJS.kI,$CLJS.G([$CLJS.mu,$CLJS.CF,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.VG],null)],null)]));$CLJS.jH($CLJS.gI,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.VG],null)],null)]));
$CLJS.jH($CLJS.rI,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.VG],null)],null)]));$CLJS.cH($CLJS.gI,$CLJS.PG);$CLJS.cH($CLJS.rI,$CLJS.PG);$CLJS.jH($CLJS.pI,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.VG],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.IG],null)],null)]));$CLJS.cH($CLJS.pI,$CLJS.PG);
$CLJS.jH($CLJS.zH,$CLJS.G([$CLJS.mu,$CLJS.CF,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.VG],null)],null)]));$CLJS.Y(CI,new $CLJS.Q(null,17,5,$CLJS.R,[$CLJS.kt,yda,wda,pda,lda,kda,oda,qda,rda,sda,vda,tda,zda,xda,mda,uda,$CLJS.wl],null));$CLJS.Y($CLJS.xI,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.qj,new $CLJS.h(null,1,[$CLJS.Sn,1],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,CI],null)],null));
$CLJS.EI=new $CLJS.Q(null,10,5,$CLJS.R,[new $CLJS.h(null,4,[$CLJS.NH,$CLJS.Iz,$CLJS.yI,!1,$CLJS.BI,$CLJS.oH,$CLJS.zI,function(){return new $CLJS.h(null,3,[$CLJS.aG,$CLJS.vG("Count of rows"),$CLJS.EH,$CLJS.vG("Count"),$CLJS.Tu,$CLJS.vG("Total number of rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.NH,$CLJS.gI,$CLJS.wI,$CLJS.Wj,$CLJS.yI,!0,$CLJS.BI,$CLJS.oH,$CLJS.zI,function(){return new $CLJS.h(null,3,[$CLJS.aG,$CLJS.vG("Sum of ..."),$CLJS.EH,$CLJS.vG("Sum"),$CLJS.Tu,$CLJS.vG("Sum of all the values of a column.")],
null)}],null),new $CLJS.h(null,5,[$CLJS.NH,$CLJS.OH,$CLJS.wI,$CLJS.Wj,$CLJS.yI,!0,$CLJS.BI,$CLJS.oH,$CLJS.zI,function(){return new $CLJS.h(null,3,[$CLJS.aG,$CLJS.vG("Average of ..."),$CLJS.EH,$CLJS.vG("Average"),$CLJS.Tu,$CLJS.vG("Average of all the values of a column")],null)}],null),new $CLJS.h(null,5,[$CLJS.NH,$CLJS.hI,$CLJS.wI,$CLJS.Wj,$CLJS.yI,!0,$CLJS.BI,$CLJS.QH,$CLJS.zI,function(){return new $CLJS.h(null,3,[$CLJS.aG,$CLJS.vG("Median of ..."),$CLJS.EH,$CLJS.vG("Median"),$CLJS.Tu,$CLJS.vG("Median of all the values of a column")],
null)}],null),new $CLJS.h(null,5,[$CLJS.NH,$CLJS.yH,$CLJS.wI,$CLJS.zt,$CLJS.yI,!0,$CLJS.BI,$CLJS.oH,$CLJS.zI,function(){return new $CLJS.h(null,3,[$CLJS.aG,$CLJS.vG("Number of distinct values of ..."),$CLJS.EH,$CLJS.vG("Distinct values"),$CLJS.Tu,$CLJS.vG("Number of unique values of a column among all the rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.NH,$CLJS.rI,$CLJS.wI,$CLJS.Wj,$CLJS.yI,!0,$CLJS.BI,$CLJS.oH,$CLJS.zI,function(){return new $CLJS.h(null,3,[$CLJS.aG,$CLJS.vG("Cumulative sum of ..."),
$CLJS.EH,$CLJS.vG("Sum"),$CLJS.Tu,$CLJS.vG("Additive sum of all the values of a column.\ne.x. total revenue over time.")],null)}],null),new $CLJS.h(null,4,[$CLJS.NH,$CLJS.VH,$CLJS.yI,!1,$CLJS.BI,$CLJS.oH,$CLJS.zI,function(){return new $CLJS.h(null,3,[$CLJS.aG,$CLJS.vG("Cumulative count of rows"),$CLJS.EH,$CLJS.vG("Count"),$CLJS.Tu,$CLJS.vG("Additive count of the number of rows.\ne.x. total number of sales over time.")],null)}],null),new $CLJS.h(null,5,[$CLJS.NH,$CLJS.kI,$CLJS.wI,$CLJS.Wj,$CLJS.yI,
!0,$CLJS.BI,$CLJS.dI,$CLJS.zI,function(){return new $CLJS.h(null,3,[$CLJS.aG,$CLJS.vG("Standard deviation of ..."),$CLJS.EH,$CLJS.vG("SD"),$CLJS.Tu,$CLJS.vG("Number which expresses how much the values of a column vary among all rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.NH,$CLJS.Sn,$CLJS.wI,$CLJS.Pi,$CLJS.yI,!0,$CLJS.BI,$CLJS.oH,$CLJS.zI,function(){return new $CLJS.h(null,3,[$CLJS.aG,$CLJS.vG("Minimum of ..."),$CLJS.EH,$CLJS.vG("Min"),$CLJS.Tu,$CLJS.vG("Minimum value of a column")],
null)}],null),new $CLJS.h(null,5,[$CLJS.NH,$CLJS.Zj,$CLJS.wI,$CLJS.Pi,$CLJS.yI,!0,$CLJS.BI,$CLJS.oH,$CLJS.zI,function(){return new $CLJS.h(null,3,[$CLJS.aG,$CLJS.vG("Maximum of ..."),$CLJS.EH,$CLJS.vG("Max"),$CLJS.Tu,$CLJS.vG("Maximum value of a column")],null)}],null)],null);
$CLJS.Y(nda,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.Yj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ov,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ns,$CLJS.DI],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.NH,$CLJS.Wf.j(new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.bk],null),$CLJS.Ze.h($CLJS.NH),$CLJS.EI)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.wI,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jt,$CLJS.Oi],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.yI,$CLJS.yt],null),new $CLJS.Q(null,2,5,
$CLJS.R,[$CLJS.BI,$CLJS.Oi],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.zI,$CLJS.md],null)],null));