var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./medley.core.js");require("./metabase.lib.card.js");require("./metabase.lib.convert.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.util.js");require("./metabase.util.malli.js");
'use strict';var L2,nla,P2,Q2,ola,pla,qla,rla,tla,ula,vla,sla,T2,U2;L2=function(a,b){var c=$CLJS.Fd,d=K2;for(a=$CLJS.z(a);;)if(null!=a){d=$CLJS.I.j(d,$CLJS.B(a),c);if(c===d)return b;a=$CLJS.C(a)}else return d};
$CLJS.M2=function(a,b){return function f(d,e){return new $CLJS.le(null,function(){var k=$CLJS.z(e);if(k){if($CLJS.zd(k)){for(var l=$CLJS.kc(k),m=$CLJS.E(l),t=$CLJS.oe(m),u=0;;)if(u<m){var v=function(){var x=d+u,A=$CLJS.kd(l,u);return a.g?a.g(x,A):a.call(null,x,A)}();null!=v&&t.add(v);u+=1}else break;return $CLJS.re($CLJS.te(t),f(d+m,$CLJS.lc(k)))}m=function(){var x=$CLJS.B(k);return a.g?a.g(d,x):a.call(null,d,x)}();return null==m?f(d+1,$CLJS.Kc(k)):$CLJS.ce(m,f(d+1,$CLJS.Kc(k)))}return null},null,
null)}(0,b)};$CLJS.N2=function(a,b){a=$CLJS.fY(a,b);return 0<a?a-1:null};
$CLJS.O2=function(a,b,c,d){a=$CLJS.eY(a);b=$CLJS.n(b)?b:-1;var e=$CLJS.gY(a,b);e=!($CLJS.z($CLJS.JH.h(e))||$CLJS.z($CLJS.IH.h(e)));c=$CLJS.pY.l(a,b,$CLJS.Hk,$CLJS.G([c,function(f){return $CLJS.$d.g($CLJS.uf(f),$CLJS.TX.h(d))}]));return e?$CLJS.Hk.N((0,$CLJS.pY)(c,b,function(f){return $CLJS.tY($CLJS.wk.l(f,$CLJS.pT,$CLJS.G([$CLJS.DG])),$CLJS.eP,function(k){return $CLJS.Gk.g(function(l){return $CLJS.wk.g(l,$CLJS.DG)},k)})}),$CLJS.RM,$CLJS.Ek.g(function(f){return $CLJS.Wf.g($CLJS.tf,f)},$CLJS.Lk),0,
$CLJS.fY(a,b)+1):c};nla=function(a,b){b=$CLJS.r1(a,b);return $CLJS.n(b)?$CLJS.x2(a,b):null};P2=function(a){return $CLJS.Wf.j($CLJS.Ug,$CLJS.Fk.h(function(b){return $CLJS.ke(b)&&$CLJS.Ck.g(b,$CLJS.ov)}),$CLJS.Pg(a))};Q2=function(a){return $CLJS.Wf.j($CLJS.N,$CLJS.$l(function(b,c){$CLJS.H(c,0,null);c=$CLJS.H(c,1,null);c=$CLJS.O(c);c=$CLJS.I.g(c,$CLJS.kG);return new $CLJS.Q(null,2,5,$CLJS.R,[c,b],null)}),$CLJS.JH.h(a))};
$CLJS.R2=function(a,b,c){var d=$CLJS.Wk.l;b=$CLJS.Sa($CLJS.N2(a,b))?function(){var f=$CLJS.oX.h($CLJS.B($CLJS.RM.h(a)));return $CLJS.n(f)?(f=nla(a,f),$CLJS.n(f)?$CLJS.fJ(function(k){return $CLJS.F.g($CLJS.Qi.h(k),c)},f):null):null}():null;try{var e=$CLJS.k2(a,c)}catch(f){e=null}return d.call($CLJS.Wk,$CLJS.G([b,e]))};
ola=function(a,b,c){var d=$CLJS.De($CLJS.bf(function(e){return $CLJS.F.g($CLJS.j0.h(e),a)&&$CLJS.F.g($CLJS.pC($CLJS.y0,$CLJS.w0)(e),b)},c));return $CLJS.n(d)?d:$CLJS.bf(function(e){return $CLJS.F.g($CLJS.T.h(e),a)&&$CLJS.F.g($CLJS.pC($CLJS.y0,$CLJS.w0)(e),b)},c)};
pla=function(a,b,c,d){var e=$CLJS.De($CLJS.bf(function(f){var k=$CLJS.F.g($CLJS.Qi.h(f),a);if(k){if(k=$CLJS.Sa(b)){k=$CLJS.rM.h(f);var l=new $CLJS.Tg(null,new $CLJS.h(null,2,[$CLJS.n0,null,$CLJS.s0,null],null),null);k=l.h?l.h(k):l.call(null,k)}return $CLJS.n(k)?k:$CLJS.F.g($CLJS.pC($CLJS.y0,$CLJS.w0)(f),b)}return k},c));if($CLJS.n(e))return e;c=$CLJS.n(d)?$CLJS.De($CLJS.bf(function(f){return $CLJS.F.g($CLJS.Qi.h(f),a)},c)):null;return $CLJS.n(c)?c:$CLJS.tf};
qla=function(a,b){var c=$CLJS.De($CLJS.Fk.g($CLJS.BM,b));if($CLJS.n(c)){if($CLJS.Sa($CLJS.C(c)))return $CLJS.B(c);throw $CLJS.ai("Ambiguous match! Implement more logic in disambiguate-matches.",new $CLJS.h(null,2,[$CLJS.X,a,$CLJS.dY,b],null));}return null};
rla=function(a,b){b=$CLJS.De($CLJS.Fk.g(function(c){var d=$CLJS.pC($CLJS.y0,$CLJS.w0)(c);$CLJS.n(d)?(c=$CLJS.rM.h(c),d=new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.n0,null],null),null),c=d.h?d.h(c):d.call(null,c),c=$CLJS.Sa(c)):c=d;return c},b));return $CLJS.n(b)?$CLJS.Sa($CLJS.C(b))?$CLJS.B(b):qla(a,b):null};
tla=function(a,b){var c=$CLJS.rG(a);c=$CLJS.O(c);var d=$CLJS.I.g(c,$CLJS.VQ);if($CLJS.n(d)){b=$CLJS.De($CLJS.bf(function(e){return $CLJS.F.g($CLJS.pC($CLJS.y0,$CLJS.w0)(e),d)},b));if($CLJS.n(b)){if($CLJS.Sa($CLJS.C(b)))return $CLJS.B(b);throw $CLJS.ai("Multiple plausible matches with the same :join-alias - more disambiguation needed",new $CLJS.h(null,2,[$CLJS.X,a,sla,b],null));}return null}return rla(a,b)};ula=new $CLJS.K("metabase.lib.equality","no-right","metabase.lib.equality/no-right",2015744769);
vla=new $CLJS.K("metabase.lib.equality","no-left","metabase.lib.equality/no-left",-1403666439);$CLJS.S2=new $CLJS.K(null,"generous?","generous?",1247836118);$CLJS.wla=new $CLJS.K(null,"matching-refs","matching-refs",324693611);sla=new $CLJS.K(null,"matches","matches",635497998);T2=new $CLJS.K(null,"left","left",-399115937);U2=new $CLJS.K("metabase.lib.equality","different-dispatch-values","metabase.lib.equality/different-dispatch-values",-2107733395);var V2=function(){var a=$CLJS.Oe($CLJS.N),b=$CLJS.Oe($CLJS.N),c=$CLJS.Oe($CLJS.N),d=$CLJS.Oe($CLJS.N),e=$CLJS.I.j(new $CLJS.h(null,1,[$CLJS.$i,$CLJS.VD],null),$CLJS.$i,$CLJS.Nh.o?$CLJS.Nh.o():$CLJS.Nh.call(null));return new $CLJS.Xh($CLJS.xh.g("metabase.lib.equality","\x3d"),function(f,k){f=$CLJS.TD(f);k=$CLJS.TD(k);return $CLJS.Ck.g(f,k)?U2:f},e,a,b,c,d)}();V2.m(null,U2,function(){return!1});
V2.m(null,$CLJS.cD,function(a,b){var c=P2(a),d=P2(b);return $CLJS.F.g(c,d)&&$CLJS.Ie(function(e){return V2.g($CLJS.I.g(a,e),$CLJS.I.g(b,e))},c)});V2.m(null,$CLJS.aD,function(a,b){var c=$CLJS.F.g($CLJS.E(a),$CLJS.E(b));if(c)for(c=$CLJS.z(a),$CLJS.B(c),$CLJS.C(c),c=$CLJS.z(b),$CLJS.B(c),$CLJS.C(c),c=a,a=b;;){c=$CLJS.z(c);b=$CLJS.B(c);var d=$CLJS.C(c);c=b;b=d;a=$CLJS.z(a);d=$CLJS.B(a);a=$CLJS.C(a);c=V2.g(c,d);if($CLJS.n(c)){if(c=$CLJS.sd(b))return c;c=b}else return c}else return c});var K2=null;
V2.m(null,$CLJS.vX,function(a,b){var c=K2;K2=new $CLJS.h(null,2,[T2,Q2(a),$CLJS.lz,Q2(b)],null);try{var d=$CLJS.Uh(V2,$CLJS.cD);return d.g?d.g(a,b):d.call(null,a,b)}finally{K2=c}});
V2.m(null,$CLJS.JH,function(a,b){var c=$CLJS.H(a,0,null),d=$CLJS.H(a,1,null),e=$CLJS.H(a,2,null),f=$CLJS.H(b,0,null),k=$CLJS.H(b,1,null),l=$CLJS.H(b,2,null);return(a=$CLJS.F.l(3,$CLJS.E(a),$CLJS.G([$CLJS.E(b)])))?(c=$CLJS.F.g(c,f))?(d=V2.g(d,k),$CLJS.n(d)?$CLJS.n(K2)?$CLJS.F.g(L2(new $CLJS.Q(null,2,5,$CLJS.R,[T2,e],null),vla),L2(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.lz,l],null),ula)):$CLJS.F.g(e,l):d):c:a});
V2.m(null,$CLJS.Wh,function(a,b){if($CLJS.wd(a)){var c=$CLJS.Uh(V2,$CLJS.cD);return c.g?c.g(a,b):c.call(null,a,b)}return $CLJS.vd(a)?(c=$CLJS.Uh(V2,$CLJS.aD),c.g?c.g(a,b):c.call(null,a,b)):$CLJS.F.g(a,b)});
$CLJS.W2=function(){function a(f,k,l,m,t){$CLJS.H(l,0,null);$CLJS.H(l,1,null);var u=$CLJS.H(l,2,null),v=e.j?e.j(l,m,t):e.call(null,l,m,t);return $CLJS.n(v)?v:$CLJS.n($CLJS.n(f)?"number"===typeof u:f)&&(m=$CLJS.De($CLJS.Fk.g($CLJS.Qi,m)),$CLJS.n(m)&&(k=$CLJS.R2(f,k,u),$CLJS.n(k)))?(f=$CLJS.S.j,u=$CLJS.j0.h(k),k=$CLJS.n(u)?u:$CLJS.T.h(k),l=f.call($CLJS.S,l,2,k),e.j?e.j(l,m,t):e.call(null,l,m,t)):null}function b(f,k,l,m){return e.N?e.N(f,k,l,m,$CLJS.N):e.call(null,f,k,l,m,$CLJS.N)}function c(f,k,l){var m=
$CLJS.H(f,0,null),t=$CLJS.H(f,1,null),u=$CLJS.H(f,2,null);l=$CLJS.O(l);l=$CLJS.I.g(l,$CLJS.S2);switch(m instanceof $CLJS.K?m.T:null){case "aggregation":return $CLJS.fJ(function(v){return $CLJS.F.g($CLJS.rM.h(v),$CLJS.KM)&&$CLJS.F.g($CLJS.P1.h(v),u)},k);case "expression":case "field":switch(k="string"===typeof u?ola(u,$CLJS.VQ.h(t),k):pla(u,$CLJS.VQ.h(t),k,l),$CLJS.E(k)){case 0:return null;case 1:return $CLJS.B(k);default:return tla(f,k)}default:throw $CLJS.ai("Unknown type of ref",new $CLJS.h(null,
1,[$CLJS.X,f],null));}}function d(f,k){return e.j?e.j(f,k,$CLJS.N):e.call(null,f,k,$CLJS.N)}var e=null;e=function(f,k,l,m,t){switch(arguments.length){case 2:return d.call(this,f,k);case 3:return c.call(this,f,k,l);case 4:return b.call(this,f,k,l,m);case 5:return a.call(this,f,k,l,m,t)}throw Error("Invalid arity: "+arguments.length);};e.g=d;e.j=c;e.v=b;e.N=a;return e}();
$CLJS.xla=function(){function a(d,e,f,k){return $CLJS.W2.v(d,e,$CLJS.T_.j(d,e,f),k)}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();