var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./medley.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var gJ,hJ,Pda,jJ,Qda,Rda,Sda,kJ,iJ;$CLJS.eJ=function(a,b){return $CLJS.ec($CLJS.ab(function(c,d){var e=a.h?a.h(d):a.call(null,d);return $CLJS.Zf.j(c,e,$CLJS.$d.g($CLJS.I.j(c,e,$CLJS.tf),d))},$CLJS.cc($CLJS.N),b))};$CLJS.fJ=function(a,b){return $CLJS.ab(function(c,d){return $CLJS.n(a.h?a.h(d):a.call(null,d))?$CLJS.Rc(d):null},null,b)};
gJ=function(a,b){$CLJS.H(a,0,null);$CLJS.H(a,1,null);$CLJS.H(a,2,null);a=$CLJS.H(a,3,null);b=$CLJS.gE(b,$CLJS.HE)?$CLJS.RI:$CLJS.gE(b,$CLJS.FF)?$CLJS.NI:$CLJS.gE(b,$CLJS.fF)?$CLJS.PI:null;return $CLJS.n(b)?$CLJS.bH(b,a):!0};
hJ=function(a){a=$CLJS.z(a);$CLJS.B(a);a=$CLJS.C(a);$CLJS.B(a);a=$CLJS.C(a);a=$CLJS.eJ(function(d){return $CLJS.gE($CLJS.yG(d),$CLJS.SE)},a);var b=$CLJS.O(a);a=$CLJS.I.g(b,!1);b=$CLJS.I.g(b,!0);if($CLJS.Ck.g($CLJS.E(a),1))return"Temporal arithmetic expression must contain exactly one non-interval value";if(1>$CLJS.E(b))return"Temporal arithmetic expression must contain at least one :interval";var c=$CLJS.yG($CLJS.B(a));return $CLJS.Je(function(d){$CLJS.H(d,0,null);$CLJS.H(d,1,null);$CLJS.H(d,2,null);
var e=$CLJS.H(d,3,null);return $CLJS.n(gJ(d,c))?null:["Cannot add a ",$CLJS.p.h(e)," interval to a ",$CLJS.p.h(c)," expression"].join("")},b)};
Pda=function(a){return new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.qt,new $CLJS.h(null,1,[$CLJS.wu,[$CLJS.p.h(a)," clause with a temporal expression and one or more :interval clauses"].join("")],null),new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ns,a],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.jG],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ys,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,iJ],null)],
null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.GG],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ys,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,iJ],null)],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ht,new $CLJS.h(null,1,[$CLJS.yu,function(b){b=$CLJS.O(b);b=$CLJS.I.g(b,$CLJS.uj);return["Invalid ",$CLJS.p.h(a)," clause: ",$CLJS.p.h(hJ(b))].join("")}],null),$CLJS.Ke(hJ)],null)],null)};
jJ=function(a){var b=$CLJS.R,c=Pda(a);a=new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.wj,new $CLJS.h(null,1,[$CLJS.wu,[$CLJS.p.h(a)," clause with numeric args"].join("")],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ns,a],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.jG],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Ys,new $CLJS.h(null,1,[$CLJS.Sn,2],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.VG],null)],null)],null)],null);
return new $CLJS.Q(null,3,5,b,[$CLJS.kt,c,a],null)};Qda=function(a){return $CLJS.Od($CLJS.eH,$CLJS.Ze.g(function(b){var c=$CLJS.yG(b),d=$CLJS.gE(c,$CLJS.KG);b=d?$CLJS.bH($CLJS.YH,b):d;return $CLJS.n(b)?$CLJS.Fj:c},a))};Rda=function(a){a=$CLJS.fJ(function(b){return!$CLJS.gE(b,$CLJS.SE)},$CLJS.Ze.g($CLJS.yG,a));return $CLJS.gE(a,$CLJS.KG)?$CLJS.Mj:a};Sda=function(a){return $CLJS.n($CLJS.Je(function(b){return $CLJS.gE($CLJS.yG(b),$CLJS.SE)},a))?Rda(a):Qda(a)};
kJ=new $CLJS.K("metabase.lib.schema.expression.arithmetic","args.numbers","metabase.lib.schema.expression.arithmetic/args.numbers",-179216621);$CLJS.lJ=new $CLJS.K("lib.type-of","type-is-type-of-arithmetic-args","lib.type-of/type-is-type-of-arithmetic-args",-626871615);iJ=new $CLJS.K("mbql.clause","interval","mbql.clause/interval",-990446354);$CLJS.Y(new $CLJS.K("metabase.lib.schema.expression.arithmetic","args.temporal","metabase.lib.schema.expression.arithmetic/args.temporal",1338604923),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.qt,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Ai,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.EG,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.GG],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[new $CLJS.K(null,"intervals","intervals",2096054013),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Qs,new $CLJS.Q(null,
2,5,$CLJS.R,[$CLJS.X,iJ],null)],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ht,new $CLJS.h(null,1,[$CLJS.wu,"Temporal arithmetic expression with valid interval units for the expression type"],null),function(a){var b=$CLJS.z(a);a=$CLJS.B(b);b=$CLJS.C(b);var c=$CLJS.yG(a);return $CLJS.Ie(function(d){return gJ(d,c)},b)}],null)],null));
$CLJS.Y(kJ,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Ys,new $CLJS.h(null,1,[$CLJS.Sn,2],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.VG],null)],null)],null));$CLJS.vI.g($CLJS.Qs,jJ($CLJS.Qs));$CLJS.vI.g($CLJS.mu,jJ($CLJS.mu));$CLJS.lH($CLJS.Rs,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Tt,kJ],null)]));$CLJS.lH($CLJS.qI,$CLJS.G([$CLJS.mu,$CLJS.CF,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Tt,kJ],null)]));
for(var mJ=$CLJS.z(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Qs,$CLJS.mu,$CLJS.Rs],null)),nJ=null,oJ=0,pJ=0;;)if(pJ<oJ){var Tda=nJ.X(null,pJ);$CLJS.cH(Tda,$CLJS.lJ);pJ+=1}else{var qJ=$CLJS.z(mJ);if(qJ){var rJ=qJ;if($CLJS.zd(rJ)){var sJ=$CLJS.kc(rJ),Uda=$CLJS.lc(rJ),Vda=sJ,Wda=$CLJS.E(sJ);mJ=Uda;nJ=Vda;oJ=Wda}else{var Xda=$CLJS.B(rJ);$CLJS.cH(Xda,$CLJS.lJ);mJ=$CLJS.C(rJ);nJ=null;oJ=0}pJ=0}else break}$CLJS.xG.m(null,$CLJS.lJ,function(a){a=$CLJS.z(a);$CLJS.B(a);a=$CLJS.C(a);$CLJS.B(a);a=$CLJS.C(a);return Sda(a)});
$CLJS.jH($CLJS.tH,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.VG],null)],null)]));$CLJS.cH($CLJS.tH,$CLJS.PG);
for(var tJ=$CLJS.z(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.sI,$CLJS.mH,$CLJS.HH],null)),uJ=null,vJ=0,wJ=0;;)if(wJ<vJ){var Yda=uJ.X(null,wJ);$CLJS.jH(Yda,$CLJS.G([$CLJS.mu,$CLJS.CF,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.VG],null)],null)]));wJ+=1}else{var xJ=$CLJS.z(tJ);if(xJ){var yJ=xJ;if($CLJS.zd(yJ)){var zJ=$CLJS.kc(yJ),Zda=$CLJS.lc(yJ),$da=zJ,aea=$CLJS.E(zJ);tJ=Zda;uJ=$da;vJ=aea}else{var bea=$CLJS.B(yJ);$CLJS.jH(bea,$CLJS.G([$CLJS.mu,$CLJS.CF,new $CLJS.Q(null,
2,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.VG],null)],null)]));tJ=$CLJS.C(yJ);uJ=null;vJ=0}wJ=0}else break}
for(var AJ=$CLJS.z(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.pH,$CLJS.SH,$CLJS.ZH],null)),BJ=null,CJ=0,DJ=0;;)if(DJ<CJ){var cea=BJ.X(null,DJ);$CLJS.jH(cea,$CLJS.G([$CLJS.mu,$CLJS.nj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.VG],null)],null)]));DJ+=1}else{var EJ=$CLJS.z(AJ);if(EJ){var FJ=EJ;if($CLJS.zd(FJ)){var GJ=$CLJS.kc(FJ),dea=$CLJS.lc(FJ),eea=GJ,fea=$CLJS.E(GJ);AJ=dea;BJ=eea;CJ=fea}else{var gea=$CLJS.B(FJ);$CLJS.jH(gea,$CLJS.G([$CLJS.mu,$CLJS.nj,new $CLJS.Q(null,
2,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.VG],null)],null)]));AJ=$CLJS.C(FJ);BJ=null;CJ=0}DJ=0}else break}$CLJS.jH($CLJS.qH,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.VG],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.VG],null)],null)]));
$CLJS.xG.m(null,$CLJS.qH,function(a){$CLJS.H(a,0,null);$CLJS.H(a,1,null);var b=$CLJS.H(a,2,null);a=$CLJS.H(a,3,null);return $CLJS.gE($CLJS.yG(b),$CLJS.nj)&&$CLJS.gE($CLJS.yG(a),$CLJS.nj)?$CLJS.nj:$CLJS.CF});